import {
  type Duration,
  format,
  isDate,
  isPast,
  isSameDay,
  isValid,
  parse,
  parseISO,
  sub,
} from "date-fns";

function isAutorenewedOrUnknown(date: Maybe<number | string | Date>) {
  return (
    !date || date === "Auto-renews until further notice" || date === "Unknown"
  );
}

/**
 *
 * @param date - A Date object, or a valid date parameter for parseDate otherwise.
 * @returns {string} - A consistent, string representation of the date.
 */
export function formatDate(
  date: Maybe<number | string | Date>,
  formatType?: string
) {
  if (!date) {
    return "Unknown";
  }

  const parsedDate = parseDate(date);
  if (parsedDate === null) {
    return "Unknown";
  }

  return format(parsedDate, formatType || "yyyy-MM-dd");
}

export function isDateInPast(date: Maybe<number | string | Date>) {
  if (isAutorenewedOrUnknown(date)) return false;

  const parsedDate = parseDate(date);
  if (!parsedDate) return false;
  const utcDate = new Date(
    parsedDate.getUTCFullYear(),
    parsedDate.getUTCMonth(),
    parsedDate.getUTCDate()
  );

  if (isSameDay(utcDate, new Date())) return false;
  return isPast(parsedDate);
}

export function dateWithinThirtyDays(date: Maybe<number | string | Date>) {
  return dateWithinDuration(date, { days: 30 });
}

export function dateWithinDuration(
  date: Maybe<number | string | Date>,
  duration: Duration
) {
  if (isAutorenewedOrUnknown(date)) return false;

  const parsedDate = parseDate(date);
  if (!parsedDate) return false;
  const utcDate = new Date(
    parsedDate.getUTCFullYear(),
    parsedDate.getUTCMonth(),
    parsedDate.getUTCDate()
  );
  return parsedDate && isPast(sub(utcDate, duration));
}

/**
 *
 * @param date
 * @returns {Date|null|Date|*}
 */
export function parseDate(date: Maybe<number | string | Date>): Date | null {
  if (!date || date === "Unknown") {
    return null;
  }
  if (typeof date === "number") {
    if (date === 0) {
      // Generally indicates bad data on our side, rather than a desire to a 1970 date.
      return null;
    }

    const dateObject = new Date(date);
    return isValid(dateObject) ? dateObject : null;
  }

  if (isDate(date)) return date as Date;
  // We know date is a string after the above checks.
  const dateStr = date as string;

  let parseResult: Date;
  parseResult = parseISO(dateStr);
  if (!isValid(parseResult)) {
    parseResult = parse(dateStr, "MM/dd/yyyy", new Date());
  }
  if (!isValid(parseResult)) {
    parseResult = parse(dateStr, "MM-dd-yyyy", new Date());
  }
  if (!isValid(parseResult)) {
    return null;
  }
  return parseResult;
}
