import clsx from "clsx";
import _get from "lodash/get";
import {
  type CSSProperties,
  type ElementType,
  type MouseEventHandler,
  type ReactNode,
  forwardRef,
} from "react";

import { textColorClass } from "../../utils/colors";

import type {
  TypographyColor,
  TypographySize,
  TypographyVariant,
} from "./types";

type ConfigType = {
  weight: string;
  className: string;
  component: string;
};

/* Map variant and sizes to a corresponding component by default and
 * optional extra classes. */
export const VARIANT_CONFIG = {
  display: {
    xl: {
      weight: "font-normal",
      className: "text-cp-display-xl",
      component: "h1",
    },
    lg: {
      weight: "font-normal",
      className: "text-cp-display-lg",
      component: "h1",
    },
    md: {
      weight: "font-normal",
      className: "text-cp-display-md",
      component: "h2",
    },
    sm: {
      weight: "font-medium",
      className: "text-cp-display-sm",
      component: "h3",
    },
    xs: {
      weight: "font-semibold",
      className: "text-cp-display-xs font-semibold uppercase",
      component: "h4",
    },
  },
  monogram: {
    sm: {
      weight: "font-small",
      className: "text-cp-body-xl",
      component: "h3",
    },
    md: {
      weight: "font-medium",
      className: "text-cp-body-xxl",
      component: "h3",
    },
  },
  headline: {
    lg: {
      weight: "font-normal",
      className: "text-cp-headline-lg",
      component: "h1",
    },
    md: {
      weight: "font-normal",
      className: "text-cp-headline-md",
      component: "h2",
    },
    sm: {
      weight: "font-normal",
      className: "text-cp-headline-sm",
      component: "h3",
    },
    xs: {
      weight: "font-normal",
      className: "text-cp-headline-xs",
      component: "h4",
    },
  },
  body: {
    xl: {
      weight: "font-normal",
      className: "text-cp-body-xxl",
      component: "p",
    },
    lg: {
      weight: "font-normal",
      className: "text-cp-body-lg",
      component: "p",
    },
    md: {
      weight: "font-normal",
      className: "text-cp-body-md",
      component: "p",
    },
    sm: {
      weight: "font-normal",
      className: "text-cp-body-sm",
      component: "p",
    },
  },
  meta: {
    md: {
      weight: "font-normal",
      className: "text-cp-meta-md",
      component: "p",
    },
    sm: {
      weight: "font-normal",
      className: "text-cp-meta-sm",
      component: "p",
    },
  },
  cta: {
    md: {
      weight: "font-semibold",
      className: "text-cp-cta-md",
      component: "div",
    },
    sm: {
      weight: "font-semibold",
      className: "text-cp-cta-sm",
      component: "div",
    },
  },
};

export interface TypographyProps {
  children?: ReactNode;
  className?: string;
  color?: TypographyColor;
  component?: ElementType;
  emphasis?: boolean;
  variant?: TypographyVariant;
  size?: TypographySize;
  truncate?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  capitalize?: boolean;
  onClick?: MouseEventHandler;
  onMouseOut?: MouseEventHandler;
  href?: string;
  style?: CSSProperties;
  tabIndex?: number;
  dataTestId?: string;
  htmlFor?: string;
  italic?: boolean;
  title?: string;
}

const Typography = forwardRef(function Typography(
  {
    children,
    className,
    color = "default",
    component,
    emphasis,
    variant = "body",
    size = "md",
    truncate,
    uppercase,
    underline,
    italic,
    capitalize,
    dataTestId,
    htmlFor,
    ...rest
  }: TypographyProps,
  ref
): JSX.Element {
  const config =
    _get(VARIANT_CONFIG, `${variant}.${size}`) || ({} as ConfigType);
  const Component = component || config.component || "div";
  return (
    <Component
      ref={ref}
      className={clsx(
        className,
        _get(textColorClass, color),
        config.className,
        {
          "font-homeHeader":
            ["display", "monogram"].includes(variant) && size !== "xs",
          "font-semibold": emphasis,
          [config.weight]: !emphasis,
          "overflow-hidden whitespace-nowrap text-ellipsis": truncate,
          uppercase,
          underline,
          capitalize,
          italic,
        }
      )}
      data-testid={dataTestId}
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </Component>
  );
});

export default Typography;
