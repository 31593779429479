import Modal, { modalSizes } from "../../shared/Modal/Modal";

import PasswordForm from "./PasswordForm";

interface PasswordModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  title?: string;
}

export default function SetPasswordModal({
  hideModal,
  onComplete,
  isBlocking = true,
}: PasswordModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-login-modal account"
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      title="Welcome to Pavilion!"
      subtitle="Please enter a password"
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <PasswordForm onComplete={onComplete} />
    </Modal>
  );
}
