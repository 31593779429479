/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `AUTOGENERATED` - Autogenerated
 * * `MANUAL_ENTRY` - Manual Entry
 * * `AIRTABLE` - Airtable
 * * `LEGACY_UPWORK` - Legacy Upwork
 * * `FRAGMENTS` - Fragments
 */
export enum SourceEnum {
    AUTOGENERATED = 'AUTOGENERATED',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
    AIRTABLE = 'AIRTABLE',
    LEGACY_UPWORK = 'LEGACY_UPWORK',
    FRAGMENTS = 'FRAGMENTS',
}
