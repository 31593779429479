import { useEffect } from "react";

import { contractDocumentsFilterState } from "../../../jotai/searchFilters";
import { PopoverMenu, RadioButtonGroup } from "../../../library";
import { getParam } from "../../../utils";
import {
  ContractDocumentsFilterOptions,
  searchFilters,
} from "../../../utils/enums";

import { useAtom } from "jotai";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

interface ContractDocumentsFilterProps {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
}

const contractDocumentsFilterOptions = [
  {
    key: ContractDocumentsFilterOptions.ALL_CONTRACTS,
    label: "Show all contracts",
  },
  {
    key: ContractDocumentsFilterOptions.ONLY_WITH_DOCS,
    label: "Show only contracts that have documents available",
  },
];

export default function ContractDocumentsFilter({
  filters,
  onChange,
  onToggleFilter,
}: ContractDocumentsFilterProps) {
  const [contractDocumentsFilter, setContractDocumentsFilter] = useAtom(
    contractDocumentsFilterState
  );

  const onSelectFilter = (selected: string) => {
    const showOnlyWithDocs = filters.includes(
      ContractDocumentsFilterOptions.ONLY_WITH_DOCS
    );
    const selectedOnlyWithDocs =
      selected === ContractDocumentsFilterOptions.ONLY_WITH_DOCS;

    if (!showOnlyWithDocs && selectedOnlyWithDocs) {
      onToggleFilter(true, searchFilters.ONLY_WITH_DOCS);
    } else if (showOnlyWithDocs && !selectedOnlyWithDocs) {
      onToggleFilter(false, searchFilters.ONLY_WITH_DOCS);
    } else {
      onChange({
        type: "contractDocuments",
        value: selected,
      });
    }
    setContractDocumentsFilter(selected as ContractDocumentsFilterOptions);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only rerun when filters change.
  useEffect(() => {
    if (
      filters.includes(searchFilters.ONLY_WITH_DOCS) &&
      contractDocumentsFilter !== ContractDocumentsFilterOptions.ONLY_WITH_DOCS
    ) {
      setContractDocumentsFilter(ContractDocumentsFilterOptions.ONLY_WITH_DOCS);
    }
  }, [filters]);

  return (
    <RadioButtonGroup
      value={contractDocumentsFilter}
      options={contractDocumentsFilterOptions}
      onChange={onSelectFilter}
    />
  );
}

export function ContractDocumentsFilterPopover(
  props: ContractDocumentsFilterProps
) {
  const params = getParam("filters");
  return (
    <PopoverMenu
      text="Contract documents"
      border
      enabled={params.includes(searchFilters.ONLY_WITH_DOCS)}
      noWrap
    >
      <div className="min-w-[452px] flex flex-col gap-4 my-2">
        <ContractDocumentsFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
