import paperPlaneSparkles from "../../../img/welcome/paperPlaneSparkles.svg";
import { Link, Typography } from "../../library";
import Modal from "../../shared/Modal/Modal";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";
import { generateHrefForEmail } from "../../utils/format";

export default function EmailVerificationSuccessModal({
  hideModal,
  showCta = true,
}: {
  hideModal: () => void;
  showCta?: boolean;
}) {
  return (
    <Modal
      contentClassName="flex flex-col text-center gap-6"
      hideModal={hideModal}
      ctaText={showCta ? "I verified my email" : undefined}
      onCtaClick={showCta ? hideModal : undefined}
      ctaSectionClass={showCta ? "flex justify-center" : undefined}
    >
      <Typography
        color="brand.default.secondary.enabled"
        variant="headline"
        emphasis
      >
        Email verification sent
      </Typography>
      <img src={paperPlaneSparkles} className="h-24 self-center" />
      <Typography color="neutral.default.primary.enabled">
        You should receive a link to verify your email in your inbox shortly.
      </Typography>
      <Typography color="neutral.default.primary.enabled">
        If you didn’t receive an email, check the email address you created an
        account with and your spam folder.
      </Typography>
      <Typography color="neutral.default.primary.enabled">
        Contact{" "}
        <Link
          underline={false}
          emphasis={false}
          href={generateHrefForEmail(SUPPORT_EMAIL_ADDRESS)}
        >
          {SUPPORT_EMAIL_ADDRESS}
        </Link>{" "}
        for additional help.
      </Typography>
    </Modal>
  );
}
