/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `keyword` - Keyword
 * * `supplier` - Supplier
 */
export enum SuggestionTypeEnum {
    KEYWORD = 'keyword',
    SUPPLIER = 'supplier',
}
