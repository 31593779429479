import type { Duration } from "date-fns";

import {
  ExpirationDurations,
  type ExpirationFilterType,
  ExpirationStatuses,
} from "../../../utils/enums";

export const FILTER_DURATIONS: Record<
  ExpirationFilterType,
  Duration | undefined
> = {
  [ExpirationDurations.LESS_THAN_6_MONTHS]: { months: 6 },
  [ExpirationDurations.MONTHS_6]: { months: 6 },
  [ExpirationDurations.YEARS_1]: { years: 1 },
  [ExpirationDurations.YEARS_2]: { years: 2 },
  [ExpirationStatuses.ALL_ACTIVE]: undefined,
  [ExpirationStatuses.ACTIVE_AND_EXPIRED]: undefined,
};
