import clsx from "clsx";
import type { ReactNode } from "react";
import { Banner, Link, Typography } from "../../library";

export enum BannerType {
  ANON = "ANON",
  NON_COLLAB = "NON_COLLAB",
  FIRST_VISIT = "FIRST_VISIT",
  BLOG_POST = "BLOG_POST",
}

const COPY: Record<BannerType, { title?: string; subtitle: ReactNode }> = {
  [BannerType.ANON]: {
    title: "Log in or sign up to view more information about this project",
    subtitle:
      "Share contracts and suppliers, edit information about the project, and more.",
  },
  [BannerType.NON_COLLAB]: {
    title: "You have not been invited to this project yet",
    subtitle:
      "Ask the project owner to add you to this project so that you can share contracts and suppliers, edit information about the project, and more.",
  },
  [BannerType.FIRST_VISIT]: {
    title: "You’ve been invited to this project!",
    subtitle:
      "Use this page to share contracts and suppliers with your colleagues, contact suppliers, add notes, and more.",
  },
  [BannerType.BLOG_POST]: {
    subtitle: (
      <Typography>
        To get the most out of your projects, read more on our{" "}
        <Link href="/about/resources/projects-bringing-departments-procurement-teams-together">
          blog
        </Link>
        .
      </Typography>
    ),
  },
};
export default function ProjectDetailBanner({
  type,
  compressed = false,
}: { type: BannerType; compressed?: boolean }) {
  return (
    <Banner
      className={clsx("bg-gradient-to-tr", {
        "mt-6": !compressed,
        "from-cp-persimmon-80 to-cp-violet-300": type !== BannerType.BLOG_POST,
        "from-cp-limeade-250 to-cp-cyan-100": type === BannerType.BLOG_POST,
      })}
    >
      {COPY[type].title && (
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="neutral.boldest.enabled"
        >
          {COPY[type].title}
        </Typography>
      )}
      <Typography color="neutral.boldest.enabled" className="mt-2">
        {COPY[type].subtitle}
      </Typography>
    </Banner>
  );
}
