import { Typography } from "../../../../library";
import SupplierEditFormWrapper from "../SupplierEditFormWrapper";
import type { SupplierEditForm, SupplierServiceAreaValues } from "../types";

export default function SupplierServiceAreaForm({
  onSubmit,
  initialValues,
  fields,
}: SupplierEditForm<SupplierServiceAreaValues>) {
  return (
    <div>
      <Typography>
        Enter in the area where can you do business through shareable contracts.
      </Typography>
      <SupplierEditFormWrapper
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
}
