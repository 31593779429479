import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import useFilteredSearchResults from "../../hooks/search/useFilteredSearchResults";
import {
  entityContractsIsLoadingState,
  entityContractsParamsState,
  entityContractsResponseDataState,
} from "../../jotai/entityContracts";
import {
  expirationFilterState,
  matchedSearchResultCountState,
} from "../../jotai/searchFilters";
import { userZipState } from "../../jotai/user";
import { Link, Table, Typography } from "../../library";
import { expirationText, getContractUrl } from "../../shared/ContractBase";
import { formatDate, isDateInPast } from "../../utils/date";
import EntityContractsSearchSection from "./EntityContractsSearchSection";
import NoContractsBanner from "./NoContractsBanner";
import type { TableRow } from "./types";

export default function EntityContractsTable() {
  const entityContractsResponseData = useAtomValue(
    entityContractsResponseDataState
  );
  const entityContractsIsLoading = useAtomValue(entityContractsIsLoadingState);
  const entityContractsParams = useAtomValue(entityContractsParamsState);
  const filteredSearchResults = useFilteredSearchResults({
    results: entityContractsResponseData?.contractData?.results || [],
    filterLowSimilarityResults: false,
    filterUnresponsiveContacts: false,
    excludeAgencyId: null,
  });
  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);

  const expirationFilter = useAtomValue(expirationFilterState);
  const userZip = useAtomValue(userZipState);

  const matchedHits = useMemo(
    () => filteredSearchResults.slice(0, matchedSearchResultCount),
    [filteredSearchResults, matchedSearchResultCount]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Args to getContractUrl are not used in the effect.
  useEffect(() => {
    if (matchedHits.length > 0) {
      const newRows = matchedHits.map((contract) => {
        const contractUrl = getContractUrl(
          contract.solicitationId,
          contract.docid,
          entityContractsParams?.query,
          userZip,
          false,
          false,
          expirationFilter,
          []
        );

        return {
          contractNumber: contract.contractNumber,
          url: contractUrl.toString(),
          title: contract.title,
          suppliers: contract.suppliersToDisplay?.join(", ") || "",
          expirationDate: formatDate(contract.expirationTimestamp),
        };
      });
      setTableRows(newRows);
    }
  }, [matchedHits]);

  return (
    <div className="flex flex-col gap-6">
      {tableRows.length === 0 && !entityContractsIsLoading && (
        <NoContractsBanner />
      )}
      <EntityContractsSearchSection
        requestID={entityContractsResponseData?.params?.requestId || ""}
      />
      <div className="mt-8 mb-20 overflow-x-auto">
        <Table
          data={tableRows}
          className="w-full"
          columns={[
            {
              key: "contractNumber",
              label: "Contract #",
              isSortable: true,
              render: (v) => v,
            },
            {
              key: "title",
              label: "Contract title",
              isSortable: true,
              render: (_v, { title, url }) => (
                <Link href={url} emphasis={false} underline={false}>
                  {title}
                </Link>
              ),
            },
            {
              key: "suppliers",
              label: "Supplier",
              isSortable: true,
              render: (v) => v,
            },
            {
              key: "expirationDate",
              label: "Expiration date",
              isSortable: true,
              render: (v) => {
                const text = expirationText(v, {
                  formatType: "MMM d, y",
                  showDateOnly: true,
                  showUnknown: true,
                });
                const expired = isDateInPast(v);
                return (
                  <>
                    <Typography
                      color={
                        expired
                          ? "feedback.bold.error"
                          : "neutral.bolder.enabled"
                      }
                    >
                      {text}
                    </Typography>
                    {expired && (
                      <Typography color="feedback.bold.error">
                        (expired)
                      </Typography>
                    )}
                  </>
                );
              },
            },
          ]}
          isLoading={entityContractsIsLoading}
        />
      </div>
    </div>
  );
}
