import type { QuoteRequestRequest } from "../../generated";
import { FormWrapper } from "../../library/form";
import { REQUIREMENT_PAGE_QUESTIONS } from "./constants";

export function QuoteRequestForm({
  handleSubmit,
  isLoading,
}: {
  handleSubmit: (values: QuoteRequestRequest) => void;
  isLoading: boolean;
}) {
  return (
    <FormWrapper
      fields={REQUIREMENT_PAGE_QUESTIONS}
      initialValues={{
        description: "",
        preferredSuppliers: "",
        additionalRequirements: [],
        additionalRequirementsDetails: "",
        requestedCc: [],
        isOpenToComparableProducts: true,
      }}
      onSubmit={handleSubmit}
      submitCta="Submit"
      submitClassName="w-fit"
      disabled={isLoading}
    />
  );
}
