import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { Badge, Typography } from "../../library";

export interface Info {
  header: string;
  body: string;
}
interface EducationInfoProps {
  info: Info[];
}
export default function EducationalInfo({ info }: EducationInfoProps) {
  return (
    <ul className="hidden md:flex flex-col gap-4">
      {info.map(({ header, body }) => (
        <li key={header} className="flex flex-row items-start gap-3">
          <Badge
            Icon={CheckCircleOutlineRoundedIcon}
            iconClass="text-cp-lapis-400 mt-0.5"
            includeMargin={false}
            size="lg"
          />
          <div className="flex flex-col">
            <Typography
              emphasis
              variant="body"
              size="lg"
              color="neutral.default.primary.enabled"
            >
              {header}
            </Typography>
            <Typography
              variant="body"
              size="lg"
              color="neutral.default.primary.enabled"
            >
              {body}
            </Typography>
          </div>
        </li>
      ))}
    </ul>
  );
}
