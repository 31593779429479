import { Typography } from "../../library";
import LoadingHit from "../SearchPage/SearchHit/LoadingHit";

interface LoadingCarouselProps {
  title: string;
  cols?: number;
}

// Loading carousel which displays a list of loading cards
export default function LoadingCarousel({ title, cols }: LoadingCarouselProps) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between w-full items-center h-10 mt-5">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          {title}
        </Typography>
      </div>
      <div
        className={`w-full grid grid-cols-1 auto-rows-auto md:grid-cols-2 xl:grid-cols-${cols} md:items-stretch justify-between gap-6`}
      >
        <LoadingHit className="bg-cp-white-100" />
        <LoadingHit className="bg-cp-white-100" />
        <LoadingHit className="bg-cp-white-100" />
        {cols === 2 && <LoadingHit className="bg-cp-white-100" />}
      </div>
    </div>
  );
}
