import clsx from "clsx";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai";
import { ApiService, PermissionRoleEnum } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { popupState } from "../../../jotai/page";
import { buyerProfileState, userDetailsState } from "../../../jotai/user";
import {
  ActionMenu,
  Avatar,
  AvatarSizes,
  AvatarVariant,
  DropdownPicker,
  Link,
  Tooltip,
  Typography,
} from "../../../library";
import { TableBody } from "../../../library/Table/TableBody";
import { elevationClass } from "../../../utils/designTokens";
import { PopupType, modals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import { trackChangeRole } from "../../../utils/tracking";
import {
  LabelForPermissionRoleToLabel,
  PermissionRoleOptions,
  type TeamMember,
} from "./types";

interface TeamTableProps {
  teamList: TeamMember[];
  numAdmins: number;
  governmentAgencyId: string;
  hasRequestedAdmin: boolean;
}

export default function TeamTable({
  teamList,
  numAdmins,
  governmentAgencyId,
  hasRequestedAdmin,
}: TeamTableProps) {
  const userDetails = useAtomValue(userDetailsState);
  const setPopupState = useSetAtom(popupState);
  const { permissionRole } = useAtomValue(buyerProfileState);
  const isAdmin = permissionRole === PermissionRoleEnum.ADMIN;
  const showChangeOnlyAdmin = useShowModal(modals.CHANGE_ONLY_ADMIN_MODAL);
  const showConfirmChangeAdmin = useShowModal(
    modals.CONFIRM_CHANGE_ADMIN_MODAL
  );

  function resendInvite(email: string) {
    ApiService.apiV1ContactEmailInviteTeamCreate({
      emails: [email],
    });
    setPopupState({
      analyticsClassName:
        "analytics-team-management-resend-invite-success-popup",
      name: PopupType.SUCCESS,
      durationSeconds: 5,
      children: <Typography color="inverse">Invite resent!</Typography>,
      show: true,
    });
  }

  async function changeRole(value: string, email: string, refreshPage = false) {
    if (!userDetails.email) {
      return;
    }
    try {
      await ApiService.apiV1RoleManagementChangeRoleCreate({
        affectedUserEmail: email,
        permissionRole: value as PermissionRoleEnum,
      });
      trackChangeRole({
        agencyId: governmentAgencyId,
        userEmail: userDetails.email,
        affectedUserEmail: email,
        newRole: value,
      });
    } catch (e) {
      handleError(e);
    }
    if (refreshPage) {
      window.location.reload();
    }
  }

  function handleChange(value: string, email: string) {
    // Show modals if current user was an admin and trying to change their own role
    if (
      email !== userDetails.email ||
      (!isAdmin && value === PermissionRoleEnum.ADMIN)
    ) {
      changeRole(value, email);
      return;
    }

    // Different flows if they're the only admin
    if (numAdmins === 1) {
      showChangeOnlyAdmin({
        teamList,
        userEmail: userDetails.email,
        onComplete: () => {
          changeRole(value, email, true);
        },
      });
      return;
    }
    showConfirmChangeAdmin({
      onComplete: () => {
        changeRole(value, email, true);
      },
    });
  }

  return (
    <table title="Team members">
      <TableBody
        columns={[
          {
            key: "name",
            label: "",
            render: (v, { monogram, isInvited, email }) => (
              <div className="flex gap-2 items-center">
                <Avatar
                  size={AvatarSizes.MEDIUM}
                  variant={AvatarVariant.CIRCLE}
                  monogram={monogram.toUpperCase()}
                  className={elevationClass["elevation-1"]}
                  bgColor={
                    isInvited
                      ? "neutral.subtlest.pressed"
                      : "brand.subtler.hovered"
                  }
                  textColor={
                    isInvited
                      ? "neutral.bolder.enabled"
                      : "brand.boldest.enabled"
                  }
                />
                <div>
                  <Typography
                    italic={isInvited}
                    className={clsx("flex items-center")}
                    color={
                      isInvited
                        ? "neutral.bold.enabled"
                        : "neutral.boldest.enabled"
                    }
                  >
                    {v}
                    {email === userDetails.email && (
                      <Typography
                        className="flex items-center ml-1"
                        component="span"
                        color="neutral.bold.enabled"
                      >
                        (You)
                      </Typography>
                    )}
                  </Typography>
                  {!!email && (
                    <div className="flex justify-between">
                      {email.length > 35 ? (
                        <Tooltip info={email}>
                          <Typography
                            color="neutral.bold.enabled"
                            className="flex items-center mt-1"
                            size="sm"
                          >
                            {email.slice(0, 35)}...
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          color="neutral.bold.enabled"
                          className="flex items-center mt-1"
                          size="sm"
                        >
                          {email}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          {
            key: "title",
            label: "",
            render: (_, { email, title }) =>
              !!email && email === userDetails.email && !title ? (
                <Link
                  emphasis={false}
                  newWindow={false}
                  className="flex items-center h-8"
                  href="/profile#myInfo"
                  dataTestId="add-title"
                >
                  Add title
                </Link>
              ) : (
                <Typography
                  color="neutral.bolder.enabled"
                  className="flex items-center h-8"
                >
                  {title}
                </Typography>
              ),
          },
          {
            key: "permissionRole",
            label: "",
            render: (_, { email, permissionRole, isInvited }) =>
              !!email && (
                <div
                  className={clsx("flex", {
                    "justify-end": isInvited,
                  })}
                >
                  {isAdmin && !isInvited ? (
                    <div className="w-72">
                      <DropdownPicker
                        initialValue={permissionRole}
                        onChange={(value) => {
                          handleChange(value, email);
                        }}
                        options={PermissionRoleOptions}
                        dataTestId={`${email}-role-dropdown`}
                      />
                    </div>
                  ) : (
                    <div>
                      <Typography
                        color="neutral.bolder.enabled"
                        className="flex items-center h-8"
                        dataTestId="permissionRole"
                      >
                        {LabelForPermissionRoleToLabel[permissionRole]}
                      </Typography>
                      {email === userDetails.email && hasRequestedAdmin && (
                        <Typography
                          color="neutral.bold.enabled"
                          italic
                          size="sm"
                          variant="meta"
                        >
                          Admin role requested
                        </Typography>
                      )}
                    </div>
                  )}
                  {isInvited && (
                    <ActionMenu
                      align="right"
                      buttonClassName="analytics-team-management-member-menu"
                      ariaLabel={`Menu for ${email}`}
                      items={[
                        {
                          analyticsClassName:
                            "analytics-team-management-member-reinvite",
                          text: "Resend invite",
                          onClick: () => resendInvite(email),
                        },
                      ]}
                    />
                  )}
                </div>
              ),
          },
        ]}
        data={teamList}
      />
    </table>
  );
}
