import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import costSaving from "../../../../img/home/statistics/cost-saving.svg";
import publicEntity from "../../../../img/home/statistics/public-entity.svg";
import timeSaved from "../../../../img/home/statistics/time-saved.svg";
import { Button, Typography } from "../../../library";
import { COMPANY_NAME } from "../../../utils/constants";

const statistic = [
  {
    key: "costSaving",
    image: costSaving,
    statisticText: "10%",
    statisticSubtext:
      "Average cost savings through a shareable and cooperative contract",
  },
  {
    key: "timeSaved",
    image: timeSaved,
    statisticText: "20 hrs",
    statisticSubtext: "Average time saved per week searching for contracts",
  },
  {
    key: "publicEntity",
    image: publicEntity,
    statisticText: "$0",
    statisticSubtext: "Pavilion is free for public entities, always.",
  },
];

interface StatisticsSectionProps {
  search: () => void;
}

export default function StatisticsSection({ search }: StatisticsSectionProps) {
  return (
    <div className="bg-cp-violet-200 items-center">
      <div className="py-12 md:py-16 cp-page-container xl:px-0 m-auto flex flex-col items-center gap-y-9">
        <Typography
          component="h2"
          variant="headline"
          size="lg"
          color="brand.boldest.enabled"
          className="font-homeHeader text-center md:text-cp-display-lg flex"
        >
          Work smarter with {COMPANY_NAME}
        </Typography>
        <div className="grid grid-cols-1 gap-8 md:gap-12 md:grid-cols-3 md:gap-6 w-full">
          {statistic.map((stat) => (
            <Statistic {...stat} key={stat.key} />
          ))}
        </div>
        <Button
          className="analytics-homepage-searchFromStats"
          badgeProps={{ Icon: SearchRoundedIcon }}
          onClick={search}
        >
          Search for free now
        </Button>
      </div>
    </div>
  );
}

function Statistic(stat: {
  statisticText: string;
  statisticSubtext: string;
  image: string;
}) {
  return (
    <div
      key={stat.statisticText}
      className="flex gap-4 md:flex-col md:items-center"
    >
      <img
        className="w-24 h-24 md:w-32 md:h-32"
        src={stat.image}
        alt="Statistics"
      />
      <div>
        <Typography
          variant="headline"
          size="lg"
          color="brand.default.primary.enabled"
          className="font-normal font-homeHeader md:text-center md:text-cp-display-xl"
        >
          {stat.statisticText}
        </Typography>
        <Typography
          variant="body"
          color="neutral.boldest.enabled"
          className="md:text-center md:text-cp-headline-sm max-w-[300px]"
        >
          {stat.statisticSubtext}
        </Typography>
      </div>
    </div>
  );
}
