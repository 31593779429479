import { useState } from "react";

import loaderGifIcon from "../../../img/loaders/loader.gif";
import { ApiService, type ArchiveProjectRequest } from "../../generated";
import {
  Button,
  ButtonThemes,
  LabeledInput,
  RadioButtonGroup,
} from "../../library";
import Modal from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";
import { trackArchiveProject } from "../../utils/tracking";
import { archiveProjectOptions, archiveProjectOtherOption } from "./constants";

interface ArchiveProjectReasonModalProps {
  projectId: string;
  hideModal: () => void;
  onComplete: () => void;
}

export default function ArchiveProjectReasonModal({
  projectId,
  hideModal,
  onComplete,
}: ArchiveProjectReasonModalProps) {
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherInput, setOtherInput] = useState("");
  const radioOptions = archiveProjectOptions.map((option) => ({
    key: option,
    label: option,
  }));

  const onSelectReason = (value: string) => {
    setOtherInput("");
    setSelectedReason(value);
  };

  function onContinue() {
    try {
      setIsCTALoading(true);
      const requestBody: ArchiveProjectRequest = {};
      const reason =
        selectedReason === archiveProjectOtherOption
          ? otherInput
          : selectedReason;
      if (selectedReason) {
        requestBody.archiveReason = reason;
      }
      ApiService.apiV1ProjectsArchiveCreate(projectId, requestBody);
      trackArchiveProject({ projectId, reason });
      onComplete();
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-archive-project-reason-modal"
      formID="analytics-archive-project-reason-modal-submit"
      title="Why didn't you use any of these contracts?"
      ctaSection={
        <div className="flex gap-3 flex-row justify-center">
          {isCTALoading ? (
            <img src={loaderGifIcon} className="w-6" alt="Loading" />
          ) : (
            <>
              <Button
                className="analytics-archive-project-reason-skip-cta"
                onClick={onContinue}
                theme={ButtonThemes.SECONDARY_DARK}
                type="submit"
              >
                Skip
              </Button>
              <Button
                className="analytics-archive-project-reason-cta"
                disabled={
                  !selectedReason ||
                  (selectedReason === archiveProjectOtherOption &&
                    !otherInput) ||
                  isCTALoading
                }
                onClick={onContinue}
                type="submit"
                dataTestId="archive-project-reason-cta"
              >
                Continue
              </Button>
            </>
          )}
        </div>
      }
    >
      <div className="flex flex-col gap-4 w-3/4 m-auto">
        <RadioButtonGroup
          value={selectedReason}
          onChange={onSelectReason}
          options={[
            ...radioOptions,
            {
              key: archiveProjectOtherOption,
              label: archiveProjectOtherOption,
            },
          ]}
          labelClassName="mt-0"
        />
        {selectedReason === archiveProjectOtherOption && (
          <LabeledInput
            name="other"
            size="md"
            value={otherInput}
            onChange={(e) => setOtherInput(e.target.value)}
            required
            dataTestId="other-reason-text"
          />
        )}
      </div>
    </Modal>
  );
}
