import ArrowForward from "@mui/icons-material/ArrowForward";
import AutoAwesomeOutlined from "@mui/icons-material/AutoAwesomeOutlined";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { useAtomValue } from "jotai";
import { type ReactNode, useEffect, useState } from "react";

import {
  ApiService,
  type PublicAgenciesCompletionStatus,
  type SupplierEditRequestSummary,
  type UserSupplier,
} from "../../../generated";
import useSupplierEditUrl from "../../../hooks/useSupplierEditUrl";
import { userState } from "../../../jotai/user";
import { Badge, Button, ButtonSizes, Link, Typography } from "../../../library";
import AccordionGroup, {
  type AccordionItem,
} from "../../../library/AccordionGroup";
import Tag, { TagVariants } from "../../../shared/Tag";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { handleError } from "../../../utils/generatedApi";

interface Task {
  title: string;
  subtitle: string;
  isDone: boolean;
  actions: ReactNode[];
}

interface TaskCard {
  title: string;
  key: string;
  tasks: Task[];
}

// TODO(https://app.shortcut.com/coprocure/story/24627): Use SupplierTasks component
function getProSupplierTaskCards(
  supplier: UserSupplier,
  supplierEditUrl: string,
  editRequestSummary: SupplierEditRequestSummary,
  publicAgenciesCompletionStatus: PublicAgenciesCompletionStatus
): TaskCard[] {
  return [
    {
      title: "Increase visibility in search",
      key: "search-visibility",
      tasks: [
        {
          title: "Optimize your priority contracts",
          subtitle:
            "Add critical data to increase your visibility in relevant searches.",
          isDone: supplier.hasOptimizedAllPriorityContracts,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              size={ButtonSizes.SMALL}
              className="analytics-supplier-welcome-page-review-contracts-cta"
              key="action-review-contracts"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#contracts`,
                underline: false,
                newWindow: false,
              }}
            >
              Optimize contracts
            </Button>,
          ],
        },
        {
          title: "Add product and service list",
          subtitle: "Help buyers confirm what you sell.",
          isDone: !!editRequestSummary.productLists?.length,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              size={ButtonSizes.SMALL}
              key="action-upload-product-list"
              className="analytics-supplier-welcome-page-upload-product-list-cta"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#products-and-services`,
                underline: false,
                newWindow: false,
              }}
            >
              Upload product/service list
            </Button>,
          ],
        },
        {
          title: "Add competitors",
          subtitle:
            "Increase your exposure when buyers search for your competitors.",
          isDone: !!editRequestSummary.competitors,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              size={ButtonSizes.SMALL}
              key="action-add-competitors"
              className="analytics-supplier-welcome-page-add-competitors-cta"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#competitors`,
                underline: false,
                newWindow: false,
              }}
            >
              Add competitors
            </Button>,
          ],
        },
      ],
    },
    {
      title: "Optimize for inbound leads",
      key: "lead-optimization",
      tasks: [
        {
          title: "Add your sales representatives",
          subtitle: "Give buyers the right point of contact to email or call.",
          isDone: supplier.hasManualContactWithVerifiedPhone,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              className="analytics-supplier-welcome-page-add-sales-representatives-cta"
              key="action-add-sales-reps"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#contacts`,
                underline: false,
                newWindow: false,
              }}
            >
              Add sales representatives
            </Button>,
          ],
        },
        {
          title: "Set your service area",
          subtitle: "Get contacted by buyers in locations you serve.",
          isDone: supplier.hasSubmittedManualServiceArea,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#service-area`,
                underline: false,
                newWindow: false,
              }}
              size={ButtonSizes.SMALL}
              className="analytics-supplier-welcome-page-set-service-area-cta"
              key="action-set-service-area"
            >
              Set service area
            </Button>,
          ],
        },
        {
          title: "Add your business description",
          subtitle: "Help buyers confirm what you sell.",
          isDone: !!supplier.about,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#about`,
                underline: false,
                newWindow: false,
              }}
              className="analytics-supplier-welcome-page-add-business-description-cta"
              key="action-add-business-description"
            >
              Add business description
            </Button>,
          ],
        },
        {
          title: "Add previous public sector experience",
          subtitle:
            "Show buyers that you’ve worked with similar city, county, or education entities before. Only verified buyers will see the full list.",
          isDone: !!publicAgenciesCompletionStatus.completed,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#public-entities`,
                underline: false,
                newWindow: false,
              }}
              className="analytics-supplier-welcome-page-add-previous-public-agencies-cta"
              key="action-add-previous-public-agencies"
            >
              Add public entities
            </Button>,
          ],
        },
      ],
    },
  ];
}

const mapTaskCardToAccordionItem = (taskCard: TaskCard, isLoading: boolean) => {
  {
    const numTasks = taskCard.tasks.length;
    const numTasksCompleted = taskCard.tasks.filter(
      (task) => task.isDone
    ).length;
    const allTasksCompleted = numTasksCompleted === numTasks;

    return {
      key: taskCard.key,
      header: (
        <div className="flex flex-row gap-4">
          <Typography
            variant="headline"
            color={
              allTasksCompleted
                ? "feedback.bold.success"
                : "brand.boldest.enabled"
            }
          >
            {taskCard.title}
          </Typography>
          {allTasksCompleted && (
            <Tag
              variant={TagVariants.VERIFIED}
              className="h-fit"
              Icon={AutoAwesomeOutlined}
            >
              Complete
            </Tag>
          )}
        </div>
      ),
      subheader:
        allTasksCompleted || isLoading ? null : (
          <Typography
            variant="meta"
            color={
              numTasksCompleted
                ? "feedback.bold.success"
                : "neutral.bold.enabled"
            }
          >
            {numTasksCompleted} of {numTasks} completed
          </Typography>
        ),
      children: (
        <div className="flex flex-col gap-y-6">
          {taskCard.tasks.map((task) => (
            <div className="flex flex-row gap-x-3" key={task.title}>
              <Badge
                Icon={task.isDone ? CheckCircle : CircleOutlined}
                className={task.isDone ? "text-cp-leaf-400" : ""}
                inline
              />
              <div>
                <Typography
                  variant="headline"
                  size="xs"
                  emphasis
                  color={
                    task.isDone
                      ? "feedback.bold.success"
                      : "neutral.boldest.enabled"
                  }
                >
                  {task.title}
                </Typography>
                {!task.isDone && (
                  <>
                    <Typography color="neutral.bolder.enabled">
                      {task.subtitle}
                    </Typography>
                    <div className="mt-3 flex flex-row gap-3">
                      {task.actions}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ),
    };
  }
};

export default function ProSupplierWelcomeTasks({
  className,
}: { className?: string }) {
  const { supplier } = useAtomValue(userState);
  const supplierEditUrl = useSupplierEditUrl();
  const [isLoading, setIsLoading] = useState(true);

  const [editRequestSummary, setEditRequestSummary] =
    useState<SupplierEditRequestSummary>({
      competitors: null,
      productLists: [],
      contractFileUploads: [],
      contractFileDeletes: [],
    });

  const [publicAgenciesCompletionStatus, setPublicAgenciesCompletionStatus] =
    useState<PublicAgenciesCompletionStatus>({
      numStatesConfirmed: 0,
      numStatesRequired: 1,
      numAgenciesConfirmed: 0,
      numAgenciesRequired: 10,
      completed: false,
    });

  const hasSelfServeOnboardingFeature = supplierHasFeature(
    supplier?.activeAgreements,
    "selfServeOnboarding"
  );
  const hasSelfServeSocialProofFeature = supplierHasFeature(
    supplier?.activeAgreements,
    "selfServeSocialProof"
  );

  useEffect(() => {
    if (supplier?.handle) {
      setIsLoading(true);
      (async () => {
        const promises = [];
        try {
          if (hasSelfServeOnboardingFeature) {
            promises.push(
              ApiService.apiV1SuppliersEditRequestSummaryRetrieve(
                supplier.id
              ).then((val: SupplierEditRequestSummary) =>
                setEditRequestSummary(val)
              )
            );
          }
          if (hasSelfServeSocialProofFeature) {
            promises.push(
              ApiService.apiV1SuppliersPublicAgenciesCompletionStatusRetrieve(
                supplier.id
              ).then((val: PublicAgenciesCompletionStatus) =>
                setPublicAgenciesCompletionStatus(val)
              )
            );
          }
          await Promise.all(promises);
        } catch (err) {
          handleError(err);
        }
        setIsLoading(false);
      })();
    }
  }, [supplier, hasSelfServeOnboardingFeature, hasSelfServeSocialProofFeature]);

  if (!hasSelfServeOnboardingFeature) {
    return;
  }

  const taskCards = supplier
    ? getProSupplierTaskCards(
        supplier,
        supplierEditUrl || "",
        editRequestSummary,
        publicAgenciesCompletionStatus
      )
    : [];
  const accordionTasks: AccordionItem[] = taskCards.map((taskCard) =>
    mapTaskCardToAccordionItem(taskCard, isLoading)
  );

  const initialKey = taskCards.find((section) =>
    section.tasks.some((task) => !task.isDone)
  )?.key;

  return (
    <AccordionGroup
      cards={accordionTasks}
      initialKey={initialKey}
      className={className}
      isLoading={isLoading}
    />
  );
}
