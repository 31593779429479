import { ContractFileTypeEnum } from "../../../generated";
import type { UploadContractDetails } from "../../../modals/UploadDocumentsModal";
import type { GroupedContractDocuments } from "../types";
import UploadFileTypeSection from "./UploadFileTypeSection";

interface FilesUploadSectionProps {
  files: GroupedContractDocuments;
  supplierId: number;
  uploadContractDetails: UploadContractDetails;
  setCompletedStatus: (completed: boolean) => void;
}

export default function FilesUploadSection({
  files,
  supplierId,
  uploadContractDetails,
  setCompletedStatus,
}: FilesUploadSectionProps) {
  return (
    <div className="flex flex-col gap-6">
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.CONTRACT}
        files={files.contractDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
        description="Allow buyers to confirm your award is valid, see expiration details, and confirm cooperative use language."
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.BID_SOLICITATION}
        files={files.bidSolicitationDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
        description="Enable buyers to confirm your offerings are covered under the scope of work and evaluate the competitive nature of the RFP / bid process."
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.BID_TABULATION}
        files={files.bidTabulationDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
        description="Show the criteria used to award selected vendors. Highlight criteria you have ranked highly on against competitors awarded on the same contract."
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.PRICING}
        files={files.pricingDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
        description="Clarify high-level pricing discounts or guidance across the contract. Contract pages with pricing documents improve your ranking in search."
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.RENEWAL}
        files={files.renewalDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
        description="Confirm you are active under this contract for the latest renewal term."
      />
      <UploadFileTypeSection
        fileType={ContractFileTypeEnum.OTHER}
        files={files.otherDocuments}
        supplierId={supplierId}
        uploadContractDetails={uploadContractDetails}
        setCompletedStatus={setCompletedStatus}
      />
    </div>
  );
}
