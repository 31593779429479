import clsx from "clsx";

import { AvatarSizes } from "../../library";

export const ICON_SIZES: Record<
  AvatarSizes,
  { sizeClass: string; viewBox: string }
> = {
  [AvatarSizes.SMALL]: {
    sizeClass: "w-4 h-4",
    viewBox: "4 4 32 32",
  },
  [AvatarSizes.MEDIUM]: {
    sizeClass: "w-6 h-6",
    viewBox: "4 4 32 32",
  },
  [AvatarSizes.MED_LG]: {
    sizeClass: "TBD",
    viewBox: "TBD",
  },
  [AvatarSizes.LARGE]: {
    sizeClass: "w-12 h-12",
    viewBox: "-4 -4 48 48",
  },
  [AvatarSizes.XL]: {
    sizeClass: "w-16 h-16",
    viewBox: "2 0 36 36",
  },
};
interface SingleDocumentIconProps {
  className?: string;
  size: AvatarSizes;
}
export function SingleDocumentIcon({
  size,
  className,
}: SingleDocumentIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(ICON_SIZES[size].sizeClass, className)}
      fill="none"
      viewBox={ICON_SIZES[size].viewBox}
    >
      <g filter="url(#a)">
        <path
          fill="#FFF8F4"
          d="M26.193 5.28h-9.508L9 12.992v12.331a4.858 4.858 0 001.415 3.416 4.825 4.825 0 003.403 1.421h12.364a4.825 4.825 0 003.403-1.421A4.858 4.858 0 0031 25.323V10.116a4.858 4.858 0 00-1.415-3.415 4.825 4.825 0 00-3.403-1.421h.011z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 25.323V10.116a4.858 4.858 0 00-1.415-3.415 4.825 4.825 0 00-3.403-1.421h.011-9.508L9 12.992v12.331a4.858 4.858 0 001.415 3.416 4.825 4.825 0 003.403 1.421H19"
        />
        <path
          fill="#FFCBA6"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.024 12.966h3.186a4.603 4.603 0 003.246-1.355 4.635 4.635 0 001.35-3.258V5.28h-.12l-7.662 7.686z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.751 15.58h12.51m-12.51 4.39h12.514m-12.514 4.794h5.25"
        />
        <circle
          cx="26"
          cy="25.22"
          r="2.5"
          fill="#FFE9D8"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="#FFE9D8"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 31.97a3 3 0 013-3h4a3 3 0 013 3 .75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="39"
          height="44.44"
          x="0.5"
          y="0.78"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.552941 0 0 0 0 0.211765 0 0 0 0 0.0941176 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_276_140"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.552941 0 0 0 0 0.211765 0 0 0 0 0.0941176 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_276_140"
            result="effect2_dropShadow_276_140"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_276_140"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
