import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import clsx from "clsx";
import type { ElementType } from "react";
import { bgColorClass, borderColorClass } from "../../utils/colors";
import Badge from "../Badge";
import Link from "../Link";

const borderClass = clsx(
  borderColorClass.brand.bold.enabled,
  "border border-1"
);

export default function BookmarkLink({
  onClick,
  href,
  label,
  border,
  background,
  dataTestId,
  newWindow = false,
  badgeIcon = ArrowForwardRoundedIcon,
}: {
  onClick: () => void;
  href?: string;
  label: string;
  border?: boolean;
  background?: boolean;
  dataTestId?: string;
  target?: string;
  newWindow?: boolean;
  badgeIcon?: ElementType;
}) {
  return (
    <Link
      href={href}
      newWindow={newWindow}
      onClick={onClick}
      emphasis={false}
      underline={false}
      size="sm"
      className={clsx(
        {
          [bgColorClass.brand.subtlest.enabled]: background,
          [borderClass]: border,
        },
        "w-fit px-2 py-1 rounded-3 flex items-center gap-1 whitespace-nowrap"
      )}
      dataTestId={dataTestId}
    >
      {label}
      <Badge Icon={badgeIcon} size="sm-md" includeMargin={false} />
    </Link>
  );
}
