import { AdditionalRequirementsEnum } from "../../generated/models/AdditionalRequirementsEnum";
import {
  type InputSizes,
  LabeledInputStyles,
} from "../../library/Input/LabeledInput";
import { CheckboxField, ChipInputField } from "../../library/form";
import LabeledInputField from "../../library/form/LabeledInputField";
import SelectableChipsField from "../../library/form/SelectableChipsField";
import TextareaField from "../../library/form/TextareaField";
import type { FormFieldProps } from "../../library/form/types";
import yup from "../../utils/yupPhone";

const ADDITIONAL_REQUIREMENTS_OPTIONS = [
  {
    value: AdditionalRequirementsEnum.TIME_SENSITIVE_PURCHASE,
    label: "This is a time-sensitive purchase",
  },
  {
    value: AdditionalRequirementsEnum.INSTALLATION_OR_SERVICES,
    label: "I need installation or other services",
  },
  {
    value: AdditionalRequirementsEnum.SPECIFIC_BRAND,
    label: "I need a specific brand",
  },
  {
    value: AdditionalRequirementsEnum.PREFER_LOCAL_SUPPLIERS,
    label: "I prefer local suppliers",
  },
  {
    value: AdditionalRequirementsEnum.PRICE_CONSTRAINTS,
    label: "I have pricing constraints",
  },
  {
    value: AdditionalRequirementsEnum.MEET_DIVERSITY_CRITERIA,
    label: "Suppliers must meet diversity criteria",
  },
  {
    value: AdditionalRequirementsEnum.MEET_INSURANCE_REQUIREMENTS,
    label: "Supplier must meet insurance requirements",
  },
].map((option, index) => ({ ...option, key: index.toString() }));

export const REQUIREMENT_PAGE_QUESTIONS: FormFieldProps[] = [
  {
    name: "description",
    label: "What are you looking for?",
    sublabel:
      "Details like a description or list of items, quantity, and whether your requirements are flexible help us get you the best supplier matches.",
    component: TextareaField,
    labelStyle: LabeledInputStyles.FLOATING,
    size: "sm" as InputSizes,
    validate: yup
      .string()
      .required("Please provide a description of your requirements"),
  },
  {
    name: "isOpenToComparableProducts",
    label: "I'm open to comparable products",
    component: CheckboxField,
    className: "mt-[-1rem]",
  },
  {
    name: "preferredSuppliers",
    label: "Add preferred suppliers you'd like to work with (optional).",
    sublabel: "We'll find comparable suppliers.",
    size: "md" as InputSizes,
    component: LabeledInputField,
    labelStyle: LabeledInputStyles.FLOATING,
  },
  {
    name: "additionalRequirements",
    label: "Let us know if you have additional requirements (optional).",
    sublabel: "",
    size: "md" as InputSizes,
    component: SelectableChipsField,
    options: ADDITIONAL_REQUIREMENTS_OPTIONS,
  },
  {
    name: "additionalRequirementsDetails",
    label:
      "Add details about any other requirements, including preferred contract sources, if any (optional).",
    size: "md" as InputSizes,
    component: LabeledInputField,
    labelStyle: LabeledInputStyles.FLOATING,
  },
  {
    name: "requestedCc",
    label: "Add team member emails to include on supplier threads (optional).",
    size: "md" as InputSizes,
    component: ChipInputField,
    labelStyle: LabeledInputStyles.FLOATING,
  },
];

export const CARD_FORM_QUESTIONS: FormFieldProps[] = [
  {
    name: "description",
    label: "What are you buying?",
    component: LabeledInputField,
    size: "md" as InputSizes,
    validate: yup.string().required(" "),
  },
];

export const MODAL_FORM: FormFieldProps[] = [
  {
    name: "additionalRequirementsDetails",
    label: "Details about what you need",
    sublabel:
      "like a description, specifications, quantity, and whether your requirements are flexible.",
    size: "md" as InputSizes,
    component: TextareaField,
    labelStyle: LabeledInputStyles.FLOATING,
    rows: 3,
    validate: yup
      .string()
      .required("Please provide a description of your requirements"),
  },
  {
    name: "purchaseTimeframe",
    label: "Timeframe for purchase",
    sublabel: "- when do you need this by?",
    size: "md" as InputSizes,
    component: LabeledInputField,
    labelStyle: LabeledInputStyles.FLOATING,
  },
  {
    name: "requestedCc",
    label: "Email for someone who can answer supplier questions",
    sublabel: "- e.g. an end user or a department email.",
    size: "md" as InputSizes,
    component: ChipInputField,
    labelStyle: LabeledInputStyles.FLOATING,
  },
  {
    name: "requestedPhoneNumber",
    label: "Phone number",
    sublabel: "- we'll call if we have any questions.",
    size: "md" as InputSizes,
    component: LabeledInputField,
    labelStyle: LabeledInputStyles.FLOATING,
    validate: yup.string().phone(false),
  },
];
