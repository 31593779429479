import clsx from "clsx";

import { Link, Typography } from "../../library";
import CopyableText from "../../library/CopyableText";

export interface MessageSupplierHeaderProps {
  heading: string;
  subheading?: string;
  subheadingLinkProps?: {
    text: string;
    url: string;
    analyticsClassName: string;
  };
  subheadingCopyableTextProps?: {
    text: string;
    onClick: () => void;
    onCopy: () => void;
    analyticsClassName: string;
  };
}

export function MessageSupplierHeader({
  heading,
  subheading,
  subheadingCopyableTextProps,
  subheadingLinkProps,
}: MessageSupplierHeaderProps) {
  return (
    <div className="flex flex-col gap-2 md:gap-3">
      <Typography
        variant="headline"
        color="brand.boldest.enabled"
        emphasis
        className="analytics-solicitation-contract-title md:line-clamp-1"
      >
        {heading}
      </Typography>
      <div className="gap-1 empty:hidden">
        {subheadingCopyableTextProps && (
          <CopyableText
            textToCopy={subheadingCopyableTextProps.text}
            onClick={subheadingCopyableTextProps.onClick}
            onCopy={subheadingCopyableTextProps.onCopy}
            color="neutral.bolder.enabled"
            size="sm"
            analyticsClassName={subheadingCopyableTextProps.analyticsClassName}
          >
            {subheadingCopyableTextProps.text}
          </CopyableText>
        )}
        {subheadingLinkProps && (
          <Link
            href={subheadingLinkProps.url}
            color="brand.bold.enabled"
            className={clsx(
              "line-clamp-1",
              subheadingLinkProps.analyticsClassName
            )}
            underline={false}
            emphasis
          >
            {subheadingLinkProps.text}
          </Link>
        )}
        {subheading && <Typography variant="meta">{subheading}</Typography>}
      </div>
    </div>
  );
}
