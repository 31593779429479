/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Contract` - Contract
 * * `Supplier` - Supplier
 */
export enum ItemTypeEnum {
    CONTRACT = 'Contract',
    SUPPLIER = 'Supplier',
}
