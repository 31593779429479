import { useMemo } from "react";

import type { FormFieldProps, Validate } from "../library/form/types";

export function useValidationSchema(fields: FormFieldProps[]) {
  const validationSchema = useMemo(() => {
    return fields.reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [fields]);

  return validationSchema;
}
