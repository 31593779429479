import { useAtomValue } from "jotai";
import pluralize from "pluralize";

import { userState } from "../jotai/user";
import { Typography } from "../library";
import type { TypographyProps } from "../library/Typography";
import { supplierHasFeature } from "../utils/featureManagement";

interface PendingContractTextProps extends TypographyProps {
  numPendingDocs?: number;
}

export default function PendingContractText({
  numPendingDocs,
  ...rest
}: PendingContractTextProps) {
  const { isAdmin, supplier } = useAtomValue(userState);
  if (
    (!supplierHasFeature(supplier?.activeAgreements, "contractManagement") &&
      !isAdmin) ||
    !numPendingDocs
  ) {
    return null;
  }

  return (
    <Typography color="brand.boldest.enabled" {...rest}>
      {`[${numPendingDocs} ${pluralize(
        "document",
        numPendingDocs
      )} pending review]`}
    </Typography>
  );
}
