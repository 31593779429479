import { useEffect } from "react";

import { supplierLocationFilterState } from "../../../jotai/searchFilters";
import { PopoverMenu, RadioButtonGroup } from "../../../library";
import { getParam } from "../../../utils";
import {
  SupplierLocationFilterOptions,
  searchFilters,
} from "../../../utils/enums";

import { useAtom } from "jotai";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";
interface SupplierLocationFilterProps {
  stateCode: string;
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
}

export default function SupplierLocationFilter({
  stateCode,
  filters,
  onChange,
  onToggleFilter,
}: SupplierLocationFilterProps) {
  const [supplierLocationFilter, setSupplierLocationFilter] = useAtom(
    supplierLocationFilterState
  );

  const supplierLocationFilterOptions = [
    {
      key: SupplierLocationFilterOptions.ALL_SUPPLIERS,
      label: "Show all suppliers",
    },
    {
      key: SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS,
      label: `Show only suppliers who serve ${stateCode}`,
      sublabel: `Only suppliers headquartered in ${stateCode} or on ${stateCode} contracts.`,
    },
  ];

  const onSelectFilter = (selected: string) => {
    const showSpecificSuppliers = filters.includes(
      searchFilters.LOCATION_SPECIFIC_SUPPLIERS
    );

    if (
      !showSpecificSuppliers &&
      selected === SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      onToggleFilter(true, searchFilters.LOCATION_SPECIFIC_SUPPLIERS);
    } else if (
      showSpecificSuppliers &&
      selected !== SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      onToggleFilter(false, searchFilters.LOCATION_SPECIFIC_SUPPLIERS);
    } else {
      onChange({
        type: "supplierLocation",
        value: selected,
      });
    }
    setSupplierLocationFilter(selected as SupplierLocationFilterOptions);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run on first mount.
  useEffect(() => {
    if (
      filters.includes(searchFilters.LOCATION_SPECIFIC_SUPPLIERS) &&
      supplierLocationFilter !==
        SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      setSupplierLocationFilter(
        SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
      );
    }
  }, [filters]);

  return (
    <RadioButtonGroup
      value={supplierLocationFilter}
      options={supplierLocationFilterOptions}
      onChange={onSelectFilter}
    />
  );
}

export function SupplierLocationFilterPopover(
  props: SupplierLocationFilterProps
) {
  const params = getParam("filters");
  return (
    <PopoverMenu
      text="Supplier location"
      border
      enabled={params.includes(searchFilters.LOCATION_SPECIFIC_SUPPLIERS)}
      noWrap
    >
      <div className="min-w-[452px] flex flex-col gap-4 my-2">
        <SupplierLocationFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
