import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import clsx from "clsx";
import {
  type MouseEvent,
  type MouseEventHandler,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { copyTextToClipboard } from "../shared/CopyButton";

import type { TypographyProps } from "./Typography";

import { Badge, Tooltip, Typography } from "./index";

interface CopyableTextProps extends TypographyProps {
  textToCopy?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onCopy?: (arg?: unknown) => void;
  className?: string;
  analyticsClassName?: string;
  showIconOnlyOnHover?: boolean;
  truncateText?: boolean;
}

export default function CopyableText({
  textToCopy,
  children,
  onClick,
  onCopy,
  className,
  analyticsClassName,
  showIconOnlyOnHover = true,
  truncateText = true,
  ...rest
}: CopyableTextProps) {
  const [isTextCopied, setIsTextCopied] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = () => {
      onCopy?.();
    };
    const element = ref.current;
    element?.addEventListener("copy", listener);
    return () => {
      element?.removeEventListener("copy", listener);
    };
  }, [onCopy]);

  async function handleClick(e: MouseEvent<HTMLDivElement>) {
    const copied = await copyTextToClipboard(
      textToCopy || (children as string)
    );
    setIsTextCopied(copied);
    onClick?.(e);
  }

  function handleMouseOutOrBlur() {
    setIsTextCopied(false);
  }

  return (
    <Tooltip
      className={clsx(className, "w-fit")}
      placement="right"
      info={isTextCopied ? "Copied!" : "Copy to clipboard"}
    >
      <div
        ref={ref}
        onClick={handleClick}
        onMouseOut={() => setIsTextCopied(false)}
        onBlur={handleMouseOutOrBlur}
        className={clsx(
          analyticsClassName,
          "flex gap-2 group items-center cursor-pointer"
        )}
      >
        <Typography
          {...rest}
          className={clsx({ "line-clamp-2": truncateText })}
        >
          {children}
        </Typography>
        <Badge
          Icon={ContentCopyRoundedIcon}
          iconClass="fill-cp-neutral-palette-600"
          className={clsx({
            "invisible group-hover:visible": showIconOnlyOnHover,
          })}
        />
      </div>
    </Tooltip>
  );
}
