import { useState } from "react";
import type { BuyerLeadAgency } from "../../generated";

import AutocompleteInput, {
  type AutocompleteInputOnSelect,
} from "../../library/AutocompleteInput";
import { stateOptions } from "../../utils/constants";
import { debouncedGetBuyerLeadAgenciesList } from "../BLAAutocompleteInput/debouncedGetBuyerLeadAgecies";

interface BuyerLeadAgencyItem {
  id: string;
  label: string;
  data: BuyerLeadAgency | { stateCode: string; id: null; name: null };
}

export type BLAAutocompleteInputOnChange = AutocompleteInputOnSelect<
  BuyerLeadAgency | { stateCode: string; id: null; name: null }
>;

interface BLAAndStateAutocompleteInputProps {
  onChange: BLAAutocompleteInputOnChange;
  placeholder?: string;
  errorMessage?: string;
  dataTestId?: string;
  label?: string;
  sublabel?: string;
}

export default function BLAAndStateAutocompleteInput({
  onChange,
  placeholder = "ex. City of Mesa, AZ",
  errorMessage,
  dataTestId,
  label,
  sublabel,
}: BLAAndStateAutocompleteInputProps) {
  const [agenciesList, setAgenciesList] = useState<BuyerLeadAgencyItem[]>([]);

  return (
    <AutocompleteInput
      name="governmentAgencyOrState"
      initialValue={null}
      initialSelectedId={null}
      onChange={(text) =>
        debouncedGetBuyerLeadAgenciesList(text, {
          parentsOnly: true,
          onComplete: (agencies) => {
            const agenciesList = agencies.map((agency) => {
              let label = agency.name;
              if (agency.appendStateForDisplay) {
                label = [agency.name, agency.stateCode].join(", ");
              }

              return {
                id: agency.id,
                label,
                data: agency,
              };
            });

            const regex = new RegExp(`^${text}.*`, "i");
            const stateList = text
              ? stateOptions
                  .filter(
                    ({ value, label }) =>
                      value.match(regex) || label.match(regex)
                  )
                  .map(({ value, label }) => ({
                    id: value,
                    label: `All entities in ${label}`,
                    data: { stateCode: value, id: null, name: null },
                  }))
              : [];

            setAgenciesList([...stateList, ...agenciesList]);
          },
        })
      }
      onSelect={onChange}
      options={agenciesList}
      label={label}
      dataTestId={dataTestId}
      placeholder={placeholder}
      freeSoloLabel="Add a new public entity"
      errorMessage={errorMessage}
      sublabel={sublabel}
    />
  );
}
