import clsx from "clsx";

import fallbackImage from "../../../img/icons/image.svg";
import type { ProductHitDict, ProductService } from "../../generated";
import { Typography } from "../../library";
import ImageWithFallback from "../../library/ImageWithFallback";
import { borderColorClass } from "../../utils/colors";
import { styleSearchResponseText } from "./utils";

interface ProductSectionProps {
  products: (ProductHitDict | ProductService)[];
  onClick?: () => void;
}

export default function ProductSection({
  products,
  onClick,
}: ProductSectionProps) {
  if (!products.length) {
    return null;
  }
  const productValues = products.map(getProductValues);
  const productValuesWithImages = productValues.filter(
    ({ imageUrl }) => imageUrl
  ) as RenderableProductWithImage[];

  return (
    <div
      className={clsx(
        "flex items-center justify-between gap-3 max-h-16 empty:hidden",
        {
          "cursor-pointer": !!onClick,
        }
      )}
      onClick={onClick}
    >
      <Typography
        size="sm"
        className="text-ellipsis whitespace-wrap"
        color="neutral.bold.enabled"
        title={productValues[0].itemName}
      >
        Confirmed products:{" "}
        {styleSearchResponseText(productValues[0].itemText, {
          className: "neutral.bold.enabled font-normal",
          highlightClassName: "neutral.bold.enabled font-se",
        })}{" "}
        + more
      </Typography>
      {productValuesWithImages.length > 0 && (
        <div
          className={clsx("grid gap-1 shrink-0", {
            "grid-cols-3": productValuesWithImages.length >= 3,
            "grid-cols-2": productValuesWithImages.length === 2,
            "grid-cols-1": productValuesWithImages.length === 1,
          })}
        >
          {productValuesWithImages
            .slice(0, 3)
            .map(({ id, imageUrl, itemName }) => (
              <ImageWithFallback
                key={id}
                className={clsx(
                  "shrink-0 h-16 w-[5rem] bg-cover border border-solid rounded-2",
                  borderColorClass.neutral.subtle.enabled
                )}
                src={imageUrl}
                srcFallback={fallbackImage}
                alt={itemName}
                title={itemName}
                border={false}
              />
            ))}
        </div>
      )}
    </div>
  );
}

interface RenderableProduct {
  id: string;
  imageUrl: Maybe<string>;
  itemName: string;
  itemText: string;
}

interface RenderableProductWithImage extends RenderableProduct {
  imageUrl: string;
}

function isProductHit(
  product: ProductHitDict | ProductService
): product is ProductHitDict {
  return "productService" in product;
}

function getProductValues(
  product: ProductHitDict | ProductService
): RenderableProduct {
  if (isProductHit(product)) {
    const itemName = product.productService.itemName;
    let itemText = itemName;
    if (product.highlightResult?.itemName?.length) {
      itemText = product.highlightResult.itemName[0].value;
    }

    return {
      id: product.productService.id,
      imageUrl: product.photos?.[0]?.url,
      itemName,
      itemText,
    };
  }
  const itemName = product.itemName;
  let itemText = itemName;
  if (product.highlightResult?.itemName?.length) {
    itemText = product.highlightResult.itemName[0].value;
  }

  return {
    id: product.id,
    imageUrl: product.variants[0].photos?.[0]?.url,
    itemName,
    itemText,
  };
}
