import { createRoot } from "react-dom/client";

import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import EntityContractsUpdatePage from "../components/EntityContractsUpdatePage";
import type { BuyerLeadAgency } from "../generated";
import Page, { type AuthPageProps } from "./Page/Page";

interface EntityContractsUpdateProps extends AuthPageProps {
  government_agency: BuyerLeadAgency;
}

export default function EntityContractsUpdate({
  government_agency,
  is_authenticated,
  profile_type,
  active_agreements,
}: EntityContractsUpdateProps) {
  return (
    <Page
      pageType={PageType.PROFILE}
      searchSource="profile-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <PageSection>
        <EntityContractsUpdatePage governmentAgency={government_agency} />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("entity-contracts-update");
if (container) {
  const props = JSON.parse(container.dataset.data || "");
  const root = createRoot(container);
  root.render(<EntityContractsUpdate {...props} />);
}
