/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `MIN_ORDER_SIZE` - Min Order Size
 * * `SUPPLIER_SCOPE` - Supplier Scope
 * * `SUPPLIER_SHARE` - Supplier Share
 */
export enum QualificationTypeEnum {
    MIN_ORDER_SIZE = 'MIN_ORDER_SIZE',
    SUPPLIER_SCOPE = 'SUPPLIER_SCOPE',
    SUPPLIER_SHARE = 'SUPPLIER_SHARE',
}
