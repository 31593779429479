import { useAtomValue } from "jotai";

import { userTypeSignupState } from "../../jotai/user";
import PostSocialAuthForm from "../../modals/SignupSteps/PostSocialAuthForm";
import {
  type AccountModalKeys,
  type InferredBLA,
  WindowType,
} from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { getParams } from "../../utils";
import {
  LoginType,
  accountModals,
  modalTriggerURLParams,
} from "../../utils/enums";
import { trackSignupLoginExit } from "../../utils/tracking";

import { AccountsService } from "../../generated";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import {
  goToHomePage,
  goToSignupWelcomePage,
  redirectOnComplete,
} from "./helpers";

interface PostSocialAuthProps {
  firstName?: string;
  provider?: string;
  userEmail?: string;
  inferredBLA: InferredBLA;
}
export default function PostSocialAuth({
  firstName = "",
  provider,
  userEmail,
  inferredBLA,
}: PostSocialAuthProps) {
  const userType = useAtomValue(userTypeSignupState);
  const showSignupSurvey = useShowSignupSurvey();

  const urlParams = getParams();
  const invite = urlParams[modalTriggerURLParams.INVITE];

  function onCompleteBuyerSignup() {
    showSignupSurvey({
      onComplete: goToSignupWelcomePage,
    });
  }

  function onCompleteSupplierSignup(redirectUrl: string) {
    showSignupSurvey({
      onComplete: () => {
        redirectOnComplete(redirectUrl);
      },
    });
  }

  function closePage() {
    trackSignupLoginExit({
      accountType: userType,
      emailEntered: userEmail as string,
      loginType: provider ? (provider as LoginType) : LoginType.PAVILION,
      loginStage: accountModals.SIGNUP_POST_SOCIAL_AUTH as AccountModalKeys,
      loginExperience: WindowType.Page,
    });
    void AccountsService.accountsSocialSignupCancelCreate();
    goToHomePage();
  }

  // If this is an explicitly provided invite, prefer that BLA.
  if (invite && urlParams.blaId && urlParams.blaName) {
    inferredBLA.id = urlParams.blaId as string;
    inferredBLA.display_name = urlParams.blaName as string;
  }

  return (
    <AccountPage
      title={firstName ? `Hi, ${firstName}` : "Hi,"}
      ctaText="One last step before we can create your account"
      closePage={closePage}
    >
      <PostSocialAuthForm
        onComplete={
          userType === "buyer"
            ? onCompleteBuyerSignup
            : onCompleteSupplierSignup
        }
        provider={provider}
        userEmail={userEmail}
        parentWindow={WindowType.Page}
        inferredBLA={inferredBLA}
      />
    </AccountPage>
  );
}
