import clsx from "clsx";
import { type ElementType, type ReactElement, useState } from "react";

import { borderColorClass } from "../../utils/colors";
import TabLink from "./TabLink";

export interface Tab {
  // Tab display name
  name: string;
  // Component to render when tab is active
  Component: ElementType;
  // Whether to hide the tab
  hidden?: boolean;
  // Height class to apply to the tab content
  heightClass?: string;
  // Icon to display next to the tab name
  Icon?: ReactElement;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick?: (tab: Tab) => void;
  className?: string;
}

export default function Tabs({ tabs, onTabClick, className }: TabsProps) {
  const [active, setActive] = useState(0);
  tabs = tabs.filter((tab) => !tab.hidden);

  if (tabs.length === 0) return null;

  const Component = tabs[active].Component;

  function handleClick(index: number) {
    setActive(index);
    onTabClick?.(tabs[index]);
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-6 transition-height ease-in-out duration-300",
        borderColorClass.neutral.subtle.enabled,
        tabs[active].heightClass || "h-fit",
        className
      )}
    >
      <ul className="flex gap-6 border-b border-solid">
        {tabs.map((tab, index) => {
          return (
            <li
              key={tab.name}
              onClick={() => handleClick(index)}
              className="flex gap-1 items-baseline"
            >
              <TabLink
                tabName={tab.name}
                activeTabName={tabs[active].name}
                className="gap-2"
                Icon={tab.Icon}
              />
            </li>
          );
        })}
      </ul>
      <Component />
    </div>
  );
}
