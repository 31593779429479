import { useAtomValue } from "jotai";
import { useCallback } from "react";
import type { RelevantContract } from "../../generated";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
} from "../../jotai/searchFilters";
import { buyerProfileState } from "../../jotai/user";
import {
  matchesApprovedSource,
  matchesContractSource,
  matchesExpiration,
  matchesSingleAward,
} from "./utils";

/**
 * For a given RelevantContract, apply contract-specific matchers and return
 * true if the contract matches all of them. Logic is similar to
 * useFilteredSearchResults
 **/
export function useContractMatchesFilters() {
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const expirationFilter = useAtomValue(expirationFilterState);
  const contractSourceFilter = useAtomValue(contractSourceFilterState);
  const approvedSourcesOnlyFilter = useAtomValue(
    approvedSourcesOnlyFilterState
  );
  const singleAwardOnlyFilter = useAtomValue(singleAwardOnlyFilterState);

  return useCallback(
    (c: RelevantContract) => {
      const matchers: ((c: RelevantContract) => boolean)[] = [
        (r) => matchesExpiration(r, expirationFilter),
        (r) => matchesContractSource(r, contractSourceFilter),
        (r) =>
          matchesApprovedSource(
            r,
            approvedSourcesOnlyFilter,
            governmentAgency?.filterSources || [],
            governmentAgency?.approvedStates || []
          ),
        (r) => matchesSingleAward(r, singleAwardOnlyFilter),
      ];
      return matchers.every((matcher) => matcher(c));
    },
    [
      governmentAgency,
      expirationFilter,
      contractSourceFilter,
      approvedSourcesOnlyFilter,
      singleAwardOnlyFilter,
    ]
  );
}
