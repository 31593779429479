/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `full` - Full
 * * `partial` - Partial
 * * `none` - None
 * * `semantic` - Semantic
 */
export enum MatchLevelEnum {
    FULL = 'full',
    PARTIAL = 'partial',
    NONE = 'none',
    SEMANTIC = 'semantic',
}
