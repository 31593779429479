import _get from "lodash/get";
import { useMemo } from "react";
import {
  matchesForContractHit,
  styleSearchResponseText,
} from "../components/ContractSearch/utils";
import {
  type ContractHit,
  MatchLevelEnum,
  type RelevantContract,
} from "../generated";
import type { TypographyColor } from "../library/Typography/types";
import { textColorClass } from "../utils/colors";
import { truncateAroundTargetWord } from "../utils/format";

const maxWords = 8;
const maxCharacters = 200;

export function useScopeMatchElements(
  hit: ContractHit | RelevantContract,
  limit: number,
  color: TypographyColor = "neutral.bolder.enabled"
) {
  const allMatches = useMemo(() => {
    const matches = matchesForContractHit(hit);
    if (matches.length > 1)
      return matches.filter(
        ({ matchLevel }) => matchLevel !== MatchLevelEnum.SEMANTIC
      );
    return matches;
  }, [hit]);

  // Early break for when we have no match information.
  if (!allMatches.length) {
    return null;
  }

  const scopeElements = allMatches.slice(0, limit).map((match) => {
    if (match.matchLevel === MatchLevelEnum.SEMANTIC) {
      return <span key={match.value}>{match.value}</span>;
    }
    const value = truncateAroundTargetWord(
      match.value,
      "<em>",
      maxWords,
      maxCharacters
    );

    const colorClass = _get(textColorClass, color);
    return styleSearchResponseText(value, {
      highlightClassName: `${colorClass} font-semibold`,
      sentenceCase: true,
    });
  });

  return scopeElements;
}
