import type { FormikValues } from "formik";
import { useAtomValue } from "jotai";
import { useState } from "react";
import * as yup from "yup";

import { ApiService } from "../../generated";
import { isAuthenticatedState, userDetailsState } from "../../jotai/user";
import { Typography } from "../../library";
import {
  InlineFormWrapper,
  LabeledInputField,
  TextareaField,
} from "../../library/form";
import FileUploadsField from "../../library/form/FileUploadsField";
import { useSubmitAndSave } from "../../modals/constants";
import { FileUploadSource } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackBugReportSubmit } from "../../utils/tracking";

const FIELDS = [
  [
    {
      name: "name",
      component: LabeledInputField,
      label: "Name",
      className: "analytics-bug-report-name",
      dataTestId: "bug-report-name",
      validate: yup.string().required("Name cannot be empty"),
    },
    {
      name: "email",
      component: LabeledInputField,
      label: "Email",
      className: "analytics-bug-report-email",
      dataTestId: "bug-report-email",
      validate: yup.string().email().required("Email cannot be empty"),
    },
  ],
  [
    {
      name: "detail",
      component: TextareaField,
      label: "Detail",
      className: "analytics-bug-report-detail",
      dataTestId: "bug-report-detail",
      validate: yup.string().required("Message cannot be empty"),
    },
  ],
  [
    {
      name: "bugFiles",
      label: "Attach files (optional, 2 max)",
      component: FileUploadsField,
      validate: yup.array().max(2, "Please upload a max of 2 files."),
      source: FileUploadSource.BUGS_PAGE,
    },
  ],
];

export default function BugReportForm() {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const { firstName, lastName, email } = useAtomValue(userDetailsState);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const initialName =
    (firstName ? firstName : "") +
    (firstName && lastName ? " " : "") +
    (lastName ? lastName : "");

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      try {
        await ApiService.apiV1ContactBugReportCreate({
          name: values.name,
          email: values.email,
          detail: values.detail,
          files: values.bugFiles,
        });
      } catch (e) {
        handleError(e);
        setErrorMessage(
          "We could not process this bug report, please try again later."
        );
        return;
      }
      setSubmitted(true);

      const fileNames = values.bugFiles?.map((file: File) => file.name);
      const fileTypes = values.bugFiles?.map((file: File) => file.type);
      trackBugReportSubmit({
        name: values.name,
        email: values.email,
        detail: values.detail,
        fileNames,
        fileTypes,
      });
    }
  );

  return (
    <>
      {!isAuthenticated ? (
        <Typography>You must have an account to report a bug.</Typography>
      ) : submitted ? (
        <Typography>We have received your bug report. Thank you!</Typography>
      ) : (
        <>
          <Typography className="mb-6">
            Thanks for helping us improve the site! Please let us know in the
            details the steps to reproduce the bug.
          </Typography>
          <InlineFormWrapper
            fields={FIELDS}
            initialValues={{
              name: initialName,
              email,
              detail: "",
              bugFiles: [],
            }}
            submitCta="Send message"
            disabled={isLoading}
            submitClassName="analytics-submit-bug-report"
            onSubmit={handleSubmit}
          />
        </>
      )}
      {errorMessage && (
        <Typography
          variant="meta"
          color="destructive.default.primary.enabled"
          className="mt-6"
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
}
