import Squiggle from "../../../../img/home/squiggle.svg";
import OfficeWorkerBinders from "../../../../img/home/stock-images/office-worker-with-binders.png";
import PersonWritingLaptop from "../../../../img/home/stock-images/person-writing-with-laptop.png";
import { Button, ButtonThemes, Link, Typography } from "../../../library";

interface SolutionTextSectionProps {
  heading: string;
  title: string;
  body: string;
  buttonText: string;
  buttonLink: string;
  buttonAnalytics: string;
}
function SolutionTextSection({
  heading,
  title,
  body,
  buttonText,
  buttonLink,
  buttonAnalytics,
}: SolutionTextSectionProps) {
  return (
    <div className="flex flex-col gap-6 md:gap-10 text-left items-start z-[3]">
      <Typography
        variant="display"
        size="xs"
        color="brand.default.primary.enabled"
        emphasis
      >
        {heading}
      </Typography>
      <div>
        <Typography
          variant="headline"
          className="font-homeHeader md:text-cp-display-sm"
          color="neutral.boldest.enabled"
        >
          {title}
        </Typography>
        <Typography
          variant="body"
          color="neutral.boldest.enabled"
          className="mt-4 md:text-cp-headline-sm"
        >
          {body}
        </Typography>
      </div>
      <Button
        as={Link}
        className={buttonAnalytics}
        linkProps={{ href: buttonLink, underline: false }}
        theme={ButtonThemes.SECONDARY_DARK}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default function SolutionSection() {
  return (
    <>
      <img
        className="hidden md:block absolute mt-64 w-[105%] z-[2]"
        src={Squiggle}
        alt="Lapis squiggle"
      />
      <div className="bg-cp-violet-100">
        <div className="py-12 md:py-16 cp-page-container xl:px-0 m-auto flex flex-col gap-8 md:gap-16">
          <Typography
            variant="headline"
            size="lg"
            color="brand.boldest.enabled"
            className="font-homeHeader text-center md:text-cp-display-lg"
            component="h2"
          >
            Solutions for every workflow
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-16 items-center">
            <SolutionTextSection
              heading="for public entities"
              title="Pavilion leverages your network of peers to help you find the right contract with one simple search"
              body="Save time and cut costs using contracts for products, services, brands, or suppliers &#8211; all for free. Download or view solicitation and contract documents. Streamline interactions with suppliers."
              buttonText="Explore Pavilion for public entities"
              buttonLink="/about/public-entities"
              buttonAnalytics="analytics-homepage-publicEntityExplore"
            />
            <div className="hidden md:block bg-cp-midnight-300 md:max-w-[36rem] rounded-[2.5rem] md:pl-12 lg:pl-24 md:place-self-end">
              <img
                className="object-cover h-full w-fit -mt-7 max-h-[40rem]"
                src={OfficeWorkerBinders}
                alt="Office worker with binders"
              />
            </div>
            <div className="hidden md:block bg-cp-leaf-400 md:max-w-[36rem] rounded-[2.5rem]">
              <img
                className="object-cover h-full w-fit -mt-4"
                src={PersonWritingLaptop}
                alt="Person at desk writing in a notebook"
              />
            </div>
            <SolutionTextSection
              heading="for suppliers"
              title="Grow your business using contracts you've already won"
              body="Thousands of government buyers gather under Pavilion's roof each month to find businesses like yours."
              buttonText="Explore Pavilion for suppliers"
              buttonLink="/about/suppliers"
              buttonAnalytics="analytics-homepage-supplierExplore"
            />
          </div>
        </div>
      </div>
    </>
  );
}
