import { useCallback } from "react";
import type { VagueQuerySuggestionsResponse } from "../generated";
import { ApiService } from "../generated/services/ApiService";
import { modals } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import useShowModal from "./useShowModal";

export type OnCompleteOptions = {
  newQuery?: string;
  selectedOption?: string;
};
export type OnCompleteFn = (options: OnCompleteOptions) => void;

async function isAmbiguousQuery(
  query: string
): Promise<Maybe<VagueQuerySuggestionsResponse>> {
  if (!query) return null;

  try {
    const response = await ApiService.apiV1VagueQuerySuggestionsRetrieve(query);
    return response;
  } catch (error) {
    handleError(error);
    return null;
  }
}

/* This hook displays the search disambiguation experience, returning a new disambiguated
 * query based on the user's responses. The resulting function should be awaited prior
 * to continuing the search flow.  */
export default function useSearchDisambiguationSurvey(query: string) {
  const showSearchDisambiguation = useShowModal(modals.SEARCH_DISAMBIGUATION);

  return useCallback(
    (): Promise<OnCompleteOptions> =>
      new Promise((resolve) => {
        isAmbiguousQuery(query).then((response) => {
          if (!response || !response?.isVague) {
            resolve({});
            return;
          }

          const onComplete: OnCompleteFn = (options) => resolve(options);
          showSearchDisambiguation({
            originalQuery: query,
            suggestionType: response.suggestionType,
            supplierDisplayName: response.supplierDisplayName,
            searchOptions: response.suggestions,
            onComplete,
          });
        });
      }),
    [showSearchDisambiguation, query]
  );
}
