import type { FormikValues } from "formik";
import { useState } from "react";
import * as yup from "yup";

import useShowModal from "../../hooks/useShowModal";
import { Typography } from "../../library";
import { ChipInputType } from "../../library/Input/ChipInput";
import { ChipInputField, FormWrapper } from "../../library/form";
import Modal from "../../shared/Modal/Modal";
import { hasWindow } from "../../utils";
import { modals } from "../../utils/enums";
import { trackCopyProjectLink, trackProjectInvite } from "../../utils/tracking";
import { useSubmitAndSave } from "../constants";

import { ApiService } from "../../generated";
import { handleError } from "../../utils/generatedApi";
import CopyLinkInput from "./CopyLinkInput";

interface ProjectInviteModalProps {
  hideModal: () => void;
  projectId: string;
}

const FIELDS = [
  {
    name: "projectInviteEmails",
    dataTestId: "project-invite-emails",
    component: ChipInputField,
    type: ChipInputType.Email,
    message: "Enter email addresses separated by commas.",
    validate: yup
      .array()
      .min(
        1,
        "Enter at least one email address to share this project with your colleagues."
      ),
  },
];

export default function ProjectInviteModal({
  hideModal,
  projectId,
}: ProjectInviteModalProps) {
  const [error, setError] = useState("");
  const sharedUrl = hasWindow() ? window.location.href : ""; // TODO: Add UTM params for analytics?
  const showConfirmationModal = useShowModal(modals.CONFIRMATION);

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      try {
        await ApiService.apiV1ProjectsInviteCreate(projectId, {
          projectInviteEmails: values.projectInviteEmails,
        });
        trackProjectInvite({ emails: values.projectInviteEmails });
        showConfirmationModal({
          title: "Invites Sent!",
          subtitle:
            "When your colleague joins the project, you’ll see their name at the top of this page.",
          ctaText: "Close",
        });
      } catch (e) {
        handleError(e);
        setError(
          "Your invites could not be sent at this time, please try again later."
        );
      }
    }
  );

  const copyLinkMessage =
    "Your project can be shared with anyone using this link, but they will not be able to edit the project unless they are a collaborator.";

  return (
    <Modal
      hideModal={hideModal}
      title="Invite your team to collaborate on this project"
      error={error}
    >
      <div className="flex flex-col gap-8">
        <Typography color="neutral.bolder.enabled">
          Email this project to a colleague or copy the link to share.
        </Typography>
        <FormWrapper
          fields={FIELDS}
          onSubmit={handleSubmit}
          initialValues={{ projectInviteEmails: [] }}
          disabled={isLoading}
          submitClassName="analytics-invite-project-cta w-fit"
          submitCta="Send"
          inline
        />
        {sharedUrl && (
          <CopyLinkInput
            link={sharedUrl}
            message={copyLinkMessage}
            trackCopyLink={() => {
              trackCopyProjectLink({ projectId });
            }}
          />
        )}
      </div>
    </Modal>
  );
}
