import TuneRoundedIcon from "@mui/icons-material/TuneRounded";

import { useAtom } from "jotai";
import type { SearchOptions } from "../../../components/ContractSearch/types";
import { useCanShowDebug } from "../../../hooks/useCanShowDebug";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import useShowModal from "../../../hooks/useShowModal";
import useUserStateCode from "../../../hooks/useUserStateCode";
import { debugState } from "../../../jotai/search";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
} from "../../../library";
import { SearchActions, SearchSource, modals } from "../../../utils/enums";
import CompactSearchResultTypeToggle from "./CompactSearchResultTypeToggle";
import { ContractDocumentsFilterPopover } from "./ContractDocumentsFilter";
import { ExpirationFilterPopover } from "./ExpirationFilter";
import FilterPills from "./FilterPills";
import OtherFiltersPopover from "./OtherFilters";
import { SourcesFilterPopover } from "./SourcesFilter";
import { SupplierLocationFilterPopover } from "./SupplierLocationFilter";
import type { FilterParams, OnFilterChangeFn, OnToggleFilterFn } from "./types";

export enum SearchFilter {
  SOURCES = "sources",
  EXPIRATION = "expiration",
  CONTRACT_DOCUMENTS = "contractDocuments",
  SUPPLIER_LOCATION = "supplierLocation",
  OTHER = "other",
}

export default function SearchFilterHeader({
  onFilterChange,
  filters,
  setFilters,
  filtersToShow,
  searchSource,
  onSearch,
  hideSearchResultTypeToggle,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: string[];
  setFilters: (f: string[]) => void;
  filtersToShow?: SearchFilter[];
  searchSource: SearchSource;
  onSearch?: (options: SearchOptions) => void;
  hideSearchResultTypeToggle?: boolean;
}) {
  const [debug, setDebug] = useAtom(debugState);
  const isLandingPage =
    searchSource === SearchSource.LANDING_PAGE_ENTITY_SEARCH;
  const isSupplierPage = searchSource === SearchSource.SUPPLIER_PAGE;

  const performSearch = onSearch || useSearchContractWithParams();

  const userStateCode = useUserStateCode();
  const canShowDebug = useCanShowDebug() && !isSupplierPage;
  const showFiltersModal = useShowModal(modals.FILTERS);

  const showAllFilters = !filtersToShow;

  const showSourcesFilter = shouldShowFilter(SearchFilter.SOURCES);
  const showExpirationFilter = shouldShowFilter(SearchFilter.EXPIRATION);
  const showContractDocumentsFilter = shouldShowFilter(
    SearchFilter.CONTRACT_DOCUMENTS
  );
  const showSupplierLocationFilter = shouldShowFilter(
    SearchFilter.SUPPLIER_LOCATION
  );

  const showOtherFilters = shouldShowFilter(SearchFilter.OTHER);

  function shouldShowFilter(filter: SearchFilter) {
    return showAllFilters || filtersToShow?.includes(filter);
  }

  const onToggleFilter: OnToggleFilterFn = (append, filter) => {
    let update: string[];
    if (append) update = [...filters, filter];
    else update = [...filters.filter((value) => value !== filter)];

    setFilters(update);
    onFilterChange({ type: filter, value: append ? "on" : "off" });

    performSearch({
      newParams: { filters: update },
      action: SearchActions.SAVE_FILTERS,
    });
  };

  const onToggleMultipleFilters = (filterUpdates: Record<string, boolean>) => {
    let update = [...filters];

    Object.entries(filterUpdates).forEach(([filter, append]) => {
      if (append) {
        if (!update.includes(filter)) {
          update.push(filter);
        }
      } else {
        update = update.filter((value) => value !== filter);
      }
      onFilterChange({
        type: filter as FilterParams,
        value: append ? "on" : "off",
      });
    });

    setFilters(update);
    performSearch({
      newParams: { filters: update },
      action: SearchActions.SAVE_FILTERS,
    });
  };

  return (
    <>
      <div className="hidden sm:block">
        <div className="flex flex-wrap sm:flex-nowrap gap-3">
          {!hideSearchResultTypeToggle && <CompactSearchResultTypeToggle />}
          <div className="flex flex-wrap gap-3">
            {showSourcesFilter && !isSupplierPage && (
              <SourcesFilterPopover onChange={onFilterChange} />
            )}
            {showSupplierLocationFilter && userStateCode && (
              <SupplierLocationFilterPopover
                stateCode={userStateCode}
                onToggleFilter={onToggleFilter}
                onChange={onFilterChange}
                filters={filters}
              />
            )}
            {showExpirationFilter && (
              <ExpirationFilterPopover
                isLandingPage={isLandingPage}
                onChange={onFilterChange}
                filters={filters}
                onToggleFilter={onToggleFilter}
                isSupplierPage={isSupplierPage}
                onToggleMultipleFilters={onToggleMultipleFilters}
              />
            )}
            {showContractDocumentsFilter && (
              <ContractDocumentsFilterPopover
                onChange={onFilterChange}
                filters={filters}
                onToggleFilter={onToggleFilter}
              />
            )}
            {showOtherFilters && (
              <OtherFiltersPopover
                onChange={onFilterChange}
                onToggleFilter={onToggleFilter}
                filters={filters}
                searchSource={searchSource}
                onSearch={onSearch}
                setFilters={setFilters}
              />
            )}
            {canShowDebug && (
              <Button
                size={ButtonSizes.SMALL}
                theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
                onClick={() => {
                  setDebug(!debug);
                }}
              >
                Toggle Debug
              </Button>
            )}
          </div>
        </div>
        <FilterPills
          filters={showAllFilters ? filters : filtersToShow}
          userStateCode={userStateCode}
          onToggleFilter={onToggleFilter}
          onSearch={onSearch}
        />
      </div>
      <div className="sm:hidden max-w-fit">
        <div className="flex gap-3">
          {!hideSearchResultTypeToggle && <CompactSearchResultTypeToggle />}
          <Badge
            Icon={TuneRoundedIcon}
            as={Link}
            onClick={() =>
              showFiltersModal({
                filters,
                onToggleFilter,
                onFilterChange,
                isLandingPage,
              })
            }
            linkProps={{ underline: false, emphasis: false }}
            className="rounded-none"
          >
            Filter
          </Badge>
        </div>
      </div>
    </>
  );
}
