import type { ElementType, ReactNode } from "react";

import { Typography } from "../../library";
import type {
  TypographyColor,
  TypographySize,
} from "../../library/Typography/types";
import {
  PoweredByPavilion,
  WidgetThemes,
} from "../../shared/SearchBar/WidgetSearchBar/shared";

interface WidgetSearchBarTitleProps {
  title: string | ReactNode;
  subtitle?: string;
  titleTag?: ElementType;
  showPavilionLogo?: boolean;
  theme?: WidgetThemes;
}

const STYLE = {
  [WidgetThemes.DEFAULT]: {
    titleColor: "subtle",
    size: "md",
    emphasis: false,
  },
  [WidgetThemes.OPENGOV]: {
    titleColor: "brand.boldest.enabled",
    size: "sm",
    emphasis: true,
  },
};

export default function WidgetSearchBarTitle({
  title,
  subtitle,
  titleTag,
  showPavilionLogo = false,
  theme = WidgetThemes.DEFAULT,
}: WidgetSearchBarTitleProps) {
  const TitleTag = titleTag || ("h2" as ElementType);
  return (
    <div>
      <div className="w-full inline-flex justify-between">
        <Typography
          variant="headline"
          color={STYLE[theme].titleColor as TypographyColor}
          className="mb-2"
          component={TitleTag}
          size={STYLE[theme].size as TypographySize}
          emphasis={STYLE[theme].emphasis}
        >
          {title}
        </Typography>
        {showPavilionLogo && <PoweredByPavilion />}
      </div>
      <Typography variant="body" size={STYLE[theme].size as TypographySize}>
        {subtitle}
      </Typography>
    </div>
  );
}
