import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const DEFAULT_KCRPC_BLA_ID = "d033e0f1-bc4d-4228-9e4a-deea2b77b4f7";
const DEFAULT_KCRPC_ZIP = "64106";
const DEFAULT_GOV_AFFILIATION =
  "Kansas City Regional Purchasing Cooperative (KCRPC)";

function KCRPC() {
  const { zip, entity } = getParams();
  function getCustomizedUrl(url) {
    url.searchParams.append("embedSourceEntityId", DEFAULT_KCRPC_BLA_ID);
    url.searchParams.append("zip", zip || DEFAULT_KCRPC_ZIP);
    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );
    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Search KCRPC Contracts"
        subtitle="Thousands of contracts you can use covering vehicles, office equipment, software, and more."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          getCustomizedUrl={getCustomizedUrl}
          searchSource={agencySearchSource[agencies.KCRPC]}
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-kcrpc");
if (container) {
  const root = createRoot(container);
  root.render(<KCRPC />);
}
