import useShowModal from "../../hooks/useShowModal";
import { Button, ButtonThemes, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import Tag, { TagVariants } from "../../shared/Tag";
import { modals } from "../../utils/enums";
import {
  trackChooseToPiggybackContracts,
  trackSkipPiggybackLaunchModal,
} from "../../utils/tracking";

interface PiggybackLaunchModalProps {
  hideModal: () => void;
  updateUserState: () => void;
}

export default function PiggybackLaunchModal({
  hideModal,
  updateUserState,
}: PiggybackLaunchModalProps) {
  const showNextModal = useShowModal(modals.RECENTLY_VIEWED_CONTRACTS_MODAL);

  function handleSkipForNowClick() {
    trackSkipPiggybackLaunchModal();
    updateUserState?.();
    hideModal();
  }

  function handleClick() {
    trackChooseToPiggybackContracts();
    updateUserState?.();
    showNextModal({});
  }

  const ctas = (
    <div className="flex gap-4 justify-center">
      <Button
        onClick={handleSkipForNowClick}
        theme={ButtonThemes.TERTIARY_DARK}
        dataTestId="skip-for-now-button"
      >
        Skip for now
      </Button>
      <Button onClick={handleClick} dataTestId="add-contracts-cta">
        Set up notifications
      </Button>
    </div>
  );

  return (
    <Modal
      hideModal={() => {
        hideModal();
        updateUserState?.();
      }}
      title="Never miss an expiring contract"
      ctaSection={ctas}
      modalSize={modalSizes.SMALL}
      showLogoHeader
      className="analytics-piggybacks-launch-modal"
      logoHeader={
        <Tag className="-mb-5" variant={TagVariants.NEW_FEATURE}>
          New Feature
        </Tag>
      }
    >
      <Typography color="neutral.bolder.enabled">
        Get email notifications 6 months before contracts expire. That’s more
        time for you to find or create other contract vehicles in advance to
        meet your needs.
      </Typography>
    </Modal>
  );
}
