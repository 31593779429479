import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import type { ElementType, ReactNode } from "react";

import useShowModal from "../../../hooks/useShowModal";
import useSupplierEditUrl from "../../../hooks/useSupplierEditUrl";
import { userState } from "../../../jotai/user";
import { Badge, Banner, ClickableCard, Typography } from "../../../library";
import { borderColorClass } from "../../../utils/colors";
import { modals } from "../../../utils/enums";

export default function SupplierTasksSection() {
  const supplierEditUrl = useSupplierEditUrl();
  const showModal = useShowModal(modals.SUPPLIER_PHONE);
  const { email, supplier } = useAtomValue(userState);
  const needsPhone = !supplier?.manualContacts.find(
    (c) => c.email === email && !!c.phoneNumber?.phoneNumber
  );
  const needsServiceArea =
    !supplier ||
    !(supplier.manualServiceAreaNational || supplier.manualServiceAreaState);
  const needsWebsite = !supplier?.website;
  const needsDescription = !supplier?.about;
  const needsAddress = !supplier?.addressString;
  const needsContacts = !supplier?.manualContacts.length;
  const hasTasks = [
    needsPhone,
    needsServiceArea,
    needsWebsite,
    needsDescription,
    needsAddress,
    needsContacts,
  ].some((v) => v);

  return (
    <div
      className={clsx(
        "analytics-supplier-tasks-section flex flex-col gap-8 rounded-8 p-6 border border-solid",
        borderColorClass.neutral.subtle.enabled
      )}
    >
      <Typography variant="headline" size="sm" color="brand.boldest.enabled">
        Improve your performance with Pavilion
      </Typography>
      <div className="grid grid-cols-1 gap-6">
        {!hasTasks && (
          <Banner color="accent.default.tertiary.enabled" className="py-6">
            <Badge
              as={Typography}
              linkProps={{
                color: "accent.default.tertiary.enabled",
              }}
              Icon={AutoAwesomeOutlinedIcon}
              className="justify-center"
            >
              Congrats! You have completed all the tasks at the moment.
            </Badge>
          </Banner>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <TaskCard
            title="Add your business address"
            text="Increase your visibility to buyers searching from your area."
            Icon={StoreOutlinedIcon}
            show={needsAddress}
            href={supplierEditUrl}
          />
          <TaskCard
            title="Include a website link"
            text="Show more about what you do."
            Icon={LanguageOutlinedIcon}
            show={needsWebsite}
            href={supplierEditUrl}
          />
          <TaskCard
            title="Edit your contact info"
            text="Help buyers get in touch with the right point of contact at your company."
            Icon={AccountCircleOutlinedIcon}
            show={needsContacts}
            href={`${supplierEditUrl}#contacts`}
          />
          <TaskCard
            title="Add a business description"
            text="Make it easy for buyers to learn about what you do."
            Icon={EditOutlinedIcon}
            show={needsDescription}
            href={supplierEditUrl}
          />
          <TaskCard
            title="Add your phone number"
            text="Phone numbers make it easier for buyers to reach out to you about new business requests."
            Icon={AddIcCallOutlinedIcon}
            show={needsPhone}
            onClick={() => showModal({})}
          />
          <TaskCard
            title="Add your service area"
            text="Service area helps us match your business with public buyers in the geographic region you can serve."
            Icon={AddLocationAltOutlinedIcon}
            show={needsServiceArea}
            href={`${supplierEditUrl}#service-area`}
          />
        </div>
      </div>
    </div>
  );
}

interface TaskCardProps {
  Icon: ElementType;
  title: string;
  text: ReactNode;
  onClick?: () => void;
  show?: boolean;
  href?: Maybe<string>;
}

function TaskCard({
  Icon,
  title,
  text,
  onClick,
  show = true,
  href,
}: TaskCardProps) {
  if (!show) return null;
  return (
    <ClickableCard
      className="flex flex-row items-center hover:border-cp-lapis-500"
      onClick={onClick}
      href={href}
    >
      <Icon className="w-8 h-8 mx-4 md:w-12 md:h-12 md:mx-6 text-cp-lapis-500" />
      <div className="grid grid-cols-1 gap-2 pl-3 pr-6 py-6">
        <Typography
          variant="headline"
          size="sm"
          color="brand.default.secondary.enabled"
          emphasis
        >
          {title}
        </Typography>
        <Typography size="sm" color="accent.neutral.enabled">
          {text}
        </Typography>
      </div>
    </ClickableCard>
  );
}
