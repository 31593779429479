import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import clsx from "clsx";
import type { ReactNode } from "react";

import {
  autoPlacement,
  autoUpdate,
  offset,
  useFloating,
} from "@floating-ui/react";
import { TooltipContent, Typography } from "../../library";
import { textColorClass } from "../../utils/colors";

export default function ConfirmedBadge({
  tooltip,
  children,
}: { tooltip: string; children: ReactNode }) {
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [offset(4), autoPlacement({ alignment: "start" })],
  });

  return (
    <Typography
      ref={refs.setReference}
      component="span"
      size="sm"
      className={clsx(
        textColorClass.feedback.bold.success,
        "group relative inline-block align-text-bottom"
      )}
    >
      <CheckCircleRoundedIcon className="w-4 h-4" />
      &nbsp;{children}
      <TooltipContent
        ref={refs.setFloating}
        style={floatingStyles}
        className="w-80"
      >
        {tooltip}
      </TooltipContent>
    </Typography>
  );
}
