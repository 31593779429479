import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import { type MouseEvent, type ReactNode, Suspense } from "react";

import loaderGifIcon from "../../../img/loaders/loader.gif";
import { Badge, Button, Logo, LogoSizes, Typography } from "../../library";
import type { TypographySize } from "../../library/Typography/types";
import { logoPavilion } from "../../utils/img";

export enum modalSizes {
  SMALL = "SMALL",
  DEFAULT = "DEFAULT",
  LARGE = "LARGE",
}

const widthBySize = {
  [modalSizes.SMALL]: {
    width: "w-2/3",
    max_width: "max-w-140",
  },
  [modalSizes.DEFAULT]: {
    width: "w-2/3",
    max_width: "max-w-160",
  },
  [modalSizes.LARGE]: {
    width: "w-2/3",
    max_width: "max-w-200",
  },
};

interface ModalFormWrapperProps {
  needsForm: boolean;
  children: JSX.Element;
  formID?: string;
  onCtaClick?: (e?: unknown) => void;
}
const ModalFormWrapper = ({
  needsForm,
  children,
  formID,
  onCtaClick,
}: ModalFormWrapperProps) => {
  return needsForm ? (
    <form id={formID} onSubmit={onCtaClick}>
      {children}
    </form>
  ) : (
    children
  );
};

interface ModalProps {
  hideModal: () => void;
  goBack?: () => void;
  isBlocking?: boolean;
  title?: string;
  titleSize?: TypographySize;
  titleClassName?: string;
  subtitle?: string;
  error?: string;
  children: ReactNode;
  ctaText?: string;
  ctaClass?: string;
  disableCta?: boolean;
  ctaSection?: ReactNode;
  ctaSectionClass?: string;
  onCtaClick?: (e?: unknown) => void;
  isCtaLoading?: boolean;
  formID?: string;
  className?: string;
  contentClassName?: string;
  showLogoHeader?: boolean;
  logoHeader?: ReactNode;
  supplierLogoUrl?: string;
  modalSize?: modalSizes;
  centerSubtitle?: boolean;
  customWidthClass?: string;
  ignorePadding?: boolean;
}
export default function Modal({
  hideModal,
  goBack,
  isBlocking,
  title,
  titleSize,
  titleClassName,
  subtitle,
  error,
  children,
  ctaText,
  ctaClass,
  disableCta,
  ctaSection,
  ctaSectionClass,
  onCtaClick,
  isCtaLoading,
  formID,
  className,
  contentClassName,
  centerSubtitle = true,
  showLogoHeader = false,
  logoHeader,
  supplierLogoUrl,
  modalSize = modalSizes.DEFAULT,
  ignorePadding,
}: ModalProps) {
  let cta: ReactNode;
  if (ctaText && onCtaClick) {
    cta = (
      <div className={ctaSectionClass || "flex justify-center mt-12"}>
        <Suspense
          fallback={
            <Button className={ctaClass}>
              <img src={loaderGifIcon} className="w-6" alt="Loading" />
            </Button>
          }
        >
          <Button
            className={ctaClass}
            disabled={disableCta || isCtaLoading}
            onClick={onCtaClick}
            type="submit"
          >
            {isCtaLoading ? (
              <img src={loaderGifIcon} className="w-6" alt="Loading" />
            ) : (
              ctaText
            )}
          </Button>
        </Suspense>
      </div>
    );
  } else if (ctaSection) {
    cta = <div className={ctaSectionClass}>{ctaSection}</div>;
  }

  const onClickOverlay = (e: MouseEvent) => {
    e.stopPropagation();
    if (isBlocking) return;
    hideModal();
  };

  return (
    <ModalFormWrapper
      needsForm={!!formID && !!onCtaClick}
      formID={formID}
      onCtaClick={onCtaClick}
    >
      <div className="fixed w-full h-full top-0 left-0 z-3">
        <div
          className="w-full h-full bg-black opacity-50"
          onClick={onClickOverlay}
        />
        <div
          className={clsx(
            "absolute left-0 top-0 md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 bg-white flex flex-col rounded-2xl h-full md:h-fit md:max-h-[95vh] max-md:w-full max-md:max-w-none",
            { "pt-10 pb-14 px-6 md:px-10": !ignorePadding },
            className,
            widthBySize[modalSize].width,
            widthBySize[modalSize].max_width // Setting width and max width requires the opposite breakpoint
          )}
        >
          {goBack && (
            <div
              onClick={goBack}
              className={clsx(
                "analytics-modal-back absolute z-1 cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-6 h-6",
                {
                  "top-6 left-8": !ignorePadding,
                  "top-4 left-4": ignorePadding,
                }
              )}
            >
              <Badge Icon={ArrowBackRoundedIcon} className="ml-[2px]" />
            </div>
          )}
          {!isBlocking && (
            <button
              className={clsx(
                "analytics-close-modal absolute z-1 cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-5 h-5",
                {
                  "top-6 right-8": !ignorePadding,
                  "top-4 right-4": ignorePadding,
                }
              )}
              onClick={onClickOverlay}
            >
              <Badge Icon={CloseRoundedIcon} />
            </button>
          )}
          <div
            className={clsx(
              "flex flex-col items-center gap-8 mb-4 empty:hidden",
              titleClassName
            )}
          >
            {showLogoHeader &&
              (logoHeader ? (
                logoHeader
              ) : (
                <div className="flex items-center justify-center z-3 w-full">
                  <img
                    src={logoPavilion}
                    className="h-[35px]"
                    alt="Pavilion Logo"
                  />
                  {supplierLogoUrl && (
                    <>
                      <Badge
                        Icon={CloseRoundedIcon}
                        size="lg"
                        iconClass=""
                        className="ml-3 mr-3"
                      />
                      <Logo imageSrc={supplierLogoUrl} size={LogoSizes.LARGE} />
                    </>
                  )}
                </div>
              ))}
            {(title || subtitle) && (
              <div className="grid gap-2">
                {title && (
                  <Typography
                    variant="headline"
                    size={titleSize ?? "sm"}
                    color="brand.default.secondary.enabled"
                    emphasis
                    className={clsx(
                      "w-full border-solid border-white bg-white text-center"
                    )}
                  >
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    color="neutral.default.primary.enabled"
                    className={clsx({
                      "text-center": centerSubtitle,
                    })}
                  >
                    {subtitle}
                  </Typography>
                )}
              </div>
            )}
          </div>
          {/*Set overflow to auto so that the modal header is always visible*/}
          <div
            className={clsx("overflow-auto w-full", contentClassName, {
              "pt-4": !ignorePadding,
            })}
          >
            {children}
          </div>

          <div className="empty:hidden mt-8 pb-2 bg-cp-white-100">
            {cta}
            {error && (
              <Typography
                className="mt-3 text-center"
                color="neutral.bold.enabled"
              >
                {error}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </ModalFormWrapper>
  );
}
