import { Link, Table, Typography } from "../../../../library";
import { stateLabels } from "../../../../utils/constants";
import { formatDate, parseDate } from "../../../../utils/date";
import { trackSupplierDemoClick } from "../../../../utils/tracking";
import { DEMO_URL } from "../../constants";
import type { BuyerInsightsProps } from "./index";

export default function BuyerActivity({
  viewAnalytics,
  supplierData,
  isLoading,
}: Omit<BuyerInsightsProps, "className">) {
  const hasActiveContracts = supplierData.numActiveShareableContracts > 0;

  function handleClick() {
    trackSupplierDemoClick({
      ...supplierData,
      source: "Buyer Activity",
    });
    window.open(DEMO_URL);
  }

  return (
    <div className="flex flex-col gap-6">
      <Typography size="sm" color="neutral.bolder.enabled">
        Buyers who have recently viewed your contracts on Pavilion.
      </Typography>

      <div className="w-full max-h-[436px]">
        <Table
          columns={[
            {
              key: "displayName",
              label: "Public entity",
              render: () => (
                <Typography color="neutral.bolder.enabled" variant="meta">
                  Public entity
                </Typography>
              ),
              isSortable: true,
              variant: "meta",
            },
            {
              key: "state",
              label: "State",
              render: (v) => (
                <Typography color="neutral.bolder.enabled" variant="meta">
                  {stateLabels[v as string] || v}
                </Typography>
              ),
              isSortable: true,
              variant: "meta",
            },
            {
              key: "entityType",
              label: "Entity type",
              render: () => <Typography color="disabled">--</Typography>,
              isSortable: true,
              variant: "meta",
            },
            {
              key: "uniqueUsers",
              label: "Unique users",
              render: () => <Typography color="disabled">--</Typography>,
              isSortable: true,
              variant: "meta",
            },
            {
              key: "totalSessions",
              label: "Total sessions",
              render: () => <Typography color="disabled">--</Typography>,
              isSortable: true,
              variant: "meta",
            },
            {
              key: "date",
              label: "Latest activity date",
              render: (v) => (
                <Typography color="neutral.bolder.enabled" variant="meta">
                  {formatDate(parseDate(v as string), "MM/dd/yyyy")}
                </Typography>
              ),
              isSortable: true,
              variant: "meta",
            },
          ]}
          data={viewAnalytics.sessions || []}
          isLoading={isLoading}
          defaultSort={{ key: "date", descending: true }}
          className="w-full"
          headerSize="sm"
        />
      </div>

      <Typography variant="meta" size="sm" color="neutral.bolder.enabled">
        *Basic supplier accounts have limited access to buyer activity data and
        can only see the five most recent sessions.{" "}
        {hasActiveContracts && (
          <>
            <Link
              variant="meta"
              size="sm"
              emphasis={false}
              underline={false}
              onClick={handleClick}
            >
              Upgrade to a Pro
            </Link>{" "}
            <span>
              account to see more entities who have viewed you, their details,
              and recent activity.
            </span>
          </>
        )}
      </Typography>
    </div>
  );
}
