import clsx from "clsx";
import pluralize from "pluralize";

import paperPlaneSparkles from "../../../img/welcome/paperPlaneSparkles.svg";
import { messageSupplierStoreAtom } from "../../jotai/messageSupplier";
import { Button, Typography } from "../../library";
import { bgColorClass } from "../../utils/colors";
import { ProjectCreationSource } from "../Project/types";

import { useAtomValue } from "jotai";
import { ItemTypeEnum } from "../../generated";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import { useShowNewProjectModal } from "../../modals/ProjectModals/NewProjectModal";
import SimilarContractSuppliers from "./SimilarContractSuppliers";

interface MessageSupplierSuccessPageProps {
  userHasProjects: boolean;
  supplierDisplayName?: string;
  supplierHandle?: string;
  supplierId?: number;
  contractId?: string;
  solicitationId?: string;
}

export default function MessageSupplierSuccessPage({
  userHasProjects,
  supplierDisplayName,
  supplierHandle,
  supplierId,
  contractId,
  solicitationId,
}: MessageSupplierSuccessPageProps) {
  const messageSupplierStore = useAtomValue(messageSupplierStoreAtom);
  // If the user is coming from SimilarContractSuppliers, originating contract/supplier info is undefined
  const isOriginalMessage = !!supplierId && !!supplierHandle;
  const isContract = !!contractId && !!solicitationId;
  let rootKey = null;
  let rootId = null;
  let rootState = null;
  let successText = "";
  if (isOriginalMessage) {
    if (isContract) {
      rootKey = contractId;
      rootId = contractId;
    } else {
      rootKey = supplierHandle;
      rootId = supplierId;
    }
    rootState = messageSupplierStore[rootKey];
    const numSuppliers = rootState.messagedSuppliers.length - 1;
    successText = `We'll send an email to ${supplierDisplayName} representatives
    ${
      numSuppliers > 0
        ? `and ${numSuppliers} other ${pluralize("supplier", numSuppliers)} `
        : ""
    }
    and help you follow up if needed. Most suppliers respond in 1-3
    business days.`;
  } else {
    successText =
      "We'll pass along your message to the suppliers you contacted. You can expect a response directly from them to your email inbox in 1-3 days.";
  }
  return (
    <div className="bg-cp-white-100 mt-12 mb-80 cp-page-container text-left grid gap-y-10">
      <div className="flex flex-col lg:w-2/3">
        <div
          className={clsx(
            bgColorClass.accent.limeade.enabled,
            "p-6 rounded-2xl flex gap-12"
          )}
        >
          <div className="flex flex-col gap-2">
            <Typography
              color="brand.default.secondary.enabled"
              variant="headline"
              emphasis
            >
              Message sent!
            </Typography>
            <Typography color="neutral.default.primary.enabled">
              {successText}
            </Typography>
            {!userHasProjects && rootId && (
              <NewProjectPrompt
                itemId={rootId.toString()}
                itemType={
                  isContract ? ItemTypeEnum.CONTRACT : ItemTypeEnum.SUPPLIER
                }
              />
            )}
          </div>
          <img src={paperPlaneSparkles} className="h-16 self-center" />
        </div>
      </div>
      {isContract && isOriginalMessage && (
        <SimilarContractSuppliers
          supplierId={supplierId}
          supplierHandle={supplierHandle}
          contractId={contractId}
          solicitationId={solicitationId}
        />
      )}
    </div>
  );
}

type NewProjectPromptProps = {
  itemId: string;
  itemType: ItemTypeEnum;
};

function NewProjectPrompt({ itemId, itemType }: NewProjectPromptProps) {
  const showNewProjectModal = useShowNewProjectModal();
  const saveProjectItem = useSaveProjectItem();

  return (
    <>
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        className="mt-8"
        emphasis
      >
        Want to keep track of this {itemType.toLowerCase()}?
      </Typography>
      <Typography color="neutral.default.primary.enabled">
        You can save this {itemType.toLowerCase()} to an existing project or
        create a new project to ensure that you don{"'"}t lose these contract
        details.
      </Typography>
      <Button
        theme={Button.themes.PRIMARY_DARK}
        onClick={() => {
          showNewProjectModal({
            newProjectPrompt: true,
            itemId,
            itemType,
            onCreate: (projectId) =>
              saveProjectItem({ projectId, itemId, itemType }),
            source: ProjectCreationSource.MESSAGE_SUPPLIER,
          });
        }}
        className="analytics-post-message-new-project-prompt-button w-fit"
        dataTestId="post-message-new-project-prompt-button"
        size={Button.sizes.SMALL}
      >
        Save {itemType.toLowerCase()}
      </Button>
    </>
  );
}
