import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { Button, ButtonSizes, ButtonThemes } from "../../library";

import { useShowNewProjectModal } from "../../modals/ProjectModals/NewProjectModal";
import type { ProjectCreationSource } from "./types";

export default function CreateProjectButton({
  source,
}: {
  source: ProjectCreationSource;
}) {
  const showNewProjectModal = useShowNewProjectModal();

  return (
    <Button
      className="h-fit"
      badgeProps={{
        Icon: AddRoundedIcon,
      }}
      onClick={() => showNewProjectModal({ source })}
      size={ButtonSizes.LARGE}
      theme={ButtonThemes.PRIMARY_DARK}
      type="button"
    >
      Create Project
    </Button>
  );
}
