import clsx from "clsx";
import { useEffect, useState } from "react";

import { Button, ButtonThemes } from "../../library";

interface DownloadAllButtonProps {
  onClick: () => void;
  disabled: boolean;
  className?: string;
}
export default function DownloadAllButton({
  onClick,
  disabled,
  className,
}: DownloadAllButtonProps) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [supported, setSupported] = useState(true);

  useEffect(() => {
    // feature detection
    try {
      const isFileSaverSupported = !!new Blob();
      setSupported(isFileSaverSupported);
    } catch {
      setSupported(false);
    }
  }, []);

  const handleDownload = async () => {
    setIsDownloading(true);
    await onClick();
    setIsDownloading(false);
  };

  if (!supported) return null;

  return (
    <Button
      theme={ButtonThemes.SECONDARY_DARK}
      onClick={handleDownload}
      disabled={disabled || isDownloading}
      className={clsx("analytics-download-all-files", className)}
      size={Button.sizes.SMALL}
    >
      {isDownloading ? "Downloading..." : "Download all docs"}
    </Button>
  );
}
