import costSaving from "../../../../img/home/statistics/cost-saving.svg";
import paperPlane from "../../../../img/welcome/paperPlane.svg";
import searchDocs from "../../../../img/welcome/searchDocs.svg";
import { Typography } from "../../../library";
import { COMPANY_NAME } from "../../../utils/constants";

const panels = [
  {
    image: costSaving,
    text: "Find the right contract",
    subtext:
      "We search across the country’s largest network of shareable contracts, from national purchasing cooperatives to your neighbor’s filing cabinet, to show you the best results for your needs.",
  },
  {
    image: searchDocs,
    text: "Expedite due diligence",
    subtext:
      "Download or view solicitation and contract documents directly in your browser to understand pricing, specifications, scopes of work, and more.",
  },
  {
    image: paperPlane,
    text: "Streamline supplier interactions",
    subtext:
      "Connect with the right supplier contact and get a fast response. We'll start an email thread directly between you and the supplier and provide hands-on support on next steps.",
  },
];

export default function BuyerExplainerSection() {
  function explainerPanel(panel: {
    text: string;
    subtext: string;
    image: string;
  }) {
    return (
      <div key={panel.text} className="flex flex-col items-center">
        <img className="w-24 h-24 mb-4" src={panel.image} alt={panel.text} />
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          className="text-center pb-2"
          emphasis
        >
          {panel.text}
        </Typography>
        <Typography
          color="brand.boldest.enabled"
          className="text-center max-w-[300px]"
        >
          {panel.subtext}
        </Typography>
      </div>
    );
  }

  return (
    <div className="bg-cp-violet-200 items-center">
      <div className="py-16 cp-page-container xl:px-0 m-auto flex flex-col items-center gap-y-9">
        <Typography
          variant="display"
          size="sm"
          color="brand.boldest.enabled"
          className="text-center"
        >
          Fast-track the public procurement process with {COMPANY_NAME}
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
          {panels.map((stat) => explainerPanel(stat))}
        </div>
      </div>
    </div>
  );
}
