import { Form, Formik } from "formik";
import * as yup from "yup";
import HeartContract from "../../../img/icons/contracts-heart.svg";
import useShowModal from "../../hooks/useShowModal";
import { useValidationSchema } from "../../hooks/useValidationSchema";
import { Typography } from "../../library";
import FileUploadsField from "../../library/form/FileUploadsField";
import { fieldMapFn } from "../../library/form/utils";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { CSM_EMAIL_ADDRESS } from "../../utils/constants";
import { FileUploadSource, modals } from "../../utils/enums";
import SubmitButton from "../SignupSteps/SubmitButton";

interface UploadProductListModalProps {
  supplierId?: string;
  onSubmit: (productLists: File[]) => Promise<boolean>;
  hideModal: () => void;
}

const FIELDS = [
  {
    name: "productLists",
    label: "Attach spreadsheet files (.csv, .xls, .xlsx)",
    component: FileUploadsField,
    source: FileUploadSource.SUPPLIER_PRODUCTS_PAGE,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  },
];

export default function UploadProductListModal({
  onSubmit,
  hideModal,
}: UploadProductListModalProps) {
  const validationSchema = useValidationSchema(FIELDS);
  const showModal = useShowModal(modals.CONTRACT_UPLOAD_MESSAGE);
  const showConfirmationModal = () => {
    showModal({
      title: "Successful upload!",
      message:
        "Our team will review this document and upload the data into our system.",
      image: HeartContract,
      isBlocking: true,
      primaryCta: {
        title: "Close",
        onClick: hideModal,
      },
    });
  };
  const showUploadErrorModal = () => {
    showModal({
      title: "Contract upload failed",
      message: (
        <>
          Please try again. Large product lists may need to be split into
          multiple files. If you continue to have trouble, please contact your
          CSM or email{" "}
          <SupportEmailLink
            underline
            className="analytics-pro-supplier-contact-support"
            email={CSM_EMAIL_ADDRESS}
          />
        </>
      ),
      primaryCta: {
        title: "Close",
        onClick: hideModal,
      },
    });
  };
  return (
    <Modal
      hideModal={hideModal}
      className="analytics-upload-product-list-modal"
      contentClassName="flex flex-col gap-8 no-scrollbar"
      formID="analytics-upload-product-list-modal-submit"
      title="Upload product list"
      modalSize={modalSizes.SMALL}
    >
      <Typography color="neutral.bolder.enabled">
        Upload all relevant product lists.
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{ productLists: [] as File[] }}
        onSubmit={async (values: { productLists: File[] }) => {
          const success = await onSubmit(values.productLists);
          if (success) {
            showConfirmationModal();
          } else {
            showUploadErrorModal();
          }
          // TODO Here and in the other upload forms, reset touched fields
          //      so that the upload button is disabled after a successful upload.
        }}
        validationSchema={yup.object(validationSchema)}
      >
        {({ touched, isSubmitting, isValid }) => (
          <Form className="flex flex-col gap-8">
            {FIELDS.map(fieldMapFn)}
            <SubmitButton
              btnClassName="w-fit"
              isCTALoading={isSubmitting}
              ctaText="Upload product list"
              disabled={!isValid || Object.keys(touched).length === 0}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
