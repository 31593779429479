import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { ApiService } from "../generated";
import { userZipState } from "../jotai/user";
import { hasWindow } from "../utils";
import { handleError } from "../utils/generatedApi";

export default function useUrlZip() {
  const isWindow = hasWindow();
  const userZip = useAtomValue(userZipState);
  const urlZip = useMemo(() => (isWindow ? userZip : ""), [isWindow, userZip]);
  const [userQueryState, setUserQueryState] = useState("");
  useEffect(() => {
    (async () => {
      if (!urlZip || urlZip === "None") {
        return;
      }
      try {
        const response = await ApiService.apiV1StateFromZipRetrieve(urlZip);
        setUserQueryState(response.stateCode || "");
      } catch (error) {
        handleError(error);
        setUserQueryState("");
      }
    })();
  }, [urlZip]);

  return userQueryState;
}
