import clsx from "clsx";

import loaderGifIcon from "../../img/loaders/loader.gif";
import { Button, type ButtonSizes, type ButtonThemes } from "../library";

interface ViewMoreButtonProps {
  onClick: () => void;
  isLoading: boolean;
  className?: string;
  theme?: ButtonThemes;
  size?: ButtonSizes;
  children?: string;
  dataTestId?: string;
}

export default function ViewMoreButton({
  className,
  onClick,
  isLoading,
  children,
  theme = Button.themes.SECONDARY_DARK,
  size = Button.sizes.SMALL,
  dataTestId = "view-more-button",
}: ViewMoreButtonProps) {
  const ctaText = children || "View more";
  return (
    <Button
      className={clsx("w-72", className)}
      onClick={onClick}
      width="280px"
      disabled={isLoading}
      theme={theme}
      size={size}
      dataTestId={dataTestId}
    >
      {isLoading ? (
        <img alt={ctaText} src={loaderGifIcon} className="w-4" />
      ) : (
        ctaText
      )}
    </Button>
  );
}
