import { Typography } from "../library";

import EmptyInfoContainer from "./EmptyInfoContainer";

export default function NoContractsInfo() {
  return (
    <EmptyInfoContainer>
      <Typography>
        Your business doesn{"'"}t have any contracts on Pavilion.
      </Typography>
    </EmptyInfoContainer>
  );
}
