import warning from "../../img/welcome/warning.svg";
import { Banner, Typography } from "../library";

interface AccountVerificationBannerProps {
  title?: string;
  subtitle?: string;
}

export default function AccountVerificationBanner({
  title = "Account verification pending",
  subtitle = "We are in the process of verifying your profile. Edits to your profile won't be live until Pavilion verifies your account.",
}: AccountVerificationBannerProps) {
  return (
    <Banner
      className="analytics-account-verification-banner flex items-center"
      color="accent.default.quaternary.enabled"
    >
      <div className="flex flex-col gap-2 flex-1">
        <Typography variant="headline" size="sm" emphasis>
          {title}
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
      <div>
        <img src={warning} className="h-20" />
      </div>
    </Banner>
  );
}
