import { Suspense } from "react";

import loaderGifIcon from "../../../img/loaders/loader.gif";
import { Button, ButtonThemes } from "../../library";
import type { BadgeProps } from "../../library/Badge";

interface SubmitButtonProps {
  btnClassName?: string;
  isCTALoading: boolean;
  handleSubmit?: () => void;
  ctaText: string;
  badgeProps?: BadgeProps;
  dataTestId?: string;
  disabled?: boolean;
}

export default function SubmitButton({
  btnClassName,
  isCTALoading,
  handleSubmit,
  ctaText,
  badgeProps,
  dataTestId = "submit-button",
  disabled = false,
}: SubmitButtonProps) {
  return (
    <div className="flex justify-center h-14" data-testid="email-signup-button">
      <Suspense
        fallback={
          <Button className={btnClassName} theme={ButtonThemes.PRIMARY_DARK}>
            <img src={loaderGifIcon} className="w-6" alt="Loading" />
          </Button>
        }
      >
        <Button
          className={btnClassName}
          disabled={isCTALoading || disabled}
          onClick={handleSubmit}
          type="submit"
          dataTestId={dataTestId}
          theme={ButtonThemes.PRIMARY_DARK}
          badgeProps={badgeProps}
        >
          {isCTALoading ? (
            <img src={loaderGifIcon} className="w-6" alt="Loading" />
          ) : (
            <>{ctaText}</>
          )}
        </Button>
      </Suspense>
    </div>
  );
}
