import clsx from "clsx";
import { ProgressBar, Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";

const userBaseItems = [
  {
    name: "Municipalities",
    percentage: 29,
    widthClass: "w-[63px] md:w-[63px] lg:w-[126px] sm:w-[189px]",
  },
  {
    name: "Schools/School Districts",
    percentage: 22,
    widthClass: "w-[48px] md:w-[48px] lg:w-[96px] sm:w-[144px]",
  },
  {
    name: "Counties",
    percentage: 20,
    widthClass: "w-[43px] md:w-[43px] lg:w-[86px] sm:w-[129px]",
  },
  {
    name: "Higher Education",
    percentage: 14,
    widthClass: "w-[33px] md:w-[33px] lg:w-[66px] sm:w-[99px]",
  },
  {
    name: "Other Public Entities",
    percentage: 14,
    widthClass: "w-[33px] md:w-[33px] lg:w-[66px] sm:w-[99px]",
  },
];

export default function UserBaseBreakdown({
  className,
}: { className?: string }) {
  return (
    <div
      className={clsx(
        "analytics-user-base-breakdown flex flex-col gap-6 p-6 rounded-8 justify-between",
        bgColorClass.brand.subtlest.enabled,
        className
      )}
    >
      <Typography variant="headline" size="xs" color="brand.boldest.enabled">
        User base breakdown
      </Typography>
      <div className="flex flex-col">
        {userBaseItems.map((item) => (
          <div key={item.name}>
            <div className="flex">
              <Typography
                size="sm"
                color="neutral.bolder.enabled"
                className="w-1/2 p-3"
              >
                {item.name}
              </Typography>
              <div className="flex gap-4 p-3 w-1/2 items-center">
                <Typography color="neutral.bolder.enabled">{`${item.percentage}%`}</Typography>
                <ProgressBar
                  items={[
                    {
                      progress: 100,
                      className: clsx(
                        "h-4 rounded-4 bg-[#249B84]",
                        item.widthClass
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-2 p-4 rounded-4 bg-white">
        <Typography variant="display" size="xl" color="brand.bold.enabled">
          2,500+
        </Typography>
        <Typography size="xl">active SLED entities</Typography>
      </div>
    </div>
  );
}
