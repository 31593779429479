import searchNoResults from "../../../img/icons/search-no-results.svg";
import type { ContractHit } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import { Button, Card, Link, PageSection, Typography } from "../../library";
import { expirationText } from "../../shared/ContractBase";
import MobileSearchButton from "../../shared/MobileSearchButton";
import SearchBar, {
  SearchBarCtaTypes,
  SearchBarSizes,
} from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  SearchSource,
  landingPageType,
} from "../../utils/enums";
import { trackLandingPageContractClick } from "../../utils/tracking";
import ContractCard from "../ContractSearch/ContractCard";
import BuyerExplainerSection from "../Home/HomepageComponents/BuyerExplainerSection";
import BuyerFAQ from "../Home/HomepageComponents/BuyerFAQ";
import ScheduleDemoSection from "../Home/HomepageComponents/ScheduleDemoSection";

import { LandingPageType, type PageData } from "./LandingPage";

interface LoggedOutLandingPageProps {
  page: PageData;
}

function isExpired(contract: ContractHit) {
  const expirationDate = parseDate(contract.expirationTimestamp);
  return (expirationDate?.getTime() || 0) < Date.now();
}

export function LoggedOutLandingPage({ page }: LoggedOutLandingPageProps) {
  const requireLogin = useLoginWall();
  const featuredSolicitations = page.initial_list.results
    .filter((c) => !isExpired(c))
    .slice(0, 3);

  const viewAllContracts = () => {
    void requireLogin({
      triggerId: page.buyer_lead_agency_id,
      triggerType: LoginWallTriggers.LANDING_PAGE_VIEW_ALL_CONTRACTS_CLICK,
    });
  };

  return (
    <div>
      <PageSection className="py-4 xl:py-0">
        <div className="mt-16 w-full md:max-w-screen-xl flex flex-col gap-1 md:gap-5">
          <div>
            <Typography
              variant="display"
              color="brand.boldest.enabled"
              className="lg:w-2/3"
              component="h1"
            >
              {page.title}
            </Typography>
            <Typography color="subtle" className="lg:w-3/4 mt-6">
              {page.subtitle}
            </Typography>
          </div>
          <div className="flex gap-3">
            <div>
              <Link href="/">Home</Link>
            </div>
            <div>•</div>
            <div>
              {page.page_type === LandingPageType.COOPERATIVE ? (
                <Link href="/cooperatives">All Cooperatives</Link>
              ) : (
                <Link href="/agencies">All Public Entities</Link>
              )}
            </div>
            <div>•</div>
            <div>{page.title}</div>
          </div>
        </div>
      </PageSection>

      <PageSection className="my-8 xl:py-0">
        <Card
          className="bg-gradient-to-bl from-violet-200 via-white to-violet-200 grid grid-cols-4 my-12"
          responsive
        >
          <div className="col-span-3">
            <Typography variant="headline" color="subtle" className="mb-2">
              Find and use contracts
            </Typography>
            <Typography variant="body">
              Search contracts from public entities near you and trusted
              national sources, all in one place for free.
            </Typography>
            <div className="flex">
              <SearchBar
                className="mt-6 hidden md:flex"
                searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE}
                size={SearchBarSizes.FULL}
                theme={SearchBarThemes.DARK}
                submitCta={SearchBarCtaTypes.TEXT}
                buyerLeadAgencyId={page.buyer_lead_agency_id}
                customPlaceholder={`Search contracts from ${page.display_name}`}
                disambiguate
              />
              <MobileSearchButton
                searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE_MODAL}
                className="mt-6"
              />
            </div>
          </div>
          <img
            className="col-span-1 max-h-[10rem] mx-auto hidden lg:block"
            src={searchNoResults}
            alt="Search contracts"
          />
        </Card>
      </PageSection>

      <PageSection>
        {featuredSolicitations.length > 0 ? (
          <>
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              className="mb-3"
            >
              Example contracts from {page.display_name}
            </Typography>

            <Typography className="mb-6">
              Log in or sign up to view all {page.active_shareable_total} active
              shareable contracts
            </Typography>

            <div className="my-8">
              <div className="w-full grid grid-cols-1 auto-rows-auto md:grid-cols-2 xl:grid-cols-3 md:items-stretch justify-between gap-6">
                {featuredSolicitations.map((solicitation) => {
                  function handleFeaturedSolicitationClick() {
                    trackLandingPageContractClick({
                      solicitationId: solicitation.solicitationId,
                      landingPage: landingPageType.AGENCY,
                      contractId: solicitation.docid,
                      sourceValue: page.source_value,
                    });
                  }

                  return (
                    <ContractCard
                      key={solicitation.docid}
                      className="analytics-bla-featured-solicitation"
                      loginWallTriggerId={solicitation.docid}
                      loginWallTrigger={
                        LoginWallTriggers.LANDING_PAGE_CONTRACT_CLICK
                      }
                      onClick={handleFeaturedSolicitationClick}
                      title={solicitation.title}
                      awardedSuppliers={solicitation.suppliersToDisplay}
                      numSuppliers={solicitation.numSuppliers}
                      contractNumber={solicitation.contractNumber}
                      expiration={expirationText(
                        solicitation.expirationTimestamp,
                        {
                          short: true,
                        }
                      )}
                      isExpired={isExpired(solicitation)}
                      buyerLeadAgency={solicitation.buyerLeadAgency}
                      cooperative={solicitation.cooperativeAffiliation || ""}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col items-center mt-8 w-full">
                <Button
                  theme={Button.themes.PRIMARY_DARK}
                  onClick={viewAllContracts}
                  className="analytics-logged-out-bla-sign-up"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              className="mb-3"
            >
              Log in or sign up to view all {page.active_shareable_total} active
              shareable contracts
            </Typography>
            <div className="flex flex-col items-center my-8 w-full">
              <Button
                theme={Button.themes.PRIMARY_DARK}
                onClick={viewAllContracts}
                className="analytics-logged-out-bla-sign-up"
              >
                Sign up
              </Button>
            </div>
          </>
        )}
      </PageSection>

      <PageSection className="bg-cp-violet-200">
        <BuyerExplainerSection />
      </PageSection>

      <PageSection className="bg-cp-violet-100 py-12 flex-grow">
        <BuyerFAQ />
      </PageSection>

      <PageSection className="bg-cp-lapis-500">
        <ScheduleDemoSection />
      </PageSection>
    </div>
  );
}
