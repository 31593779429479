import { useAtomValue } from "jotai/index";
import listify from "listify";
import { supplierScopeState } from "../../../jotai/supplierScope";
import SummarySection from "../../../shared/SummarySection";
import { getSentenceCase } from "../../../utils";

export default function SupplierOfferingsSummary({
  offerings,
  supplierName,
  query,
}: { offerings: string[]; supplierName: string; query?: string }) {
  const { scope } = useAtomValue(supplierScopeState);
  const renderText = () => {
    if (!offerings.length) return null;

    if (scope.supplierOfferings?.length) {
      const firstRelevantOffering = scope.supplierOfferings[0].offering;
      const remainingOfferings = offerings.filter(
        (o) => o !== firstRelevantOffering
      );
      offerings = [firstRelevantOffering, ...remainingOfferings];
    }

    // Get the most semantically relevant offerings, then primary, then alphabetical
    let offeringsText = listify(
      offerings
        .filter(Boolean)
        .slice(0, 3)
        .map((v) => `"${getSentenceCase(v)}"`),
      {
        finalWord: offerings.length <= 3 ? "and" : "",
      }
    );

    if (offerings.length > 3) offeringsText += ", and more";

    return `${supplierName} offers ${offeringsText}.`;
  };

  const offeringsToShow = scope.supplierOfferings
    ? scope.supplierOfferings.map((v) => v.offering)
    : offerings;

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      items={offeringsToShow}
      linkClassName="analytics-scope-summary-keywords-toggle"
      expandText="supplier offerings"
    />
  );
}
