import clsx from "clsx";
import { useAtomValue } from "jotai";
import { searchResultTypeState } from "../../jotai/search";
import LoadingHit, { LoadingSlimHit } from "../SearchPage/SearchHit/LoadingHit";
import SupplierResultLoadingHit from "../SearchPage/SearchHit/SupplierResultLoadingHit";

interface LoadingContractCardsProps {
  className?: string;
}

export function LoadingCards({ className }: LoadingContractCardsProps) {
  const searchResultType = useAtomValue(searchResultTypeState);

  let Component = LoadingHit;
  const hasSupplierResults = searchResultType === "supplier";
  if (hasSupplierResults) {
    Component = SupplierResultLoadingHit;
  }

  return (
    <div
      className={clsx(
        "flex flex-col",
        { "gap-6": !hasSupplierResults, "gap-12": hasSupplierResults },
        className
      )}
    >
      <Component />
      <Component />
      <Component />
    </div>
  );
}

export function LoadingSlimCards() {
  return (
    <div className="flex flex-col gap-4">
      <LoadingSlimHit />
      <LoadingSlimHit />
      <LoadingSlimHit />
      <LoadingSlimHit />
      <LoadingSlimHit />
    </div>
  );
}
