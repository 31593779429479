import _partition from "lodash/partition";
import { useEffect, useMemo } from "react";

import { useAtomValue, useSetAtom } from "jotai";
import type {
  ContractHit,
  ContractSupplierHit,
  RelevantContract,
} from "../../generated";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  contractSourcesState,
  diversityCertificationsFilterState,
  expirationFilterState,
  matchedSearchResultCountState,
  singleAwardOnlyFilterState,
} from "../../jotai/searchFilters";
import { buyerProfileState, userStateState } from "../../jotai/user";
import { FILTER_DURATIONS } from "../../shared/SearchPage/SearchResults/constants";
import { isContractHit, resultHasMatch } from "../../shared/SearchPage/utils";
import { BUYER_LEAD_AGENCY_RANKS } from "../../utils/constants";
import { contractMatchTypes } from "../../utils/enums";
import useDiversityPreferences from "../useDiversityPreferences";
import {
  hasResponsiveContact,
  matchesApprovedSource,
  matchesContractSource,
  matchesDiversity,
  matchesExpiration,
  matchesSingleAward,
} from "./utils";

const PRIMARY_MATCH_TIERS = [
  contractMatchTypes.CONTRACT_NUMBER,
  contractMatchTypes.EXACT_SUPPLIER,
  contractMatchTypes.COMPETITOR_MATCH,
  contractMatchTypes.OFFERINGS,
];

export default function useFilteredSearchResults<
  T extends ContractHit | ContractSupplierHit,
>({
  results,
  filterLowSimilarityResults,
  filterUnresponsiveContacts,
  excludeAgencyId,
}: {
  results: T[];
  filterLowSimilarityResults: boolean;
  filterUnresponsiveContacts: boolean;
  excludeAgencyId: Maybe<string>;
}) {
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const { location } = useAtomValue(userStateState) || {};
  const setContractSourcesState = useSetAtom(contractSourcesState);

  const expirationFilter = useAtomValue(expirationFilterState);
  const contractSourceFilter = useAtomValue(contractSourceFilterState);
  const approvedSourcesOnlyFilter = useAtomValue(
    approvedSourcesOnlyFilterState
  );
  const diversityCertificationsFilter = useAtomValue(
    diversityCertificationsFilterState
  );
  const singleAwardOnlyFilter = useAtomValue(singleAwardOnlyFilterState);

  const setMatchedSearchResultCount = useSetAtom(matchedSearchResultCountState);
  const diversityPreferences = useDiversityPreferences();

  const sortedResults = useMemo(() => {
    let primaryResults = [...results];
    let otherResults: T[] = [];

    // TODO: Clean up this logic when we enable both feature flags.
    if (filterLowSimilarityResults || filterUnresponsiveContacts) {
      // Partition out results we do not expect to be useful or unresponsive results.
      // If there are also filters applied, these low-supply results will be discarded.
      [primaryResults, otherResults] = _partition(results, (c) => {
        if (
          resultHasMatch(c, (r) => PRIMARY_MATCH_TIERS.includes(r.matchTier))
        ) {
          return true;
        }

        // TODO: Fix labeling so that there are no semantic match tier results
        // with null or zero semantic score.
        let isHighSimilarity = true;
        if (filterLowSimilarityResults) {
          isHighSimilarity = resultHasMatch(
            c,
            (r) =>
              !r.RankingInfo.semanticScore || r.RankingInfo.semanticScore > 0.85
          );
        }

        return (
          isHighSimilarity &&
          hasResponsiveContact(c, filterUnresponsiveContacts)
        );
      });
    }

    const hasAnyFilter =
      !!FILTER_DURATIONS[expirationFilter] ||
      diversityCertificationsFilter ||
      approvedSourcesOnlyFilter ||
      singleAwardOnlyFilter ||
      !!contractSourceFilter.length;
    if (!hasAnyFilter) {
      setMatchedSearchResultCount(primaryResults.length);
      return [...primaryResults, ...otherResults];
    }

    // Partition out results excluded by frontend-applied filters.
    // If there were low-supply / unresponsive results, they are discarded here.
    [primaryResults, otherResults] = _partition(primaryResults, (c) => {
      const matchers: ((r: ContractHit | RelevantContract) => boolean)[] = [
        // OCR Matches are never considered for filtering.
        (r) => r.matchTier !== contractMatchTypes.OCR,
        (r) => matchesExpiration(r, expirationFilter),
        // Since diversity certifications are per-supplier, always use the top-level hit's credentials.
        () =>
          matchesDiversity(
            c,
            diversityCertificationsFilter,
            diversityPreferences
          ),
        (r) => matchesContractSource(r, contractSourceFilter),
        (r) =>
          matchesApprovedSource(
            r,
            approvedSourcesOnlyFilter,
            governmentAgency?.filterSources || [],
            governmentAgency?.approvedStates || []
          ),
        (r) => matchesSingleAward(r, singleAwardOnlyFilter),
      ];
      // If the hit represents a contract with multiple suppliers, check every matcher
      // against the top-level contract.
      if (isContractHit(c)) return matchers.every((matcher) => matcher(c));

      // If the hit represents a supplier with multiple contracts, ensure at least one contract
      // matches all filters ("relevantSuppliers" is a misnomer here and references contract results).
      return c.relevantSuppliers.some((r) =>
        matchers.every((matcher) => matcher(r))
      );
    });

    setMatchedSearchResultCount(primaryResults.length);
    return [...primaryResults, ...otherResults];
  }, [
    results,
    filterLowSimilarityResults,
    filterUnresponsiveContacts,
    expirationFilter,
    contractSourceFilter,
    diversityCertificationsFilter,
    singleAwardOnlyFilter,
    approvedSourcesOnlyFilter,
    setMatchedSearchResultCount,
    governmentAgency,
    diversityPreferences,
  ]);

  useEffect(() => {
    const cooperatives = new Set<string>();
    const agencies = new Set<string>();
    const localAgencies = new Set<string>();

    results?.forEach((r) => {
      // OCR Matches are never considered for filtering.
      const isOCR = !resultHasMatch(r, (c) => c.matchTier !== "OCR");
      if (isOCR) return;

      const contracts = isContractHit(r) ? [r] : r.relevantSuppliers;

      contracts.forEach((c) => {
        if (
          c.cooperativeAffiliation &&
          c.cooperativeAffiliationId !== c.buyerLeadAgencyId &&
          (!excludeAgencyId || c.cooperativeAffiliationId !== excludeAgencyId)
        ) {
          cooperatives.add(c.cooperativeAffiliation);
        }
        if (excludeAgencyId && c.buyerLeadAgencyId === excludeAgencyId) return;
        if (
          [
            BUYER_LEAD_AGENCY_RANKS.NATIONAL,
            BUYER_LEAD_AGENCY_RANKS.COOP,
          ].includes(c.buyerLeadAgencyRank)
        ) {
          cooperatives.add(c.buyerLeadAgency);
        } else if (
          location?.state &&
          c.buyerLeadAgencyState === location.state
        ) {
          localAgencies.add(c.buyerLeadAgency);
        } else {
          agencies.add(c.buyerLeadAgency);
        }

        if (c.membershipAffiliations.length) {
          c.membershipAffiliations.forEach((affiliation) =>
            cooperatives.add(affiliation)
          );
        }
      });
    });

    setContractSourcesState({
      cooperatives: Array.from(cooperatives.values()).sort(),
      agencies: Array.from(agencies.values()).sort(),
      localAgencies: Array.from(localAgencies.values()).sort(),
    });
  }, [results, location, setContractSourcesState, excludeAgencyId]);

  return sortedResults;
}
