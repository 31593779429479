import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import type { FC } from "react";

import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  CardContainer,
  Typography,
} from "../../../library";
import { CardContainerVariant } from "../../../library/CardContainer";
import GenericCard from "../../../library/GenericCard";
import type { BgColor } from "../../../utils/colors";

export interface SidebarCardProps {
  onClick?: () => void;
  onDismiss?: () => void;
  iconComponent: FC<{ size: AvatarSizes; className?: string }>;
  iconBackgroundColor: BgColor;
  headline: string;
  body: string;
  analyticsSuffix: string;
  show: boolean;
}

export default function SidebarCard(props: SidebarCardProps) {
  if (!props.show) {
    return <></>;
  }

  const {
    onClick,
    onDismiss,
    iconComponent,
    iconBackgroundColor,
    headline,
    body,
    analyticsSuffix,
  } = props;

  const Component = onClick ? GenericCard : CardContainer;
  const componentProps = onClick
    ? { onClick }
    : { variant: CardContainerVariant.SECONDARY };
  return (
    <Component
      className={clsx(
        `analytics-welcome-page-card-${analyticsSuffix}`,
        "shadow-1 !p-4 flex-1"
      )}
      {...componentProps}
    >
      <div className="flex flex-row gap-4">
        <Avatar
          size={AvatarSizes.LARGE}
          IconComponent={iconComponent}
          bgColor={iconBackgroundColor}
          variant={AvatarVariant.CIRCLE}
        />
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <Typography
              variant="headline"
              size="sm"
              color="brand.bold.enabled"
              emphasis
            >
              {headline}
            </Typography>
            {!!onDismiss && (
              <CloseRoundedIcon
                className={clsx(
                  `analytics-welcome-page-card-${analyticsSuffix}-dismiss`,
                  "cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-6 h-6"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDismiss?.();
                }}
              />
            )}
          </div>
          <Typography variant="meta" size="sm" color="neutral.bolder.enabled">
            {body}
          </Typography>
        </div>
      </div>
    </Component>
  );
}
