import { atom, useAtomValue } from "jotai";

import { loadable } from "jotai/utils";
import type { DiversityCertification } from "../components/Profile/types";
import { isAuthenticatedState } from "../jotai/user";
import { getUserDiversityCertifications, handleError } from "../utils/api";

const diversityCertificationsState = atom(async (get) => {
  if (!get(isAuthenticatedState)) return {};

  const response = await getUserDiversityCertifications();
  if (handleError(response)) return {};

  const data: Record<
    string,
    Record<string, DiversityCertification>
  > = await response.json();
  return Object.values(data).reduce(
    (values, group) => Object.assign(values, group),
    {}
  );
});

/**
 * When using async selectors along with server side rendering, it's required to
 * either use React.Suspense or a useAtomValueLoadable like below. Since
 * diversity certifications should not be updated, limit the api to a safe
 * hook that always returns a value.
 */
export default function useDiversityCertificationsValue() {
  const loadableState = loadable(diversityCertificationsState);
  const value = useAtomValue(loadableState);

  if (value.state === "hasData") return value.data;
  return {};
}
