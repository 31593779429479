import { Typography } from "../../../library";
import type { SupplierWelcomePageData } from "../types";
import ScheduleSupplierDemo from "./ScheduleSupplierDemo";
import SupplierMetrics from "./SupplierMetrics";

interface SupplierUpsellProps {
  supplierData: SupplierWelcomePageData;
}

export function SupplierUpsell({ supplierData }: SupplierUpsellProps) {
  return (
    <>
      <div className="flex flex-col gap-2">
        <Typography variant="headline" size="sm" color="brand.boldest.enabled">
          Become a Pro Supplier to dominate your category
        </Typography>
        <Typography color="neutral.boldest.enabled">
          Pavilion is the only solution that uncovers SLED agencies actively
          looking to purchase through an existing contract.
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        <SupplierMetrics supplierData={supplierData} />
        <ScheduleSupplierDemo supplierData={supplierData} />
      </div>
    </>
  );
}
