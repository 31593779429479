import type { ReactNode } from "react";

import { Link } from "../library";
import { SUPPORT_EMAIL_ADDRESS } from "../utils/constants";
import { generateHrefForEmail } from "../utils/format";

export default function SupportEmailLink({
  email = SUPPORT_EMAIL_ADDRESS,
  children,
  underline = false,
  className,
}: {
  email?: string;
  children?: ReactNode;
  underline?: boolean;
  className?: string;
}) {
  return (
    <Link
      href={generateHrefForEmail(email)}
      underline={underline}
      newWindow={false}
      emphasis={false}
      className={className}
    >
      {children || email}
    </Link>
  );
}
