import { createRoot } from "react-dom/client";

import ProfilePage from "../components/Profile/ProfilePage";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

export default function Profile({
  is_authenticated,
  profile_type,
  active_agreements,
  hasRequestedAdmin,
  numAdmins,
}: AuthPageProps & { hasRequestedAdmin: boolean; numAdmins: number }) {
  return (
    <Page
      pageType={PageType.PROFILE}
      searchSource="profile-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <ProfilePage
        hasRequestedAdmin={hasRequestedAdmin}
        numAdmins={numAdmins}
      />
    </Page>
  );
}

const container = getDOMAnchorById("profile");
if (container) {
  const root = createRoot(container);
  const hasRequestedAdmin = container.dataset.hasRequestedAdmin === "True";
  const numAdmins = Number.parseInt(
    container.dataset.numAdmins || ""
  ) as number;

  root.render(
    <Profile hasRequestedAdmin={hasRequestedAdmin} numAdmins={numAdmins} />
  );
}
