import AddRoundedIcon from "@mui/icons-material/AddRounded";
import clsx from "clsx";
import type { ChangeEventHandler } from "react";
import {
  bgColorClass,
  borderColorClass,
  iconColorClass,
} from "../../../utils/colors";
import Badge from "../../Badge";

export interface SelectableChipProps {
  name: string;
  selected: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
}

export default function SelectableChip({
  name,
  selected,
  onChange,
  label,
}: SelectableChipProps) {
  return (
    <div
      className={clsx("box-border border rounded-4 h-9", {
        [bgColorClass.brand.subtler.enabled]: selected,
        [borderColorClass.brand.bold.enabled]: selected,
      })}
    >
      <input
        type="checkbox"
        id={name}
        className="peer appearance-none absolute"
        checked={selected}
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className="inline-flex h-full items-center cursor-pointer px-3 rounded-4 peer-focus-visible:outline peer-focus-visible:outline-2 peer-focus-visible:outline-offset-2 peer-focus-visible:outline-cp-lapis-500"
      >
        <Badge
          Icon={AddRoundedIcon}
          size="sm-md"
          className="select-none"
          iconClass={clsx({
            [iconColorClass.brand.bold.enabled]: selected,
          })}
          label={label}
        />
      </label>
    </div>
  );
}
