import type { FormikProps } from "formik";
import { type ChangeEvent, useEffect, useState } from "react";

import { Checkbox, Typography } from "../../../library";
import { SupplierBusinessTypes } from "../../../utils/enums";

import type { SupplierBusinessCategoryValues } from "./types";

export interface SupplierBusinessCategoryFieldProps {
  field: {
    name: string;
    value: SupplierBusinessCategoryValues["businessTypes"];
    onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  };
  form: FormikProps<{
    [x: string]: SupplierBusinessCategoryValues["businessTypes"];
  }>;
}

interface BusinessTypeOption {
  label: string;
  businessType: string;
}

const businessTypeOptions: BusinessTypeOption[] = [
  {
    label: "My business sells commodities/goods",
    businessType: SupplierBusinessTypes.GOODS,
  },
  {
    label: "My business sells software",
    businessType: SupplierBusinessTypes.SOFTWARE,
  },
  {
    label: "My business provides services",
    businessType: SupplierBusinessTypes.SERVICES,
  },
];

export function SupplierBusinessCategoryField({
  field,
  form: { setFieldValue, setFieldTouched, touched, submitForm },
}: SupplierBusinessCategoryFieldProps) {
  const [businessCategories, setBusinessCategories] = useState<string[]>(
    field.value || []
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Submit when the field changes.
  useEffect(() => {
    if (!touched[field.name]) return;
    submitForm();
  }, [field.value]);

  const handleCategoryToggle = (checked: boolean, category: string) => {
    let newBusinessCategories = [];
    if (checked) {
      newBusinessCategories = businessCategories?.includes(category)
        ? businessCategories
        : [...businessCategories, category];
    } else {
      newBusinessCategories = businessCategories.filter(
        (item) => item !== category
      );
    }
    setBusinessCategories(newBusinessCategories);
    setFieldValue(field.name, newBusinessCategories);
    setFieldTouched(field.name, true);
  };

  return (
    <div>
      {businessTypeOptions.map((option) => (
        <Checkbox
          key={option.businessType}
          checkboxClassName="analytics-select-service-area-contiguous-us my-2"
          name={option.businessType}
          label={<Typography>{option.label}</Typography>}
          onChange={(e) =>
            handleCategoryToggle(e.target.checked, option.businessType)
          }
          checked={businessCategories?.includes(option.businessType)}
        />
      ))}
    </div>
  );
}
