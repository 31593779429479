import {
  type ChangeEvent,
  type FocusEvent,
  type FocusEventHandler,
  type KeyboardEventHandler,
  useState,
} from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import clsx from "clsx";
import { isFeatureEnabled } from "../../utils/split";
import { trackForgotPasswordClick } from "../../utils/tracking";
import LabeledInput, {
  LabeledInputVariants,
  type InputSizes,
} from "../Input/LabeledInput";
import Link from "../Link";
import type { FieldLabelProps } from "../form/types";

export const passwordErrorPolicies = {
  password_too_short: "at least 8 characters long",
  password_too_common: "not a commonly used password",
  password_entirely_numeric: "not entirely numbers",
};

interface PasswordError {
  code: keyof typeof passwordErrorPolicies;
}

export const getPasswordErrorMessage = (responseError: PasswordError[]) => {
  if (responseError && responseError.length > 0) {
    const errorCodes = responseError.map((msg) => msg.code);
    const errorConstraintString =
      errorCodes && errorCodes.length > 0
        ? errorCodes
            .filter(Boolean)
            .map((errorCode) => passwordErrorPolicies[errorCode])
            .join(" and ")
        : "";
    const errorMessage = errorConstraintString
      ? `Password must be ${errorConstraintString}`
      : "Signup failed please use a different password";
    return errorMessage;
  }
  return "";
};

interface PasswordInputProps extends FieldLabelProps {
  password?: string;
  setPassword?: (password: string) => void;
  errorMessage?: string;
  validationMessage?: string;
  validationIcons?: boolean;
  showForgotPassword?: boolean;
  sublabel?: string;
  name?: string;
  placeholder?: string;
  size?: InputSizes;
  onBlur?: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  className?: string;
  autoFocus?: boolean;
}

function PasswordInput({
  password,
  setPassword,
  errorMessage,
  validationMessage,
  showForgotPassword = false,
  sublabel,
  name = "password",
  placeholder = "Password",
  size = "md",
  onBlur,
  className,
  autoFocus = false,
  ...rest
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");
  let initialVariant = LabeledInputVariants.DEFAULT;
  let message = sublabel;
  if (errorMessage) {
    initialVariant = LabeledInputVariants.ERROR;
    message = errorMessage;
  } else if (validationMessage) {
    initialVariant = LabeledInputVariants.SUCCESS;
    message = validationMessage;
  }

  return (
    <div className={clsx(className, "relative")}>
      <LabeledInput
        type={showPassword ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        required
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPassword?.(e.target.value)
        }
        onBlur={(e: FocusEvent<HTMLInputElement>) => onBlur?.(e)}
        initialVariant={initialVariant}
        message={message}
        size={size}
        className="mb-1 placeholder:text-sm"
        dataTestId="password-input"
        autoFocus={autoFocus}
        autoComplete="new-password"
        TrailingIcon={
          showPassword ? VisibilityOutlinedIcon : VisibilityOffOutlinedIcon
        }
        onClickTrailingIcon={() => setShowPassword(!showPassword)}
        {...rest}
      />
      {showForgotPassword && (
        <Link
          emphasis={false}
          underline={false}
          size={hasRedesign ? "sm" : "md"}
          variant="meta"
          color="brand.default.primary.enabled"
          className="analytics-email-login-modal-password-reset font-normal whitespace-nowrap underline-offset-2"
          href="/accounts/password/reset"
          onClick={trackForgotPasswordClick}
        >
          Forgot your password?
        </Link>
      )}
    </div>
  );
}

export default PasswordInput;
