import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import PasswordChangeForm from "../../components/PasswordReset/PasswordChangeForm";
import { getDOMAnchorById, parseJSONPasswordError } from "../../utils";
import Page from "../Page/Page";

const container = getDOMAnchorById("password-change");
if (container) {
  const root = createRoot(container);

  // Parse errors from backend
  const newPasswordError = parseJSONPasswordError(
    _get(container, "dataset.newPasswordErrors") as string
  );
  const oldPasswordError = parseJSONPasswordError(
    _get(container, "dataset.oldPasswordErrors") as string
  );

  root.render(
    <Page
      searchSource="password-change-header-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <PasswordChangeForm
        newPasswordError={newPasswordError}
        oldPasswordError={oldPasswordError}
        invalidLink={false}
        showOldPassword
      />
    </Page>
  );
}
