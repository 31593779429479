import clsx from "clsx";
import { Fragment, type ReactElement, type ReactNode, useState } from "react";

import ViewMoreButton from "../../shared/ViewMoreButton";
import { Button, ButtonThemes, Typography } from "../index";

const MAX_COLS = 3;

interface TruncatedListingsProps {
  // String or component displaying title of listings
  title?: ReactNode;
  // Boolean to determine if 'view more' cta is displayed in header or below the listings
  showCtaInHeader?: boolean;
  // total number of listings in the database
  totalNumListings?: number;
  // Number of listings to show initially
  initialListingsShown?: number;
  // Number of additional listings to show when 'view more' cta is clicked
  increment?: number;
  // Text to display in 'view more' cta
  ctaText?: string;
  // Function to call when 'view more' cta is clicked
  onCtaClick?: () => void;
  // Class name to apply to 'view more' cta
  ctaClassName?: string;
  // Class name to apply to container
  className?: string;
  // Array of listings to display
  children: ReactElement[];
  // Boolean to determine if 'view more' cta is disabled
  isLoading?: boolean;
  // Data test id for listings container
  dataTestId?: string;
  cta?: ReactNode;
  // number of columns in full screen size
  cols?: number;
}

// Shows a truncated list of cards (or other elements) with a title and a 'view more' cta
export default function TruncatedListings({
  title,
  showCtaInHeader,
  initialListingsShown = 3,
  totalNumListings,
  increment,
  ctaText = "View more",
  onCtaClick,
  ctaClassName,
  className,
  children,
  isLoading,
  dataTestId,
  cta,
  cols = MAX_COLS,
}: TruncatedListingsProps) {
  totalNumListings = totalNumListings || children.length;
  const [numListingsShown, setNumListingsShown] =
    useState(initialListingsShown);
  if (children.length === 0) {
    return null;
  }
  if (cols > MAX_COLS) {
    // eslint-disable-next-line no-console
    console.warn(
      `TruncatedListings: cols should be <= 3. A value of ${cols} was passed. Defaulting to ${MAX_COLS}.`
    );
    cols = MAX_COLS;
  }

  // if title is a string, wrap it in a Typography component
  if (typeof title === "string") {
    title = (
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        {title}
      </Typography>
    );
  }

  // call cta function and show additional set of listings
  const handleClick = () => {
    onCtaClick?.();
    if (increment) {
      setNumListingsShown((numListingsShown) => numListingsShown + increment);
    } else {
      setNumListingsShown(totalNumListings as number);
    }
  };

  const listingsCta = cta
    ? cta
    : numListingsShown < totalNumListings && (
        <ViewMoreButton
          isLoading={isLoading || false}
          onClick={handleClick}
          className={ctaClassName}
          theme={
            showCtaInHeader
              ? ButtonThemes.TERTIARY_DARK
              : Button.themes.SECONDARY_DARK
          }
        >
          {ctaText}
        </ViewMoreButton>
      );

  return (
    //container
    <div
      className={clsx("flex flex-col gap-4", className)}
      data-testid={dataTestId}
    >
      {/* header */}
      <div className="flex flex-col md:flex-row justify-between w-full items-center">
        {title}
        {showCtaInHeader && listingsCta}
      </div>
      {/* listings */}
      <div
        className={clsx("grid grid-cols-1 gap-6", {
          "md:grid-cols-1 xl:grid-cols-1": cols === 1,
          "md:grid-cols-2 xl:grid-cols-2": cols === 2,
          "md:grid-cols-2 xl:grid-cols-3": cols >= 3,
        })}
      >
        {children.slice(0, numListingsShown).map((listing, index) => {
          // biome-ignore lint/suspicious/noArrayIndexKey: Listings are static so indexes are ok.
          return <Fragment key={index}>{listing}</Fragment>;
        })}
      </div>
      {/* cta */}
      {!showCtaInHeader && (
        <div className="flex justify-center w-full">{listingsCta}</div>
      )}
    </div>
  );
}
