import type { FormikValues } from "formik";

export function appendInitialCompleteAccountValues(
  form: FormData,
  values: FormikValues,
  email: string
) {
  form.append("firstName", values.firstName);
  form.append("lastName", values.lastName);
  form.append("username", email || "");
  form.append("email", email || "");
  form.append("phoneNumber", values.phone || "");
  form.append("phoneExtension", values.phoneExtension || "");
  // We no longer ask for password verification, but there is no way to disable double passwords in allauth
  form.append("password1", values.password);
  form.append("password2", values.password);
  return form;
}
