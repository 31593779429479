import { useAtomValue } from "jotai";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { useMemo } from "react";

import { userStateState } from "../jotai/user";

import useDiversityCertificationsValue from "./useDiversityCertificationsValue";

export default function useDiversityPreferences() {
  const userState = useAtomValue(userStateState);
  const diversityCertifications = useDiversityCertificationsValue();
  const diversityPreferences = useMemo(() => {
    if (_isEmpty(diversityCertifications)) return [];
    const certs = userState?.diversityCertifications || {};
    return _map(diversityCertifications, ({ id }, name) =>
      // We explicitly want to check for false here so that we include all certs by default.
      certs[name] !== false ? id : null
    ).filter((p) => !!p);
  }, [userState, diversityCertifications]);
  return diversityPreferences;
}
