import { ONLY_APPROVED_SOURCES } from "./constants";
import {
  ContractDocumentsFilterOptions,
  ContractSourceTypes,
  ExpirationDurations,
  searchFilters,
} from "./enums";

export interface FilterObject {
  key: string;
  label: string;
}

export function getPillLabelForSearchFilter(
  filter: string,
  userStateCode?: string
): string | undefined {
  const searchFilterToPillLabel: Record<string, string> = {
    [searchFilters.LOCATION_SPECIFIC_SUPPLIERS]: `Only suppliers who serve ${userStateCode}`,
    [searchFilters.INCLUDE_EXPIRED]: "Active and expired contracts",
    [ExpirationDurations.LESS_THAN_6_MONTHS]: "Contracts expire in <6 months",
    [ExpirationDurations.MONTHS_6]: "Contracts expire in 6+ months",
    [ExpirationDurations.YEARS_1]: "Contracts expire in 1+ year",
    [ExpirationDurations.YEARS_2]: "Contracts expire in 2+ years",
    [searchFilters.ONLY_WITH_DOCS]: "Only contracts with documents available",
    [searchFilters.INCLUDE_NON_COOP]: "Show non-cooperative contracts",
    diversityCertificationsFilter: "Suppliers meet my diversity criteria",
    singleAwardOnlyFilter: "Only single-award contracts",
    [searchFilters.IS_NOT_LOCATION_RELEVANT]: "Ignore my entity's location",
    [searchFilters.INCLUDE_UNUSABLE]: "Show unusable contracts",
    [ONLY_APPROVED_SOURCES]: "Only approved sources",
  };
  return searchFilterToPillLabel[filter];
}

export const displayedQueryParamFilters: Record<string, boolean> = {
  [searchFilters.LOCATION_SPECIFIC_SUPPLIERS]: true,
  [searchFilters.ONLY_WITH_DOCS]: true,
  [searchFilters.INCLUDE_EXPIRED]: true,
  [searchFilters.INCLUDE_NON_COOP]: true,
  [searchFilters.IS_NOT_LOCATION_RELEVANT]: true,
  [searchFilters.INCLUDE_UNUSABLE]: true,
};

export function addFilterObject(
  filters: FilterObject[],
  { key, userStateCode }: { key: string; userStateCode?: string }
): void {
  filters.push({
    key,
    label: getPillLabelForSearchFilter(key, userStateCode) || "",
  });
}

export function areContractsOfSourceTypesAllSelected(
  contractSourceFilters: string[],
  cooperatives: string[],
  localAgencies: string[],
  agencies: string[]
): {
  [ContractSourceTypes.COOPERATIVES]: boolean;
  [ContractSourceTypes.LOCAL_AGENCIES]: boolean;
  [ContractSourceTypes.AGENCIES]: boolean;
} {
  const allCoopsAreSelected =
    cooperatives.length &&
    cooperatives.every((c) => contractSourceFilters.includes(c));
  const allLocalAgenciesAreSelected =
    localAgencies.length &&
    localAgencies.every((l) => contractSourceFilters.includes(l));
  const allAgenciesAreSelected =
    agencies.length && agencies.every((a) => contractSourceFilters.includes(a));
  return {
    [ContractSourceTypes.COOPERATIVES]: !!allCoopsAreSelected,
    [ContractSourceTypes.LOCAL_AGENCIES]: !!allLocalAgenciesAreSelected,
    [ContractSourceTypes.AGENCIES]: !!allAgenciesAreSelected,
  };
}

export function getAllSelectedForContractSourceTypeLabel(
  sourceType: ContractSourceTypes,
  userStateCode?: string
): string {
  const sourceTypeToLabel: Record<ContractSourceTypes, string> = {
    [ContractSourceTypes.AGENCIES]: `All local entities${
      userStateCode ? ` outside of ${userStateCode}` : ""
    }`,
    [ContractSourceTypes.COOPERATIVES]: "All purchasing cooperatives",
    [ContractSourceTypes.LOCAL_AGENCIES]: `All local entities${
      userStateCode ? ` in ${userStateCode}` : ""
    }`,
  };
  return sourceTypeToLabel[sourceType];
}

// Utils for determining filter type
export const isAllContractSourcesFilter = (key: string) =>
  Object.values(ContractSourceTypes).includes(key as ContractSourceTypes);

export const isExpirationFilter = (key: string) =>
  [
    ...Object.values(ExpirationDurations).map((d) => d.toString()),
    searchFilters.INCLUDE_EXPIRED,
  ].includes(key);

export const isDiversityFilter = (key: string) =>
  key === "diversityCertificationsFilter";

export const isSingleAwardOnlyFilter = (key: string) =>
  key === "singleAwardOnlyFilter";

export const isApprovedSourcesFilter = (key: string) =>
  key === ONLY_APPROVED_SOURCES;

export const isSupplierLocationFilter = (key: string) =>
  key === searchFilters.LOCATION_SPECIFIC_SUPPLIERS;

export const isContractDocumentsFilter = (key: string) =>
  Object.values(ContractDocumentsFilterOptions)
    .map((d) => d.toString())
    .includes(key);

export const isFilterQueryParam = (key: string) =>
  Object.values(searchFilters)
    .map((f) => f.toString())
    .includes(key);
