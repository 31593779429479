import clsx from "clsx";
import type { ChangeEventHandler, ReactNode } from "react";
import Typography from "../../Typography";

const checkedCn =
  "border-cp-midnight-300 checked:border-cp-lapis-500 checked:bg-cp-lapis-500 checked:bg-checked";

export interface CheckboxProps {
  label?: ReactNode;
  sublabel?: string;
  checked: boolean;
  onChange: Maybe<ChangeEventHandler<HTMLInputElement>>;
  align?: "top" | "center" | "bottom";
  className?: string;
  checkboxClassName?: string;
  fullWidth?: boolean;
  name: string;
  size?: "sm" | "md";
  disabled?: boolean;
  dataTestId?: string;
}
function Checkbox({
  label,
  sublabel,
  name,
  checked,
  onChange,
  align,
  fullWidth = true,
  className,
  checkboxClassName,
  disabled,
  size = "md",
  dataTestId = "checkbox",
}: CheckboxProps) {
  if (align === undefined) {
    align = sublabel ? "top" : "center";
  }

  return (
    <div
      className={clsx(
        "flex",
        {
          "pointer-events-none cursor-none disabled:border-cp-neutral-palette-400":
            disabled,
          "items-center": align === "center",
          "items-start": align === "top",
          "items-end": align === "bottom",
          "w-full": fullWidth,
          "w-fit": !fullWidth,
        },
        className
      )}
    >
      <input
        id={name}
        type="checkbox"
        className={clsx(
          "appearance-none cursor-pointer min-h-4 min-w-4 rounded focus-visible:ring-1 focus-visible:ring-offset-2 bg-white border border-solid bg-center bg-no-repeat shrink-0",
          checkedCn,
          {
            "hover:bg-cp-midnight-100 hover:border-cp-midnight-100 disabled:bg-cp-neutral-50 disabled:border-cp-neutral-palette-400 focus-visible:ring-cp-lapis-500":
              checked,
            "hover:bg-cp-lapis-100 disabled:border-cp-neutral-palette-400 focus-visible:ring-cp-midnight-300":
              !checked,
            "mr-2 h-4 w-4": size !== "sm",
            "mr-1 h-3 w-3": size === "sm",
            "translate-y-1": align === "top",
          },
          checkboxClassName
        )}
        checked={checked}
        onChange={onChange ?? (() => null)}
        disabled={disabled}
        data-testid={dataTestId}
      />
      <Typography
        color="neutral.boldest.enabled"
        component="label"
        className="flex-grow cursor-pointer m-0"
        htmlFor={name}
      >
        {label}
        {sublabel && (
          <Typography
            variant="meta"
            color="neutral.bold.enabled"
            className="mt-0.5"
          >
            {sublabel}
          </Typography>
        )}
      </Typography>
    </div>
  );
}

export default Checkbox;
