import { Typography } from "../../library";

const BUYER_ASKS = [
  "Pricing & discounts",
  "Fulfillment timelines",
  "Terms & conditions",
];

export default function MessageSupplierInformation({
  isPageFromContract,
}: {
  isPageFromContract: boolean;
}) {
  const renderedList = isPageFromContract
    ? BUYER_ASKS
    : ["Choosing the best contract"].concat(BUYER_ASKS);
  return (
    <div className="bg-cp-violet-100 p-6 border border-solid border-cp-lapis-100 rounded-2xl gap-4 h-fit grid lg:col-span-1">
      <Typography color="brand.boldest" emphasis>
        Most buyers ask about
      </Typography>
      <ul className="marker:text-cp-neutral-palette-900 list-disc list-inside">
        {renderedList.map((item) => (
          <li key={item}>
            <Typography component="span" color="neutral.boldest.enabled">
              {item}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography color="brand.bold" emphasis>
        What happens next
      </Typography>
      <Typography color="neutral.boldest.enabled">
        We{"'"}ll send an email to the supplier and help you follow up if
        needed. Most suppliers respond in 1-3 business days.
      </Typography>
    </div>
  );
}
