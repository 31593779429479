import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { userInitializedState, userStateState } from "../jotai/user";
import { modals } from "../utils/enums";
import { isFeatureEnabled } from "../utils/split";
import useShowModal from "./useShowModal";

export type OnCompleteOptions = {
  filters?: string[];
};
export type OnCompleteFn = (options: OnCompleteOptions) => void;

/* This hook displays the search intent survey experience, returning filters
 * based on the user's responses. The resulting function should be awaited prior
 * to continuing the search flow.  */
export default function useSearchIntentSurvey() {
  const userState = useAtomValue(userStateState);
  // 8/20/2024: This flag is turned off for everyone right now,
  // but we plan on turning it back on with updated copy in the future.
  const hasFeature = isFeatureEnabled("elasticIntentSurvey");
  const isInitialized = useAtomValue(userInitializedState);

  const showSearchIntent = useShowModal(modals.SEARCH_INTENT_MODAL);

  return useCallback(
    (): Promise<OnCompleteOptions> =>
      new Promise((resolve) => {
        if (!isInitialized || !hasFeature || !userState.showIntentSurvey) {
          resolve({});
          return;
        }

        const onComplete: OnCompleteFn = (options) => resolve(options);
        showSearchIntent({
          onComplete,
        });
      }),
    [showSearchIntent, userState, hasFeature, isInitialized]
  );
}
