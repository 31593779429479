import { Transition } from "@headlessui/react";
import clsx from "clsx";
import _get from "lodash/get";
import type { ReactNode } from "react";

import { useSetAtom } from "jotai";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";
import { sessionPopupState } from "../jotai/page";
import { Typography } from "../library";
import CloseButton from "../library/Button/CloseButton";
import type { TypographyProps } from "../library/Typography";
import { type BgColor, bgColorClass } from "../utils/colors";
import { elevationClass } from "../utils/designTokens";

export enum AnimatedPopupTypes {
  TIMED = "timed",
  CLICK = "click",
}

interface BasePopupProps {
  className?: string;
  children: ReactNode;
  bgColor?: BgColor;
  contentClassName?: string;
  typographyProps?: TypographyProps;
}

interface AnimatedPopupProps extends BasePopupProps {
  type?: AnimatedPopupTypes;
  show: boolean;
  setShow?: (x: boolean) => void;
  durationSeconds?: number;
  onClose?: () => void;
  floating?: boolean;
  allowDismiss?: boolean;
}

export interface TimedAnimatedPopupProps extends AnimatedPopupProps {
  type?: AnimatedPopupTypes.TIMED;
  setShow?: (x: boolean) => void;
  durationSeconds?: number;
}

export interface ClickAnimatedPopupProps extends AnimatedPopupProps {
  type?: AnimatedPopupTypes.CLICK;
  onClose?: () => void;
}

export default function AnimatedPopup({
  className,
  children,
  show,
  type,
  bgColor = "brand.bold.primary.enabled",
  contentClassName,
  typographyProps,
  durationSeconds = 2,
  floating = true,
  allowDismiss = true,
  setShow = () => {},
  onClose = () => {},
}: TimedAnimatedPopupProps | ClickAnimatedPopupProps) {
  const setSessionPopupState = useSetAtom(sessionPopupState);
  return (
    <Transition
      appear
      enter="fadeIn duration-500"
      enterFrom="transform opacity-0 mt-0"
      enterTo={clsx("transform opacity-100", { "mt-12": floating })}
      leave="fadeOut duration-500"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      show={typeof window !== "undefined" && show}
      afterEnter={() => {
        if (type !== AnimatedPopupTypes.TIMED) return;
        setTimeout(() => {
          setSessionPopupState(null);
          setShow(false);
        }, durationSeconds * 1000);
      }}
    >
      <div
        className={clsx(
          "block py-5 px-6 text-center shadow-[rgba(38, 32, 197, 0.10)]",
          _get(bgColorClass, bgColor),
          _get(elevationClass, "elevation-2"),
          { "z-4 left-1/2 -translate-x-1/2 fixed top-24": floating },
          className
        )}
      >
        <Typography
          component="div"
          size="sm"
          variant="body"
          color="neutral.subtlest.enabled"
          className={clsx(
            "flex justify-between items-center gap-x-6",
            contentClassName
          )}
          {...typographyProps}
        >
          {children}
          {type === AnimatedPopupTypes.CLICK && allowDismiss && (
            <CloseButton
              onClose={() => {
                setSessionPopupState(null);
                onClose();
              }}
              dataTestId="popup-close-button"
            />
          )}
        </Typography>
      </div>
    </Transition>
  );
}

export function SuccessPopup({
  type = AnimatedPopupTypes.TIMED,
  className,
  children,
  show,
  durationSeconds = 2,
  setShow = () => {},
  onClose = () => {},
}: TimedAnimatedPopupProps | ClickAnimatedPopupProps) {
  return (
    <AnimatedPopup
      type={type}
      className={clsx("w-fit rounded-2xl", className)}
      show={show}
      durationSeconds={durationSeconds}
      setShow={setShow}
      onClose={onClose}
      bgColor="feedback.bold.success"
    >
      {children}
    </AnimatedPopup>
  );
}

export function ErrorPopup({
  type = AnimatedPopupTypes.CLICK,
  className,
  children,
  show,
  setShow,
  durationSeconds = 2,
  onClose,
}: TimedAnimatedPopupProps | ClickAnimatedPopupProps) {
  return (
    <AnimatedPopup
      type={type}
      className={clsx("w-fit rounded-2xl", className)}
      show={show}
      setShow={setShow}
      durationSeconds={durationSeconds}
      onClose={onClose}
      bgColor="feedback.bold.error"
    >
      {children}
    </AnimatedPopup>
  );
}

interface AnnouncementPopupPropTypes extends BasePopupProps {
  name: string;
  allowDismiss?: boolean;
  forceDismiss?: boolean;
  onClose?: () => void;
}

/**
 * Banner that spans the full width of the screen.
 */
export function AnnouncementPopup({
  className,
  children,
  name,
  allowDismiss = true,
  forceDismiss = false,
  onClose,
}: AnnouncementPopupPropTypes) {
  const [hasDismissed, setHasDismissed] = useStateWithLocalStorage(
    `dismissed-${name}`,
    allowDismiss && forceDismiss
  );

  const show = typeof window !== "undefined" && !hasDismissed;

  return (
    <AnimatedPopup
      // Place the banner directly under the header (which is 5rem tall)
      className={clsx("flex justify-between top-[5rem] sticky", className)}
      show={show}
      floating={false}
      type={AnimatedPopupTypes.CLICK}
      allowDismiss={allowDismiss}
      onClose={() => {
        setHasDismissed(true);
        onClose?.();
      }}
      bgColor="brand.boldest.enabled"
      contentClassName="w-full px-5 md:px-30"
      typographyProps={{ size: "md", emphasis: true }}
    >
      {children}
    </AnimatedPopup>
  );
}

interface InfoPopupPropTypes extends BasePopupProps {
  name: string;
  forceDismiss: boolean;
}
export function InfoPopup({
  className,
  children,
  name,
  forceDismiss = false,
}: InfoPopupPropTypes) {
  const [hasDismissed, setHasDismissed] = useStateWithLocalStorage(
    `dismissed-${name}`,
    forceDismiss
  );

  const show = typeof window !== "undefined" && !hasDismissed;

  return (
    <AnimatedPopup
      className={clsx("top-24 w-fit rounded-2xl", className)}
      show={show}
      type={AnimatedPopupTypes.CLICK}
      onClose={() => setHasDismissed(true)}
    >
      {children}
    </AnimatedPopup>
  );
}
