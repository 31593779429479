import clsx from "clsx";
import { Suspense, lazy, useMemo, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useSelectTabByHash from "../../hooks/useSelectTabByHash";
import { borderColorClass, textColorClass } from "../../utils/colors";
import EntityContractsTable from "./EntityContractsTable";

const PiggybackedContractsSection = lazy(
  () => import("./PiggybackedContracts")
);

export default function ContractTabs() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = useMemo(
    () => [
      {
        title: "Entity contracts",
        hash: "entity-contracts",
        Panel: EntityContractsTable,
      },
      {
        title: "Piggybacked contracts",
        hash: "piggybacked-contracts",
        Panel: () => (
          <Suspense fallback={null}>
            <PiggybackedContractsSection />
          </Suspense>
        ),
      },
    ],
    []
  );

  useSelectTabByHash(tabs, setSelectedTabIndex);

  return (
    <Tabs
      className="flex flex-col gap-10 w-full mt-10"
      forceRenderTabPanel
      selectedIndex={selectedTabIndex}
      onSelect={(index) => {
        window.location.hash = `#${tabs[index].hash}`;
      }}
    >
      <TabList
        className={clsx(
          "flex gap-10 border-solid border-b overflow-auto text-cp-headline-md",
          borderColorClass.neutral.default
        )}
      >
        {tabs.map(({ title, hash }, idx) => (
          <Tab
            key={`${hash}-tab`}
            className={clsx(
              `analytics-${hash}-tab cursor-pointer mr-6 inline-block group`,
              {
                [textColorClass.brand.boldest.enabled]:
                  selectedTabIndex === idx,
                [textColorClass.neutral.bold.enabled]: selectedTabIndex !== idx,
              }
            )}
          >
            {title}
            <div
              className={clsx(
                "bg-cp-lapis-500 h-[2px] transition-all ease-in mt-2",
                {
                  "w-full": selectedTabIndex === idx,
                  "group-hover:w-3 w-0": selectedTabIndex !== idx,
                }
              )}
            />
          </Tab>
        ))}
      </TabList>
      {tabs.map(({ hash, Panel }, idx) => (
        <TabPanel
          key={`${hash}-panel`}
          className={clsx({
            hidden: selectedTabIndex !== idx,
          })}
        >
          <Panel />
        </TabPanel>
      ))}
    </Tabs>
  );
}
