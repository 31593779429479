import type { TData, TableProps } from "..";
import TableRow from "../TableRow";

export function TableBody<T extends TData, P extends TData>({
  columns,
  data,
  subColumns,
  subColumnsStart = 0,
}: Pick<
  TableProps<T, P>,
  "columns" | "data" | "subColumns" | "subColumnsStart"
>) {
  return (
    <tbody className="overflow-y-auto">
      {data.map((d, ix) => {
        return (
          <TableRow
            // biome-ignore lint/suspicious/noArrayIndexKey: An improvement here would be generating an id for each row and using that.
            key={ix}
            columns={columns}
            rowData={d}
            subColumns={subColumns}
            subColumnsStart={subColumnsStart}
          />
        );
      })}
    </tbody>
  );
}
