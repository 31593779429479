import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

import SearchCategories from "./SearchCategories";

const categories = [
  "Athletic Equipment & Repair",
  "Athletic Trainer Equipment",
  "Automotive Parts, Supplies & Repair",
  "Cloth Goods, Footwear, Letter Awards, Letter Jackets/Sweaters, etc.",
  "Computer-Technology Hardware & Service",
  "Computer Technology Software",
  "Electronics Equipment & Service",
  "Facility Equipment, Services & Supplies",
  "Furniture: Office, Classroom, Cafeteria, etc.",
  "Health Services Equipment, Supplies & Service",
  "Janitorial Equipment & Supplies",
  "Library Materials & Supplies",
  "Musical Instruments, Supplies & Service",
  "Network Equipment, Supplies & Service",
  "Office Machines & Service",
  "Office Supplies",
  "Paper: Copy, Construction, Art, etc.",
  "Personnel Administration",
  "Physical Education Equipment & Service",
  "Plaques, Trophies, Engraving & Awards",
  "Playground Equipment, Supplies & Service",
  "Printing Services",
  "Promotional Materials & Fundraising",
  "School Supplies: Instructional Supplies, Instructional Equipment, STAAR/TEKS Materials, Textbooks, etc.",
  "Transportation: Vehicle Maintenance, Charter Transport, etc",
  "Uniforms: Athletic, Music, etc",
  "Vocational Supplies, Equipment & Services",
];

const DEFAULT_GOV_AFFILIATION = "Region 18 ESC";
const DEFAULT_SOURCE_ENTITY_ID = "864d1d93-4089-4ec1-b25d-8dc2172d1410";
const DEFAULT_GOV_ZIP = "79706";

function ESC18() {
  const { zip, entity } = getParams();
  function getCustomizedUrl(url) {
    const zipToAppend = zip || DEFAULT_GOV_ZIP;
    url.searchParams.append("zip", zipToAppend);

    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );

    url.searchParams.set("embedSourceEntityId", DEFAULT_SOURCE_ENTITY_ID);

    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Search Region 18 ESC Contracts"
        subtitle="Search Region 18 ESC contracts plus tens of thousands more competitively solicited shareable contracts you can use now."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          searchSource={agencySearchSource[agencies.ESC18]}
          queryPlaceholder="What are you buying? Equipment, IT, services..."
          getCustomizedUrl={getCustomizedUrl}
        >
          {(query, setQuery) => (
            <SearchCategories
              categories={categories}
              query={query}
              setQuery={setQuery}
            />
          )}
        </BaseWidgetSearchBar>
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-esc18");
if (container) {
  const root = createRoot(container);
  root.render(<ESC18 />);
}
