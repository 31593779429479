import { useMemo } from "react";

import { useAtomValue } from "jotai";
import type { ContractResult } from "../../../generated";
import {
  supplierSearchResponseDataState,
  topSupplierContractCardState,
} from "../../../jotai/search";
import { Typography } from "../../../library";
import { contractMatchTypes } from "../../../utils/enums";
import { resultHasMatch } from "../utils";

interface PrimaryResultsHeaderTextProps {
  visibleResults: ContractResult[];
  primaryResults: ContractResult[];
  otherResults: ContractResult[];
}
export default function PrimaryResultsHeaderText({
  visibleResults,
  primaryResults,
  otherResults,
}: PrimaryResultsHeaderTextProps) {
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const topSupplierContractCard = useAtomValue(topSupplierContractCardState);

  const hasCompetitorMatches = useMemo(
    () =>
      visibleResults.some((hit) =>
        resultHasMatch(
          hit,
          (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
        )
      ),
    [visibleResults]
  );

  const hasMatchedExactSupplier = useMemo(
    () =>
      primaryResults.some((hit) =>
        resultHasMatch(
          hit,
          (r) => r.matchTier === contractMatchTypes.EXACT_SUPPLIER
        )
      ) || topSupplierContractCard,
    [primaryResults, topSupplierContractCard]
  );

  const hasUnmatchedExactSupplier = useMemo(
    () =>
      otherResults.some((hit) =>
        resultHasMatch(
          hit,
          (r) => r.matchTier === contractMatchTypes.EXACT_SUPPLIER
        )
      ),
    [otherResults]
  );
  const supplier = supplierResponseData?.supplierData?.suppliers?.[0]?.supplier;

  // must have competitor matches, no matched exact supplier, and at least one supplier
  if (!hasCompetitorMatches || hasMatchedExactSupplier || !supplier) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography color="neutral.bolder.enabled">
        {supplier.displayName} doesn't have any usable contracts
        {hasUnmatchedExactSupplier && " that match your filter"}. We found some
        other contracts that are relevant to your query.
      </Typography>
    </div>
  );
}
