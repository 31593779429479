import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import BuyerOptInModal from "./BuyerOptinModal";
import type { SharedBuyerOptInModalProps } from "./types";

interface BuyerOptInSaveContractModalProps extends SharedBuyerOptInModalProps {}

export type ShowBuyerOptInSaveContractModal = (
  args: Omit<BuyerOptInSaveContractModalProps, "hideModal">
) => void;

export default function BuyerOptInSaveContractModal({
  ...props
}: BuyerOptInSaveContractModalProps) {
  return (
    <BuyerOptInModal
      {...props}
      title="Get pricing and contract details in your inbox"
      subtitle="We'll connect you with the right contacts to share pricing and answer any questions you have."
      ctaText="Save contract to project"
      ctaBadgeProps={{
        Icon: BookmarkRoundedIcon,
        reverse: true,
      }}
      analyticsClassName="analytics-buyer-contract-source-optin-save-to-project-modal"
    />
  );
}
