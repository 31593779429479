import { createRoot } from "react-dom/client";

import { Link, Typography } from "../../library";
import { getDOMAnchorById } from "../../utils";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";
import { generateHrefForEmail } from "../../utils/format";
import Page from "../Page/Page";

const container = getDOMAnchorById("password-reset-email-sent");
if (container) {
  const root = createRoot(container);
  root.render(
    <Page
      profileType={null}
      activeAgreements={null}
      searchSource="password-reset-email-sent-header-search-bar"
    >
      <div className="cp-page-container mt-20">
        <Typography className="mb-6" variant="headline" size="lg">
          Password reset sent
        </Typography>
        <Typography className="mb-6">
          If you have a Pavilion account with the email you entered, you&apos;ll
          receive a link to reset your password in your inbox
        </Typography>
        <Typography className="mb-6">
          If you didn&apos;t receive an email, check the email address you
          created an account with and your spam folder. Please note that if you
          signed up using Google or Microsoft, you will not receive an email to
          reset your password. Please sign in using the third party provider.
        </Typography>
        <Typography>
          Contact{" "}
          <Link
            href={generateHrefForEmail(SUPPORT_EMAIL_ADDRESS)}
            underline={false}
            newWindow={false}
            emphasis={false}
          >
            {SUPPORT_EMAIL_ADDRESS}
          </Link>{" "}
          for additional help.
        </Typography>
      </div>
    </Page>
  );
}
