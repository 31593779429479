import clsx from "clsx";
import _range from "lodash/range";
import { useState } from "react";

import { bgColorClass, borderColorClass } from "../../../utils/colors";
import Typography from "../../Typography";
import type { ColumnDefinition, TData, TableProps } from "../index";
import { getExpandableKey } from "../index";
import ExpandIconTableCell from "./ExpandIconTableCell";

const CELL_STYLE = "p-3 align-top";
const TABLE_ROW_BORDER_STYLE = "border-b border-solid";

interface TableRowProps<T extends TData, P extends TData>
  extends Pick<TableProps<T, P>, "columns" | "subColumns" | "subColumnsStart"> {
  rowData: T;
  isSubRow?: boolean;
  isSubHeader?: boolean;
  isLastSubRow?: boolean;
}

export default function TableRow<T extends TData, P extends TData>({
  columns,
  rowData,
  subColumns,
  isSubHeader = false,
  isSubRow = false,
  subColumnsStart,
  isLastSubRow = false,
}: TableRowProps<T, P>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandableKey = getExpandableKey(rowData);
  const subRowData: P[] = (rowData[expandableKey] as P[]) || [];
  const isMainRow = !isSubRow && !isSubHeader;
  const canExpand = !!subColumns && !!subRowData.length;

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <tr
        className={clsx({
          [bgColorClass.accent.neutral.enabled]: isSubHeader,
          [bgColorClass.neutral.subtlest.hovered]: isSubRow,
        })}
      >
        {!isMainRow && (
          <TableRowPadding
            padding={subColumnsStart || 1}
            className={getBorderStyle({
              isExpanded,
              isSubHeader,
              isSubRow,
              isLastSubRow,
            })}
          />
        )}
        {isSubHeader
          ? subColumns?.map(({ key, label }) => {
              return (
                <Typography
                  key={key as string}
                  className="px-3 py-1"
                  component="td"
                  color="neutral.bolder.enabled"
                  variant="meta"
                  size="sm"
                >
                  {label}
                </Typography>
              );
            })
          : columns.map(({ key, render }) => {
              if (key === "expandIcon") {
                return (
                  <ExpandIconTableCell
                    key={key as string}
                    isExpanded={isExpanded}
                    canExpand={canExpand}
                    handleExpand={handleExpand}
                    className={getBorderStyle({
                      isExpanded,
                      isSubHeader,
                      isSubRow,
                      isLastSubRow,
                    })}
                  />
                );
              }
              return (
                <Typography
                  key={key as string}
                  className={clsx(
                    CELL_STYLE,
                    getBorderStyle({
                      isExpanded,
                      isSubHeader,
                      isSubRow,
                      isLastSubRow,
                    })
                  )}
                  component="td"
                  color="neutral.bolder.enabled"
                  variant="meta"
                >
                  {render(rowData[key], rowData)}
                </Typography>
              );
            })}
      </tr>
      {isExpanded && subColumns && !!subRowData.length && (
        <TableRow
          columns={subColumns as ColumnDefinition<P, keyof P>[]}
          rowData={subRowData[0]}
          subColumns={subColumns as ColumnDefinition<P, keyof P>[]}
          isSubHeader
          subColumnsStart={subColumnsStart}
        />
      )}
      {isExpanded &&
        subRowData?.map((d, index) => (
          <TableRow
            key={d.toString()}
            columns={subColumns as ColumnDefinition<P, keyof P>[]}
            rowData={d}
            isSubRow
            subColumnsStart={subColumnsStart}
            isLastSubRow={index === subRowData.length - 1}
          />
        ))}
    </>
  );
}

function getBorderStyle({
  isExpanded,
  isSubHeader,
  isSubRow,
  isLastSubRow,
}: {
  isExpanded: boolean;
  isSubHeader: boolean;
  isSubRow: boolean;
  isLastSubRow: boolean;
}) {
  if (isExpanded || isSubHeader) return "";
  if (isSubRow && !isLastSubRow)
    return `${TABLE_ROW_BORDER_STYLE} ${borderColorClass.accent.neutral.enabled}`;
  return `${TABLE_ROW_BORDER_STYLE}  ${borderColorClass.neutral.subtle.enabled}`;
}

function TableRowPadding({
  padding,
  className,
}: { padding: number; className?: string }) {
  const paddingArr = _range(padding).fill(0);
  return (
    <>
      {paddingArr.map((_, index) => (
        <td
          // biome-ignore lint/suspicious/noArrayIndexKey: list is static
          key={index}
          className={className}
        />
      ))}
    </>
  );
}
