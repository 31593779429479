import clsx from "clsx";
import {
  type AriaAttributes,
  Children,
  type DOMAttributes,
  useEffect,
  useState,
} from "react";

import { Typography } from "../index";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    label?: string;
  }
}
const BASE_STYLES =
  "p-1 w-full flex items-center rounded-3xl h-11 bg-cp-violet-100 min-w-60";
const BASE_TAB_STYLES = `inline-flex items-center justify-center rounded-2xl cursor-pointer
   duration-200 transition-colors relative font-semibold h-8
   focus-visible:ring-1 focus-visible:ring-cp-lapis-500 focus-visible:ring-offset-4 focus-visible:outline`;

interface ITab {
  props: {
    label: string;
    children: string;
  };
}

export interface ToggleTabsProps {
  children: ITab[];
  className?: string;
  onSelectTab?: (index?: number) => void;
  defaultTabIndex?: number;
}

export function ToggleTabs({
  children,
  className,
  onSelectTab,
  defaultTabIndex = 0,
}: ToggleTabsProps) {
  // Ensure that the defaultTabIndex value is less than or equal to the number of tabs
  const [activeTabIndex, setActiveTabIndex] = useState(
    defaultTabIndex % Children.count(children)
  );
  const activeTabClass =
    "border border-solid border-cp-lapis-500 bg-cp-violet-200";

  useEffect(() => {
    setActiveTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);
  function widthClassName(noOfChildren: number) {
    if (noOfChildren > 2) {
      // eslint-disable-next-line no-console
      console.warn("ToggleTabs supports a maximum of 2 tabs");
    }
    return clsx({
      "w-1/2": noOfChildren === 2,
    });
  }

  function handleClick(idx: number) {
    setActiveTabIndex(idx);
    onSelectTab?.(idx);
  }
  const noOfChildren = children.length;
  return (
    <div className={className}>
      <ul className={BASE_STYLES}>
        {children.map((tab: ITab, idx: number) => {
          return (
            <li
              className={clsx(BASE_TAB_STYLES, widthClassName(noOfChildren), {
                [activeTabClass]: activeTabIndex === idx,
              })}
              // biome-ignore lint/suspicious/noArrayIndexKey: These are static, so an index is ok.
              key={idx}
              onClick={() => handleClick(idx)}
              aria-label={tab.props.label}
              data-testid={tab.props.label}
            >
              <Typography
                variant="cta"
                size="md"
                color="brand.default.primary.enabled"
              >
                {tab.props.label}
              </Typography>
            </li>
          );
        })}
      </ul>
      <div>{children[activeTabIndex].props.children}</div>
    </div>
  );
}

export default ToggleTabs;
