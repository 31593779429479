import pluralize from "pluralize";
import { useEffect, useState } from "react";

import { useAtomValue } from "jotai";
import type { TrackContractClickWithinSolicitationCarouselFn } from "../../components/SolicitationPage/types";
import {
  ApiService,
  type RecommendedContractRequest,
  type SemanticRecommendedContract,
} from "../../generated";
import { pageIdState, pageTypeState } from "../../jotai/page";
import { userInitializedState, userZipState } from "../../jotai/user";
import { formatLocal } from "../../utils/format";
import { handleError } from "../../utils/generatedApi";
import SolicitationCarousel from "./SolicitationCarousel";

interface SemanticRecommendationCarouselProps {
  id: string;
  query: Maybe<string>;
  onRender: (solicitations: SemanticRecommendedContract[]) => void;
  trackContractClickWithinSolicitation?: TrackContractClickWithinSolicitationCarouselFn;
}

export default function SemanticRecommendationCarousel({
  id,
  query,
  onRender,
  trackContractClickWithinSolicitation,
}: SemanticRecommendationCarouselProps): JSX.Element | null {
  const userZip = useAtomValue(userZipState);
  const isInitialized = useAtomValue(userInitializedState);
  const pageType = useAtomValue(pageTypeState);
  const pageId = useAtomValue(pageIdState);
  const [recommendations, setRecommendations] =
    useState<SemanticRecommendedContract[]>();
  const [geo, setGeo] = useState<Maybe<string>>(null);
  const getCarouselTitle = () => {
    return `Explore similar ${pluralize(
      "contract",
      recommendations?.length
    )}${geo ? ` in ${geo}` : ""}`;
  };

  useEffect(() => {
    if (!isInitialized) return;

    const body: RecommendedContractRequest = { docid: id, size: 4 };
    if (userZip) body.zip = userZip;

    (async () => {
      try {
        const data =
          await ApiService.apiV1RecommendationsSemanticContractCreate(body);

        if (data.results) {
          const { results, queryLocation } = data;

          if (queryLocation) {
            const { state, city, zip } = queryLocation;
            if (state || city) {
              setGeo(formatLocal({ state, city, zip }));
            }
          }
          setRecommendations(results);
        }
      } catch (err) {
        handleError(err);
      }
    })();
  }, [id, userZip, isInitialized]);

  if (!recommendations) {
    return null;
  }

  return (
    <SolicitationCarousel
      solicitations={recommendations}
      sourceData={{ pageType, pageId }}
      query={query}
      queryZip={userZip}
      onRender={onRender}
      trackContractClick={trackContractClickWithinSolicitation}
      title={getCarouselTitle()}
    />
  );
}
