import clsx from "clsx";

import {
  type InputSizes,
  type LabeledInputVariants,
  VARIANTS,
} from "./LabeledInput";

export type InputComponentType = "input" | "textarea";

export const INPUT_SIZES: Record<InputSizes, string> = {
  lg: "h-16",
  md: "h-12",
  sm: "h-10",
};

export const TEXTAREA_SIZES: Record<InputSizes, string> = {
  lg: "h-60 mb-1",
  md: "h-40 mb-1",
  sm: "h-30 mb-1",
};

export const INPUT_PADDING = "px-4 py-3";

export const INPUT_CONTENT_STYLE =
  "w-full bg-transparent inline-block placeholder:text-cp-neutral-palette-600 placeholder:text-cp-body-md disabled:opacity-75 focus-within:placeholder:opacity-0 peer relative";

export const INPUT_STYLE = clsx(INPUT_CONTENT_STYLE, INPUT_PADDING);

export const SHARED_INPUT_BORDER_STYLE =
  "w-full focus-visible:outline-1 focus-visible:outline-offset-8 focus-visible:outline rounded-3";

export const borderClassName = (variant: LabeledInputVariants) =>
  clsx(
    "relative border border-solid rounded-3 flex items-center group",
    VARIANTS[variant].borderClass
  );

export const sizeClassname = (
  size: InputSizes,
  component: InputComponentType
) => clsx({ [INPUT_SIZES[size]]: component === "input" });

export const delimiters = ["Enter", ",", " "];

export const BORDER_LABEL_CLASS =
  "absolute top-[-0.5rem] left-4 bg-cp-white-100";
