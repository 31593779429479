import type { FormikValues } from "formik";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";

import { supplierState, userState } from "../../../jotai/user";
import { FormWrapper, PHONE_FIELDS } from "../../../library/form";
import { ErrorPopup } from "../../../popups/AnimatedPopup";
import Modal from "../../../shared/Modal/Modal";
import {
  addSupplierContact,
  handleError,
  patchSupplierContact,
} from "../../../utils/api";

interface SupplierPhoneModalProps {
  hideModal: () => void;
}

export default function SupplierPhoneModal({
  hideModal,
}: SupplierPhoneModalProps) {
  const [error, setError] = useState(false);
  const [supplier, setSupplier] = useAtom(supplierState);
  const { email, firstName, lastName } = useAtomValue(userState);
  const [loading, setLoading] = useState(false);

  async function updateSupplierPhone(values: FormikValues) {
    setLoading(true);

    const userContact = supplier.manualContacts.find((c) => c.email === email);
    let response: Response;
    if (userContact) {
      response = await patchSupplierContact(
        {
          ...userContact,
          phoneNumber: values.phone,
        },
        userContact.id,
        supplier.handle as string
      );
    } else {
      response = await addSupplierContact(
        {
          email: email as string,
          firstName,
          lastName,
          phoneNumber: values.phone,
        },
        supplier.handle as string
      );
    }

    setLoading(false);
    if (handleError(response)) {
      setError(true);
      return;
    }

    const data = await response.json();

    setSupplier({
      ...supplier,
      manualContacts: [
        data,
        ...supplier.manualContacts.filter((c) => c.id !== userContact?.id),
      ],
    });

    hideModal();
  }

  return (
    <Modal title="Add your work phone number" hideModal={hideModal}>
      <FormWrapper
        initialValues={{ phone_number: "" }}
        fields={PHONE_FIELDS}
        submitCta="Save"
        submitClassName="analytics-supplier-phone"
        onSubmit={updateSupplierPhone}
        disabled={!!supplier.handle && loading}
      />
      <ErrorPopup show={error} onClose={() => setError(false)}>
        Unable to save phone number. Please try again.
      </ErrorPopup>
    </Modal>
  );
}
