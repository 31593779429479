import type { ContractFileTypeEnum } from "../../../generated";
import UploadedFileCard from "../../../library/FileDropzone/UploadedFileCard";
import type { BasicContractFile } from "../../../shared/types";

export default function EditableFileItem({
  file,
  onDelete,
}: { file: BasicContractFile; onDelete: (name: string) => void }) {
  return (
    <div className="flex flex-row gap-2 items-center w-[30rem]">
      <UploadedFileCard
        file={{
          name: file.name,
          type: file.type as ContractFileTypeEnum,
        }}
        onDelete={onDelete}
      />
    </div>
  );
}
