import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import PasswordChangeForm from "../../components/PasswordReset/PasswordChangeForm";
import { getDOMAnchorById, parseJSONPasswordError } from "../../utils";
import Page from "../Page/Page";

const container = getDOMAnchorById("password-reset-change");
if (container) {
  const root = createRoot(container);

  // Parse errors from backend
  const passwordError = parseJSONPasswordError(
    _get(container, "dataset.newPasswordErrors") as string
  );

  root.render(
    <Page
      searchSource="password-reset-change-header-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <PasswordChangeForm
        newPasswordError={passwordError}
        invalidLink={_get(container, "dataset.invalidLink") === "True"}
      />
    </Page>
  );
}
