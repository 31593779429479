import clsx from "clsx";
import compliance from "../../../../img/icons/compliance.svg";
import fastSupplierResponse from "../../../../img/icons/fast-supplier-response.svg";
import findContracts from "../../../../img/icons/find-contracts.svg";
import { Button, PageSection, Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";

const valueProps = [
  {
    key: "valuePropSuppliers",
    text: "Find suppliers for everything",
    subText:
      "Search for suppliers across the largest collection of cooperative contracts",
    imageUrl: findContracts,
    imageAlt: "",
  },
  {
    key: "valuePropCompliance",
    text: "Make compliance easy",
    subText:
      "Find suppliers on contracts that meet your compliance needs & preferences",
    imageUrl: compliance,
    imageAlt: "",
  },
  {
    key: "valuePropSupplierResponse",
    text: "Get a fast supplier response",
    subText: "Ask questions, get estimates, and request quotes",
    imageUrl: fastSupplierResponse,
    imageAlt: "",
  },
];

export default function ValuePropSection({
  search,
}: {
  search: () => void;
}) {
  return (
    <PageSection
      className={clsx("py-12 md:py-16", bgColorClass.accent.persimmon.subtle)}
    >
      <div className="grid grid-cols-1 gap-8 md:gap-12 md:grid-cols-3 md:gap-6 w-full">
        {valueProps.map((valueProp) => (
          <ValueProp {...valueProp} key={valueProp.key} />
        ))}
      </div>
      <div className="mt-10 w-full flex justify-center">
        <Button
          className="w-fit analytics-homepage-searchFromValueProps"
          onClick={search}
        >
          Start your search
        </Button>
      </div>
    </PageSection>
  );
}

function ValueProp({
  imageUrl,
  imageAlt,
  text,
  subText,
}: { imageUrl: string; imageAlt: string; text: string; subText: string }) {
  return (
    <div className="flex gap-4 md:flex-col md:items-center lg:max-w-[336px]">
      <img
        className="mb-4 xl:h-[8rem] md:h-[7rem] h-[4rem]"
        src={imageUrl}
        alt={imageAlt}
      />
      <div className="flex flex-col items-center gap-2">
        <Typography
          variant="headline"
          size="md"
          color="brand.boldest.enabled"
          className="font-homeHeader w-full lg:w-[256px] md:text-cp-headline-lg lg:text-center"
          component="h2"
        >
          {text}
        </Typography>
        <Typography
          variant="body"
          size="md"
          color="brand.boldest.enabled"
          className="lg:text-center md:text-cp-body-lg"
        >
          {subText}
        </Typography>
      </div>
    </div>
  );
}
