import { Typography } from "../library";
import { getSentenceCase } from "../utils";

export default function SimilarOfferings({
  offerings,
  className,
}: {
  offerings: string[];
  className?: string;
}) {
  const text = offerings.map((o) => getSentenceCase(o.trim())).join("; ");

  return (
    <Typography color="neutral.bolder.enabled" className={className}>
      Similar offerings: {text}
    </Typography>
  );
}
