import clsx from "clsx";

import { borderColorClass } from "../utils/colors";
import { elevationClass } from "../utils/designTokens";
import RadioButton from "./Input/RadioButton";
import Typography from "./Typography";

const buttonClassName = (
  isActive: boolean,
  border: boolean,
  className: Maybe<string>
) =>
  clsx(
    "flex justify-between items-center rounded-4 gap-4",
    border ? "border border-solid p-4" : "px-4 py-2",
    {
      [borderColorClass.brand.bold.enabled]: border && isActive,
      [elevationClass["elevation-1"]]: border && isActive,
      "hover:shadow-2": !isActive,
    },
    className
  );

export default function BuyerOptInRadioButton({
  isSelected,
  onClick,
  label,
  border = false,
  children,
  className,
}: {
  className?: string;
  isSelected?: boolean;
  onClick: () => void;
  label: string;
  border?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <button
      className={buttonClassName(!!isSelected, border, className)}
      onClick={onClick}
    >
      <div className="flex gap-2">
        <RadioButton checked={isSelected} readOnly />
        <Typography
          className="text-left flex-1"
          color="neutral.boldest.enabled"
          emphasis={border}
        >
          {label}
        </Typography>
      </div>
      {children}
    </button>
  );
}
