import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import clsx from "clsx";
import { Badge, Typography } from "../../../library";
import { bgColorClass, iconColorClass } from "../../../utils/colors";

export default function StepCircle({
  text,
  index,
}: { text: string; index: number }) {
  return (
    <div className="flex items-center gap-1">
      <div
        className={clsx(
          "flex flex-none items-center justify-center rounded-full w-6 h-6",
          {
            [bgColorClass.accent.limeade.enabled]: index === 0,
            [bgColorClass.neutral.subtlest.hovered]: index !== 0,
          }
        )}
      >
        <Typography
          component="span"
          variant="meta"
          size="sm"
          color="brand.boldest.enabled"
        >
          {index === 0 ? (
            <Badge
              Icon={CheckRoundedIcon}
              size="sm-md"
              iconClass={iconColorClass.accent.limeade.enabled}
              includeMargin={false}
            />
          ) : (
            index + 1
          )}
        </Typography>
      </div>
      <Typography variant="meta" size="sm" color="neutral.boldest.enabled">
        {text}
      </Typography>
    </div>
  );
}
