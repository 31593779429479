import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import useRequestID from "../../../hooks/useRequestID";
import { Badge, Link, Typography } from "../../../library";
import { supplierHasFeature } from "../../../utils/featureManagement";
import {
  trackViewSupplierCatalog,
  trackViewSupplierWebsite,
} from "../../../utils/tracking";

export default function SupplierAboutSummary({
  id,
  about,
  aliases,
  website,
  handle,
  addressCity,
  addressStateCode,
  catalog,
  activeAgreements,
}: {
  id: number;
  about: Maybe<string>;
  aliases?: string[];
  website: Maybe<string>;
  handle: Maybe<string>;
  addressCity: Maybe<string>;
  addressStateCode: Maybe<string>;
  catalog: Maybe<string>;
  activeAgreements: string[];
}) {
  const requestID = useRequestID();

  const showCatalog =
    supplierHasFeature(activeAgreements, "supplierCatalog") && catalog;
  const showWebsite = !showCatalog && website;
  const onSupplierWebsiteClick = () => {
    trackViewSupplierWebsite({
      destinationUrl: website,
      supplierId: id,
      supplierHandle: handle,
      requestId: requestID,
    });
  };
  const onSupplierCatalogClick = () => {
    trackViewSupplierCatalog({
      destinationUrl: catalog,
      supplierId: id,
      supplierHandle: handle,
      requestId: requestID,
    });
  };
  if (
    !about &&
    !aliases?.length &&
    !addressCity &&
    !addressStateCode &&
    !showWebsite &&
    !showCatalog
  ) {
    return null;
  }

  function getAliasText(aliases: string[]) {
    if (!aliases?.length) {
      return null;
    }
    if (aliases.length === 1) {
      return `Also known as ${aliases[0]}`;
    }
    if (aliases.length === 2) {
      return `Also known as ${aliases[0]} and ${aliases[1]}`;
    }
    if (aliases.length === 3) {
      return `Also known as ${aliases[0]}, ${aliases[1]}, and ${aliases[2]}`;
    }
    return `Also known as ${aliases[0]}, ${aliases[1]}, ${aliases[2]} and ${
      aliases.length - 3
    } more`;
  }

  return (
    <div className="grid gap-4">
      {(addressCity || addressStateCode) && (
        <Badge
          Icon={PlaceOutlinedIcon}
          size="md"
          iconClass="text-cp-black-100"
          as={Typography}
          linkProps={{ color: "neutral.default.primary.enabled" }}
        >
          {[addressCity, addressStateCode].filter(Boolean).join(", ")}
        </Badge>
      )}
      {about && (
        <div className="grid gap-4">
          <Typography
            color="neutral.default.primary.enabled"
            className="whitespace-pre-line"
          >
            {about}
          </Typography>
        </div>
      )}
      {!!aliases?.length && (
        <Typography color="neutral.default.primary.enabled">
          {getAliasText(aliases || [])}
        </Typography>
      )}
      {showCatalog && (
        <Badge
          as={Link}
          linkProps={{ href: catalog, underline: false }}
          Icon={LaunchRoundedIcon}
          onClick={onSupplierCatalogClick}
          className="w-fit"
        >
          Visit supplier catalog
        </Badge>
      )}
      {showWebsite && (
        <Badge
          as={Link}
          linkProps={{ href: website, underline: false }}
          onClick={onSupplierWebsiteClick}
          Icon={LaunchRoundedIcon}
          className="w-fit"
        >
          Visit website
        </Badge>
      )}
    </div>
  );
}
