import clsx from "clsx";

import noProjects from "../../../../assets/img/icons/noProjects.png";
import { Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";

export default function NoProjectItemsSection() {
  return (
    <div
      className={clsx(
        "pt-6 px-6 border border-solid rounded-2xl text-center flex flex-col gap-4 items-center",
        borderColorClass.neutral.subtle.enabled
      )}
    >
      <Typography
        color="neutral.bold.enabled"
        variant="headline"
        size="sm"
        emphasis
      >
        No saved contracts and suppliers yet
      </Typography>
      <Typography color="neutral.bold.enabled">
        Search and save contracts to this project to annotate and compare
      </Typography>
      <img className="md:max-w-[34rem] max-w-[26rem]" src={noProjects} />
    </div>
  );
}
