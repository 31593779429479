import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

import { Typography } from "../../library";
import { getDOMAnchorsByClass } from "../../utils";

const NoSearchResults = lazy(() => import("../../components/NoSearchResults"));

function Embed404() {
  return (
    <div className="mt-6 text-center w-5/12 m-auto">
      <Suspense fallback={<div className="h-[236px]" />}>
        <NoSearchResults className="mx-auto mb-6 w-0 md:w-auto md:max-h-1/4-screen" />
      </Suspense>
      <Typography variant="headline" size="lg" emphasis color="brand.bold">
        Embed not found
      </Typography>
      <Typography color="subtler" className="mt-6 mb-6">
        Contact support@withpavilion.com to reset this embed
      </Typography>
    </div>
  );
}

const containers = getDOMAnchorsByClass("iframe-404");
if (containers && containers.length > 0) {
  for (let i = 0; i < containers.length; i++) {
    const root = createRoot(containers[i]);
    root.render(<Embed404 />);
  }
}
