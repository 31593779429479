import { Field, useField } from "formik";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { inferredEntitySignupState } from "../../../jotai/signup";
import { LabeledInputVariants } from "../../../library";
import type { BuyerLeadAgencyFieldValue } from "../../../library/form/BuyerLeadAgencyField";
import type { FormFieldProps } from "../../../library/form/types";

/**
 * To be used with component `BuyerLeadAgencyField`.
 * Subscribes and updates field values with `inferredEntitySignupState`.
 */
export default function InferredBuyerLeadAgencyField(props: FormFieldProps) {
  const [field, { value }, { setValue }] = useField<BuyerLeadAgencyFieldValue>({
    name: props.name,
  });
  const [validationMessage, setValidationMessage] = useState("");
  const [variant, setVariant] = useState(LabeledInputVariants.DEFAULT);
  const [inferredEntity, setInferredEntity] = useAtom(
    inferredEntitySignupState
  );

  useEffect(() => {
    const isBlaOnPavilion =
      inferredEntity?.buyerLeadAgencyDisplayName || value.governmentAgencyId;
    if (!isBlaOnPavilion) {
      setValidationMessage("");
      setVariant(LabeledInputVariants.DEFAULT);
      return;
    }

    const shouldResetField =
      inferredEntity?.buyerLeadAgencyDisplayName &&
      variant === LabeledInputVariants.SUCCESS &&
      value.governmentAffiliationDisplayName !==
        inferredEntity.buyerLeadAgencyDisplayName;

    if (shouldResetField) {
      setInferredEntity(null);
      setValidationMessage("");
      setVariant(LabeledInputVariants.DEFAULT);
      return;
    }

    // If the field is blank, auto-fill from inferredBla. We don't
    // want to overwrite the user's input if they've already typed something.
    if (
      value.governmentAffiliationDisplayName === "" &&
      inferredEntity?.buyerLeadAgencyDisplayName &&
      inferredEntity?.buyerLeadAgencyId
    ) {
      setValue({
        governmentAgencyId: inferredEntity.buyerLeadAgencyId,
        governmentAffiliationDisplayName:
          inferredEntity.buyerLeadAgencyDisplayName,
      });
    }
    // TODO: https://app.shortcut.com/coprocure/story/25118/show-social-proof-on-intial-sign-up
    // "{x} of your coworkers are on Pavilion" when we have 2+ accounts
    // "Your coworkers are on Pavilion" when we have 1 user account
    // "Your entity is on Pavilion" when we have 0 accounts

    setValidationMessage("Your entity is on Pavilion!");
    setVariant(LabeledInputVariants.SUCCESS);
  }, [setValue, setInferredEntity, inferredEntity, variant, value]);

  return (
    <Field
      field={field}
      validationMessage={validationMessage}
      initialVariant={variant}
      editable
      {...props}
    />
  );
}
