import type { FormikProps } from "formik";
import { type ChangeEvent, useState } from "react";

import clsx from "clsx";
import { BuyerRoleEnum } from "../../../generated";
import {
  LabeledInputVariants,
  RadioButtonGroup,
  Typography,
} from "../../../library";
import { borderClassName } from "../../../library/Input/constants";
import type { FieldLabelProps } from "../../../library/form/types";

const buyerRoleOptions = [
  {
    key: BuyerRoleEnum.BUYER,
    label: "I'm a procurement professional",
  },
  {
    key: BuyerRoleEnum.LEADERSHIP,
    label: "I lead a procurement team",
  },
  {
    key: BuyerRoleEnum.OTHER,
    label: "I work in another department",
  },
];

export interface BuyerRoleFieldProps extends FieldLabelProps {
  field: {
    name: string;
    value: BuyerRoleEnum;
    onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  };
  form: FormikProps<{
    [x: string]: BuyerRoleEnum;
  }>;
}

export function BuyerRoleRadioGroupField({
  field,
  form: { setFieldValue, setFieldTouched },
  ...props
}: BuyerRoleFieldProps) {
  const [role, setRole] = useState<Maybe<BuyerRoleEnum>>(null);

  const onChange = (value: string) => {
    setFieldValue(field.name, value, true);
    setRole(value as BuyerRoleEnum);
    setFieldTouched(field.name, true);
  };

  return (
    <div className="grid gap-2">
      <Typography
        emphasis={props.labelEmphasis}
        size={props.labelSize}
        variant={props.labelTextVariant}
      >
        {props.label}
      </Typography>
      <RadioButtonGroup
        className="analytics-supplier-contact-opt-in"
        value={role || "unselected"}
        options={buyerRoleOptions}
        onChange={onChange}
        itemClassName={clsx(
          borderClassName(LabeledInputVariants.DEFAULT),
          "rounded-4 p-3 w-fit"
        )}
        labelColor="neutral.bolder.enabled"
        labelSize="sm"
        labelEmphasis
      />
    </div>
  );
}
