import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const DEFAULT_ZIP_FOR_CITY_OF_LA_CA = "90001";
const DEFAULT_BLA_ID_FOR_CITY_OF_LA_CA = "34d82432-55ee-4a98-8aa0-06c87fff7408";
const DEFAULT_GOV_AFFILIATION = "City of Los Angeles";

function CityOfLACA() {
  // all param fields are optional
  const { zip, entity } = getParams();

  function getCustomizedUrl(url) {
    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );
    url.searchParams.append(
      "embedSourceEntityId",
      DEFAULT_BLA_ID_FOR_CITY_OF_LA_CA
    );
    url.searchParams.append("zip", zip || DEFAULT_ZIP_FOR_CITY_OF_LA_CA);

    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Search for Shareable Contracts"
        subtitle="Search tens of thousands of competitively solicited shareable contracts available to use now."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          searchSource={agencySearchSource[agencies.CITY_OF_LA_CA]}
          getCustomizedUrl={getCustomizedUrl}
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-city-of-la-ca");
if (container) {
  const root = createRoot(container);
  root.render(<CityOfLACA />);
}
