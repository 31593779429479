import Cookies from "js-cookie";
import { useState } from "react";

const getItem = (key: string) => Cookies.get(key);

const setItem = (
  key: string,
  value: string,
  expiration: Maybe<number | Date> = null
) => {
  if (value !== undefined) {
    if (expiration) {
      Cookies.set(key, value, { expires: expiration });
    } else {
      Cookies.set(key, value);
    }
  }
};

/**
 * Retrieve and store cookies for our application.
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 * @param {Number} expiration Number of days until expiration
 */
export default function useCookie<T extends string>(
  key: string,
  defaultValue: string,
  expiration: Maybe<number | Date> = null
): [T, (value: T) => void] {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState<T>(getCookie() as T);
  const updateCookie = (value: T) => {
    setCookie(value);
    setItem(key, value, expiration);
  };
  return [cookie, updateCookie];
}
