import clsx from "clsx";
import type { ReactNode } from "react";
import { Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";

export default function ContactBox({
  headline,
  body,
}: { headline: string; body: ReactNode | string }) {
  return (
    <div
      className={clsx(
        borderColorClass.brand.subtle.enabled,
        "flex flex-col min-w-[16.5rem] gap-4 border border-solid rounded-4 p-6"
      )}
    >
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        emphasis
      >
        {headline}
      </Typography>
      <Typography color="accent.neutral.enabled">{body}</Typography>
    </div>
  );
}
