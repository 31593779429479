import { captureMessage } from "@sentry/browser";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useRef, useState } from "react";
import { MessageSupplierSource } from "../../components/MessageSupplierPage/types";
import { ApiError, ApiService, ExpansionStatusEnum } from "../../generated";
import useSendEmailVerification from "../../hooks/useSendEmailVerification";
import useShowModal from "../../hooks/useShowModal";
import { popupState } from "../../jotai/page";
import {
  governmentAffiliationDisplayNameLocalState,
  userDetailsState,
  userEmailVerifiedState,
} from "../../jotai/user";
import { IconRadioButton, LabeledInput, Link, Typography } from "../../library";
import type { BadgeProps } from "../../library/Badge";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getRequestID } from "../../utils";
import {
  getUserEmailVerified,
  postContactEmailSupplier,
} from "../../utils/api";
import { GOV_EMAIL_REGEX } from "../../utils/constants";
import {
  MODAL_SOURCE,
  PopupType,
  accountModals,
  modals,
} from "../../utils/enums";
import {
  getErrorMessage,
  handleError as handleGeneratedError,
} from "../../utils/generatedApi";
import {
  type TrackSupplierOutreachParams,
  trackContactSupplierGTM,
  trackContactSupplierHeap,
  trackSupplierOutreachOptInWithUnverifiedEmail,
} from "../../utils/tracking";
import SubmitButton from "../SignupSteps/SubmitButton";
import {
  modalSourceToContactSupplierHeapSource,
  modalSourceToInteractionTypeEnum,
} from "./constants";
import type { SharedBuyerOptInModalProps } from "./types";

export interface BuyerOptInModalProps extends SharedBuyerOptInModalProps {
  title: string;
  subtitle: string;
  ctaText: string;
  ctaBadgeProps?: BadgeProps;
  analyticsClassName: string;
}

type ShowBuyerOptInModalProps = Omit<
  SharedBuyerOptInModalProps,
  "hideModal" | "modalType"
>;

enum OptInModalOptions {
  OPT_IN = "OPT_IN",
  NONE = "NONE",
}

export default function BuyerOptInModal({
  modalType,
  supplier,
  contractId,
  solicitationId,
  supplierPOC,
  query,
  contractTitle,
  onComplete,
  onSkip,
  downloadFiles,
  trackOptIn,
  trackOptOut,
  shouldHideModalAfterCta = true,
  hideModal,
  source,
  title,
  subtitle,
  ctaText,
  ctaBadgeProps,
  analyticsClassName,
}: BuyerOptInModalProps) {
  const [optionSelected, setOptionSelected] = useState<
    OptInModalOptions | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState<string>("");
  const [{ firstName, lastName, email: buyerEmail }, setUserDetailState] =
    useAtom(userDetailsState);
  const userBLA = useAtomValue(governmentAffiliationDisplayNameLocalState);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailVerificationError, setEmailVerificationError] =
    useState<string>("");
  const setPopupState = useSetAtom(popupState);
  const showVerifyEmailModal = useShowModal(modals.PLEASE_VERIFY_EMAIL_MODAL);
  const sendVerificationEmail = useSendEmailVerification({
    source: MODAL_SOURCE.BUYER_OPT_IN,
    onError: setEmailVerificationError,
  });
  const showChangeEmailModal = useShowModal(accountModals.CHANGE_EMAIL);
  const showBuyerOptInModal = useShowModal(modalType);
  const trackSupplierOutreachParams: TrackSupplierOutreachParams = {
    contractId,
    solicitationId,
    supplierHandle: supplier.handle,
    supplierId: supplier.id,
    userEmail: buyerEmail as string,
    requestID: getRequestID(),
  };
  const interactionType = modalSourceToInteractionTypeEnum(source);
  const descriptionRef = useRef<HTMLInputElement>(null);

  function focusDescription() {
    if (optionSelected !== OptInModalOptions.OPT_IN || !descriptionRef.current)
      return;
    descriptionRef.current.focus();
  }

  function handleSkip() {
    trackOptOut(trackSupplierOutreachParams);
    onSkip();
    if (downloadFiles) downloadFiles();
    if (shouldHideModalAfterCta) {
      hideModal();
    }
  }

  async function handleSubmitOptIn() {
    const isVerifiedGovEmail = await checkEmail();
    if (!isVerifiedGovEmail) {
      trackSupplierOutreachOptInWithUnverifiedEmail(
        trackSupplierOutreachParams
      );
      return;
    }
    if (!buyerEmail) {
      return;
    }
    if (!interactionType) {
      // In theory, `source` shold always be defined, so
      // `modalSourceToInteractionTypeEnum` should always return a value.
      captureMessage("interactionType should not be null");
      return;
    }

    setIsLoading(true);

    try {
      const connectionRequest = await postContactEmailSupplier({
        contractId,
        suppliers: [supplier.handle],
        name: [firstName, lastName].join(" ").trim(),
        cc: [],
        email: buyerEmail,
        publicAgency: userBLA,
        description: requestDetails,
        interactionType,
      });
      await ApiService.apiV1SupplierConnectionRequestsPartialUpdate(
        connectionRequest.id,
        { expansionStatus: ExpansionStatusEnum.OPEN }
      );
    } catch (err) {
      if (
        handleGeneratedError(err, {
          logToSentry: true,
          log400ErrorsToSentry: false,
        })
      ) {
        if (err instanceof ApiError && err.status === 400) {
          setErrorMessage(getErrorMessage(err));
        }
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    _trackSendMessage();
    trackOptIn(trackSupplierOutreachParams);
    onComplete();
    if (downloadFiles) {
      downloadFiles();
    }
    setPopupState({
      analyticsClassName: `${analyticsClassName}-success`,
      name: PopupType.SUCCESS,
      children: (
        <Typography color="inverse">
          Supplier has been notified and will be in touch soon!
        </Typography>
      ),
      show: true,
    });
    if (shouldHideModalAfterCta) {
      hideModal();
    }
  }

  const handleShowBuyerOptInModal = useCallback(() => {
    const props: ShowBuyerOptInModalProps = {
      supplier,
      contractId,
      contractTitle,
      solicitationId,
      query,
      supplierPOC,
      source,
      trackOptIn,
      trackOptOut,
      onSkip,
      onComplete,
      downloadFiles,
      shouldHideModalAfterCta,
    };
    showBuyerOptInModal({ ...props });
  }, [
    showBuyerOptInModal,
    supplier,
    contractId,
    contractTitle,
    solicitationId,
    query,
    supplierPOC,
    source,
    trackOptIn,
    trackOptOut,
    onSkip,
    onComplete,
    downloadFiles,
    shouldHideModalAfterCta,
  ]);

  const checkEmail = useAtomCallback(
    useCallback(
      async (get, set) => {
        const emailVerified = get(userEmailVerifiedState);
        if (buyerEmail && !GOV_EMAIL_REGEX.test(buyerEmail)) {
          showChangeEmailModal({
            title: "Please Update Your Email",
            subtitle: `It looks like you're sending this message
              from a personal or non-government email address.
              Please update your email to a government email address.`,
            onComplete: (newEmail: string) => {
              setEmailChangeSuccess("You can send your message now.");
              setUserDetailState((prev) => ({
                ...prev,
                email: newEmail,
                firstName,
                lastName,
              }));
            },
            requireGovEmail: true,
            canSkip: false,
          });
          return false;
        }

        if (!emailVerified) {
          // We check email verified status again via an API call because a user could have
          // verified their email before a page reload, so then jotai state would not update.
          try {
            const verified = await getUserEmailVerified();

            if (!verified) {
              sendVerificationEmail(() =>
                showVerifyEmailModal({
                  onCtaClick: handleShowBuyerOptInModal,
                })
              );
              return false;
            }
            set(userEmailVerifiedState, verified);
          } catch (err) {
            if (handleGeneratedError(err)) {
              setErrorMessage(
                "Something went wrong. Please try verifying your email from your profile."
              );
              return false;
            }
          }
        }
        return true;
      },
      [
        buyerEmail,
        firstName,
        lastName,
        sendVerificationEmail,
        setUserDetailState,
        showChangeEmailModal,
        showVerifyEmailModal,
        handleShowBuyerOptInModal,
      ]
    )
  );

  async function handleSubmit() {
    if (optionSelected === OptInModalOptions.OPT_IN) {
      await handleSubmitOptIn();
    } else if (optionSelected === OptInModalOptions.NONE) {
      handleSkip();
    }
  }

  function _trackSendMessage() {
    const heapSource = modalSourceToContactSupplierHeapSource(source);
    trackContactSupplierGTM();
    if (!interactionType || !heapSource) return;

    trackContactSupplierHeap({
      contractId,
      solicitationId,
      supplierHandles: [supplier.handle],
      supplierIds: [supplier.id],
      email: buyerEmail,
      buyerLoc: userBLA || "",
      hasVerifiedContact: !!supplierPOC,
      supplierPOC: supplierPOC || "",
      promotedSupplierIds: [],
      promotedSupplierCount: 0,
      promotedSupplierHandles: [],
      ccEmails: [],
      numCcEmails: 0,
      source: heapSource,
      interactionType,
      messageSupplierSource: MessageSupplierSource.BUYER_OPT_IN,
      requestID: getRequestID(),
    });
  }

  return (
    <Modal
      modalSize={modalSizes.SMALL}
      hideModal={hideModal}
      title={title}
      subtitle={subtitle}
      centerSubtitle={false}
      className={analyticsClassName}
      isBlocking
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <IconRadioButton
            isSelected={optionSelected === OptInModalOptions.OPT_IN}
            onClick={() => setOptionSelected(OptInModalOptions.OPT_IN)}
            label="Yes, supplier can email me and Pavilion can connect me with more qualified suppliers for my need"
            border
          />
          {optionSelected === OptInModalOptions.OPT_IN && (
            <LabeledInput
              name="description"
              label="What are you buying?"
              value={requestDetails}
              onChange={(e) => {
                setRequestDetails(e.target.value);
              }}
              ref={descriptionRef}
              size="md"
              required
              autoFocus
            />
          )}
          <IconRadioButton
            isSelected={optionSelected === OptInModalOptions.NONE}
            onClick={() => setOptionSelected(OptInModalOptions.NONE)}
            label="No, I don't want pricing and contract details in my inbox"
          />
        </div>
        <div
          onClick={(_e) => {
            optionSelected !== OptInModalOptions.NONE &&
              requestDetails === "" &&
              focusDescription();
          }}
        >
          <SubmitButton
            isCTALoading={isLoading}
            handleSubmit={handleSubmit}
            ctaText={ctaText}
            badgeProps={ctaBadgeProps}
            disabled={
              (optionSelected !== OptInModalOptions.NONE &&
                requestDetails === "") ||
              !optionSelected
            }
          />
        </div>
        <Typography size="sm" color="neutral.bold.enabled">
          Change your contact settings in{" "}
          <Link size="sm" emphasis={false} href="/profile#preferences">
            preferences
          </Link>
          .
        </Typography>
        {emailVerificationError ||
          (errorMessage && (
            <Typography color="feedback.bold.error">
              {emailVerificationError || errorMessage}
            </Typography>
          ))}
        {emailChangeSuccess && (
          <Typography color="feedback.bold.success">
            {emailChangeSuccess}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
