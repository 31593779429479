import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import type { TrackSupplierClickArgs } from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  getNumberSupplierHits,
} from "../components/ContractSearch/utils";
import {
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  searchResultTypeState,
  supplierSearchResponseDataState,
} from "../jotai/search";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
} from "../jotai/searchFilters";
import { SearchTypes, ViewContractRankCTA } from "../utils/enums";
import { trackContractClick } from "../utils/tracking";

export default function useTrackSupplierClick() {
  const track = useCallback(
    async (get: Getter, _set: Setter, data: TrackSupplierClickArgs) => {
      const supplierResponseData = get(supplierSearchResponseDataState);
      const searchParams = get(contractSearchParamsState);
      const analyticsParams = get(contractSearchAnalyticsParamsState);
      const trackingCounts = get(contractSearchTrackingCountsState);
      const diversityCertificationsFilter = get(
        diversityCertificationsFilterState
      );
      const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
      const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
      const contractSourceFilter = get(contractSourceFilterState);
      const expirationFilter = get(expirationFilterState);
      const contractDocumentsFilter = get(contractDocumentsFilterState);
      const searchResultType = get(searchResultTypeState);

      const params = formatSearchPageParams(searchParams);
      const { searchSource, requestID } = analyticsParams;

      // For supplier results, absolute depth equals depth, because we list supplier results first
      trackContractClick({
        ...trackingCounts,
        matchTier: null,
        semanticScore: null,
        filterScore: null,
        ...data,
        relativeDepth: data.absoluteDepth,
        contractId: null,
        buyerLeadAgencyId: null,
        buyerLeadAgency: null,
        cooperativeAffiliation: null,
        firstPageSupplierCount: getNumberSupplierHits(supplierResponseData),
        searchQuery: params.query,
        queryZip: params.zip,
        searchType:
          searchResultType === "supplier"
            ? SearchTypes.SUPPLIER
            : SearchTypes.CONTRACT,
        userSelectedFromDefault: null,
        searchSource,
        requestID,
        expirationFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        singleAwardOnlyFilter,
        contractSourceFilter,
        contractDocumentsFilter,
        ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
      });
    },
    []
  );
  return useAtomCallback(track);
}
