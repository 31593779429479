import { useAtom } from "jotai";
import _keyBy from "lodash/keyBy";

import { supplierState } from "../../../../jotai/user";
import { Button, Typography } from "../../../../library";
import { modals } from "../../../../utils/enums";
import SupplierContactContainer from "../../SupplierContactContainer";
import SupplierLockedField from "../SupplierLockedField";
import type { Supplier } from "../types";

import { useMemo } from "react";
import type { UserSupplierContact } from "../../../../generated";
import useShowModal from "../../../../hooks/useShowModal";
interface SupplierContactSectionProps {
  handle: string;
  supplierEmail: string;
  disabled?: boolean;
  setUpdateDate: (value: Date | undefined) => void;
  setCurrentSupplier: (supplier: Supplier) => void;
  currentSupplier: Supplier;
}

export default function SupplierContactSection({
  handle,
  supplierEmail,
  disabled = false,
  setUpdateDate,
  currentSupplier,
  setCurrentSupplier,
}: SupplierContactSectionProps) {
  const showAddContactModal = useShowModal(modals.ADD_CONTACT);
  const [supplier, setSupplier] = useAtom(supplierState);

  const onContactUpsert = (newContact: UserSupplierContact) => {
    const contactsById = {
      ..._keyBy(currentSupplier.contacts, "id"),
      [newContact.id]: newContact,
    };

    setCurrentSupplier({
      ...currentSupplier,
      contacts: Object.values(contactsById),
    });
    setUpdateDate(new Date());
    setSupplier({
      ...supplier,
      manualContacts: Object.values(contactsById),
    });
  };

  const onContactDelete = (contactToDelete: UserSupplierContact) => {
    const newContacts =
      currentSupplier.contacts?.filter(({ id }) => id !== contactToDelete.id) ||
      [];
    setCurrentSupplier({
      ...currentSupplier,
      contacts: newContacts,
      defaultContactId:
        contactToDelete.id === currentSupplier.defaultContactId
          ? null
          : currentSupplier.defaultContactId,
    });
    setUpdateDate(new Date());
    setSupplier({
      ...supplier,
      manualContacts: newContacts,
    });
  };

  // Although the contacts are sorted in the backend, we need to sort them again
  // because of the upsert/delete operations that can change the order.
  const currentSupplierContactsDefaultFirst = useMemo(() => {
    if (currentSupplier.contacts) {
      const newContacts = [...currentSupplier.contacts];
      return newContacts.sort(({ id }) =>
        id === currentSupplier.defaultContactId ? -1 : 1
      );
    }
    return [];
  }, [currentSupplier.contacts, currentSupplier.defaultContactId]);

  return (
    <div className="mb-16 flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-2 justify-between items-center">
          <Typography
            variant="headline"
            size="sm"
            color="brand.default.secondary.enabled"
            emphasis
          >
            Active business contacts
          </Typography>
          {!disabled && (
            <Button
              size={Button.sizes.SMALL}
              theme={Button.themes.SECONDARY_DARK}
              className="analytics-add-supplier-contact"
              onClick={() => {
                showAddContactModal({
                  handle,
                  onUpsert: onContactUpsert,
                });
              }}
            >
              Add new contact +
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Typography variant="body" color="neutral.default.primary.enabled">
            Add contacts at your business who can respond to leads from
            Pavilion.
          </Typography>
          <Typography variant="body" color="neutral.default.primary.enabled">
            We'll send emails from leads to your default contact, and other team
            members will be cc'd. Phone calls will be forwarded to your line
            with a unique extension for each buyer.
          </Typography>
        </div>
      </div>
      <SupplierLockedField disabled={disabled}>
        <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
          {currentSupplierContactsDefaultFirst?.map((contact) => (
            <SupplierContactContainer
              key={contact.id}
              contact={contact}
              handle={handle}
              supplierName={currentSupplier.displayName}
              supplierEmail={supplierEmail}
              onContactUpsert={onContactUpsert}
              onContactDelete={onContactDelete}
            />
          ))}
        </ul>
        {(!currentSupplier?.contacts || !currentSupplier.contacts.length) && (
          <div className="p-8 bg-cp-white-200 w-full mb-10 rounded-lg text-center">
            <Typography>
              To add contacts, click on the {'"'}Add contact{'"'} button and
              enter the required information.
            </Typography>
          </div>
        )}
      </SupplierLockedField>
    </div>
  );
}
