/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `NONE` - None
 * * `CENTRALIZED` - Centralized
 * * `DISTRIBUTED` - Distributed
 * * `PRIVATE_DISTRIBUTED` - Private Distributed
 */
export enum MembershipTypeEnum {
    NONE = 'NONE',
    CENTRALIZED = 'CENTRALIZED',
    DISTRIBUTED = 'DISTRIBUTED',
    PRIVATE_DISTRIBUTED = 'PRIVATE_DISTRIBUTED',
}
