import { useMemo } from "react";

import { getParam, hasWindow } from "../utils";

import { useAtomValue } from "jotai";
import { debugState } from "../jotai/search";
import { useCanShowDebug } from "./useCanShowDebug";

/**
 * Utility function for checking the debug=true flag.
 * Don't use this without checking isAdmin.
 */
export function getIsDebug() {
  return getParam("debug", "false") === "true";
}

/**
 * Hook for checking whether to show debug info.
 * Requires that the user be a site admin with the flag set.
 */
export default function useIsDebug() {
  const isDebug = useAtomValue(debugState);
  const isWindow = hasWindow();
  const debugParam = useMemo(() => isWindow && getIsDebug(), [isWindow]);
  const isAdmin = useCanShowDebug();
  return isAdmin && (isDebug || debugParam);
}
