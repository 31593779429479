import type { SearchOptions } from "../../../components/ContractSearch/types";
import useResetAndGetFilters from "../../../hooks/useResetAndGetFilters";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { SearchActions } from "../../../utils/enums";

export default function useResetFiltersAndSearch(
  isSupplierPage = false,
  onSearch?: (options: SearchOptions) => void,
  setFilters?: (filters: string[]) => void
) {
  const searchContractWithParams = onSearch || useSearchContractWithParams();
  const resetAndGetFilters = useResetAndGetFilters({ isSupplierPage });
  return () => {
    const filters = resetAndGetFilters();
    setFilters?.(filters);
    searchContractWithParams({
      newParams: { filters },
      action: SearchActions.SAVE_FILTERS,
    });
  };
}
