import clsx from "clsx";
import type { FC, ReactNode } from "react";

import {
  type AvatarSizes,
  Button,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import GenericCard from "../../../library/GenericCard";
import type { CTA } from "../../../shared/ContractBase/types";
import PendingContractText from "../../../shared/PendingContractText";
import type { LoginWallTriggers } from "../../../utils/enums";

import { titleCase } from "title-case";
import ContractCardHeader from "./ContractCardHeader";
import ContractCardMetadata from "./ContractCardMetadata";
import type { ContractCardSize } from "./types";

export interface ContractCardProps {
  title: string;
  size?: ContractCardSize;
  className?: string;
  loginWallTrigger?: LoginWallTriggers;
  loginWallTriggerId?: string;
  onClick?: () => void;
  onClickTitle?: () => void;
  ctas?: CTA[];
  trackSerpClick?: () => void;
  contractTags?: ReactNode[];
  supplierTags?: ReactNode[];
  contractNumber: string;
  expiration?: string;
  semanticContext?: ReactNode;
  isExpired?: boolean;
  numPendingDocs?: number;
  awardedSuppliers?: string[];
  buyerLeadAgency?: string;
  cooperative?: string;
  IconComponent?: Maybe<FC<{ size: AvatarSizes; className?: string }>>;
  IconClass?: string;
  searchMatches?: ReactNode;
  selected?: boolean;
  numSuppliers?: number;
  showEditContractCta?: boolean;
}

export default function ContractCard({
  className,
  size = "large",
  loginWallTrigger,
  loginWallTriggerId,
  onClick,
  onClickTitle,
  trackSerpClick,
  ctas,
  title,
  contractTags,
  supplierTags,
  contractNumber,
  expiration,
  semanticContext,
  isExpired,
  numPendingDocs,
  awardedSuppliers,
  numSuppliers,
  buyerLeadAgency,
  cooperative,
  IconComponent,
  IconClass,
  searchMatches,
  selected,
  showEditContractCta,
}: ContractCardProps) {
  const getCardTitle = () => {
    const shouldUseLink = !!onClickTitle;
    // Even though Link is just a wrapper around Typography with as="a",
    // we should use it because <a> tags are important for screenreaders.
    const Component = shouldUseLink ? Link : Typography;
    return (
      <div className="flex flex-row">
        <Component
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
          className={clsx("break-words", {
            "line-clamp-1": size === "large",
            "line-clamp-2": size === "compact",
          })}
          onClick={(e) => {
            if (onClickTitle) {
              e.stopPropagation();
              onClickTitle();
            }
          }}
        >
          {titleCase(title || "")}{" "}
          <PendingContractText
            numPendingDocs={numPendingDocs}
            component="span"
          />
        </Component>
      </div>
    );
  };
  const cardTags = (supplierTags || []).concat(contractTags || []);

  return (
    <GenericCard
      className={clsx(className, { "border-2": selected })}
      loginWallTrigger={loginWallTrigger}
      loginWallTriggerId={loginWallTriggerId}
      onClick={onClick}
      trackSerpClick={trackSerpClick}
      borderColor={selected ? "brand.bold.enabled" : "neutral.subtle.enabled"}
    >
      <ContractCardHeader
        title={getCardTitle()}
        contractNumber={contractNumber}
        expiration={expiration}
        semanticContext={semanticContext}
        isExpired={isExpired}
        ctas={ctas}
        IconComponent={IconComponent}
        IconClass={IconClass}
      />
      <ContractCardMetadata
        size={size}
        suppliers={awardedSuppliers}
        buyerLeadAgency={buyerLeadAgency}
        cooperative={cooperative}
        numSuppliers={numSuppliers}
      />
      {searchMatches}
      <div
        className={clsx("flex", {
          "flex-row justify-between": size === "large",
          "flex-col gap-3 mt-auto": size === "compact",
        })}
      >
        <div className="my-auto flex flex-col gap-4">
          {!!cardTags?.length && (
            <div className="flex gap-2 flex-wrap">{cardTags}</div>
          )}
        </div>
        <div className="my-auto">
          <Button
            size={Button.sizes.SMALL}
            className={clsx(
              "min-w-max analytics-contract-card-view-contract-cta",
              {
                "w-full": size === "compact",
              }
            )}
            theme={ButtonThemes.SECONDARY_DARK}
          >
            {showEditContractCta ? "Edit" : "View"} contract
          </Button>
        </div>
      </div>
    </GenericCard>
  );
}
