import { useEffect, useState } from "react";

interface usePaginatedQueryOptions<T> {
  initialList: T[];
  initialPage: number;
  total: number;
  fetchList: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize?: number;
    // biome-ignore lint/suspicious/noExplicitAny: Defer type handling to the caller. Generics could make this better.
  }) => Promise<{ json: () => Promise<T[]> }> | Promise<T[]> | Promise<any>;
  // biome-ignore lint/suspicious/noExplicitAny: Defer type handling to the caller. Generics could make this better.
  onResponse: (data: any) => { list: T[]; count?: number };
}

/**
 * A hook that retrieves a list of items from a paginated API.
 *
 * @param  initialList {Array[Object]} A list of objects to populate the list with initially.
 * @param  initialPage {number} The first page to retrieve.
 * @param total {number} The total number of items to retrieve.
 * @param  fetchList {({ page: number, pageSize: number }) => Promise<Response>}
 *    A function that returns an async response.
 * @param  onResponse {(Object) => { list: Object[], count: number }} a function that
 *    receives the raw response data and returns a list and total count for pagination.
 *
 * @return { list: object[], hasNext: bool, fetchMore: Func, isLoading: bool }
 */
export default function usePaginatedQuery<T>({
  initialList,
  initialPage,
  total,
  fetchList,
  onResponse,
}: usePaginatedQueryOptions<T>) {
  const [list, setList] = useState<T[]>(initialList);
  const [page, setPage] = useState(initialPage);
  const [hasNext, setHasNext] = useState(!total || initialList.length < total);
  const [isLoading, setIsLoading] = useState(!initialList.length);
  const [count, setCount] = useState<number | undefined>(total);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch the initial list once on mount.
  useEffect(() => {
    // If the page already has preloaded an initial list, then skip, else get first page
    if (initialList.length) {
      setPage(1);
      return;
    }
    fetchMore();
  }, []);

  const refreshList = async () => {
    setIsLoading(true);
    let data: T[];
    const response = await fetchList({ page: 0 });
    if (response instanceof Response) {
      data = await response.json();
    } else {
      data = response as T[];
    }
    setIsLoading(false);

    const result = onResponse(data);

    setList(result.list);
    setHasNext(result.list.length < (total || result.count || 0));
    setPage(1);
    setCount(result.count);
  };

  const fetchMore = async () => {
    setIsLoading(true);
    let data: T[];
    const response = await fetchList({ page });
    if (response instanceof Response) {
      data = await response.json();
    } else {
      data = response as T[];
    }
    setIsLoading(false);

    const result = onResponse(data);
    const nextList = [...list, ...result.list];

    setList(nextList);
    setHasNext(nextList.length < (total || result.count || 0));
    setPage(page + 1);
    setCount(result.count);
  };
  return { list, hasNext, fetchMore, isLoading, page, count, refreshList };
}
