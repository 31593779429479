import { createRoot } from "react-dom/client";

import MessageSupplierSuccessPage from "../components/MessageSupplierPage/MessageSupplierSuccessPage";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

interface MessageSupplierSuccessProps extends AuthPageProps {
  userHasProjects: boolean;
  supplierDisplayName?: string;
  supplierHandle?: string;
  supplierId?: number;
  contractId?: string;
  solicitationId?: string;
}

export default function MessageSupplierSuccess({
  is_authenticated,
  profile_type,
  active_agreements,
  userHasProjects,
  supplierDisplayName,
  supplierHandle,
  supplierId,
  contractId,
  solicitationId,
}: MessageSupplierSuccessProps) {
  return (
    <Page
      searchSource="message-supplier-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <MessageSupplierSuccessPage
        userHasProjects={userHasProjects}
        supplierId={supplierId}
        supplierDisplayName={supplierDisplayName}
        supplierHandle={supplierHandle}
        contractId={contractId}
        solicitationId={solicitationId}
      />
    </Page>
  );
}

const container = getDOMAnchorById("message-supplier-success");
if (container) {
  const root = createRoot(container);
  const userHasProjects = container.dataset.userHasProjects === "True";
  const supplierDisplayName = container.dataset.supplierDisplayName;
  const supplierHandle = container.dataset.supplierHandle;
  const supplierId = Number.parseInt(container.dataset.supplierId || "");
  const solicitationId = container.dataset.solicitationId;
  const contractId = container.dataset.contractId;

  root.render(
    <MessageSupplierSuccess
      userHasProjects={userHasProjects}
      supplierDisplayName={supplierDisplayName}
      supplierHandle={supplierHandle}
      supplierId={supplierId}
      solicitationId={solicitationId}
      contractId={contractId}
    />
  );
}
