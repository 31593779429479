import { InteractionTypeEnum } from "../../generated";
import { ContactSupplierHeapSource, MODAL_SOURCE } from "../../utils/enums";

export type BuyerOptInModalSource =
  | MODAL_SOURCE.BEFORE_DOWNLOAD
  | MODAL_SOURCE.CONTRACT_SOURCE
  | MODAL_SOURCE.SAVE_CONTRACT;

export function modalSourceToInteractionTypeEnum(
  source: BuyerOptInModalSource
): InteractionTypeEnum | null {
  switch (source) {
    case MODAL_SOURCE.BEFORE_DOWNLOAD:
      return InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_DOWNLOAD_FILES;
    case MODAL_SOURCE.CONTRACT_SOURCE:
      return InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_VIEW_SOURCE;
    case MODAL_SOURCE.SAVE_CONTRACT:
      return InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_SAVE_CONTRACT;
    default:
      return null;
  }
}

export function modalSourceToContactSupplierHeapSource(
  source: BuyerOptInModalSource
): ContactSupplierHeapSource | null {
  switch (source) {
    case MODAL_SOURCE.BEFORE_DOWNLOAD:
      return ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_BEFORE_DOWNLOAD;
    case MODAL_SOURCE.CONTRACT_SOURCE:
      return ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_CONTRACT_SOURCE;
    case MODAL_SOURCE.SAVE_CONTRACT:
      return ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_SAVE_CONTRACT;
    default:
      return null;
  }
}
