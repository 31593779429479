import { createRoot } from "react-dom/client";

import InitialSignupPage from "../../components/Account/InitialSignup";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import Page from "../Page/Page";

export default function InitialSignup() {
  return (
    <Page
      pageId=""
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      profileType={null}
      activeAgreements={null}
    >
      <InitialSignupPage />
    </Page>
  );
}

const container = getDOMAnchorById("initial-signup-container");
if (container) {
  const root = createRoot(container);
  root.render(<InitialSignup />);
}
