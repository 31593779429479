import { useEffect, useRef } from "react";
import type { Renewal } from "../../generated";
import { ProgressBar, type ProgressItem, Typography } from "../../library";
import { AUTORENEWAL_EXPIRATION_MS } from "../../utils/constants";
import { formatDate, parseDate } from "../../utils/date";
import { trackHoverOverContractRenewalTracker } from "../../utils/tracking";

export default function ContractRenewalTracker({
  renewals,
  expirationDate,
  effectiveDate,
  isAutorenewing = false,
}: {
  renewals: Renewal[];
  expirationDate: Maybe<Date | string>;
  effectiveDate: Maybe<Date | string>;
  isAutorenewing: Maybe<boolean>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let hoverTimer: string | number | NodeJS.Timeout | undefined;
    const listener = () => {
      hoverTimer = setTimeout(() => {
        trackHoverOverContractRenewalTracker({
          expirationDate,
          effectiveDate,
          isAutorenewing,
        });
      }, 500);
    };

    const cancelListener = () => {
      clearTimeout(hoverTimer);
    };

    const element = ref.current;
    element?.addEventListener("mouseenter", listener);
    element?.addEventListener("mouseleave", cancelListener);

    return () => {
      element?.removeEventListener("mouseenter", listener);
      element?.removeEventListener("mouseleave", cancelListener);
    };
  }, [expirationDate, effectiveDate, isAutorenewing]);

  const calculateProgress = (
    startDate: Date | string,
    endDate: Date | string
  ) => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const now = Date.now();
    const progress = ((now - start) / (end - start)) * 100;
    return Math.min(Math.max(progress, 0), 100);
  };

  const items: ProgressItem[] = renewals.map((renewal, index, arr) => {
    const renewalProgress = calculateProgress(
      renewal.startDate,
      renewal.endDate
    );

    const startDateText = formatDate(parseDate(renewal.startDate), "MMMM d, y");
    const endDateText = formatDate(parseDate(renewal.endDate), "MMMM d, y");

    const isLastRenewal = index === arr.length - 1;

    return {
      progress: renewalProgress,
      tooltipContent: (
        <div className="w-fit whitespace-nowrap">
          <Typography className="mb-2" emphasis>
            Renewal term {index + 1}
          </Typography>
          <Typography className="mb-2">
            {startDateText}-{endDateText}
          </Typography>
          {isLastRenewal && (
            <Typography>{`Final expiration: ${endDateText}`}</Typography>
          )}
        </div>
      ),
    };
  });

  let contractProgress: number;
  if (items.some(({ progress }) => progress > 0)) {
    contractProgress = 100;
  } else if (effectiveDate && expirationDate) {
    contractProgress = calculateProgress(effectiveDate, expirationDate);
  } else if (
    !effectiveDate &&
    expirationDate &&
    new Date(expirationDate).getTime() <= Date.now()
  ) {
    contractProgress = 100;
  } else {
    contractProgress = 50;
  }

  const contractBar: ProgressItem = {
    progress: contractProgress,
    tooltipContent: effectiveDate ? (
      <div className="w-fit whitespace-nowrap">
        <Typography className="mb-1" emphasis>
          Contract Started
        </Typography>
        <Typography>
          {formatDate(parseDate(effectiveDate), "MMMM d, y")}
        </Typography>
      </div>
    ) : undefined,
  };

  items.unshift(contractBar);

  const nextRenewal = renewals.find(
    (renewal) => calculateProgress(renewal.startDate, renewal.endDate) === 0
  );

  let nextRenewalDisplayText = "";

  if (nextRenewal && isAutorenewing) {
    const nextRenewalDate = nextRenewal
      ? formatDate(parseDate(nextRenewal.startDate), "MMMM d, y")
      : "";
    nextRenewalDisplayText = `Auto-renews on ${nextRenewalDate}`;
  } else if (nextRenewal) {
    const nextRenewalDateObj = parseDate(nextRenewal.startDate);
    nextRenewalDateObj?.setDate(nextRenewalDateObj?.getDate() - 1);
    const dayBeforeNextRenewalDate = formatDate(
      nextRenewalDateObj,
      "MMMM d, y"
    );
    nextRenewalDisplayText = `Current term expires on ${dayBeforeNextRenewalDate}`;
  }

  const finalExpirationDate =
    renewals.length > 0
      ? renewals[renewals.length - 1].endDate
      : expirationDate;

  const isContractExpired =
    finalExpirationDate &&
    new Date(finalExpirationDate).getTime() <= Date.now();
  let finalExpirationDisplayText = "";

  if (isContractExpired) {
    finalExpirationDisplayText = `Expired on ${formatDate(
      parseDate(finalExpirationDate),
      "MMMM d, y"
    )}`;
  } else if (
    finalExpirationDate &&
    new Date(finalExpirationDate).getTime() >= AUTORENEWAL_EXPIRATION_MS
  ) {
    if (isAutorenewing) {
      finalExpirationDisplayText = "Auto-renews indefinitely";
    } else {
      finalExpirationDisplayText = "No expiration";
    }
  } else {
    finalExpirationDisplayText = `Renewals through ${formatDate(
      parseDate(finalExpirationDate),
      "MMMM d, y"
    )}`;
  }

  return (
    <div>
      <div
        className={`flex ${
          nextRenewalDisplayText ? "justify-between" : "justify-start"
        } items-center`}
      >
        {nextRenewalDisplayText && (
          <Typography variant="meta" color="neutral.boldest.enabled">
            {nextRenewalDisplayText}
          </Typography>
        )}
        <Typography variant="meta" color="neutral.boldest.enabled">
          {finalExpirationDisplayText}
        </Typography>
      </div>
      {!isContractExpired && (
        <div ref={ref} className="mt-2">
          <ProgressBar items={items} />
        </div>
      )}
    </div>
  );
}
