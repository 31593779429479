import { createRoot } from "react-dom/client";

import { Button, Link, Typography } from "../../library";
import { getDOMAnchorById } from "../../utils";
import Page from "../Page/Page";

const container = getDOMAnchorById("password-change-done");
if (container) {
  const root = createRoot(container);
  root.render(
    <Page
      searchSource="password-change-done-header-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <div className="cp-page-container mt-20">
        <Typography className="mb-10">Your password has been set.</Typography>
        <Button
          as={Link}
          className="w-1/4 h-10"
          linkProps={{ href: "/profile", underline: false }}
        >
          Return to profile page
        </Button>
      </div>
    </Page>
  );
}
