import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import type {
  AnalyticsParamsProps,
  SearchParams,
  TrackingCountsProps,
} from "../components/ContractSearch/types";
import { bothSearchesLoaded } from "../components/ContractSearch/utils";
import type {
  ContractResult,
  ContractSearchResponse,
  SupplierSearchResponse,
} from "../generated";
import { NoExactMatchesVariants } from "../shared/SearchPage/NoExactMatches";
import { getParam, hasWindow, setParamNoHistory } from "../utils";
import {
  matchedSearchResultCountState,
  numFiltersAppliedState,
} from "./searchFilters";

// Used under the Welcome search bar to demonstrate personalization.
export const recentSuccessfulSearchesState = atomWithStorage<string[]>(
  "recentSuccessfulSearches",
  []
);

export function useAddRecentSuccessfulSearch() {
  const [successfulSearches, setSuccessfulSearches] = useAtom(
    recentSuccessfulSearchesState
  );

  return (newSuccessfulSearch: string) => {
    const normalizedSearch = newSuccessfulSearch.trim();
    if (
      !normalizedSearch.trim() ||
      successfulSearches.includes(normalizedSearch)
    )
      return;

    setSuccessfulSearches([
      normalizedSearch,
      ...successfulSearches.slice(0, 2),
    ]);
  };
}

// TODO: Refactor searchSource to use this state variable.
export const widgetSearchSourceState = atomWithStorage(
  "widgetSearchSourceState",
  getParam("widget-search-source")
);

export const contractSearchParamsState = atom<SearchParams>({} as SearchParams);

export const contractSearchResponseDataState =
  atom<ContractSearchResponse | null>(null);

export const supplierSearchResponseDataState =
  atom<SupplierSearchResponse | null>(null);

export const topSupplierContractCardState = atom<ContractResult | null>(null);

export const supplierSearchIsLoadingState = atom(false);

export const contractSearchAnalyticsParamsState = atom<AnalyticsParamsProps>(
  {} as AnalyticsParamsProps
);

export const contractSearchTrackingCountsState = atom<TrackingCountsProps>({
  firstPageStrongMatchCount: 0,
  firstPagePossibleMatchCount: 0,
  firstPageSemanticMatchCount: 0,
});

export const contractSearchIsLoadingState = atom(false);

export const initialSearchesAreLoadingState = atom(false);

function getInitialPage() {
  let parsedPageNumber = Number.parseInt(getParam("page"));
  if (Number.isNaN(parsedPageNumber)) {
    parsedPageNumber = 0;
  }
  return parsedPageNumber;
}

export const contractSearchPageState = atom(
  getInitialPage(),
  (_get, set, newValue: number) => {
    set(contractSearchPageState, newValue);
    setParamNoHistory("page", newValue.toString());
  }
);

export const allSearchesLoadedState = atom((get) => {
  return bothSearchesLoaded({
    contractResponseData: get(contractSearchResponseDataState),
    supplierResponseData: get(supplierSearchResponseDataState),
    contractSearchIsLoading: get(contractSearchIsLoadingState),
    supplierSearchIsLoading: get(supplierSearchIsLoadingState),
  });
});

export const requestIDState = atom((get) => {
  const urlParamsRequestID = hasWindow() ? getParam("requestID", "") : "";
  const contractRequestId =
    get(contractSearchResponseDataState)?.params?.requestId || "";
  const analyticsRequestId =
    get(contractSearchAnalyticsParamsState).requestID || "";

  return contractRequestId || analyticsRequestId || urlParamsRequestID;
});

export const searchQueryState = atom((get) => {
  const urlParamsQuery = hasWindow() ? getParam("query") : "";
  const contractDataQuery = get(contractSearchResponseDataState)?.params?.query;
  return contractDataQuery || urlParamsQuery;
});

export const noExactMatchesVariantState = atom<NoExactMatchesVariants>(
  (get) => {
    const numFiltersApplied = get(numFiltersAppliedState);
    const hasMatchedSearchResults = get(hasMatchedSearchResultsState);
    if (!hasMatchedSearchResults) {
      return numFiltersApplied
        ? NoExactMatchesVariants.RESTRICTIVE_FILTERS
        : NoExactMatchesVariants.NO_RESULTS;
    }

    const showOtherResults = get(showOtherResultsState);
    if (showOtherResults) return NoExactMatchesVariants.OTHER_RESULTS;

    return NoExactMatchesVariants.NULL;
  }
);

export const hasMatchedSearchResultsState = atom((get) => {
  const numResults =
    get(contractSearchResponseDataState)?.contractData?.results?.length || 0;
  const matchedSearchResultCount = get(matchedSearchResultCountState);
  return !!numResults && !!matchedSearchResultCount;
});

const showOtherResultsState = atom((get) => {
  const matchedSearchResultCount = get(matchedSearchResultCountState);
  const numResults =
    get(contractSearchResponseDataState)?.contractData?.results.length || 0;
  const numFiltersApplied = get(numFiltersAppliedState);
  const hasMatchedSearchResults = get(hasMatchedSearchResultsState);
  return (
    hasMatchedSearchResults &&
    numResults > matchedSearchResultCount &&
    !numFiltersApplied
  );
});

export function showSupplierRedirect({
  supplierResponseData,
}: {
  supplierResponseData: SupplierSearchResponse | null;
}) {
  return (
    // The backend only sends this data if it's confident it should be used.
    !!supplierResponseData?.supplierData?.suppliers &&
    supplierResponseData?.supplierData?.suggestedSearches?.length !== 0
  );
}

export const showSupplierRedirectState = atom<boolean>((get) => {
  const supplierResponseData = get(supplierSearchResponseDataState);
  return showSupplierRedirect({ supplierResponseData });
});

export const disallowedSupplierSearchQueryState = atom<string | null>(null);
export const selectedSupplierSearchSuggestionState = atom<string | null>(null);

export const searchResultTypeState = atom<"supplier" | "contract">("supplier");

export const debugState = atom(false);
