export enum SearchBarThemes {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export const colorsByTheme = {
  [SearchBarThemes.DARK]: {
    bgColor: "bg-cp-lapis-500",
    borderColor: "border-cp-lapis-500",
    ctaTextColor: "text-cp-white-100",
    divideColor: "divide-cp-lapis-500",
    autocompleteHighlightColor: "text-cp-lapis-500",
  },
  [SearchBarThemes.LIGHT]: {
    bgColor: "bg-cp-violet-300",
    borderColor: "border-cp-lapis-100",
    ctaTextColor: "text-cp-black-400",
    divideColor: "divide-cp-violet-200",
    autocompleteHighlightColor: "",
  },
};
