import { useCallback, useEffect } from "react";

import { hasWindow } from "../utils";

interface WithHash {
  hash: string;
}

export default function useSelectTabByHash<T extends WithHash>(
  // this should be memoized
  tabs: T[],
  setIndex: (ix: number) => void
) {
  const findSelectedIndex = useCallback(() => {
    if (!hasWindow()) return 0;
    const index = tabs.findIndex(
      ({ hash }) => hash === window.location.hash.replace("#", "")
    );
    if (index < 0) return 0;
    return index;
  }, [tabs]);

  const handleHashChange = useCallback(
    () => setIndex(findSelectedIndex()),
    [setIndex, findSelectedIndex]
  );

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once on mount to check if there's an existing hash
  useEffect(() => {
    setIndex(findSelectedIndex());
  }, [tabs]);
  return [findSelectedIndex];
}
