import { ItemTypeEnum } from "../generated";
import { LoginWallTriggers } from "../utils/enums";

export const BUTTON_PROPS = {
  [ItemTypeEnum.CONTRACT]: {
    loginWallTitle: "You must login or sign up to save this contract",
    triggerType: LoginWallTriggers.SAVE_CONTRACT_TO_PROJECT,
    savedCopy: "Saved contract",
    saveCtaCopy: "Save contract",
    viewSavedCtaCopy: "Contract saved! View your saved contract",
  },
  [ItemTypeEnum.SUPPLIER]: {
    loginWallTitle: "You must login or sign up to save this supplier",
    triggerType: LoginWallTriggers.SAVE_SUPPLIER_TO_PROJECT,
    savedCopy: "Saved supplier",
    saveCtaCopy: "Save supplier",
    viewSavedCtaCopy:
      "Supplier profile saved! View your saved supplier profile",
  },
};
