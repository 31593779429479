import { Chip } from "@mui/material";
import clsx from "clsx";

import type { MessageSupplierData } from "../../jotai/messageSupplier";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  Typography,
} from "../../library";
import {
  LabeledInputVariants,
  VARIANTS,
} from "../../library/Input/LabeledInput";
import {
  INPUT_STYLE,
  SHARED_INPUT_BORDER_STYLE,
  borderClassName,
} from "../../library/Input/constants";
import { LogoSizes } from "../../library/Logo";
import { dedupeSuppliers } from "../../shared/SupplierCard/utils";

export interface SupplierChipInputProps {
  isPageFromContract: boolean;
  suppliersToContact: MessageSupplierData[];
  readOnlySupplierHandles: string[];
  numSuppliers: number;
  handleChipDelete: (supplierHandle: string) => void;
  showOtherSuppliersModal: () => void;
}

export default function SupplierChipInput({
  isPageFromContract,
  suppliersToContact,
  readOnlySupplierHandles,
  numSuppliers,
  handleChipDelete,
  showOtherSuppliersModal,
}: SupplierChipInputProps) {
  // It's possible that users have selected multiple recommended contracts
  // awarded to the same supplier. Dedupe to show only 1 chip for each supplier.
  const dedupedSuppliers = dedupeSuppliers(suppliersToContact);

  const deduplicatedSuppliersToContact = suppliersToContact
    ? dedupedSuppliers
    : [];
  const getAvatar = (supplier: MessageSupplierData) => {
    if (supplier.supplier.logoUrl) {
      return (
        <Avatar
          logoImageSrc={supplier.supplier.logoUrl}
          logoSize={LogoSizes.XXS}
          size={AvatarSizes.SMALL}
          variant={AvatarVariant.CIRCLE}
        />
      );
    }
    const initial = supplier.supplier.displayName[0];
    return (
      <Avatar
        monogram={initial}
        size={AvatarSizes.SMALL}
        bgColor="success.bold.primary.hovered"
        typographyClassName="leading-none"
      />
    );
  };
  const shouldShowAddMoreSuppliersButton =
    numSuppliers > 1 && isPageFromContract;

  return (
    <div
      className={clsx(
        SHARED_INPUT_BORDER_STYLE,
        VARIANTS[LabeledInputVariants.DEFAULT].containerClass
      )}
    >
      <div
        className={clsx(
          borderClassName(LabeledInputVariants.DEFAULT),
          INPUT_STYLE
        )}
      >
        <div className="flex flex-wrap items-center gap-2">
          <Typography color="neutral.bolder.enabled">To</Typography>
          {deduplicatedSuppliersToContact.map((supplier) => {
            const nameOrTitle =
              supplier.contact?.fullName || supplier.contact?.title;
            const label = nameOrTitle
              ? `${nameOrTitle} at ${supplier.supplier.displayName}`
              : `${supplier.supplier.displayName}`;
            if (readOnlySupplierHandles.includes(supplier.supplier.handle)) {
              return (
                <Chip
                  avatar={getAvatar(supplier)}
                  key={supplier.supplier.handle}
                  label={label}
                />
              );
            }
            return (
              <Chip
                className="analytics-message-supplier-chip-selected-delete"
                avatar={getAvatar(supplier)}
                key={supplier.supplier.handle}
                label={label}
                onDelete={() => handleChipDelete(supplier.supplier.handle)}
              />
            );
          })}
          {shouldShowAddMoreSuppliersButton && (
            <Button
              theme={ButtonThemes.TERTIARY_DARK}
              size={ButtonSizes.SMALL}
              onClick={showOtherSuppliersModal}
              className="h-8 self-end whitespace-nowrap"
            >
              + Add more suppliers
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
