import clsx from "clsx";

import { AvatarSizes } from "../../library";

const ICON_SIZES: Record<AvatarSizes, { sizeClass: string; viewBox: string }> =
  {
    [AvatarSizes.SMALL]: {
      sizeClass: "w-4 h-4",
      viewBox: "6 2 32 32",
    },
    [AvatarSizes.MEDIUM]: {
      sizeClass: "w-6 h-6",
      viewBox: "8 4 32 32",
    },
    [AvatarSizes.MED_LG]: {
      sizeClass: "TBD",
      viewBox: "TBD",
    },
    [AvatarSizes.LARGE]: {
      sizeClass: "w-12 h-12",
      viewBox: "-2 -4 48 48",
    },
    [AvatarSizes.XL]: {
      sizeClass: "w-16 h-16",
      viewBox: "4 0 36 36",
    },
  };

interface FileFolderIconProps {
  className?: string;
  size: AvatarSizes;
}
export function FileFolderIcon({ size, className }: FileFolderIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(ICON_SIZES[size].sizeClass, className)}
      viewBox={ICON_SIZES[size].viewBox}
      fill="none"
    >
      <rect width="24" height="19" x="10" y="12" fill="#FFF8F4" rx="1" />
      <g filter="url(#a)">
        <path
          fill="#939495"
          d="M10.726 32a1.78 1.78 0 0 1-1.303-.548 1.78 1.78 0 0 1-.548-1.302V13.603c0-.503.21-.965.632-1.387.422-.421.884-.632 1.387-.632h7.118c.284 0 .549.056.795.168.246.112.467.257.664.435l1.948 1.977h11.687c.39 0 .745.108 1.064.324.319.216.553.482.703.798H20.948l-2.58-2.58h-7.474a.873.873 0 0 0-.645.252.873.873 0 0 0-.252.645V30.88c0-.262.051-.36.154-.295.103.066.238.145.407.239l2.972-10.924c.14-.43.387-.783.74-1.058a1.96 1.96 0 0 1 1.243-.414h19.665c.668 0 1.212.265 1.634.796.422.531.535 1.121.34 1.77l-2.675 9.64c-.127.451-.352.793-.676 1.023-.323.23-.723.345-1.2.345H10.726Zm1.007-1.121h20.854c.187 0 .36-.056.519-.168a.97.97 0 0 0 .35-.477l2.53-9.535a.817.817 0 0 0-.14-.8.89.89 0 0 0-.73-.35H15.469a.945.945 0 0 0-.533.168.807.807 0 0 0-.336.477l-2.866 10.685Zm0 0 2.866-10.685a9.56 9.56 0 0 1 .14-.477l.056-.168-.098.35c-.065.234-.145.5-.238.8l-2.53 9.535a9.56 9.56 0 0 1-.14.477l-.056.168Z"
        />
      </g>
      <g filter="url(#b)">
        <rect width="16" height="18" x="16.885" y="7.293" fill="#fff" rx="3" />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M29.584 7.293h-9.203a3.5 3.5 0 0 0-3.5 3.506l.004 2.19v9.109a3.589 3.589 0 0 0 1.045 2.523 3.564 3.564 0 0 0 2.514 1.05h3.827c5.54 0 8.864.642 8.864-3.573V10.865a3.588 3.588 0 0 0-1.045-2.522 3.564 3.564 0 0 0-2.514-1.05h.008Z"
        />
        <path
          fill="#939495"
          d="M25.843 22.043h-5.417a.525.525 0 0 1-.386-.155.525.525 0 0 1-.155-.387c0-.154.051-.283.155-.386a.524.524 0 0 1 .386-.155h5.417c.154 0 .283.051.387.155a.525.525 0 0 1 .155.386.525.525 0 0 1-.155.387.525.525 0 0 1-.387.155ZM29.093 17.71h-8.667a.524.524 0 0 1-.386-.156.524.524 0 0 1-.155-.386c0-.154.051-.283.155-.386a.524.524 0 0 1 .386-.156h8.667c.154 0 .283.052.387.155a.525.525 0 0 1 .155.387.525.525 0 0 1-.155.386.525.525 0 0 1-.387.156ZM29.093 13.376h-8.667a.524.524 0 0 1-.386-.155.525.525 0 0 1-.155-.386c0-.155.051-.283.155-.387a.524.524 0 0 1 .386-.155h8.667c.154 0 .283.052.387.155a.525.525 0 0 1 .155.387.525.525 0 0 1-.155.386.525.525 0 0 1-.387.155Z"
        />
        <path
          fill="#ECFCFE"
          d="M31.575 5.293h-7.023l-5.677 5.696v9.109a3.589 3.589 0 0 0 1.045 2.523 3.564 3.564 0 0 0 2.514 1.05h9.132a3.564 3.564 0 0 0 2.514-1.05 3.589 3.589 0 0 0 1.045-2.523V8.865a3.589 3.589 0 0 0-1.045-2.522 3.564 3.564 0 0 0-2.514-1.05h.009Z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31.575 5.293h-7.023l-5.677 5.696v9.109a3.589 3.589 0 0 0 1.045 2.523 3.564 3.564 0 0 0 2.514 1.05h3.827c5.54 0 8.864.642 8.864-3.573V8.865a3.589 3.589 0 0 0-1.045-2.522 3.564 3.564 0 0 0-2.514-1.05h.009Z"
        />
        <path
          fill="#C1F9FD"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.893 10.97h2.353a3.4 3.4 0 0 0 2.398-1 3.424 3.424 0 0 0 .997-2.407v-2.27h-.09l-5.658 5.677Z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.384 12.9h9.24M22.385 16.145h9.243M22.385 19.684h3.877"
        />
      </g>
      <path
        fill="#FFE9D8"
        d="M13.621 20.515A2 2 0 0 1 15.561 19h18.877a2 2 0 0 1 1.94 2.485l-2 8A2 2 0 0 1 32.439 31H11l2.621-10.485Z"
      />
      <path
        fill="#939495"
        d="M10.655 31.962h-.505c.153-.567.153-.567.336-1.177L13.46 19.86c.14-.43.387-.783.74-1.059a1.96 1.96 0 0 1 1.243-.413h19.665c.667 0 1.212.265 1.634.796.421.531.535 1.121.34 1.77l-2.675 9.639c-.127.452-.353.793-.676 1.023-.323.23-.724.345-1.2.345H10.655Zm1.007-1.121h20.854a.88.88 0 0 0 .518-.169.969.969 0 0 0 .351-.476l2.53-9.536a.817.817 0 0 0-.14-.799.89.89 0 0 0-.73-.35H15.397a.945.945 0 0 0-.533.168.807.807 0 0 0-.336.477L11.66 30.84Zm0 0 2.866-10.686a9.67 9.67 0 0 1 .14-.476l.056-.169-.098.351c-.066.234-.145.5-.239.8l-2.53 9.535a9.412 9.412 0 0 1-.14.476l-.056.169Z"
      />
      <defs>
        <filter
          id="a"
          width="44.37"
          height="36.416"
          x=".875"
          y="7.584"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.122708 0 0 0 0 0.516667 0 0 0 0 0.504483 0 0 0 0.38 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_228_25522"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_228_25522"
            result="shape"
          />
        </filter>
        <filter
          id="b"
          width="35.244"
          height="37.413"
          x="8.381"
          y=".793"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.122708 0 0 0 0 0.516667 0 0 0 0 0.504483 0 0 0 0.38 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_228_25522"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_228_25522"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
