import clsx from "clsx";

import LabeledInput, {
  LabeledInputVariants,
  type InputSizes,
  type TextInputTypes,
} from "../Input/LabeledInput";

import type { FieldInputProps } from "formik";
import type { FocusEventHandler } from "react";
import type { CustomFormProps, FieldLabelProps } from "./types";

export interface LabeledInputFieldProps<T = string> extends FieldLabelProps {
  field: FieldInputProps<T>;
  editable: boolean;
  label: string;
  sublabel?: string;
  form: CustomFormProps;
  placeholder?: string;
  size: InputSizes;
  type?: TextInputTypes;
  className?: string;
  dataTestId?: string;
  validationMessage?: string;
  validationIcons?: boolean;
  component?: "input" | "textarea";
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: () => void;
  message?: string;
}

export default function LabeledInputField({
  field,
  form: { touched, errors },
  placeholder,
  size = "md",
  editable,
  type = "text",
  className,
  dataTestId,
  validationMessage,
  onChange,
  component,
  onBlur,
  ...rest
}: LabeledInputFieldProps) {
  if (!editable) {
    return <div className="flex-grow py-2.5">{field.value || "(none)"}</div>;
  }

  let initialVariant = LabeledInputVariants.DEFAULT;
  let message = "";

  if (touched[field.name] && errors[field.name]) {
    initialVariant = LabeledInputVariants.ERROR;
    message = errors[field.name] as string;
  } else if (field.value && !errors[field.name] && validationMessage) {
    initialVariant = LabeledInputVariants.SUCCESS;
    message = validationMessage;
  }

  return (
    <LabeledInput
      type={type}
      component={component}
      className={clsx(className, "flex-grow")}
      size={size}
      name={field.name}
      placeholder={placeholder}
      onChange={(e) => {
        onChange?.();
        field.onChange(e);
      }}
      onBlur={(e) => {
        onBlur?.(e);
        field.onBlur(e);
      }}
      value={field.value || ""}
      initialVariant={initialVariant}
      message={message}
      dataTestId={dataTestId}
      {...rest}
    />
  );
}
