import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import type { ReactNode } from "react";

import { goToHomePage } from "../../components/Account/helpers";
import { Badge, Link, Typography } from "../../library";
import {
  browserLocalStorage,
  browserSessionStorage,
  getCSRFToken,
} from "../../utils";
import { postLogout } from "../../utils/api";
import { logoPavilion } from "../../utils/img";

import EducationalInfo, { type Info } from "./EducationalInfo";

interface AccountPageProps {
  title: string;
  children: ReactNode;
  ctaText?: string;
  ctaComponent?: ReactNode;
  info?: Info[];
  topSection?: ReactNode;
  goBack?: () => void;
  closePage?: () => void;
  hideClosePage?: boolean;
  userCreated?: boolean;
}
export default function AccountPage({
  title = "Welcome back",
  children,
  ctaText,
  ctaComponent,
  info,
  topSection,
  goBack,
  closePage,
  hideClosePage = false,
  userCreated = false,
}: AccountPageProps) {
  // This function clears any user data that was stored temporary FE and BE storage
  // and returns the user to the state they were in before starting the login/signup process
  async function clearAccountSession() {
    const form = new FormData();
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    // this clears any BE session data
    await postLogout(form);
    browserLocalStorage?.clear();
    browserSessionStorage?.clear();
    if (closePage) {
      closePage();
    } else {
      goToHomePage();
    }
  }

  return (
    <div className="bg-gradient-to-bl from-violet-200 via-white to-violet-200 min-h-[calc(100vh-18rem)] w-full">
      <div className="flex flex-col gap-2 md:gap-22 max-w-300 mx-auto px-5 pt-5 pb-20 lg:px-12 xl:px-30 xl:pt-20 relative">
        <div className="flex justify-between items-center">
          {goBack && (
            <div
              onClick={goBack}
              className="cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-6 h-6"
            >
              <Badge Icon={ArrowBackRoundedIcon} className="ml-0.5" />
            </div>
          )}
          <div>
            <Link href="/" target="_parent">
              <img
                src={logoPavilion}
                className="h-10 hidden md:block w-30"
                alt="Logo"
              />{" "}
            </Link>
          </div>
          {!hideClosePage && (
            <div
              className="analytics-close-modal cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-5 h-5"
              onClick={userCreated ? closePage : clearAccountSession}
            >
              <Badge Icon={CloseRoundedIcon} iconClass="text-cp-black-100" />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-around md:flex-row gap-5">
          <div className="flex flex-col w-full md:w-1/2 justify-end md:justify-start">
            <div className="flex flex-col md:gap-8 gap-4 w-full max-w-96 mx-auto">
              <Typography
                variant="display"
                size="md"
                color="brand.default.secondary.enabled"
                className="text-center md:text-left"
              >
                {title}
              </Typography>
              <div>
                {topSection}
                {!!info?.length && <EducationalInfo info={info} />}
                <Typography
                  color="neutral.default.primary.enabled"
                  className="text-center md:text-left"
                >
                  {ctaText}
                </Typography>
                {ctaComponent}
              </div>
            </div>
          </div>
          <div className="flex w-full md:w-1/2 justify-center md:justify-start items-start">
            <div className="bg-white rounded-4xl justify-center w-full max-w-140 p-10 flex flex-col gap-10 border border-solid border-cp-white-200">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
