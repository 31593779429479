import clsx from "clsx";
import { type ReactNode, useEffect, useRef, useState } from "react";

import Typography from "./Typography";
import type {
  TypographyColor,
  TypographySize,
  TypographyVariant,
} from "./Typography/types";

/**
 * This component clamps a provided block of text to 2 lines and provides
 * a view more button to show the full block of text. It's helpful for writing
 * condensed views but still allowing the full block to be visible if
 * desired.
 */
export default function ClampedText({
  children,
  color,
  clampColor = "brand.bold.enabled",
  onClickClamp,
  trackShowClamp,
  viewMoreAnalyticsSuffix,
  className,
  size = "md",
  variant = "body",
  inline = false,
  underline = true,
}: {
  children: ReactNode;
  color?: TypographyColor;
  clampColor?: TypographyColor;
  size?: TypographySize;
  variant?: TypographyVariant;
  trackShowClamp?: () => void;
  onClickClamp?: (clamp: boolean) => void;
  viewMoreAnalyticsSuffix?: string;
  className?: string;
  inline?: boolean;
  underline?: boolean;
}) {
  const [clamp, setClamp] = useState(true);
  const [showClamp, setShowClamp] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only recheck if the reference changes.
  useEffect(() => {
    if (!ref.current) return;
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      trackShowClamp?.();
      setShowClamp(true);
    }
  }, [ref]);

  return (
    <p className="relative">
      <Typography
        component="span"
        color={color}
        ref={ref}
        className={clsx(className, {
          "line-clamp-2": !inline && clamp,
          // The "Read more" button is 70px wide, so add a margin so it can be positioned
          // at the end of the text.
          "line-clamp-1 mr-[70px]": inline && clamp,
        })}
        variant={variant}
        size={size}
      >
        {children}
      </Typography>{" "}
      {showClamp && (
        <Typography
          underline={underline}
          component="span"
          className={clsx("cursor-pointer whitespace-nowrap", {
            [`analytics-${viewMoreAnalyticsSuffix}-shown`]:
              viewMoreAnalyticsSuffix,
            "absolute right-0 top-0": inline && clamp,
          })}
          size={size}
          variant={variant}
          color={clampColor}
          onClick={() => {
            setClamp(!clamp);
            onClickClamp?.(clamp);
          }}
        >
          {clamp ? "Read more" : "Read less"}
        </Typography>
      )}
    </p>
  );
}
