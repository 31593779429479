import {
  Field,
  Form,
  Formik,
  type FormikHelpers,
  type FormikValues,
} from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import {
  blaFieldSignupState,
  inferredEntitySignupState,
} from "../../../jotai/signup";
import { userEmailState } from "../../../jotai/user";
import { Button } from "../../../library";
import Typography from "../../../library/Typography";
import {
  LoginType,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../../utils/enums";
import { trackSignupFlowFailure } from "../../../utils/tracking";
import type { InferredBLA, WindowType } from "../types";
import useSubmitBuyerInfo from "../useSubmitBuyerInfo";

import type { Validate } from "../../../library/form/types";
import yup from "../../../utils/yupPhone";
import StepCircle from "./StepCircle";
import {
  REDESIGNED_BUYER_ROLE_FIELD,
  REDESIGNED_NAME_FIELD,
} from "./constants";

interface RedesignedBuyerSignupFormProps {
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
  inviteBLA: InferredBLA | null;
}

export default function RedesignedBuyerSignupForm({
  onComplete,
  parentWindow,
  inviteBLA,
}: RedesignedBuyerSignupFormProps) {
  const email = useAtomValue(userEmailState);
  const blaFromSignup = useAtomValue(blaFieldSignupState);
  const inferredEntity = useAtomValue(inferredEntitySignupState);

  let prefilledBLA = "";
  let prefilledBLAId = null;
  // It's possible for the user to overwrite the inferred BLA or the invite BLA,
  // so only track if it matches buyerProfileFromSignup
  if (blaFromSignup) {
    const {
      governmentAffiliationDisplayName: blaSignupDisplayName,
      governmentAgencyId: blaSignupId,
    } = blaFromSignup;

    if (inferredEntity) {
      if (blaSignupDisplayName === inferredEntity.buyerLeadAgencyDisplayName) {
        prefilledBLA = inferredEntity.buyerLeadAgencyDisplayName;
      }
      if (blaSignupId === inferredEntity.buyerLeadAgencyId) {
        prefilledBLAId = inferredEntity.buyerLeadAgencyId || null;
      }
    } else if (inviteBLA) {
      if (blaSignupDisplayName === inviteBLA.display_name) {
        prefilledBLA = inviteBLA.display_name;
      }
      if (blaSignupId === inviteBLA.id) {
        prefilledBLAId = inviteBLA.id;
      }
    }
  }

  const [loading, submitBuyerInfo] = useSubmitBuyerInfo({
    email,
    onComplete,
    prefilledBLA,
    prefilledBLAId,
    parentWindow,
  });

  const validationSchema = useMemo(() => {
    return [REDESIGNED_BUYER_ROLE_FIELD, REDESIGNED_NAME_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  function trackInvalidForm(error: Record<string, string>) {
    trackSignupFlowFailure({
      emailEntered: email,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  function handleSubmit(
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) {
    if (!blaFromSignup) {
      trackInvalidForm({
        buyerProfileFromSignup:
          "Expected buyerProfileFromSignup atom value to exist",
      });
      return;
    }
    const [firstName, lastName] = values.name.split(" ");
    submitBuyerInfo(
      {
        buyerRole: values.buyerRole,
        buyerProfile: blaFromSignup,
        firstName,
        lastName,
      },
      helpers
    );
  }
  // TODO: Use entrypoint to customize copy for 3rd step
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const stepFromEntrypoint = "View contract";
  const steps = ["Sign up", "Create account", stepFromEntrypoint];

  return (
    <div className="grid gap-6">
      <div className="flex gap-3">
        {steps.map((text, index) => (
          <StepCircle key={text} text={text} index={index} />
        ))}
      </div>
      <div className="grid gap-4">
        <div className="grid gap-1">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Let's set up Pavilion for you
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Tell us about yourself so we can customize your experience.
          </Typography>
        </div>
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={
            {
              buyerRole: null,
              name: "",
            } as FormikValues
          }
          onSubmit={handleSubmit}
          validationSchema={yup.object(validationSchema)}
        >
          {({ validateForm }) => (
            <Form className="grid gap-4">
              <div className="grid gap-3">
                <Field {...REDESIGNED_BUYER_ROLE_FIELD} editable />
                <Field {...REDESIGNED_NAME_FIELD} editable />
              </div>
              <Button
                className="w-full analytics-email-signup-modal-cta"
                type="submit"
                disabled={loading}
                onClick={validateForm}
              >
                Create account
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
