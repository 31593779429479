import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const DEFAULT_GOV_AFFILIATION = "Orange County, CA";
const DEFAULT_SOURCE_ENTITY_ID = "43a6eab2-9ab9-484a-9ce1-73e7476e529c";
const DEFAULT_GOV_ZIP = "92780";

function OrangeCountyCA() {
  // all param fields are optional
  const { zip, entity } = getParams();
  function getCustomizedUrl(url) {
    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );
    const zipcode = zip || DEFAULT_GOV_ZIP;

    url.searchParams.set("zip", zipcode);
    url.searchParams.set("embedSourceEntityId", DEFAULT_SOURCE_ENTITY_ID);
    return url;
  }
  return (
    <div>
      <WidgetSearchBarTitle
        title={
          entity === "Orange County SSA"
            ? "Search Shareable Contracts"
            : "Search Orange County Contracts"
        }
        subtitle="Thousands of contracts you can use covering vehicles, office equipment, recycling, and more."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          getCustomizedUrl={getCustomizedUrl}
          searchSource={agencySearchSource[agencies.ORANGE_COUNTY_CA]}
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-orange-county-ca");
if (container) {
  const root = createRoot(container);
  root.render(<OrangeCountyCA />);
}
