import { intervalToDuration } from "date-fns";
import { useEffect, useRef } from "react";

interface MSViewerProps {
  // The URL of the file to display.
  url: string;
  onLoad: () => void;
  onClose: (args: number) => void;
  onFileKeptOpen: () => void;
}

// This component is used to display Microsoft Office files in the browser.
export default function MSViewer({
  url,
  onLoad,
  onClose,
  onFileKeptOpen,
}: MSViewerProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const officeAppsUrl = "https://view.officeapps.live.com/op/embed.aspx?src=";
  const officeAppsSrc = `${officeAppsUrl}${encodeURIComponent(url)}`;
  const timeOpened = useRef(new Date());
  // biome-ignore lint/correctness/useExhaustiveDependencies: Initialize the open timer when the document changes.
  useEffect(() => {
    // This is used to track how long the file was open for.
    const triggerOnCloseEvent = true;
    timeOpened.current = new Date();
    const docOpenTimeout = setTimeout(onFileKeptOpen, 10000);

    return () => {
      const timeClosed = new Date();
      const durationOpen = intervalToDuration({
        start: timeOpened.current,
        end: timeClosed,
      });
      if (triggerOnCloseEvent) {
        onClose(durationOpen.seconds || 0);
      }
      clearTimeout(docOpenTimeout);
    };
  }, [url]);
  return (
    <iframe
      ref={iframeRef}
      src={officeAppsSrc}
      onLoad={onLoad}
      title="contract document viewer"
      className="w-full h-[80vh]"
    />
  );
}
