import { clsx } from "clsx";
import { useMemo, useState } from "react";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  contractSourcesState,
} from "../../../jotai/searchFilters";
import { buyerProfileState, userStateState } from "../../../jotai/user";
import { Checkbox, Link, PopoverMenu, Typography } from "../../../library";
import { OUTLINE_FOCUS_CLASS, stateLabels } from "../../../utils/constants";

import { useAtom, useAtomValue } from "jotai";
import type { OnFilterChangeFn } from "./types";

interface SourceListProps {
  id: string;
  title: string;
  sources: string[];
  selectedSources: string[];
  selectAllText: string;
  onSelect: (checked: boolean, sources: string[]) => void;
}

const MINIMIZED_LIMIT = 5;

function SourceList({
  id,
  title,
  selectedSources,
  selectAllText,
  onSelect,
  sources,
}: SourceListProps) {
  const [expanded, setExpanded] = useState(false);

  let sourceList = [...sources];
  const hasMore = sourceList.length > MINIMIZED_LIMIT;
  if (!expanded) {
    sourceList = sourceList.slice(0, MINIMIZED_LIMIT);
  }

  const hasSelectedAll = useMemo(() => {
    return sources.every((s) => selectedSources.includes(s));
  }, [sources, selectedSources]);

  return (
    <div className="flex flex-col gap-3">
      <Typography variant="meta" color="neutral.default.secondary.enabled">
        {title}
      </Typography>
      {sources.length > 1 && (
        <Checkbox
          key="all"
          label={selectAllText}
          name={`${id}-all`}
          checked={hasSelectedAll}
          onChange={() => onSelect(!hasSelectedAll, sources)}
        />
      )}
      {sourceList.map((source, ix) => (
        <Checkbox
          key={source}
          label={source}
          name={`${id}-${ix}`}
          checked={!!selectedSources.includes(source)}
          onChange={(e) => onSelect(e.target.checked, [source])}
        />
      ))}
      {hasMore && (
        <Typography
          variant="cta"
          component="button"
          color="brand.default.primary.enabled"
          className={clsx(
            OUTLINE_FOCUS_CLASS,
            "text-left max-w-fit focus-visible:rounded-3 focus-visible:outline-cp-lapis-500"
          )}
          onClick={() => setExpanded(!expanded)}
        >
          See {expanded ? "fewer" : "more"}
        </Typography>
      )}
    </div>
  );
}

interface SourcesFilterProps {
  onChange: OnFilterChangeFn;
}

export default function SourcesFilter({ onChange }: SourcesFilterProps) {
  const [contractSourceFilter, setContractSourceFilter] = useAtom(
    contractSourceFilterState
  );
  const { agencies, cooperatives, localAgencies } =
    useAtomValue(contractSourcesState);
  const { location } = useAtomValue(userStateState) || {};
  const [approvedSourcesOnlyFilter, setApprovedSourcesOnlyFilter] = useAtom(
    approvedSourcesOnlyFilterState
  );

  const { governmentAgency } = useAtomValue(buyerProfileState);

  const hasApprovedSources =
    !!governmentAgency?.approvedSources?.length ||
    !!governmentAgency?.approvedStates?.length;

  const onSelect = (checked: boolean, sources: string[]) => {
    const update = checked
      ? Array.from(new Set([...contractSourceFilter, ...sources]))
      : contractSourceFilter.filter((s) => !sources.includes(s));
    onChange({ type: "contractSource", value: update });
    setContractSourceFilter(update);
    setApprovedSourcesOnlyFilter(false);
  };

  const onToggleApprovedSources = (checked: boolean) => {
    setApprovedSourcesOnlyFilter(checked);
    setContractSourceFilter([]);
  };

  const stateLabel = location?.state ? stateLabels[location.state] : null;

  const otherSources = contractSourceFilter.filter(
    (f) =>
      !agencies.includes(f) &&
      !cooperatives.includes(f) &&
      !localAgencies.includes(f)
  );

  return (
    <>
      {hasApprovedSources && (
        <div className="flex flex-col gap-2">
          <Checkbox
            name="showOnlyApprovedSources"
            label="Sources approved by my entity"
            onChange={(e) => onToggleApprovedSources(e.target.checked)}
            checked={approvedSourcesOnlyFilter}
          />
          <Link
            href="/profile#approved-sources"
            underline={false}
            size="sm"
            className="whitespace-nowrap ml-6"
          >
            Manage pre-approved sources
          </Link>
        </div>
      )}
      {!!otherSources.length && (
        <SourceList
          id="other"
          title="Sources without results"
          selectAllText="All sources without results"
          sources={otherSources}
          selectedSources={contractSourceFilter}
          onSelect={onSelect}
        />
      )}
      {!!cooperatives.length && (
        <SourceList
          id="cooperatives"
          title="Cooperatives"
          selectAllText="All purchasing cooperatives"
          sources={cooperatives}
          selectedSources={contractSourceFilter}
          onSelect={onSelect}
        />
      )}
      {!!localAgencies.length && (
        <SourceList
          id="localAgencies"
          title={`Local entities in ${stateLabel}`}
          selectAllText={`All local entities in ${stateLabel}`}
          sources={localAgencies}
          selectedSources={contractSourceFilter}
          onSelect={onSelect}
        />
      )}
      {!!agencies.length && (
        <SourceList
          id="agencies"
          title={
            stateLabel
              ? `Local entities outside of ${stateLabel}`
              : "Local entities"
          }
          selectAllText={
            stateLabel
              ? `All local entities outside of ${stateLabel}`
              : "All local entities"
          }
          sources={agencies}
          selectedSources={contractSourceFilter}
          onSelect={onSelect}
        />
      )}
    </>
  );
}

export function SourcesFilterPopover(props: SourcesFilterProps) {
  const contractSourceFilter = useAtomValue(contractSourceFilterState);
  const { agencies, cooperatives, localAgencies } =
    useAtomValue(contractSourcesState);
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const hasApprovedSources =
    !!governmentAgency?.approvedSources?.length ||
    !!governmentAgency?.approvedStates?.length;

  // Don't show the popover if there are no sources and agency doesn't have approved sources
  if (
    !agencies.length &&
    !cooperatives.length &&
    !localAgencies.length &&
    !contractSourceFilter.length &&
    !hasApprovedSources
  )
    return null;

  return (
    <PopoverMenu
      text="Contract source"
      border
      enabled={!!contractSourceFilter.length}
      noWrap
    >
      <div className="overflow-auto max-h-[calc(100vh-18rem)] min-w-[452px] flex flex-col gap-4 my-2">
        <SourcesFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
