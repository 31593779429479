import { createRoot } from "react-dom/client";

import WelcomePage from "../components/WelcomePage";
import type { SupplierAgreement } from "../components/WelcomePage/types";
import type { ApprovalStatusEnum } from "../generated";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface WelcomeProps extends AuthPageProps {
  num_agency_contracts: Maybe<number>;
  num_expiring_agency_contracts: Maybe<number>;
  expired_agreements: Maybe<SupplierAgreement[]>;
  email_verified: boolean;
  supplier_approval_status: ApprovalStatusEnum;
  num_supplier_active_shareable_contracts: Maybe<number>;
  supplier_logo_url: Maybe<string>;
}

export default function Welcome({
  num_agency_contracts,
  num_expiring_agency_contracts,
  profile_type,
  is_authenticated,
  active_agreements,
  expired_agreements,
  email_verified,
  supplier_approval_status,
  num_supplier_active_shareable_contracts,
  supplier_logo_url,
}: WelcomeProps) {
  return (
    <Page
      pageId=""
      pageType={PageType.PROFILE}
      searchSource="profile-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <WelcomePage
        numAgencyContracts={num_agency_contracts}
        numExpiringAgencyContracts={num_expiring_agency_contracts}
        expiredAgreements={expired_agreements}
        emailVerified={email_verified}
        supplierApprovalStatus={supplier_approval_status}
        numSupplierActiveShareableContracts={
          num_supplier_active_shareable_contracts
        }
        supplierLogoUrl={supplier_logo_url}
      />
    </Page>
  );
}

const container = getDOMAnchorById("welcome");
if (container) {
  const props = JSON.parse(container.dataset.welcomeData as string);
  const root = createRoot(container);
  root.render(<Welcome {...props} />);
}
