import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import clsx from "clsx";
import pluralize from "pluralize";
import { Badge, Typography } from "../../../../library";
import BookmarkLink from "../../../../library/BookmarkLink";
import { borderColorClass, iconColorClass } from "../../../../utils/colors";
import { elevationClass } from "../../../../utils/designTokens";

interface SupplierTasksProps {
  header?: string;
  fullWidth?: boolean;
  tasks: Task[];
}

interface Task {
  label: string;
  sublabel?: string;
  completed: boolean;
  buttons?: Array<{
    label: string;
    onClick: () => void;
    href?: string;
  }>;
}

export default function SupplierTasks({
  header = "Tasks",
  fullWidth = false,
  tasks,
}: SupplierTasksProps) {
  const numCompletedTasks = tasks.filter(({ completed }) => completed).length;
  return (
    <div
      className={clsx(
        "flex flex-col h-fit rounded-3 p-6 border border-solid",
        borderColorClass.neutral.subtle.enabled,
        elevationClass["elevation-1"]
      )}
    >
      <div className="flex flex-col gap-2 pb-4 mb-4 border-b border-cp-neutral-200">
        <Typography color="brand.boldest.enabled" variant="headline" size="sm">
          {header}
        </Typography>
        <Typography color="neutral.bold.enabled" variant="meta">
          {`${numCompletedTasks} of ${tasks.length} ${pluralize("task", tasks.length)} complete`}
        </Typography>
      </div>
      {tasks.map(({ label, sublabel, completed, buttons }) => {
        const showSublabel = !completed && !!sublabel;
        const showButtons = !completed && !!buttons?.length;
        return (
          <div key={label} className={"flex flex-col mb-6 last:mb-0"}>
            {/* Status icon + rest */}
            <div className={"flex gap-3"}>
              <Badge
                Icon={
                  completed ? CheckCircleRoundedIcon : Brightness1OutlinedIcon
                }
                includeMargin={false}
                className={clsx("h-fit", {
                  "my-1": fullWidth && showButtons,
                })}
                iconClass={
                  completed
                    ? iconColorClass.feedback.bold.success
                    : iconColorClass.neutral.boldest.enabled
                }
              />
              {/* Label and buttons (+ sublabel if fullWidth) */}
              <div className="flex flex-col gap-3">
                {/* Label (and sublabel if not fullWidth) + buttons */}
                <div
                  className={clsx("flex", {
                    "gap-4": fullWidth,
                    "flex-col gap-0": !fullWidth,
                  })}
                >
                  {/* Label (+ sublabel if not fullWidth) */}
                  <div className="flex flex-col justify-center gap-1">
                    <Typography
                      color={
                        completed
                          ? "feedback.bold.success"
                          : "neutral.boldest.enabled"
                      }
                      size="sm"
                      emphasis
                    >
                      {label}
                    </Typography>
                    {showSublabel && !fullWidth && (
                      <Typography color="neutral.bolder.enabled" size="md">
                        {sublabel}
                      </Typography>
                    )}
                  </div>
                  {showButtons && (
                    <div className={!fullWidth ? "mt-3" : ""}>
                      {buttons?.map(({ label, onClick, href }) => (
                        <BookmarkLink
                          key={label}
                          label={label}
                          onClick={onClick}
                          href={href}
                          background
                          border
                        />
                      ))}
                    </div>
                  )}
                </div>
                {showSublabel && fullWidth && (
                  <div>
                    <Typography color="neutral.bolder.enabled" size="md">
                      {sublabel}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
