import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { ApprovalStatusEnum } from "../../generated";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import {
  userState as jotaiUserState,
  profileTypeState,
  supplierExpiredAgreementsState,
  userInitializedState,
} from "../../jotai/user";
import { SuccessPopup } from "../../popups/AnimatedPopup";
import { getParam, setParamNoHistory } from "../../utils";
import { ProfileType } from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import BuyerProfilePage from "./BuyerProfilePage/BuyerProfilePage";
import ProSupplierWelcomePage from "./ProSupplierWelcomePage";
import SupplierWelcomePage from "./SupplierWelcomePage";
import type { SupplierAgreement } from "./types";

interface WelcomePageProps {
  numAgencyContracts: Maybe<number>;
  numExpiringAgencyContracts: Maybe<number>;
  numSupplierActiveShareableContracts: Maybe<number>;
  expiredAgreements: Maybe<SupplierAgreement[]>;
  emailVerified: boolean;
  supplierApprovalStatus: ApprovalStatusEnum;
  supplierLogoUrl: Maybe<string>;
}

function WelcomePage({
  numAgencyContracts,
  numExpiringAgencyContracts,
  numSupplierActiveShareableContracts,
  expiredAgreements,
  emailVerified,
  supplierApprovalStatus,
  supplierLogoUrl,
}: WelcomePageProps) {
  const { userState, supplier } = useAtomValue(jotaiUserState);
  const isInitialized = useAtomValue(userInitializedState);
  const showSignupSurvey = useShowSignupSurvey();
  const setExpiredAgreements = useSetAtom(supplierExpiredAgreementsState);
  const profileType = useAtomValue(profileTypeState);
  const isSupplier = profileType === ProfileType.SUPPLIER;

  useEffect(() => {
    if (expiredAgreements) setExpiredAgreements(expiredAgreements);
  }, [expiredAgreements, setExpiredAgreements]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once after initialization.
  useEffect(() => {
    if (
      !isInitialized ||
      !isSupplier ||
      userState.hasSeenSupplierSourceChannelSurvey
    )
      return;
    showSignupSurvey({});
  }, [isInitialized]);

  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(
    getParam("emailConfirmed") === "true"
  );

  const showProSupplierWelcomePage =
    emailVerified &&
    supplierApprovalStatus === ApprovalStatusEnum.APPROVED &&
    supplierHasFeature(supplier?.activeAgreements, "basicProfileAccess");

  function getSupplierPage() {
    if (!showProSupplierWelcomePage) {
      return (
        <SupplierWelcomePage
          emailVerified={emailVerified}
          supplierApprovalStatus={supplierApprovalStatus}
          numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
        />
      );
    }

    return (
      <ProSupplierWelcomePage
        numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
        supplierLogoUrl={supplierLogoUrl || ""}
      />
    );
  }

  return (
    <>
      <SuccessPopup
        show={hasConfirmedEmail}
        setShow={(show) => {
          setHasConfirmedEmail(show);
          setParamNoHistory("emailConfirmed", null);
        }}
        onClose={() => setHasConfirmedEmail(false)}
      >
        Success! Your email is now verified.
      </SuccessPopup>

      <div className="analytics-welcome-page min-h-[calc(100vh-23rem)] flex flex-col">
        {isSupplier ? (
          getSupplierPage()
        ) : (
          <BuyerProfilePage
            numAgencyContracts={numAgencyContracts || 0}
            numExpiringAgencyContracts={numExpiringAgencyContracts || 0}
          />
        )}
      </div>
    </>
  );
}

export default WelcomePage;
