import clsx from "clsx";
import type { ReactNode } from "react";

interface PageSectionProps {
  children: ReactNode;
  className?: string;
}

export default function PageSection({ children, className }: PageSectionProps) {
  return (
    <div className={clsx("px-6 md:px-8", className)}>
      <div className="max-w-[75rem] m-auto">{children}</div>
    </div>
  );
}
