import { Button, type ButtonThemes, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";

type SecondaryCtaProps = {
  ctaText: string;
  onClick: () => void;
  buttonVariant?: ButtonThemes;
};

interface ConfirmationModalProps {
  hideModal: () => void;
  title?: string;
  subtitle?: string;
  ctaText?: string;
  secondaryCtaProps?: SecondaryCtaProps;
}

export default function ConfirmDeleteMessageModal({
  hideModal,
  title = "You have unsaved changes",
  subtitle = "If you leave this page, you’ll lose your draft.",
  ctaText = "Continue writing message",
  secondaryCtaProps,
}: ConfirmationModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      title={title}
      contentClassName="grid gap-6"
      titleSize="md"
      modalSize={modalSizes.SMALL}
    >
      <Typography>{subtitle}</Typography>
      <Button
        className="analytics-message-supplier-continue-message w-full"
        onClick={hideModal}
      >
        {ctaText}
      </Button>
      {secondaryCtaProps && (
        <Button
          className="analytics-message-supplier-delete-message w-full"
          onClick={secondaryCtaProps.onClick}
          theme={
            secondaryCtaProps.buttonVariant ?? Button.themes.SECONDARY_DARK
          }
        >
          {secondaryCtaProps.ctaText}
        </Button>
      )}
    </Modal>
  );
}
