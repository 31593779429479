import clsx from "clsx";
import { useEffect, useState } from "react";

import loaderGifIcon from "../../../img/loaders/loader.gif";
import { ApiService } from "../../generated";

import {
  Button,
  ButtonThemes,
  Checkbox,
  LabeledInput,
  RadioButtonGroup,
  Typography,
} from "../../library";
import Modal from "../../shared/Modal/Modal";

import { handleError } from "../../utils/generatedApi";
import {
  contactSupplierMethodOptions,
  findSupplierContactOptions,
  signupSurveyOtherOption,
} from "./constants";

interface SignupStepsSurveyModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
}

export default function SignupStepsSurveyModal({
  hideModal,
  onComplete,
}: SignupStepsSurveyModalProps) {
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [selectedFindContact, setSelectedFindContact] = useState<string[]>([]);
  const [selectedContactMethod, setSelectedContactMethod] = useState("");
  const [selectedOther, setSelectedOther] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const radioOptions = contactSupplierMethodOptions.map((option) => ({
    key: option,
    label: option,
  }));

  useEffect(() => {
    (async () => {
      try {
        await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
          hasSeenContactSurvey: true,
        });
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  function onContinue() {
    setIsCTALoading(true);
    const selectedFindContactOptions = selectedOther
      ? [...selectedFindContact, otherInput]
      : selectedFindContact;
    const patchJSON = {
      findSupplierContact: selectedFindContactOptions.join(","),
      contactSupplierMethod: selectedContactMethod,
    };
    ApiService.apiV1UsersBuyerProfilesPartialUpdate("me", "me", patchJSON)
      .then(() => {
        hideModal();
        onComplete?.();
      })
      .finally(() => {
        setIsCTALoading(false);
      });
  }

  const ctaDisabled =
    !selectedContactMethod ||
    isCTALoading ||
    (!selectedFindContact.length && !selectedOther) ||
    (selectedOther && !otherInput);

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={true}
      className="analytics-signup-survey-modal"
      formID="analytics-signup-survey-modal-submit"
      title="How do you find contact info for new suppliers?"
      ctaSection={
        <div className="flex gap-3 flex-row justify-center">
          <Button
            className="analytics-signup-survey-skip-cta"
            onClick={() => {
              hideModal();
              onComplete?.();
            }}
            theme={ButtonThemes.SECONDARY_DARK}
            type="submit"
          >
            {isCTALoading ? (
              <img src={loaderGifIcon} className="w-6" alt="Loading" />
            ) : (
              "Skip"
            )}
          </Button>
          <Button
            className="analytics-signup-survey-cta"
            disabled={ctaDisabled}
            onClick={onContinue}
            type="submit"
          >
            {isCTALoading ? (
              <img src={loaderGifIcon} className="w-6" alt="Loading" />
            ) : (
              "Continue"
            )}
          </Button>
        </div>
      }
      showLogoHeader
    >
      <div className="flex flex-col gap-4 w-3/4 m-auto">
        {findSupplierContactOptions.map((option) => (
          <Checkbox
            key={option}
            checked={selectedFindContact.includes(option)}
            name={option}
            label={option}
            onChange={() => {
              const index = selectedFindContact.findIndex(
                (op) => op === option
              );
              const untoggle = index !== -1;
              const newSelectedFindContact = untoggle
                ? selectedFindContact.filter((_, ix) => ix !== index)
                : [...selectedFindContact, option];
              setSelectedFindContact(newSelectedFindContact);
            }}
          />
        ))}
        <Checkbox
          name={signupSurveyOtherOption}
          label={signupSurveyOtherOption}
          checked={selectedOther}
          onChange={() => setSelectedOther(!selectedOther)}
        />
        {selectedOther && (
          <LabeledInput
            name="other"
            size="sm"
            value={otherInput}
            onChange={(e) => setOtherInput(e.target.value)}
            required
          />
        )}
      </div>
      <div className="flex flex-col gap-4 w-3/4 m-auto">
        <Typography
          variant="headline"
          size="sm"
          color="brand.default.secondary.enabled"
          emphasis
          className={clsx(
            "w-full border-solid border-white bg-white text-center mt-8"
          )}
        >
          How do you usually contact suppliers?
        </Typography>
        <RadioButtonGroup
          value={selectedContactMethod}
          onChange={(value: string) => {
            setSelectedContactMethod(value);
          }}
          options={radioOptions}
          labelClassName="mt-0"
        />
      </div>
    </Modal>
  );
}
