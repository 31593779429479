import { useAtomValue } from "jotai";
import { useState } from "react";
import type { SupplierCardType } from "../../../generated";
import { useHideSupplierSearch } from "../../../hooks/useHideSupplierSearch";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  contractSearchParamsState,
  contractSearchResponseDataState,
  requestIDState,
  searchQueryState,
  searchResultTypeState,
  selectedSupplierSearchSuggestionState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../jotai/search";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import { SearchActions } from "../../../utils/enums";
import { trackHeapEvent } from "../../../utils/tracking";
import { headerText } from "../utils";
import ResultCountText from "./ResultCountText";

export default function ContractResultsHeaderText() {
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const query = useAtomValue(searchQueryState);
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResults = searchResultType === "supplier";

  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        {supplierResults
          ? `Results for "${query}"`
          : headerText(query, contractSearchResponseData?.agencyData?.agency)}
      </Typography>
      {!supplierResults && <ResultCountText />}
    </div>
  );
}

export function SupplierCategoriesHeaderText() {
  const search = useSearchContractWithParams();
  const requestID = useAtomValue(requestIDState);
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const suggestedSearches =
    supplierResponseData?.supplierData?.suggestedSearches;
  const selectedSupplierSearchSuggestion = useAtomValue(
    selectedSupplierSearchSuggestionState
  );
  const index = suggestedSearches?.indexOf(
    selectedSupplierSearchSuggestion || ""
  );
  const [selectedQuery, setSelectedQuery] = useState<string>(
    suggestedSearches?.[index || 0] || ""
  );

  if (!suggestedSearches) {
    return null;
  }

  if (suggestedSearches.length === 1) {
    return (
      <div className="grid gap-2 analytics-search-results-header-text">
        <Typography variant="headline" emphasis color="brand.boldest.enabled">
          {searchResultType === "contract"
            ? `Other results for "${suggestedSearches[0]}"`
            : `Other suppliers that sell "${suggestedSearches[0]}"`}
        </Typography>
      </div>
    );
  }

  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        {searchResultType === "contract"
          ? "Contracts from similar suppliers"
          : `Other suppliers like ${supplierResponseData?.supplierData?.suppliers[0].supplier.displayName}`}
      </Typography>
      <div className="flex flex-row gap-2 items-center mt-2">
        {suggestedSearches.map((suggestedSearch) => (
          <Button
            key={suggestedSearch}
            theme={
              suggestedSearch === selectedQuery
                ? ButtonThemes.OUTLINE_SELECTED
                : ButtonThemes.OUTLINE
            }
            size={ButtonSizes.SMALL}
            rounded="circle"
            onClick={() => {
              trackHeapEvent("supplier-redirect-click-category", {
                suggestedSearch,
                requestID,
              });
              setSelectedQuery(suggestedSearch);
              search({
                newParams: { query: suggestedSearch },
                action: SearchActions.SEARCH_CONTRACTS_ONLY,
              });
            }}
          >
            {suggestedSearch}
          </Button>
        ))}
      </div>
    </div>
  );
}

export function SupplierResultsHeaderText({
  suppliers,
}: { suppliers: SupplierCardType[] }) {
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);
  const noContracts = suppliers[0].supplierDisplay.numActiveContracts === 0;
  const { query } = useAtomValue(contractSearchParamsState);
  const search = useSearchContractWithParams();
  const requestID = useAtomValue(requestIDState);
  const hideSupplierSearch = useHideSupplierSearch();
  const searchType = useAtomValue(searchResultTypeState);

  return (
    <div className="grid gap-2 analytics-search-results-header-text mr-2">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        Top supplier match
      </Typography>
      {(searchType === "contract" || noContracts) &&
        showSupplierRedirect &&
        !!suppliers.length && (
          <Typography size="sm">
            Showing supplier match for &quot;
            {suppliers[0].supplier.displayName}
            &quot;. Search instead for{"  "}
            <Link
              size="sm"
              underline={false}
              emphasis={false}
              onClick={() => {
                trackHeapEvent("supplier-redirect-show-contracts", {
                  query,
                  requestID,
                });
                hideSupplierSearch(query);
                search({
                  newParams: { query },
                  action: SearchActions.SEARCH_CONTRACTS_ONLY,
                });
              }}
            >
              {query} contracts.
            </Link>
          </Typography>
        )}
    </div>
  );
}
