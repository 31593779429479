import { useAtomValue } from "jotai";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import type { SupplierCardType, SupplierMatchData } from "../../generated";
import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import useLoginWall from "../../hooks/useLoginWall";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import useTrackSupplierClick from "../../hooks/useTrackSupplierClick";
import { topSupplierContractCardState } from "../../jotai/search";
import { userZipState } from "../../jotai/user";
import { goToURL, isIframe } from "../../utils";
import {
  LoginWallTriggers,
  ViewContractRankCTA,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getSupplierUrlPath } from "../../utils/format";
import SupplierCard from "../SupplierCard";
import { SearchResult } from "./SearchResults/SearchResultList";
import { getSupplierTrackingData, isContractHit } from "./utils";

interface SupplierMatchSectionProps {
  supplierMatchData?: Maybe<SupplierMatchData>;
  suppliers: SupplierCardType[];
  params: ContractSearchParams;
  requestID: string;
  supplierCardDataTestId?: string;
}

function SupplierMatchSection({
  suppliers,
  params,
  requestID,
  supplierCardDataTestId,
}: SupplierMatchSectionProps) {
  const userZip = useAtomValue(userZipState);
  const requireLogin = useLoginWall();
  const trackSerpClick = useTrackSerpClick();
  const trackSupplierClick = useTrackSupplierClick();

  const topSupplierResultCard = useAtomValue(topSupplierContractCardState);
  const diversityPreferences = useDiversityPreferences();

  const isWidget = isIframe();
  if (!suppliers?.length || isWidget) {
    return null;
  }

  if (topSupplierResultCard) {
    return (
      <SearchResult
        key={
          isContractHit(topSupplierResultCard)
            ? topSupplierResultCard.docid
            : topSupplierResultCard.supplierId
        }
        result={topSupplierResultCard}
        query={params.query}
        visibleRank={1}
        primary
        requestID={requestID}
        diversityPreferences={diversityPreferences}
      />
    );
  }

  const supplierWithContractsExists = suppliers.some(
    (supplier) => supplier.supplierDisplay.numActiveContracts
  );
  if (supplierWithContractsExists) {
    suppliers = suppliers.filter(
      (supplier) => supplier.supplierDisplay.numActiveContracts
    );
  }

  function onSupplierClick(supplier: SupplierCardType) {
    const supplierUrl = getSupplierUrlPath(
      supplier.supplier.handle,
      params.query,
      userZip,
      pageNavigationSourceTypes.SEARCH,
      requestID
    );
    const data = getSupplierTrackingData(supplier);
    requireLogin({
      triggerId: supplier.supplier.handle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK,
      onComplete: () => {
        trackSupplierClick(data);
        goToURL(supplierUrl);
      },
    });
  }

  function trackSupplierSerpClick(supplier: SupplierCardType) {
    trackSerpClick({
      contractId: null,
      matchTier: null,
      ...getSupplierTrackingData(supplier),
      ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
    });
  }

  return (
    <div className="grid gap-y-4">
      {suppliers.map((supplier) => (
        <SupplierCard
          key={supplier.supplier.id}
          supplier={supplier}
          className="max-w-[49.5rem] col-span-8"
          onClick={() => onSupplierClick(supplier)}
          trackSerpClick={() => trackSupplierSerpClick(supplier)}
          dataTestId={
            supplierCardDataTestId
              ? `${supplierCardDataTestId}-${supplier.supplier.handle}`
              : undefined
          }
        />
      ))}
    </div>
  );
}
export default SupplierMatchSection;
