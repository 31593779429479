import { type Getter, type Setter, atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService, type SemanticKeywordAndOffering } from "../generated";
import { handleError } from "../utils/generatedApi";

const supplierScopeListState = atom<SemanticKeywordAndOffering>({
  supplierOfferings: [],
  offerings: [],
});

const supplierScopeInitializedState = atom(false);

export const supplierScopeState = atom(
  (get) => {
    return {
      isInitialized: get(supplierScopeInitializedState),
      scope: get(supplierScopeListState),
    };
  },
  (
    _get,
    set,
    value: { scope: SemanticKeywordAndOffering; isInitialized: boolean }
  ) => {
    set(supplierScopeListState, value.scope);
    set(supplierScopeInitializedState, value.isInitialized);
  }
);

export function initializeSupplierScopeCallback() {
  const initialize = useCallback(
    async (get: Getter, set: Setter, supplierId: number, query: string) => {
      const supplierScope = get(supplierScopeState);
      if (supplierScope.isInitialized) return supplierScope;
      try {
        const response = await ApiService.apiV1SuppliersSemanticScopeRetrieve(
          query,
          supplierId
        );
        set(supplierScopeState, {
          scope: response,
          isInitialized: true,
        });
      } catch (error) {
        handleError(error);
        set(supplierScopeState, {
          scope: { supplierOfferings: [], offerings: [] },
          isInitialized: true,
        });
      }
      return supplierScope;
    },
    []
  );
  return useAtomCallback(initialize);
}
