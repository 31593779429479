import clsx from "clsx";
import { Field, Form, Formik, type FormikValues } from "formik";
import { type ReactNode, useState } from "react";

import { Button, ButtonSizes, ButtonThemes, Typography } from "../../library";
import { CARD_WIDTH_STYLE } from "../../library/GenericCard";
import { TextareaField } from "../../library/form";
import { useSubmitAndSave } from "../../modals/constants";
import { bgColorClass } from "../../utils/colors";

import {
  ApiService,
  type ItemTypeEnum,
  type ProjectItemNote as ProjectItemNoteType,
} from "../../generated";
import { handleError } from "../../utils/generatedApi";
import { SelfAvatar } from "./CollaboratorAvatars";
import ProjectItemNote from "./ProjectItemNote";

const WITH_NOTES_STYLE = clsx(
  "p-3 rounded-xl",
  bgColorClass.neutral.subtlest.hovered
);

interface ProjectItemWrapperProps {
  children: ReactNode;
  itemId: string;
  itemType: ItemTypeEnum;
  projectId: string;
  initialNotes: ProjectItemNoteType[];
  hasEditAccess: boolean;
  isArchived: boolean;
}

export default function ProjectItemWrapper({
  projectId,
  itemId,
  itemType,
  children,
  initialNotes,
  hasEditAccess,
  isArchived,
}: ProjectItemWrapperProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [notes, setNotes] = useState(initialNotes);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      if (!values.note.trim()) {
        return;
      }
      try {
        const data = await ApiService.apiV1ProjectsItemsNotesCreate(
          itemId,
          projectId,
          {
            text: values.note,
          }
        );
        setNotes(data.notes);
      } catch (e) {
        handleError(e);
        setErrorMessage("Unable to add note at this time.");
      }
    }
  );

  return (
    <>
      {hasEditAccess && !isArchived ? (
        <div
          className={clsx(CARD_WIDTH_STYLE, "flex flex-col gap-3", {
            [WITH_NOTES_STYLE]: !!notes.length,
          })}
        >
          {children}
          {notes.length > 0 && (
            <div className="ml-8">
              <div className="border-l-3 pl-4 flex flex-col gap-4">
                {notes.map((note) => (
                  <ProjectItemNote key={note.text} note={note} />
                ))}
              </div>
            </div>
          )}
          <div className="ml-8">
            <Formik
              enableReinitialize
              initialValues={{ note: "" }}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              <Form>
                <Field
                  name="note"
                  component={TextareaField}
                  placeholder={`Add a note about this ${itemType.toLowerCase()}`}
                  editable
                  autoComplete="off"
                  resizeNone
                  rows={1}
                  LeadingIcon={() => (
                    <div className="pl-4">
                      <SelfAvatar showTooltip={false} />
                    </div>
                  )}
                  TrailingIcon={() => (
                    <Button
                      type="submit"
                      className="mr-2"
                      theme={ButtonThemes.TERTIARY_DARK}
                      size={ButtonSizes.SMALL}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  )}
                />
              </Form>
            </Formik>
            {errorMessage && (
              <Typography
                size="sm"
                variant="meta"
                color="feedback.bold.error"
                className="mt-2 mr-2 text-left"
              >
                {errorMessage}
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}
