import { format } from "date-fns";

import type { ProjectItemNote as ProjectItemNoteType } from "../../generated";
import { Typography } from "../../library";

export default function ProjectItemNote({
  note,
}: { note: ProjectItemNoteType }) {
  return (
    <div>
      <div className="inline-flex">
        <Typography color="brand.boldest.enabled" variant="meta" emphasis>
          {note.firstName}
        </Typography>
        <Typography
          className="mx-2"
          color="neutral.bold.enabled"
          variant="meta"
        >
          •
        </Typography>
        <Typography color="neutral.bold.enabled" variant="meta">
          {format(new Date(note.createdAt), "MMM dd, yyyy")}
        </Typography>
      </div>
      <Typography
        className="mt-1 whitespace-pre-line break-words"
        color="neutral.boldest.enabled"
      >
        {note.text}
      </Typography>
    </div>
  );
}
