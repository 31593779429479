import { type Ref, forwardRef, useEffect, useState } from "react";

import type { TrackClickWithinSupplierCarouselFn } from "../../components/SolicitationPage/types";
import { pageIdState, pageTypeState } from "../../jotai/page";
import { userInitializedState, userZipState } from "../../jotai/user";
import { formatLocal } from "../../utils/format";

import { useAtomValue } from "jotai";
import {
  ApiService,
  type RecommendSupplierToSupplierRequest,
  type SupplierCardType,
} from "../../generated";
import { Typography } from "../../library";
import { handleError } from "../../utils/generatedApi";
import SupplierCarousel from "./SupplierCarousel";
interface RecommendationCarouselProps {
  id: string;
  onRender: (suppliers: SupplierCardType[]) => void;
  onClick: TrackClickWithinSupplierCarouselFn;
  className?: string;
}

const RecommendationCarousel = forwardRef(function RecommendationCarousel(
  { id, onRender, onClick, className }: RecommendationCarouselProps,
  ref: Ref<HTMLDivElement>
) {
  const userZip = useAtomValue(userZipState);
  const pageType = useAtomValue(pageTypeState);
  const pageId = useAtomValue(pageIdState);
  const isInitialized = useAtomValue(userInitializedState);
  const [recommendations, setRecommendations] = useState<SupplierCardType[]>(
    []
  );
  const [geo, setGeo] = useState<Maybe<string>>(null);

  const recommendedSuppliersTitle = geo
    ? `More suppliers for ${geo}`
    : "More suppliers";

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once after the user has been initialized.
  useEffect(() => {
    if (!isInitialized) return;
    const body: RecommendSupplierToSupplierRequest = { id, zip: null };
    if (userZip) body.zip = userZip;

    (async () => {
      try {
        const response =
          await ApiService.apiV1RecommendationsSupplierCreate(body);
        if (response.queryLocation) {
          const { state, city, zip } = response.queryLocation;
          if (state || city) {
            setGeo(formatLocal({ state, city, zip }));
          }
        }
        setRecommendations(response.suppliers);
      } catch (err) {
        handleError(err);
      }
    })();
  }, [isInitialized]);
  if (!recommendations.length) {
    return (
      <Typography ref={ref} className={className}>
        No similar suppliers found.
      </Typography>
    );
  }
  return (
    <div ref={ref} className={className}>
      <SupplierCarousel
        suppliers={recommendations}
        title={recommendedSuppliersTitle}
        sourceData={{ pageType, pageId }}
        onRender={onRender}
        onClick={onClick}
      />
    </div>
  );
});

export default RecommendationCarousel;
