import { useAtom } from "jotai";
import { type ChangeEvent, useState } from "react";

import paperPlaneSparkles from "../../../img/welcome/paperPlaneSparkles.svg";
import {
  AccountsService,
  type CheckUserRequest,
  type ExistingUser,
} from "../../generated";
import { userDetailsState } from "../../jotai/user";
import {
  LabeledInput,
  LabeledInputVariants,
  Link,
  PasswordInput,
  Typography,
} from "../../library";
import { getCSRFToken, validateEmail } from "../../utils";
import { handleError, postLogin, postSendMagicLink } from "../../utils/api";
import { LoginType, loginSignupSteps } from "../../utils/enums";
import { handleError as handleGeneratedApiError } from "../../utils/generatedApi";
import { SocialProvider } from "../../utils/social";
import {
  LoginOrigin,
  trackHeapEvent,
  trackLoginFailure,
  trackLoginSuccess,
  trackSignupFlowFailure,
} from "../../utils/tracking";
import SocialLoginButton from "../SignupSteps/SocialLoginButton";
import SubmitButton from "../SignupSteps/SubmitButton";
import { WindowType } from "../SignupSteps/types";

interface LoginFormProps {
  initialEmail?: string;
  redirectSocialUser: (data: ExistingUser) => boolean;
  goToSignupPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
  passwordLoginOnStart?: boolean;
}
export default function LoginForm({
  initialEmail,
  redirectSocialUser,
  goToSignupPageOrModal,
  onComplete,
  parentWindow,
  passwordLoginOnStart = true,
}: LoginFormProps) {
  const [email, setEmail] = useState(initialEmail || "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [userDetails, setUserDetails] = useAtom(userDetailsState);
  const [isCTALoading, setIsCTALoading] = useState(false);
  const btnClassName = "analytics-login-modal-cta";
  const [isPasswordLogin, setIsPasswordLogin] = useState(passwordLoginOnStart);
  const [hasFailedLogin, setHasFailedLogin] = useState(false);
  const [showMagicLinkSuccessMessage, setShowMagicLinkSuccessMessage] =
    useState(false);

  async function handleLogin() {
    const isValid = email && validateEmail(email);
    if (!isValid) {
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Invalid email",
        loginExperience: parentWindow,
      });
      setEmailErrorMessage("Please enter a valid email");
      return false;
    }

    const emailData: CheckUserRequest = { email };
    try {
      const response =
        await AccountsService.accountsCheckExistingUserCreate(emailData);
      if (redirectSocialUser(response)) return;
    } catch (err) {
      handleGeneratedApiError(err);
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: "Check existing user error",
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Check existing user error",
        loginExperience: parentWindow,
      });
      setHasFailedLogin(true);
      setIsCTALoading(false);
      return;
    }

    setIsCTALoading(true);
    const form = new FormData();
    setUserDetails({ ...userDetails, email });
    form.append("login", email);
    form.append("password", password);
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    const response = await postLogin(form);
    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      setIsCTALoading(false);
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Incorrect password",
        loginExperience: parentWindow,
      });
      setHasFailedLogin(true);
      setErrorMessage("Sorry, that password does not match this account.");
      return;
    }
    setIsCTALoading(false);
    trackLoginSuccess({
      emailEntered: email,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
    });
    onComplete(response.url);
  }
  async function handleSendMagicLink() {
    const isValid = email && validateEmail(email);
    if (!isValid) {
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Invalid email",
        loginExperience: parentWindow,
      });
      setEmailErrorMessage("Please enter a valid email");
      return false;
    }

    setIsCTALoading(true);

    const response = await postSendMagicLink(email);
    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      setIsCTALoading(false);
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.MAGIC_LINK,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: errorMessage,
        loginExperience: parentWindow,
      });
      setEmailErrorMessage(
        "We didn't find an account for that email address. Try a different email or create a new account."
      );
      return;
    }

    setIsCTALoading(false);
    setShowMagicLinkSuccessMessage(true);
    trackHeapEvent("magic-login-link-sent");
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    setEmailErrorMessage("");
  }

  const formContent = (
    <>
      <div className="flex flex-col gap-4">
        <LabeledInput
          type="email"
          name="email"
          label="Work email address"
          placeholder="name@work.com"
          data-testid="email-input"
          required
          value={email}
          onChange={handleChange}
          size="md"
          className="analytics-email-login-modal-email-input w-full"
          initialVariant={
            emailErrorMessage
              ? LabeledInputVariants.ERROR
              : LabeledInputVariants.DEFAULT
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void handleLogin();
            }
          }}
          message={emailErrorMessage}
          autoFocus
        />
        {isPasswordLogin && (
          <>
            <PasswordInput
              password={password}
              setPassword={setPassword}
              errorMessage={errorMessage}
              label="Enter your password"
              placeholder="Your password"
              showForgotPassword
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  void handleLogin();
                }
              }}
              className="grid gap-2"
            />
            <SubmitButton
              isCTALoading={isCTALoading}
              handleSubmit={handleLogin}
              ctaText="Log in"
              btnClassName={btnClassName}
              dataTestId="email-login-button"
            />
            {parentWindow === WindowType.Modal && (
              <Typography size="sm" variant="body">
                Or we can{" "}
                <Link
                  className="analytics-switch-to-magic-link-login"
                  size="sm"
                  data-testid="select-email-login"
                  onClick={() => setIsPasswordLogin(false)}
                >
                  email you a magic link
                </Link>{" "}
                for a password-free sign in.
              </Typography>
            )}
            {parentWindow === WindowType.Page && hasFailedLogin && (
              <Typography size="sm" variant="body">
                Having trouble? Login with a{" "}
                <Link
                  className="analytics-switch-to-magic-link-login"
                  size="sm"
                  data-testid="select-email-login"
                  onClick={() => setIsPasswordLogin(false)}
                >
                  link delivered to your email inbox
                </Link>{" "}
                instead.
              </Typography>
            )}
          </>
        )}
        {!isPasswordLogin && (
          <>
            <SubmitButton
              isCTALoading={isCTALoading}
              handleSubmit={handleSendMagicLink}
              ctaText="Log in with email"
              btnClassName={btnClassName}
              dataTestId="magic-link-button"
            />
            <Typography size="sm" variant="body">
              We’ll email you a magic link for a password-free sign in. Or you
              can{" "}
              <Link
                className="analytics-switch-to-password-login"
                size="sm"
                data-testid="select-email-login"
                onClick={() => setIsPasswordLogin(true)}
              >
                log in with password instead
              </Link>
              .
            </Typography>
          </>
        )}
        <div className="flex flex-col-reverse gap-4">
          <SocialLoginButton
            className="analytics-email-login-modal-microsoft button-exists peer"
            provider={SocialProvider.microsoft}
            onComplete={onComplete}
            parentWindow={parentWindow}
          />
          <SocialLoginButton
            className="analytics-email-login-modal-google button-exists peer"
            provider={SocialProvider.google}
            onComplete={onComplete}
            parentWindow={parentWindow}
          />{" "}
          {/* Conditionally render the --or-- section, only if at least one of the social buttons exists */}
          <div className="hidden peer-[.button-exists]:flex text-center items-center w-full before:bg-cp-lapis-100 before:flex-1 before:h-px after:bg-cp-lapis-100 after:flex-1 after:h-px">
            <Typography className="mx-4" color="subtle">
              or
            </Typography>
          </div>
        </div>
      </div>
      <Typography size="sm" variant="body">
        Don&apos;t have an account?{" "}
        <Link
          size="sm"
          variant="body"
          emphasis={false}
          onClick={goToSignupPageOrModal}
          newWindow={false}
        >
          Sign up
        </Link>
      </Typography>
    </>
  );

  return (
    <div className="grid gap-8 text-center">
      {showMagicLinkSuccessMessage ? (
        <MagicLinkSuccessMessage
          parentWindow={parentWindow}
          switchToManualLogin={() => {
            setIsPasswordLogin(true);
            setShowMagicLinkSuccessMessage(false);
          }}
        />
      ) : (
        formContent
      )}
    </div>
  );
}

function MagicLinkSuccessMessage({
  parentWindow,
  switchToManualLogin,
}: {
  parentWindow: WindowType;
  switchToManualLogin: () => void;
}): JSX.Element {
  return (
    <div
      className="grid gap-8 text-center"
      data-testid="magic-link-success-message"
    >
      {parentWindow === WindowType.Page && (
        <Typography
          emphasis
          size="sm"
          variant="headline"
          color="brand.boldest.enabled"
        >
          Login email sent!
        </Typography>
      )}
      <Typography size="md" variant="body" color="neutral.boldest.enabled">
        Please check your email for a link, and click the link to log in. You
        can close this tab.
      </Typography>
      <img
        src={paperPlaneSparkles}
        className="block w-36 h-36 mx-auto"
        alt="Letter in an envelope"
      />
      <Typography size="sm" variant="body">
        Not seeing the email?{" "}
        <Link
          className="analytics-post-submit-switch-to-password-login"
          size="sm"
          variant="body"
          onClick={switchToManualLogin}
        >
          Click here to log in with password.
        </Link>
      </Typography>
    </div>
  );
}
