import { Button, ButtonThemes, Link, Typography } from "../../library";
import Modal from "../../shared/Modal/Modal";
import { goToURL } from "../../utils";

interface ArchiveProjectSuccessModalProps {
  showEntityContractsView?: boolean;
  hideModal: () => void;
}

export default function ArchiveProjectSuccessModal({
  hideModal,
  showEntityContractsView = false,
}: ArchiveProjectSuccessModalProps) {
  const ctas = (
    <div className="flex flex-col gap-4 items-center">
      {showEntityContractsView ? (
        <div className="inline-flex gap-4">
          <Button
            theme={ButtonThemes.SECONDARY_DARK}
            onClick={() => {
              goToURL("/projects", {}, false);
            }}
            className="analytics-archive-project-success-goToProject"
          >
            Back to all projects
          </Button>
          <Button
            theme={ButtonThemes.PRIMARY_DARK}
            onClick={() => {
              goToURL("/entity-contracts#piggybacked-contracts", {}, false);
            }}
            className="analytics-archive-project-success-goToEntityContracts"
          >
            View piggybacked contracts
          </Button>
        </div>
      ) : (
        <Button
          theme={ButtonThemes.PRIMARY_DARK}
          onClick={() => {
            goToURL("/projects", {}, false);
          }}
          className="analytics-archive-project-success-goToProject"
        >
          Back to all projects
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Success! Your project is archived"
      ctaSection={ctas}
      showLogoHeader={false}
      className="analytics-archive-project-success-modal"
    >
      {showEntityContractsView ? (
        <Typography>
          Your project is now archived. You can find the contract you used in
          your list of piggybacked contracts.
        </Typography>
      ) : (
        <Typography>
          You can find this archived project on the{" "}
          <Link href="/projects">Projects</Link> page.
        </Typography>
      )}
    </Modal>
  );
}
