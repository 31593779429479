import clsx from "clsx";
import { Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";
interface ProjectDescriptionProps {
  description: string;
  startedAt: string;
  onClickShowEditModal: () => void;
}

export default function ProjectDescription({
  description,
  startedAt,
  onClickShowEditModal,
}: ProjectDescriptionProps) {
  const startedAtText = (
    <Typography color="neutral.bold.enabled" size="sm" variant="meta">
      Started {startedAt}
    </Typography>
  );

  return (
    <div
      className={clsx(
        "flex flex-col gap-3 border border-solid rounded-2xl p-6",
        borderColorClass.brand.subtle.enabled
      )}
    >
      <Typography
        size="sm"
        emphasis
        variant="headline"
        color="brand.boldest.enabled"
      >
        Project Overview
      </Typography>
      {description ? (
        <Typography color="neutral.boldest.enabled" size="sm">
          {description}
        </Typography>
      ) : (
        <Typography
          className="cursor-pointer"
          color="brand.bold.enabled"
          size="sm"
          underline
          onClick={onClickShowEditModal}
        >
          Add description
        </Typography>
      )}
      {startedAtText}
    </div>
  );
}
