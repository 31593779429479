import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import useRequestID from "../../../hooks/useRequestID";
import { approvedSourcesOnlyFilterState } from "../../../jotai/searchFilters";
import {
  buyerProfileState,
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userEmailVerifiedState,
  userInitializedState,
} from "../../../jotai/user";
import {
  CardContainer,
  CardContainerVariant,
  PageSection,
} from "../../../library";
import type { SupplierProfileData } from "../../../pages/Supplier";
import AccountVerificationBanner from "../../../shared/AccountVerificationBanner";
import AnchorRefLinks, {
  type AnchorRefLink,
} from "../../../shared/AnchorRefLinks";
import ProductList from "../../../shared/ProductList";
import RecommendationCarousel from "../../../shared/RecommendationCarousel/RecommendationCarousel";
import { PageType, ProfileType } from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";
import {
  referredFromSearchEngine,
  trackClickSupplierRecommendation,
  trackViewSupplierRecommendations,
} from "../../../utils/tracking";
import ScannableSupplierAbout from "./ScannableSupplierAbout";
import ScannableSupplierContractList from "./ScannableSupplierContractList";
import ScannableSupplierDetails from "./ScannableSupplierDetails";
import { PAGE_WIDTH_CLASS } from "./constants";
import type { TrackContactSupplierClickFn } from "./types";

interface ScannableSupplierProfileProps {
  supplier: SupplierProfileData;
  hasClaimedSupplier: boolean;
  ownsSupplier: boolean;
  renderSupplierCTA: () => JSX.Element;
  trackClickContactSupplier: TrackContactSupplierClickFn;
  offeringsList: string[];
}

export default function ScannableSupplierProfile({
  supplier,
  hasClaimedSupplier,
  ownsSupplier,
  renderSupplierCTA,
  trackClickContactSupplier,
  offeringsList,
}: ScannableSupplierProfileProps) {
  const {
    id,
    name,
    about,
    supplier_contact: { website },
    contracts_count,
    handle,
    supplier_compliance,
    active_agreements,
    service_area_data,
    logo_url,
    sat_url,
    has_products,
    summary,
  } = supplier;
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const profileType = useAtomValue(profileTypeState);
  const isInitialized = useAtomValue(userInitializedState);
  const isEmailVerified = useAtomValue(userEmailVerifiedState);
  const isUnverified = hasClaimedSupplier && !isEmailVerified;
  const isPending = hasClaimedSupplier && isEmailVerified && !ownsSupplier;
  const userSupplier = useAtomValue(supplierState);
  const showProductListAndCta =
    userSupplier.handle === handle || profileType !== ProfileType.SUPPLIER;

  const contractsRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const simliarSuppliersRef = useRef<HTMLDivElement>(null);

  const requestID = useRequestID();

  const { governmentAgency } = useAtomValue(buyerProfileState);
  const setApprovedSourcesOnlyFilter = useSetAtom(
    approvedSourcesOnlyFilterState
  );

  useEffect(() => {
    setApprovedSourcesOnlyFilter(
      !!governmentAgency && !!governmentAgency?.showOnlyApprovedSources
    );
  }, [setApprovedSourcesOnlyFilter, governmentAgency]);

  const anchorLinkTrackingParams = {
    supplierId: supplier.id,
    supplierHandle: supplier.handle,
    pageType: PageType.SUPPLIER,
  };

  const links: AnchorRefLink[] = [
    {
      pageId: supplier.id.toString(),
      name: `Contracts (${contracts_count})`,
      ref: contractsRef,
      className: "analytics-supplier-contracts-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: contractsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Products",
      ref: productsRef,
      className: "analytics-supplier-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: productsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Supplier details",
      ref: detailsRef,
      className: "analytics-supplier-details-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: detailsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Similar suppliers",
      ref: simliarSuppliersRef,
      className: "analytics-supplier-similar-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !isAuthenticated,
    },
  ];

  return (
    <PageSection className="flex flex-col mt-12 mb-24">
      {isInitialized &&
        (isUnverified || isPending) &&
        !referredFromSearchEngine() && (
          <div className="mb-8 w-full">
            <AccountVerificationBanner />
          </div>
        )}
      <div
        className={clsx(
          "w-full grid gap-y-6 grid-cols-12 md:gap-x-10 md:my-0 bg-white",
          PAGE_WIDTH_CLASS
        )}
      >
        <div className="col-span-full flex flex-col gap-y-8 md:col-span-8">
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit flex flex-col gap-6"
          >
            <ScannableSupplierAbout
              supplierId={id}
              supplierName={name}
              supplierHandle={handle}
              supplierActiveAgreements={active_agreements}
              supplierCompliance={supplier_compliance}
              supplierServiceArea={service_area_data}
              supplierAbout={about}
              supplierSummary={summary}
              offeringsList={offeringsList}
              logoUrl={logo_url}
              satUrl={sat_url}
              showSaveToProject={
                isAuthenticated && profileType === ProfileType.BUYER
              }
              trackClickContactSupplier={trackClickContactSupplier}
            />
          </CardContainer>
          <AnchorRefLinks links={links} />
          {contracts_count > 0 && (
            <CardContainer
              ref={contractsRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit flex flex-col gap-4 scroll-m-[9.25rem]"
            >
              <ScannableSupplierContractList
                supplierId={id}
                supplierName={name}
                supplierHandle={handle}
              />
            </CardContainer>
          )}
          {has_products && showProductListAndCta && (
            <CardContainer
              ref={productsRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
            >
              <ProductList
                supplierHandle={supplier.handle}
                supplierName={supplier.name}
                supplierId={supplier.id}
                onSupplierPage
              />
            </CardContainer>
          )}
          <ScannableSupplierDetails
            className="scroll-m-[9.25rem]"
            ref={detailsRef}
            supplierId={id}
            supplierHandle={handle}
            supplierWebsite={website}
            activeAgreements={active_agreements}
            diversityCertifications={
              supplier_compliance.diversityCertifications
            }
            publicEntitiesServed={supplier_compliance.publicEntitiesServed}
          />
        </div>
        {showProductListAndCta && (
          <div className="block col-span-full lg:hidden empty:hidden">
            {renderSupplierCTA()}
          </div>
        )}
        {showProductListAndCta && (
          <div className="hidden lg:block col-span-full lg:col-span-4 empty:hidden">
            <div className="sticky top-24">{renderSupplierCTA()}</div>
          </div>
        )}
        {isAuthenticated && (
          <RecommendationCarousel
            ref={simliarSuppliersRef}
            className="col-span-full pt-8 border-solid border-cp-white-200 border-t"
            id={handle}
            onRender={(suppliers) => {
              if (suppliers?.length) {
                trackViewSupplierRecommendations({
                  supplierId: supplier.id,
                  supplierName: supplier.name,
                  supplierHandle: supplier.handle,
                  requestId: requestID,
                  numRecommendations: suppliers.length,
                  supplierRecommendations: suppliers
                    .map((s) => s.supplier.handle)
                    .join(","),
                  proSupplierRecommendations: suppliers
                    .filter((s) =>
                      supplierHasFeature(
                        s.supplierAgreement.activeAgreements,
                        "analyticsTrackIsPro"
                      )
                    )
                    .map((s) => s.supplier.handle)
                    .join(","),
                });
              }
            }}
            onClick={({ supplier: recSupplier }) => {
              trackClickSupplierRecommendation({
                supplierId: supplier.id,
                supplierName: supplier.name,
                supplierHandle: supplier.handle,
                requestId: requestID,
                recommendedSupplierId: recSupplier.supplier.id,
                recommendedSupplierHandle: recSupplier.supplier.handle,
                recommendedProSupplier: supplierHasFeature(
                  recSupplier.supplierAgreement.activeAgreements,
                  "analyticsTrackIsPro"
                ),
              });
            }}
          />
        )}
      </div>
    </PageSection>
  );
}
