import * as yup from "yup";

import type { FormFieldProps } from "./types";

import { SUPPLIER_PROFILE_TYPES } from "../../utils/constants";
import DropdownField from "./DropdownField";

const supplierRoleOptions = [
  {
    key: "0",
    value: SUPPLIER_PROFILE_TYPES.MARKETING,
    label: "Marketing",
  },
  {
    key: "1",
    value: SUPPLIER_PROFILE_TYPES.SALES,
    label: "Sales",
  },
  {
    key: "2",
    value: SUPPLIER_PROFILE_TYPES.HEAD_OF_PUBLIC_SECTOR,
    label: "Head of public sector",
  },
  {
    key: "3",
    value: SUPPLIER_PROFILE_TYPES.BID_MANAGER,
    label: "Bid manager",
  },
  {
    key: "4",
    value: SUPPLIER_PROFILE_TYPES.REVENUE_OPERATIONS,
    label: "Revenue operations",
  },
  {
    key: "5",
    value: SUPPLIER_PROFILE_TYPES.EXECUTIVE,
    label: "Executive",
  },
  {
    key: "6",
    value: SUPPLIER_PROFILE_TYPES.OTHER,
    label: "Other",
  },
];

interface RoleFieldProps extends FormFieldProps {
  placeholder: NonNullable<FormFieldProps["placeholder"]>;
  options: NonNullable<FormFieldProps["options"]>;
}

export const SUPPLIER_ROLE_FIELDS: RoleFieldProps[] = [
  {
    name: "supplierRole",
    options: supplierRoleOptions,
    label: "What is your role at your company?",
    placeholder: "Select your role",
    component: DropdownField,
    dataTestId: "supplier-role",
    validate: yup.string().required("This field is required."),
    className: "w-full !max-h-40",
  },
];
