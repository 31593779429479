import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";

import type { ElementType } from "react";
import { Badge, BoldedText } from "../../library";

interface SearchAutocompleteOptionsProps {
  title: string;
  Icon?: ElementType;
  results?: string[];
  analyticsClass: string;
  totalOptions: string[];
  activeIndex: number;
  handleSubmit: (result: string, index: number, searchType: string) => void;
  searchQuery: string;
  analyticsType: string;
}

export default function SearchAutocompleteOptions({
  title,
  Icon,
  results = [],
  analyticsClass,
  totalOptions,
  activeIndex,
  handleSubmit,
  searchQuery,
  analyticsType,
}: SearchAutocompleteOptionsProps) {
  if (results.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="text-cp-meta-sm mb-1.5 px-6 font-semibold text-cp-midnight-300 mt-4">
        {Icon && <Icon className="mr-2 w-6 h-6 text-cp-neutral-palette-800" />}
        {title}
      </div>
      {results.map((result, i) => (
        <div
          key={result}
          className={clsx(
            "py-1.5 hover:bg-cp-violet-100 cursor-pointer",
            analyticsClass,
            {
              "bg-cp-violet-100": totalOptions[activeIndex] === result,
            }
          )}
          onClick={() => {
            handleSubmit(result, i, analyticsType);
          }}
        >
          <Badge
            className="px-6"
            Icon={SearchRoundedIcon}
            iconClass="text-cp-black-100"
          >
            <BoldedText text={result} highlight={searchQuery} />
          </Badge>
        </div>
      ))}
    </div>
  );
}
