/*
 * A basic card component representing a clickable link with a shadow on hover.
 */
import clsx from "clsx";
import type { ReactNode } from "react";

interface ClickableCardProps {
  className?: string;
  onClick?: (e?: unknown) => void;
  onClickCapture?: (e?: unknown) => void;
  href?: Maybe<string>;
  children?: ReactNode;
  openInNewTab?: boolean;
}

export default function ClickableCard({
  className,
  onClick = () => {},
  onClickCapture = () => {},
  href,
  children,
  openInNewTab = false,
  ...rest
}: ClickableCardProps) {
  let targetProps = {};
  if (openInNewTab) {
    targetProps = { target: "_blank", rel: "noreferrer" };
  }

  return (
    <a
      href={href || undefined}
      onClick={onClick}
      onClickCapture={onClickCapture}
      className={clsx(
        "rounded-lg border border-solid border-cp-white-200 hover:shadow-[0_0_2px_rgba(0,0,0,0.25),0_1px_10px_#E5EAF2] cursor-pointer",
        className
      )}
      {...targetProps}
      {...rest}
    >
      {children}
    </a>
  );
}
