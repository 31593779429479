import {
  Field,
  Form,
  Formik,
  type FormikHelpers,
  type FormikValues,
} from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { userEmailState } from "../../../jotai/user";
import { Button } from "../../../library";
import Typography from "../../../library/Typography";
import {
  LoginType,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../../utils/enums";
import { trackSignupFlowFailure } from "../../../utils/tracking";
import type { WindowType } from "../types";

import { supplierFieldSignupState } from "../../../jotai/signup";
import type { Validate } from "../../../library/form/types";
import { getParams } from "../../../utils";
import yup from "../../../utils/yupPhone";
import useSubmitSupplierInfo from "../useSubmitSupplierInfo";
import StepCircle from "./StepCircle";
import {
  REDESIGNED_NAME_FIELD,
  REDESIGNED_PHONE_FIELD,
  REDESIGNED_SUPPLIER_ROLE_FIELD,
} from "./constants";

interface RedesignedSupplierSignupFormProps {
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
}

export default function RedesignedSupplierSignupForm({
  onComplete,
  parentWindow,
}: RedesignedSupplierSignupFormProps) {
  const email = useAtomValue(userEmailState);
  const supplierFromSignup = useAtomValue(supplierFieldSignupState);
  const urlParams = getParams();
  const isSupplierInvite =
    !!urlParams.supplierInvite &&
    !!urlParams.supplierHandle &&
    !!urlParams.supplierName &&
    !!urlParams.email;

  const [loading, formErrorMessage, submitSupplierInfo] = useSubmitSupplierInfo(
    {
      email,
      onComplete,
      parentWindow,
      isSupplierInvite,
    }
  );

  const validationSchema = useMemo(() => {
    return [
      REDESIGNED_NAME_FIELD,
      REDESIGNED_PHONE_FIELD,
      REDESIGNED_SUPPLIER_ROLE_FIELD,
    ].reduce((schema: Record<string, Validate>, { name, validate }) => {
      if (validate) schema[name] = validate;
      return schema;
    }, {});
  }, []);

  function trackInvalidForm(error: string) {
    trackSignupFlowFailure({
      emailEntered: email,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  const handleSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (!supplierFromSignup) {
      trackInvalidForm("Expected supplierFromSignup to exist");
    }
    const [firstName, lastName] = values.name.split(" ");
    submitSupplierInfo(
      {
        ...values,
        supplier: supplierFromSignup,
        firstName,
        lastName,
      },
      helpers
    );
  };
  // TODO: Use entrypoint to customize copy for 3rd step
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const stepFromEntrypoint = "View contract";
  const steps = ["Sign up", "Create account", stepFromEntrypoint];

  return (
    <div className="grid gap-6">
      <div className="flex gap-3">
        {steps.map((text, index) => (
          <StepCircle key={text} text={text} index={index} />
        ))}
      </div>
      <div className="grid gap-4">
        <div className="grid gap-1">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Let's set up Pavilion for you
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Tell us about yourself so we can customize your experience.
          </Typography>
        </div>
        <div className="grid gap-2">
          <Formik
            enableReinitialize
            validateOnBlur
            initialValues={
              {
                name: "",
                phone: "",
                supplierRole: null,
              } as FormikValues
            }
            onSubmit={handleSubmit}
            validationSchema={yup.object(validationSchema)}
          >
            {({ validateForm }) => (
              <Form className="grid gap-4">
                <Field {...REDESIGNED_NAME_FIELD} editable />
                <Field {...REDESIGNED_PHONE_FIELD} editable />
                <Field {...REDESIGNED_SUPPLIER_ROLE_FIELD} editable />
                <Button
                  className="w-full analytics-email-signup-modal-cta"
                  type="submit"
                  disabled={loading}
                  onClick={() =>
                    validateForm().then((error) => {
                      if (error && Object.keys(error).length > 0) {
                        trackInvalidForm(JSON.stringify(error));
                      }
                    })
                  }
                >
                  Create account
                </Button>
              </Form>
            )}
          </Formik>
          {formErrorMessage && (
            <Typography size="sm" color="destructive.default.primary.enabled">
              {formErrorMessage}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
