import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { FILTER_DURATIONS } from "../shared/SearchPage/SearchResults/constants";
import {
  ContractDocumentsFilterOptions,
  type ExpirationFilterType,
  ExpirationStatuses,
  SupplierLocationFilterOptions,
} from "../utils/enums";
import { trackSearchFilterMatches } from "../utils/tracking";
import { contractSearchAnalyticsParamsState } from "./search";

export const approvedSourcesOnlyFilterState = atom(false);

export const expirationFilterState = atom<ExpirationFilterType>(
  ExpirationStatuses.ALL_ACTIVE
);

export const contractSourceFilterState = atom<string[]>([]);

export const diversityCertificationsFilterState = atom(false);

export const singleAwardOnlyFilterState = atom(false);

export const supplierLocationFilterState = atom(
  SupplierLocationFilterOptions.ALL_SUPPLIERS
);

export const contractDocumentsFilterState = atom(
  ContractDocumentsFilterOptions.ALL_CONTRACTS
);

export const numFiltersAppliedState = atom<number>((get) => {
  const expirationFilter = get(expirationFilterState);
  const contractSourceFilter = get(contractSourceFilterState);
  const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
  const diversityCertificationsFilter = get(diversityCertificationsFilterState);
  const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
  const supplierLocationFilter = get(supplierLocationFilterState);
  const contractDocumentsFilter = get(contractDocumentsFilterState);

  let filters = contractSourceFilter.length;
  if (diversityCertificationsFilter) filters++;
  if (approvedSourcesOnlyFilter) filters++;
  if (singleAwardOnlyFilter) filters++;
  if (FILTER_DURATIONS[expirationFilter]) filters++;
  if (supplierLocationFilter !== SupplierLocationFilterOptions.ALL_SUPPLIERS) {
    filters++;
  }
  if (
    contractDocumentsFilter !== ContractDocumentsFilterOptions.ALL_CONTRACTS
  ) {
    filters++;
  }
  return filters;
});

interface IContractSourcesState {
  agencies: string[];
  localAgencies: string[];
  cooperatives: string[];
}

const contractSourceCooperativesState = atom<string[]>([]);

const contractSourceLocalAgenciesState = atom<string[]>([]);

const contractSourceAgenciesState = atom<string[]>([]);

export const contractSourcesState = atom(
  (get) => ({
    agencies: get(contractSourceAgenciesState),
    cooperatives: get(contractSourceCooperativesState),
    localAgencies: get(contractSourceLocalAgenciesState),
  }),
  (_get, set, value: IContractSourcesState) => {
    const { agencies, cooperatives, localAgencies } = value;
    set(contractSourceAgenciesState, agencies);
    set(contractSourceCooperativesState, cooperatives);
    set(contractSourceLocalAgenciesState, localAgencies);
  }
);

const matchedSearchResultCountFamily = atomFamily((initialCount: number) => {
  const baseAtom = atom(initialCount);

  const derivedAtom = atom(
    (get) => get(baseAtom),
    async (get, set, newValue: number) => {
      const oldValue = get(baseAtom);
      if (newValue === -1 || newValue === oldValue) return;

      set(baseAtom, newValue);

      const analyticsParams = get(contractSearchAnalyticsParamsState);
      const diversityCertificationsFilter = get(
        diversityCertificationsFilterState
      );
      const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
      const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
      const expirationFilter = get(expirationFilterState);
      const contractSourceFilter = get(contractSourceFilterState);
      const contractDocumentsFilter = get(contractDocumentsFilterState);

      trackSearchFilterMatches({
        requestID: analyticsParams.requestID || "",
        matches: newValue,
        expirationFilter,
        contractSourceFilter,
        contractDocumentsFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        singleAwardOnlyFilter,
      });
    }
  );

  return derivedAtom;
});

export const matchedSearchResultCountState = matchedSearchResultCountFamily(-1);
