import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import PasswordResetEmailForm from "../../components/PasswordReset/PasswordResetEmailForm";
import { getDOMAnchorById } from "../../utils";
import Page from "../Page/Page";

const container = getDOMAnchorById("password-reset-email");
if (container) {
  const root = createRoot(container);

  // Parse errors from the backend
  let emailErrorMessage = "";
  try {
    const emailErrors = JSON.parse(
      _get(container, "dataset.emailErrors") || ""
    ).map((error: { message: string }) => error.message);
    emailErrorMessage =
      emailErrors && emailErrors.length > 0 ? emailErrors.join(",") : "";
  } catch {
    emailErrorMessage = "";
  }

  root.render(
    <Page
      searchSource="password-reset-email-header-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <PasswordResetEmailForm emailError={emailErrorMessage} />
    </Page>
  );
}
