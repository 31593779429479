import clsx from "clsx";
import type { ReactNode } from "react";

import { Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";

interface SupplierLockedFieldProps {
  disabled?: boolean;
  children: ReactNode;
}
export default function SupplierLockedField({
  disabled = false,
  children,
}: SupplierLockedFieldProps) {
  if (disabled) {
    return (
      <Typography
        size="sm"
        color="accent.persimmon.enabled"
        className={clsx(
          "rounded-3 px-4 py-2",
          bgColorClass.accent.persimmon.subtle
        )}
      >
        This field requires the user to be verified before editing.
      </Typography>
    );
  }

  return children;
}
