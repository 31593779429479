import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";
import { type KeyboardEvent, useEffect, useState } from "react";
import { Badge, Typography } from "../../../library";
import {
  bgColorClass,
  borderColorClass,
  textColorClass,
} from "../../../utils/colors";

interface SimpleSearchBarProps {
  placeholder: string;
  query: string;
  ariaLabel: string;
  search: (query: string) => void;
}
/**
 * A simpler search bar without autocomplete dropdown. Can be used
 * for specific use cases outside of general contract search.
 */
export default function SimpleSearchBar({
  placeholder,
  query,
  ariaLabel,
  search,
}: SimpleSearchBarProps) {
  const [text, setText] = useState(query);
  function handleQueryKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      search(text);
    }
  }

  useEffect(() => {
    setText(query);
  }, [query]);

  return (
    <div className="flex">
      <div
        className={clsx(
          "flex items-center bg-white border border-solid border-r-0 rounded-l-3",
          borderColorClass.brand.subtle.enabled
        )}
      >
        <Typography
          size="sm"
          component="div"
          className="relative items-center justify-between py-2 px-4 w-64"
        >
          <div className="flex items-center">
            <input
              autoComplete="off"
              name="query"
              placeholder={placeholder}
              required
              className="p-0 w-full rounded-none placeholder:font-light"
              onKeyUp={handleQueryKeyUp}
              onChange={(e) => setText(e.target.value)}
              value={text}
              aria-label={ariaLabel}
            />
          </div>
        </Typography>
      </div>
      <button
        aria-label="Search"
        className={clsx(
          "py-2 px-3 flex items-center justify-center rounded-r-3",
          bgColorClass.brand.subtle.enabled
        )}
        onClick={() => search(text)}
      >
        <Badge
          Icon={SearchRoundedIcon}
          size="md"
          iconClass={textColorClass.brand.boldest.enabled}
          includeMargin={false}
        />
      </button>
    </div>
  );
}
