import { useEffect, useState } from "react";
import type { TrackContractClickWithinSolicitationCarouselFn } from "../components/SolicitationPage/types";
import type { BaseContract } from "../generated";
import type { ContractSolicitationDetails } from "../pages/Contract";
import { expirationText } from "../shared/ContractBase";
import { getParams } from "../utils";
import { pageNavigationSourceTypes } from "../utils/enums";
import { supplierHasFeature } from "../utils/featureManagement";
import {
  addLinkShareTrackingParams,
  trackContractClickWithinSolicitation,
  trackPossibleSharedLinkVisit,
  trackViewSolicitationContract,
} from "../utils/tracking";

interface UseTrackContractPageViewProps {
  contractId: string;
  title: string;
  contractDetails: ContractSolicitationDetails;
  solicitationId?: string;
  numSuppliersOnSolicitation: number;
  buyerLeadAgency: string;
  cooperativeAffiliation: string;
  sourceKey: string;
  pageType: Maybe<string>;
  otherContracts: BaseContract[];
  query: string;
}
export const useTrackContractPageView = ({
  contractId,
  title,
  contractDetails,
  solicitationId,
  numSuppliersOnSolicitation,
  buyerLeadAgency,
  cooperativeAffiliation,
  sourceKey,
  pageType,
  otherContracts,
  query,
}: UseTrackContractPageViewProps) => {
  const [requestID, setRequestID] = useState<string>("");

  const params = getParams();
  const searchSource = (params.searchSource || "") as string;
  const searchType = (params.searchType || "") as string;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once when the page first renders to track viewing.
  useEffect(() => {
    let pageNavigationSource =
      params.pageNavigationSource?.toString() ||
      pageNavigationSourceTypes.DIRECT;
    if (params.tuid || params.tsid) {
      trackPossibleSharedLinkVisit({
        originatingUserId: params.tuid as string,
        originatingSessionId: params.tsid as string,
        contractId,
        fileId: params.fileID as string,
      });
      pageNavigationSource = (params.pageNavigationSource ||
        pageNavigationSourceTypes.REFERRED) as string;
    } else {
      addLinkShareTrackingParams();
    }

    if (params.requestID) {
      setRequestID(params.requestID as string);
    }

    trackViewSolicitationContract({
      contractTitle: title,
      supplierId: contractDetails.supplier.id,
      supplierName: contractDetails.supplier.displayName,
      supplierHandle: contractDetails.supplier.handle,
      contractId,
      solicitationId,
      numSuppliersOnSolicitation,
      numDocs: contractDetails.file_information?.length,
      verifiedContacts: !!contractDetails.supplier_contact?.full_name,
      hasContractOfferings: contractDetails.offerings?.length > 0,
      expirationDate: expirationText(contractDetails.expiration_date, {
        showDateOnly: true,
        showUnknown: true,
      }),
      searchQuery: query,
      queryZip: params.queryZip as string | undefined, // Not using useUserZip since it is async and could potentially be empty + params should have the accurate zip code
      contractType: pageType,
      buyerLeadAgency,
      cooperativeAffiliation,
      sourceKey,
      searchType,
      pageNavigationSource,
      searchSource,
      requestID: params.requestID?.toString(),
      // Track supplier impressions from other suppliers.
      displayedOtherSuppliersOnContract: otherContracts
        .map((c) => c.supplier.handle)
        .join(","),
      displayedProSuppliersOnContract: otherContracts
        .filter((c) =>
          supplierHasFeature(
            c.supplierAgreement.activeAgreements,
            "analyticsTrackIsPro"
          )
        )
        .map((c) => c.supplier.handle)
        .join(","),
    });
  }, []);

  const trackContractClick: TrackContractClickWithinSolicitationCarouselFn = ({
    contractId: contractIdClicked,
    similarPublicEntities,
    ...rest
  }) => {
    trackContractClickWithinSolicitation({
      searchQuery: query,
      contractId,
      solicitationId,
      contractIdClicked,
      hasSimilarEntities: !!similarPublicEntities?.length,
      numEntitiesInState:
        similarPublicEntities?.filter(({ same_state }) => same_state).length ||
        0,
      numEntitiesInCategory:
        similarPublicEntities?.filter(({ same_category }) => same_category)
          .length || 0,
      ...rest,
    });
  };
  return { trackContractClick, requestID };
};
