/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `TIME_SENSITIVE_PURCHASE` - This is a time-sensitive purchase
 * * `INSTALLATION_OR_SERVICES` - I need installation or other services
 * * `SPECIFIC_BRAND` - I need a specific brand
 * * `PREFER_LOCAL_SUPPLIERS` - I prefer local suppliers
 * * `PRICE_CONSTRAINTS` - I have pricing constraints
 * * `MEET_DIVERSITY_CRITERIA` - Suppliers must meet diversity criteria
 * * `MEET_INSURANCE_REQUIREMENTS` - Supplier must meet insurance requirements
 */
export enum AdditionalRequirementsEnum {
    TIME_SENSITIVE_PURCHASE = 'TIME_SENSITIVE_PURCHASE',
    INSTALLATION_OR_SERVICES = 'INSTALLATION_OR_SERVICES',
    SPECIFIC_BRAND = 'SPECIFIC_BRAND',
    PREFER_LOCAL_SUPPLIERS = 'PREFER_LOCAL_SUPPLIERS',
    PRICE_CONSTRAINTS = 'PRICE_CONSTRAINTS',
    MEET_DIVERSITY_CRITERIA = 'MEET_DIVERSITY_CRITERIA',
    MEET_INSURANCE_REQUIREMENTS = 'MEET_INSURANCE_REQUIREMENTS',
}
