/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `HIGH` - High
 * * `LOW` - Low
 * * `NEGATIVE` - Negative
 */
export enum SupplierServiceAreaDataConfidenceEnum {
    HIGH = 'HIGH',
    LOW = 'LOW',
    NEGATIVE = 'NEGATIVE',
}
