import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import {
  LandingPage as LandingPageComponent,
  type PageData,
} from "../components/LandingPage/LandingPage";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface LandingPageProps extends AuthPageProps {
  page_type: string;
  data: PageData[];
}

export default function LandingPage({
  page_type,
  is_authenticated,
  profile_type,
  active_agreements,
  data,
}: LandingPageProps) {
  return (
    <Page
      searchSource={`landing-page-header-search-bar-${page_type}`}
      pageType={PageType.LANDING_PAGE}
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <LandingPageComponent page={data[0]} isSSR />
    </Page>
  );
}

const container = getDOMAnchorById("landing-page");
const data = _get(container, "dataset.landingpage");
if (data) {
  const page = JSON.parse(data)[0];
  const pageType = page.page_type;
  const root = createRoot(container as HTMLElement);
  root.render(
    <Page
      searchSource={`landing-page-header-search-bar-${pageType}`}
      pageType={PageType.LANDING_PAGE}
      profileType={null}
      activeAgreements={null}
    >
      <LandingPageComponent page={page} isSSR={false} />
    </Page>
  );
}
