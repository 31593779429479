/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `APPROVED` - Approved
 * * `PENDING` - Pending
 * * `REJECTED` - Rejected
 */
export enum ApprovedSourceStatusEnum {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}
