import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import clsx from "clsx";
import type { MouseEventHandler, ReactNode } from "react";

import Button, { type ButtonProps } from "../../Button";
import Link from "../../Link";
import Typography, { type TypographyProps } from "../../Typography";

export interface MenuItemProps extends TypographyProps {
  key?: string;
  label: string;
  onClick?: MouseEventHandler;
  className?: string;
  href?: string;
  analyticsClassName?: string;
}

export type Align = "left" | "right";

export const itemsClassName = (
  align: Align,
  responsive?: boolean,
  className?: string
) =>
  clsx(
    "absolute border border-solid border-cp-violet-100 bg-white shadow-lg z-2",
    className,
    {
      "left-0 sm:left-auto right-0": align === "right" && responsive,
      "right-0": align === "right" && !responsive,
      "right-0 sm:right-auto left-0": align === "left" && responsive,
      "left-0": align === "left",
      "sm:py-2 sm:rounded-xl": responsive,
      "py-2 rounded-xl": !responsive,
    }
  );

export const paddingClassName = (responsive?: boolean) =>
  clsx({
    "py-4 sm:py-2.5 px-8 sm:px-6": responsive,
    "py-2.5 px-6": !responsive,
  });

export const itemClassName = (paddingClass: string, responsive?: boolean) =>
  clsx(
    "w-full flex sm:whitespace-nowrap cursor-pointer hover:bg-cp-violet-100",
    paddingClass,
    {
      "font-semibold sm:font-normal": responsive,
    }
  );

export interface DropdownMenuProps {
  children: ReactNode;
  items: MenuItemProps[];
  align?: Align;
  responsive?: boolean;
  label?: string;
  className?: string;
  buttonProps?: ButtonProps;
  scroll?: boolean;
}

export default function DropdownMenu({
  items,
  children,
  align = "left",
  responsive,
  label = "",
  className,
  buttonProps,
  scroll = true,
}: DropdownMenuProps) {
  const itemsClass = itemsClassName(align, responsive, className);

  const paddingClass = paddingClassName(responsive);
  const itemClass = itemClassName(paddingClass, responsive);

  return (
    <Menu>
      <MenuButton
        as={buttonProps ? Button : "button"}
        {...buttonProps}
        className={clsx(
          buttonProps?.className,
          "w-full focus-visible:outline focus-visible:outline-1 focus-visible:outline-cp-lapis-500 focus-visible:rounded-xl"
        )}
      >
        {children}
      </MenuButton>
      <MenuItems
        className={clsx(itemsClass, {
          "overflow-auto max-h-[calc(100vh-18rem)]": scroll,
        })}
      >
        {label && (
          <Typography
            variant="display"
            size="xs"
            className={clsx("cursor-default", paddingClass)}
            color="brand.boldest.enabled"
            emphasis
          >
            {label}
          </Typography>
        )}
        {items.map(({ className, href, key, label, onClick, ...rest }) => (
          <MenuItem key={key || label}>
            {href ? (
              <Link
                href={href}
                className={clsx(className, itemClass)}
                onClick={onClick}
                target="_parent"
                underline={false}
                {...rest}
              >
                {label}
              </Link>
            ) : (
              <Typography
                className={clsx(className, itemClass)}
                onClick={onClick}
                {...rest}
              >
                {label}
              </Typography>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
