import { Button, ButtonThemes, TextButton } from "../../library";

interface AuthenticationMenuProps {
  onClickSignUp: () => void;
  onClickLogin: () => void;
}

export default function AuthenticationMenu({
  onClickSignUp,
  onClickLogin,
}: AuthenticationMenuProps): JSX.Element {
  return (
    <div className="hidden md:flex gap-4 pl-4 pr-8 mr-4 xl:border-l border-solid border-cp-lapis-100">
      <Button
        className="analytics-homepage-login shrink-0 text-cp-body-sm"
        dataTestId="navbar-login"
        size={Button.sizes.SMALL}
        theme={ButtonThemes.SECONDARY_DARK}
        onClick={onClickLogin}
      >
        Log in
      </Button>
      <TextButton
        className="analytics-homepage-signup shrink-0"
        dataTestId="navbar-signup"
        size={Button.sizes.SMALL}
        onClick={onClickSignUp}
        textProps={{
          size: "sm",
          variant: "body",
          emphasis: true,
          color: "neutral.inverse.primary.enabled",
        }}
      >
        Sign up
      </TextButton>
    </div>
  );
}
