import { Disclosure } from "@headlessui/react";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import clsx from "clsx";
import type { ReactNode } from "react";

import { iconColorClass } from "../../utils/colors";
import Typography from "../Typography";

type DisclosureItem = { title: string; text: ReactNode };

export default function DisclosureList({
  items,
  ctaClassname,
}: {
  items: DisclosureItem[];
  ctaClassname?: string;
}) {
  return (
    <>
      {items.map(({ title, text }) => (
        <Disclosure key={title}>
          <Disclosure.Button
            className={clsx(
              "flex justify-between py-4 px-4 border-b border-solid border-cp-white-300",
              ctaClassname
            )}
          >
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              size="sm"
              emphasis
            >
              {title}
            </Typography>
            <ArrowDownwardRoundedIcon
              className={clsx(
                "ui-open:rotate-180 ui-open:transform",
                iconColorClass.brand.bold.enabled
              )}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4">{text}</Disclosure.Panel>
        </Disclosure>
      ))}
    </>
  );
}
