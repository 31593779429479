import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import clsx from "clsx";
import pluralize from "pluralize";
import { type ElementType, type ReactNode, useEffect, useState } from "react";

import { useAtomValue } from "jotai";
import noProjects from "../../../../img/icons/noProjects.png";
import searchNoResults from "../../../../img/icons/search-no-results.svg";
import { ApiService, type Project } from "../../../generated";
import { modalState } from "../../../jotai/page";
import { buyerProfileState } from "../../../jotai/user";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import GenericCard from "../../../library/GenericCard";
import Tag, { TagVariants } from "../../../shared/Tag";
import { NO_MODAL, goToURL } from "../../../utils";
import { bgColorClass, borderColorClass } from "../../../utils/colors";
import { SUPPORT_EMAIL_ADDRESS } from "../../../utils/constants";
import { generateHrefForEmail } from "../../../utils/format";
import { handleError } from "../../../utils/generatedApi";
import CreateProjectButton from "../../Project/CreateProjectButton";
import ProjectCard from "../../Project/ProjectCard";
import { ProjectCreationSource } from "../../Project/types";

interface WorkspaceSubSectionProps {
  Icon: ElementType;
  name: string;
  showViewAll: boolean;
  onViewAll: () => void;
  children: ReactNode;
}

function WorkspaceSubSection({
  Icon,
  name,
  showViewAll,
  onViewAll,
  children,
}: WorkspaceSubSectionProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <Typography
          color="brand.default.secondary.enabled"
          variant="display"
          size="xs"
          className="flex gap-1 items-center"
          uppercase
        >
          <Icon className="w-5 h-5 mb-0.5" /> {name}
        </Typography>
        {showViewAll && (
          <Button
            size={ButtonSizes.SMALL}
            theme={ButtonThemes.TERTIARY_DARK}
            onClick={onViewAll}
          >
            View all
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}

export default function WorkspaceSection({
  numAgencyContracts,
  numExpiringAgencyContracts,
}: {
  numAgencyContracts: number;
  numExpiringAgencyContracts: number;
}) {
  const currentModal = useAtomValue(modalState);
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const buyerProfile = useAtomValue(buyerProfileState);

  useEffect(() => {
    if (currentModal !== NO_MODAL) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();
        const activeProjects = projects.filter(({ archivedAt }) => !archivedAt);
        setUserProjects(activeProjects);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [currentModal]);

  return (
    <div
      className={clsx(
        "px-6 py-8 flex flex-col gap-7 rounded-3",
        bgColorClass.accent.persimmon.subtle
      )}
    >
      <Typography
        color="brand.default.secondary.enabled"
        variant="headline"
        emphasis
      >
        My workspace
      </Typography>
      <WorkspaceSubSection
        Icon={FolderOpenRoundedIcon}
        name="ACTIVE PROJECTS"
        showViewAll
        onViewAll={() => {
          goToURL("/projects");
        }}
      >
        {userProjects.length > 0 ? (
          <div className="flex flex-col gap-4 md:grid md:grid-cols-2">
            {userProjects
              .sort((a, b) => {
                return (
                  new Date(b.startedAt).getTime() -
                  new Date(a.startedAt).getTime()
                );
              })
              .slice(0, 2)
              .map((project) => (
                <ProjectCard key={project.id} {...project} />
              ))}
          </div>
        ) : (
          <NoProjects />
        )}
      </WorkspaceSubSection>
      {buyerProfile?.governmentAffiliationDisplayName && (
        <WorkspaceSubSection
          Icon={HomeWorkOutlinedIcon}
          name={`${buyerProfile?.governmentAffiliationDisplayName} Contracts`}
          showViewAll
          onViewAll={() => {
            goToURL("/entity-contracts");
          }}
        >
          {numAgencyContracts > 0 ? (
            <div className="flex flex-col gap-4 md:grid md:grid-cols-2">
              <EntityProjecCard
                entityName={buyerProfile?.governmentAffiliationDisplayName}
                numAgencyContracts={numAgencyContracts}
                numExpiringAgencyContracts={numExpiringAgencyContracts}
              />
            </div>
          ) : (
            <NoEntityContractsPlaceholder
              entityName={buyerProfile?.governmentAffiliationDisplayName}
            />
          )}
        </WorkspaceSubSection>
      )}
    </div>
  );
}

function EntityProjecCard({
  entityName,
  numAgencyContracts,
  numExpiringAgencyContracts,
}: {
  entityName: string;
  numAgencyContracts: number;
  numExpiringAgencyContracts: number;
}) {
  return (
    <GenericCard
      onClick={() => {
        goToURL("/entity-contracts");
      }}
      responsive
    >
      <div className="flex flex-col w-full">
        <div className="inline-flex gap-3 justify-between">
          <Typography
            color="brand.default.primary.enabled"
            emphasis
            size="sm"
            variant="headline"
          >
            {entityName} contract
          </Typography>
          <OpenInNewRoundedIcon className="text-cp-lapis-500 w-5 h-5 mt-1" />
        </div>
        <Typography
          color="neutral.bold.enabled"
          size="sm"
          variant="meta"
          className="mt-1"
        >
          {numAgencyContracts} {pluralize("contract", numAgencyContracts)}
        </Typography>
        {!!numExpiringAgencyContracts && (
          <Tag className="mt-4" variant={TagVariants.WARNING}>
            {numExpiringAgencyContracts}{" "}
            {pluralize("contract", numExpiringAgencyContracts)} expiring soon
          </Tag>
        )}
      </div>
    </GenericCard>
  );
}

function NoProjects() {
  return (
    <div
      className={clsx(
        "pt-6 px-6 border border-solid rounded-2xl text-center flex flex-col gap-4 items-center",
        borderColorClass.neutral.subtle.enabled,
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <Typography color="neutral.bold.enabled">
        Create a project to save your contract research, collaborate with
        colleagues, and more.
      </Typography>
      <div className="flex flex-col sm:flex-row items-center gap-4 mb-4 sm:mb-0">
        <img
          className="max-w-[15rem] md:max-w-[26rem]"
          src={noProjects}
          alt="contracts folder"
        />
        <CreateProjectButton
          source={ProjectCreationSource.BUYER_PROFILE_PAGE}
        />
      </div>
    </div>
  );
}

function NoEntityContractsPlaceholder({
  entityName,
}: {
  entityName: Maybe<string>;
}) {
  return (
    <div
      className={clsx(
        "p-6 border border-solid rounded-2xl text-center flex flex-col gap-4 items-center",
        borderColorClass.neutral.subtle.enabled,
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <img
        className="h-20 mb-4 align-center"
        src={searchNoResults}
        alt="No saved contracts"
      />
      <Typography size="sm" color="neutral.bolder.enabled" className="mx-2">
        {entityName} doesn{"'"}t have any contracts on Pavilion
      </Typography>
      <Button
        as={Link}
        theme={Button.themes.PRIMARY_DARK}
        linkProps={{
          href: generateHrefForEmail(SUPPORT_EMAIL_ADDRESS),
          underline: false,
        }}
        dataTestId="no-contracts-search-button"
        className="analytics-buyer-profile-no-saved-contracts-upload-contracts mt-4"
        size={ButtonSizes.SMALL}
      >
        Upload contracts
      </Button>
    </div>
  );
}
