import paperPlaneSparkles from "../../../img/welcome/paperPlaneSparkles.svg";
import { Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";

export enum SupplierUpgradeInterestType {
  CUSTOM_OPP_SIZING_REPORT = "Custom Opp Sizing Report",
  SCHEDULE_DEMO = "Schedule Demo",
}

const INTEREST_TYPE_TO_SUBTITLE = {
  [SupplierUpgradeInterestType.CUSTOM_OPP_SIZING_REPORT]:
    "We'll email you when your report is ready for review.",
  [SupplierUpgradeInterestType.SCHEDULE_DEMO]:
    "We'll be in touch soon to schedule a demo.",
};

export default function ConfirmSupplierUpgradeInterestModal({
  hideModal,
  interestType,
}: {
  hideModal: () => void;
  interestType: SupplierUpgradeInterestType;
}) {
  return (
    <Modal
      contentClassName="flex flex-col text-center gap-6"
      hideModal={hideModal}
      modalSize={modalSizes.SMALL}
    >
      <Typography
        color="brand.boldest.enabled"
        variant="headline"
        size="sm"
        emphasis
      >
        We've received your request!
      </Typography>
      <img
        src={paperPlaneSparkles}
        alt="paper plane"
        className="h-36 self-center"
      />

      <Typography color="neutral.bolder.enabled">
        {INTEREST_TYPE_TO_SUBTITLE[interestType]}
      </Typography>
    </Modal>
  );
}
