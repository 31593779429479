import { useAtomValue } from "jotai";
import { userTypeSignupState } from "../../../jotai/user";
import { Typography } from "../../../library";
import { LoginType } from "../../../utils/enums";
import { SocialProvider } from "../../../utils/social";
import { trackInitialSignup } from "../../../utils/tracking";
import SocialLoginButton from "../SocialLoginButton";
import type { WindowType } from "../types";

export default function SocialLoginSection({
  heading,
  onComplete,
  parentWindow,
}: {
  heading: string;
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
}) {
  const userType = useAtomValue(userTypeSignupState);
  const trackSocialSignup = (provider: LoginType) =>
    trackInitialSignup({
      accountType: userType,
      loginExperience: parentWindow,
      loginType: provider as unknown as LoginType,
      pushToLogin: false,
    });
  return (
    <div className="flex flex-col-reverse gap-2">
      <div className="flex gap-2 button-exists peer">
        <SocialLoginButton
          className="analytics-email-login-modal-google button-exists peer"
          provider={SocialProvider.google}
          onComplete={onComplete}
          parentWindow={parentWindow}
          trackSignup={() => trackSocialSignup(LoginType.GOOGLE)}
        />
        <SocialLoginButton
          className="analytics-email-login-modal-microsoft button-exists peer"
          provider={SocialProvider.microsoft}
          onComplete={onComplete}
          parentWindow={parentWindow}
          trackSignup={() => trackSocialSignup(LoginType.MICROSOFT)}
        />
      </div>
      {/* Conditionally render only if at least one of the social buttons exists */}
      <div className="hidden peer-[.button-exists]:flex">
        <Typography size="sm" color="neutral.bolder.enabled">
          {heading}
        </Typography>
      </div>
    </div>
  );
}
