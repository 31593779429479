import { Suspense, lazy } from "react";
import { Typography } from "../../../library";
import Tag, { TagVariants } from "../../../shared/Tag";

const RocketImage = lazy(() => import("../../../shared/RocketImage"));

const CONTAINER_STYLES =
  "analytics-pavilion-at-a-glance flex p-6 rounded-8 gap-6 shadow-1 bg-gradient-to-r from-cp-persimmon-80 to-cp-violet-300 justify-between";

export default function ExpiredAgreementsUpsell() {
  return (
    <div className={CONTAINER_STYLES}>
      <div className="flex flex-col gap-5">
        <Tag variant={TagVariants.NEW_FEATURE_DARK}>Upgrade to Pro</Tag>
        <div className="grid gap-2">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="neutral.boldest.enabled"
          >
            Continue to dominate your category
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            Keep enjoying the full benefits of Pro, including exclusive public
            entity data. Reach out to your Pavilion rep to discuss renewal.
          </Typography>
        </div>
      </div>
      <Suspense fallback={null}>
        <RocketImage className="hidden md:flex w-30 h-30 basis-content" />
      </Suspense>
    </div>
  );
}
