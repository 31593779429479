import clsx from "clsx";
import { useAtomValue } from "jotai";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import useShowModal from "../../hooks/useShowModal";
import { contractSearchParamsState, requestIDState } from "../../jotai/search";
import { CardContainer, CardContainerVariant, Typography } from "../../library";
import { FormWrapper } from "../../library/form";
import Tag, { TagVariants } from "../../shared/Tag";
import { elevationClass } from "../../utils/designTokens";
import { modals } from "../../utils/enums";
import {
  trackQuoteRequestSidebarCtaClick,
  trackQuoteRequestWelcomePageCtaClick,
} from "../../utils/tracking";
import QuoteRequestRepsOnline from "./QuoteRequestRepsOnline";
import { CARD_FORM_QUESTIONS } from "./constants";

export function QuoteRequestFormCard() {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);
  const searchParams = useAtomValue(contractSearchParamsState);
  const requestID = useAtomValue(requestIDState);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-5 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <FormWrapper
        fields={CARD_FORM_QUESTIONS}
        initialValues={{
          description: "",
        }}
        onSubmit={({
          description,
        }: Pick<QuoteRequestRequest, "description">) => {
          trackQuoteRequestSidebarCtaClick({
            searchQuery: searchParams.query,
            requestID,
          });
          showQuoteRequest({ description });
        }}
        submitCta="Get concierge support"
        submitClassName="!w-full analytics-quote-request-sidebar-cta"
      />
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}

export function QuoteRequestFormBanner({ className }: { className?: string }) {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 w-full gap-6 max-w-[49.5rem] rounded-6",
        elevationClass["elevation-1"],
        className
      )}
    >
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 flex flex-col gap-2">
          <div className="flex flex-wrap items-center gap-y-1 gap-x-4">
            <Typography
              size="xl"
              color="brand.boldest.enabled"
              className="font-homeHeader"
            >
              Get 3 quotes in 48 hours
            </Typography>
            <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
          </div>

          <Typography color="neutral.boldest.enabled">
            Tell us what you need and your concierge will connect you with
            qualified suppliers.
          </Typography>
        </div>
        <QuoteRequestRepsOnline />
      </div>
      <FormWrapper
        fields={CARD_FORM_QUESTIONS}
        initialValues={{
          description: "",
        }}
        onSubmit={({
          description,
        }: Pick<QuoteRequestRequest, "description">) => {
          trackQuoteRequestWelcomePageCtaClick();
          showQuoteRequest({ description });
        }}
        submitCta="Get concierge support"
        submitClassName="sm:!w-fit self-center"
      />
    </CardContainer>
  );
}
