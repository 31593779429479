import clsx from "clsx";
import { DisclosureList, Link, Typography } from "../../../library";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { bgColorClass } from "../../../utils/colors";

const faqs = [
  {
    title: "What is Pavilion?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion is the search engine for state, local, and educational
        institutions to find existing cooperative contracts they can purchase
        from – without going through a new competitive process. Think about us
        as the {'"'}Google{'"'} for cooperative contracts.
      </Typography>
    ),
  },
  {
    title: "How can Pavilion help my business?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion helps you sell more off of existing cooperative contracts you
        {"'"}ve already won. High-intent buyers search Pavilion with budget
        they're looking to deploy immediately. This allows you to get your
        contracts in front of qualified buyers when the purchasing decision is
        made so that you can skip going through a new solicitation process to
        win business.
      </Typography>
    ),
  },
  {
    title: "What types of contracts are on Pavilion?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        We have the largest repository of contracts that can be used for
        cooperative procurement (sometimes called {'"'}piggybacking{'"'} or{" "}
        {'"'}intergovernmental purchasing{'"'}). This is an established best
        practice that allows you to win new business through your existing
        contracts from national / regional cooperatives, states, and local
        agencies. These contracts are competitively solicited and typically
        include a {'"'}cooperative,{'"'} {'"'}piggyback,{'"'}
        or {'"'}interlocal government use{'"'} clause that explicitly states
        that the contract may be utilized by other public entities. Sometimes,
        this language may restrict use of the contract to certain types of
        public entities, a specific membership, or a specific geographic area.
      </Typography>
    ),
  },
  {
    title:
      "What determines if my contract or business shows up in search results?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion is designed to help public buyers find and purchase from
        businesses that have already been awarded a competitively solicited
        contract by another public entity. Our algorithm is designed to show the
        contracts and businesses that are most relevant to the public buyer{"'"}
        s search, including scope, geographic constraints, compliance
        restrictions, and other factors.
      </Typography>
    ),
  },
  {
    title: "What does Pavilion cost?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion is free for public agencies to use, and is also free for you to
        be listed on Pavilion. You can sign up to become a Pro Supplier to gain
        access to paid features that allows you to give us more information
        about your business and contracts. This helps our search algorithm match
        you with relevant searches, and gives more information to buyers when
        they{"'"}re making their purchasing decision.{" "}
        <Link
          href="https://go.withpavilion.com/demorequest/lp"
          emphasis={false}
          underline={false}
          className="analytics-supplier-welcome-page-schedule-demo-cta"
        >
          Schedule a demo to learn more
        </Link>
        .
      </Typography>
    ),
  },
  {
    title: "Where can I learn more about selling to the public sector?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        <Link
          href="https://go.withpavilion.com/winning-new-public-sector-sales-with-cooperative-procurement/lp"
          emphasis={false}
          underline={false}
          className="analytics-supplier-welcome-page-ebook-cta"
        >
          Download our free ebook
        </Link>{" "}
        or visit our{" "}
        <Link
          href="https://go.withpavilion.com/winning-new-public-sector-sales-with-cooperative-procurement/lp"
          emphasis={false}
          underline={false}
          className="analytics-supplier-welcome-page-resources-cta"
        >
          resources page
        </Link>{" "}
        for resources to help navigate government procurement and learn more
        about cooperative procurement.
      </Typography>
    ),
  },
  {
    title: "I don't see my question here. How can I get help?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        We{"'"}re always happy to help! Just send us a note at{" "}
        <SupportEmailLink />.
      </Typography>
    ),
  },
];

export default function SupplierFAQ() {
  return (
    <div
      className={clsx(
        "analytics-supplier-faq flex flex-col gap-3 py-10 px-5 sm:gap-10 sm:px-20 lg:py-20 lg:px-30",
        bgColorClass.brand.subtlest.enabled
      )}
    >
      <Typography variant="headline" color="brand.boldest.enabled">
        Frequently asked questions
      </Typography>
      <div className="flex flex-col gap-0 md:gap-4">
        <DisclosureList
          items={faqs}
          ctaClassname="text-left analytics-supplier-faq-cta"
        />
      </div>
    </div>
  );
}
