import { lazy, useState } from "react";

import { Link, Typography } from "../../library";
import { WindowType } from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { redirectOnComplete } from "../Account/helpers";

import type { UserSupplierContact } from "../../generated";
import ConfirmSupplierContactForm from "./ConfirmSupplierContactForm";
import type { SupplierData } from "./types";

// Lazy load component with SVG to avoid inflating main bundle
const SupplierContactSavedForm = lazy(
  () => import("./SupplierContactSavedForm")
);

export interface ConfirmSupplierContactPageProps {
  supplier: SupplierData;
  contact: UserSupplierContact;
  sharedLinkId: string;
}

export function ConfirmSupplierContactPage({
  supplier,
  contact,
  sharedLinkId,
}: ConfirmSupplierContactPageProps) {
  const [contactSaved, setContactSaved] = useState(false);
  const [currentContact, setCurrentContact] = useState(contact);
  const { displayName, numActiveContracts } = supplier;

  let hasContractsOnPavilionCopy = " is "; // no contracts
  if (numActiveContracts && numActiveContracts > 1) {
    // multiple contracts
    hasContractsOnPavilionCopy = ` has ${numActiveContracts} contracts `;
  } else if (numActiveContracts) {
    // 1 contract
    hasContractsOnPavilionCopy = " has a contract ";
  }

  return (
    <AccountPage
      hideClosePage={true}
      title="Get free public sector sales leads"
      ctaComponent={
        <>
          <Typography color="neutral.default.primary.enabled">
            Thousands of government agencies use Pavilion to find businesses and
            contracts they can buy from. Government buyers spend $1.5 billion
            every year with businesses in Pavilion{"'"}s network.
          </Typography>
          <br />
          <Typography color="neutral.default.primary.enabled">
            <span className="capitalize">{displayName}</span>
            {hasContractsOnPavilionCopy}
            on Pavilion, but without your contact info, buyers can{"'"}t reach
            you. You{"'"}re missing out on agencies who are ready to buy and
            want to skip the bidding process.
          </Typography>
          <br />
          <Typography color="neutral.default.primary.enabled">
            Update your contact info and get free public sector leads.
          </Typography>
          <br />
          <Typography
            color="neutral.default.primary.enabled"
            className="text-center md:text-left"
          >
            Learn more about{" "}
            <Link
              emphasis={false}
              href="https://www.withpavilion.com/about/suppliers"
            >
              how Pavilion can grow your public sector sales
            </Link>
            .
          </Typography>
        </>
      }
    >
      {contactSaved ? (
        <SupplierContactSavedForm
          contact={currentContact}
          supplierHandle={supplier.handle}
          supplierDisplayName={supplier.displayName}
          sharedLinkId={sharedLinkId}
          onComplete={redirectOnComplete}
          parentWindow={WindowType.Page}
        />
      ) : (
        <ConfirmSupplierContactForm
          contactId={contact.id}
          supplierHandle={supplier.handle}
          initialContact={contact}
          setContact={setCurrentContact}
          sharedLinkId={sharedLinkId}
          onComplete={() => setContactSaved(true)}
        />
      )}
    </AccountPage>
  );
}
