import { isAfter, isEqual, isPast } from "date-fns";

import { AUTORENEWAL_EXPIRATION_MS } from "../../utils/constants";
import { formatDate, parseDate } from "../../utils/date";

export default function expirationText(
  date: Maybe<number | string | Date>,
  opts?: {
    short?: boolean;
    formatType?: string;
    showDateOnly?: boolean;
    showUnknown?: boolean;
  }
): string {
  const parsed = parseDate(date);
  if (!parsed) {
    return opts?.showUnknown ? "Expiration Unknown" : "";
  }
  if (
    parsed &&
    (isAfter(parsed, new Date(AUTORENEWAL_EXPIRATION_MS)) ||
      isEqual(parsed, new Date(AUTORENEWAL_EXPIRATION_MS)))
  ) {
    // https://app.shortcut.com/coprocure/story/14816/potential-issue-likely-within-work-tenure-we-explicitly-have-1-1-2050-as-a-magic-value-to-indicate-autorenewal
    // Integer literal below is number of ms since epoch for Jan 1, 2050, midnight, GMT.
    return opts?.short ? "Auto-renews" : "Automatically Renewing";
  }
  const formatType =
    opts?.formatType ?? ((opts?.short && "MMM yyyy") as string | undefined);
  const formattedDate = formatDate(parsed, formatType);
  if (opts?.showDateOnly) {
    return formattedDate;
  }
  return `${isPast(parsed) ? "Expired" : "Expires"} ${formattedDate}`;
}
