import { useAtomValue } from "jotai";

import { isAuthenticatedState } from "../../jotai/user";

import type { MembershipTypeEnum } from "../../generated";
import type { ContractSearchLimitedData } from "../../shared/types";
import { LoggedInLandingPage } from "./LoggedInLandingPage";
import { LoggedOutLandingPage } from "./LoggedOutLandingPage";

export type SearchParams = {
  filters: string[];
};

export enum LandingPageType {
  AGENCY = "agencies",
  COOPERATIVE = "cooperatives",
  STATE = "states",
}

export interface PageData {
  source_value: string;
  slug: string;
  title: string;
  subtitle: string;
  display_name: string;
  search_params: SearchParams;
  page_type: LandingPageType;
  initial_list: ContractSearchLimitedData;
  total_filtered: number;
  active_shareable_total: number;
  total: number;
  requestID: string;
  buyer_lead_agency_id: string;
  membership_type: Maybe<MembershipTypeEnum>;
}

interface LandingPageProps {
  page: PageData;
  isSSR: boolean;
}
export function LandingPage({ page, isSSR = false }: LandingPageProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  if (!isAuthenticated) {
    return <LoggedOutLandingPage page={page} />;
  }

  return <LoggedInLandingPage page={page} isSSR={isSSR} />;
}
