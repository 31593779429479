import { captureException } from "@sentry/browser";
import Cookies from "js-cookie";

import { SPLIT_COOKIE_KEY } from "./constants";

import { debugLog } from ".";

const splitTreatments: Record<string, string> = {};
const heapTrackedFeatures: Record<string, boolean> = {};
let splitInitialized = false;

/**
 * Initializes our local JS copy of Split treatment values
 * from the data passed to us by the server.
 */
export function initializeSplit() {
  try {
    let serverData: Maybe<{
      tracked: Record<string, string>;
      tracked_anon: Record<string, string>;
      active: Record<string, string>;
      inactive: string[];
    }>;

    if (typeof document !== "undefined") {
      const dataElement = document.getElementById("split_treatments");
      serverData = JSON.parse(dataElement?.textContent || "");
    } else {
      serverData = global.ssr_split_treatments;
    }

    if (serverData) {
      Object.entries(serverData.tracked).forEach(
        ([featureName, treatmentValue]) => {
          splitTreatments[featureName] = treatmentValue;
          trackSplitPropertyInHeap(featureName, treatmentValue);
        }
      );
      Object.entries(serverData.tracked_anon).forEach(
        ([featureName, treatmentValue]) => {
          splitTreatments[featureName] = treatmentValue;
          trackSplitPropertyInHeap(featureName, treatmentValue);
        }
      );
      Object.entries(serverData.active).forEach(
        ([featureName, treatmentValue]) => {
          splitTreatments[featureName] = treatmentValue;
          removeSplitPropertyInHeap(featureName);
        }
      );
      serverData.inactive.forEach((featureName) => {
        removeSplitPropertyInHeap(featureName);
      });
    }
    if (typeof heap !== "undefined") {
      heap.addEventProperties({
        split_key_anon: Cookies.get(SPLIT_COOKIE_KEY) || "",
      });
    }
  } catch (e) {
    debugLog("Failed to initialize Split treatment data");
    captureException(e);
  }
  splitInitialized = true;
}

function trackSplitPropertyInHeap(featureName: string, treatmentValue: string) {
  if (typeof heap !== "undefined" && !(featureName in heapTrackedFeatures)) {
    const key = `Split-${featureName}`;
    heap.addEventProperties({
      [key]: treatmentValue,
    });
    heapTrackedFeatures[featureName] = true;
  }
}

function removeSplitPropertyInHeap(featureName: string) {
  if (typeof heap !== "undefined") {
    const key = `Split-${featureName}`;
    heap.removeEventProperty(key);
  }
}

/**
 * Convenience function for checking whether a given feature
 * has the treatment for this user set to "on".
 *
 * @param {string} featureName
 * @returns {bool}
 */
export function isFeatureEnabled(featureName: string) {
  return getSplitTreatment(featureName) === "on";
}

/**
 * Returns the treatment value for the given feature for this user.
 * Typically a string like "on", "off" or "control", but can also
 * be set to custom values.
 *
 * @param {string} featureName
 * @returns {string}
 */
export function getSplitTreatment(featureName: string) {
  if (!splitInitialized) {
    initializeSplit();
  }
  let treatmentValue = splitTreatments[featureName];
  if (!treatmentValue) {
    debugLog(
      `No Split treatment value for ${featureName}, falling back to control value`
    );
    treatmentValue = "control";
  }
  return treatmentValue;
}
