import { type ReactNode, useEffect } from "react";

import { useHydrateAtoms } from "jotai/utils";
import useShowPiggybackLaunchModal from "../../components/EntityContractsPage/PiggybackedContracts/useShowPiggybackLaunchModal";
import useInitializeUser from "../../hooks/useInitializeUser";
import { pageIdState, pageTypeState } from "../../jotai/page";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierActiveAgreementsState,
} from "../../jotai/user";
import AnnouncementBanner from "../../shared/AnnouncementBanner";
import Header from "../../shared/Header";
import type { PageType, ProfileTypes, SearchSource } from "../../utils/enums";
import ModalConductor from "./ModalConductor";
import PopupConductor from "./PopupConductor";

interface PageProps {
  children?: ReactNode;
  isSSR?: boolean;
  searchSource?: string;
  showHeader?: boolean;
  pageType?: PageType;
  pageId?: string;
  isAuthenticated?: boolean;
  profileType: Maybe<ProfileTypes>;
  activeAgreements: Maybe<string>;
}

export default function Page({
  children,
  isSSR = false,
  searchSource,
  showHeader = true,
  pageType,
  pageId,
  isAuthenticated,
  profileType,
  activeAgreements,
}: PageProps) {
  const atomMap = new Map();
  if (pageType) atomMap.set(pageTypeState, pageType);
  if (pageId) atomMap.set(pageIdState, pageId);
  if (isAuthenticated) atomMap.set(isAuthenticatedState, isAuthenticated);
  if (profileType) atomMap.set(profileTypeState, profileType);
  if (activeAgreements)
    atomMap.set(supplierActiveAgreementsState, JSON.parse(activeAgreements));

  useHydrateAtoms(atomMap);

  const initializeUser = useInitializeUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once on mount.
  useEffect(() => {
    initializeUser();
  }, []);

  useShowPiggybackLaunchModal();

  return (
    <>
      {showHeader && (
        <Header searchSource={searchSource as SearchSource} isSSR={isSSR} />
      )}
      <AnnouncementBanner />
      <PopupConductor />
      {children}
      <ModalConductor />
    </>
  );
}

export type AuthPageProps = {
  is_authenticated?: boolean;
  profile_type?: ProfileTypes;
  active_agreements?: Maybe<string>;
};
