export const shapeClass = {
  shape0: "rounded-0",
  shape3: "rounded-3",
  shape4: "rounded-4",
  shape6: "rounded-6",
  shape8: "rounded-8",
  shapeFull: "rounded-full",
} as const;
export type ShapeToken = keyof typeof shapeClass;

export const elevationClass = {
  "elevation-0": "shadow-0",
  "elevation-1": "shadow-1",
  "elevation-2": "shadow-2",
  "elevation-3": "shadow-3",
} as const;
export type ElevationToken = keyof typeof elevationClass;

export const borderWidthClass = {
  0: "border-0",
  1: "border-1",
  2: "border-2",
  3: "border-3",
  15: "border-15",
  30: "border-30",
} as const;
export type BorderWidthToken = keyof typeof borderWidthClass;
