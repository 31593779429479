import _debounce from "lodash/debounce";

import { ApiService, type BuyerLeadAgency } from "../../generated";

interface DebounceBLAListProps {
  parentsOnly?: boolean;
  onComplete: (agencies: BuyerLeadAgency[]) => void;
  excludeCooperatives?: boolean;
}

export const debouncedGetBuyerLeadAgenciesList = _debounce(
  async (
    currentGovAffiliation: string,
    { parentsOnly, onComplete, excludeCooperatives }: DebounceBLAListProps
  ) => {
    if (currentGovAffiliation.length < 3) {
      onComplete([]);
      return;
    }
    try {
      const response = await ApiService.apiV1BuyerLeadAgenciesList(
        currentGovAffiliation,
        excludeCooperatives,
        parentsOnly
      );
      onComplete(response);
    } catch {
      return;
    }
  },
  500,
  { leading: false, trailing: true, maxWait: 500 }
);
