import type { Dispatch } from "react";

import type { MessageSupplierData } from "../../jotai/messageSupplier";
import { supplierHasFeature } from "../../utils/featureManagement";
import { trackToggleSupplier } from "../../utils/tracking";

export const MAX_OTHER_SUPPLIERS = 10;

export default function useToggleSupplier({
  suppliersVisibleCount,
  isRecommendationPage,
  currentSuppliersToContact,
  setCurrentSuppliersToContact,
}: {
  suppliersVisibleCount: number;
  isRecommendationPage: boolean;
  currentSuppliersToContact: MessageSupplierData[];
  setCurrentSuppliersToContact: Dispatch<MessageSupplierData[]>;
}) {
  const toggle = (supplier: MessageSupplierData) => {
    const supplierIndex = currentSuppliersToContact.findIndex(
      ({ supplier: currentSupplier }) =>
        currentSupplier.id === supplier.supplier.id
    );
    const untoggle = supplierIndex !== -1;
    if (!untoggle && currentSuppliersToContact.length >= MAX_OTHER_SUPPLIERS) {
      return;
    }
    const supplierArr = untoggle
      ? currentSuppliersToContact.filter((_, ix) => ix !== supplierIndex)
      : [...currentSuppliersToContact, supplier];
    trackToggleSupplier({
      untoggle,
      supplierId: supplier.supplier.id,
      supplierHandle: supplier.supplier.handle,
      isPromoted: supplierHasFeature(
        supplier.supplierAgreement.activeAgreements,
        "analyticsTrackIsPro"
      ),
      suppliersVisibleCount,
      isRecommendationPage,
    });
    setCurrentSuppliersToContact(supplierArr);
  };

  return toggle;
}
