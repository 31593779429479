import clsx from "clsx";

import { forwardRef } from "react";
import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";
import Typography, { type TypographyProps } from "../Typography";

type WindowPropsType = { target?: string; rel?: string };

export interface LinkProps extends TypographyProps {
  href?: string;
  newWindow?: boolean;
  target?: string;
  rel?: string;
}

const Link = forwardRef(
  (
    {
      children,
      underline = true,
      emphasis = true,
      newWindow = true,
      color = "brand.default.primary.enabled",
      className,
      ...props
    }: LinkProps,
    ref
  ) => {
    const windowProps: WindowPropsType = {};
    if (newWindow) {
      windowProps.target = "_blank";
      windowProps.rel = "noopener noreferrer";
    }

    return (
      <Typography
        ref={ref}
        component="a"
        underline={underline}
        emphasis={emphasis}
        color={color}
        className={clsx(
          OUTLINE_FOCUS_CLASS,
          "focus-visible:rounded-3 focus-visible:outline-cp-lapis-500 cursor-pointer",
          className
        )}
        tabIndex={0}
        {...windowProps}
        {...props}
      >
        {children}
      </Typography>
    );
  }
);

export default Link;
