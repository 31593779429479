import { useEffect, useState } from "react";

import { useAtom } from "jotai";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useEntityContracts from "../../hooks/useEntityContracts";
import { entityContractsParamsState } from "../../jotai/entityContracts";
import SearchBar, { SearchBarSizes } from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import SearchFilterHeader, {
  SearchFilter,
} from "../../shared/SearchPage/SearchResults/SearchFilterHeader";
import { getParams, hasWindow } from "../../utils";
import { SearchActions, SearchSource, searchFilters } from "../../utils/enums";
import { trackSearchFilterToggle } from "../../utils/tracking";

export default function EntityContractsSearchSection({
  requestID = "",
}: {
  requestID: string;
}) {
  const [filters, setFilters] = useState<string[]>([]);
  const entityPagePath = hasWindow() ? window.location.pathname : "";
  const [searchParams, setSearchParams] = useAtom(entityContractsParamsState);
  useBindPageParamDict(searchParams, setSearchParams);
  const entityContracts = useEntityContracts();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on mount.
  useEffect(() => {
    const urlParams = getParams();
    const initialFilters = searchParams?.filters
      ? `${searchParams.filters}`.split(";").filter((f) => !!f)
      : [];
    initialFilters.push(searchFilters.INCLUDE_NON_COOP);
    setFilters(initialFilters);

    setSearchParams(searchParams);

    entityContracts({
      newParams: {
        query: (urlParams.query as string) || "",
        filters: initialFilters,
      },
      action: SearchActions.SEARCH,
    });
  }, []);

  return (
    <div className="flex flex-col">
      <SearchBar
        className="bla-search-bar mt-5 mb-4"
        theme={SearchBarThemes.LIGHT}
        size={SearchBarSizes.FULL}
        isLocationRelevant={false}
        searchSource={SearchSource.ENTITY_CONTRACTS_PAGE}
        searchUrl={entityPagePath}
        disableAutocomplete
        showExactKeywordsFilter={true}
        onSearch={entityContracts}
      />
      <SearchFilterHeader
        filters={filters}
        setFilters={setFilters}
        onFilterChange={(params) => {
          trackSearchFilterToggle({
            ...params,
            query: searchParams?.query,
            requestID,
          });
        }}
        filtersToShow={[
          SearchFilter.EXPIRATION,
          SearchFilter.CONTRACT_DOCUMENTS,
        ]}
        onSearch={entityContracts}
        searchSource={SearchSource.ENTITY_CONTRACTS_PAGE}
        hideSearchResultTypeToggle
      />
    </div>
  );
}
