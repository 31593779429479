import clsx from "clsx";
import pluralize from "pluralize";
import { type Dispatch, useEffect, useState } from "react";

import {
  getMessageSupplierDataFromBaseContract,
  getPromotedSuppliersDataFromBaseContract,
} from "../../components/MessageSupplierPage/helpers";
import usePaginatedQuery from "../../hooks/usePaginatedQuery";
import type { MessageSupplierData } from "../../jotai/messageSupplier";
import { Checkbox } from "../../library";
import Modal from "../../shared/Modal/Modal";
import SupplierCard from "../../shared/SupplierCard";
import ViewMoreButton from "../../shared/ViewMoreButton";
import {
  trackMessageSupplierModalViewMore,
  trackMessageSupplierProSuppliersShown,
} from "../../utils/tracking";

import {
  ApiService,
  type BaseContract,
  type OtherContractsResponse,
} from "../../generated";
import { supplierHasFeature } from "../../utils/featureManagement";
import { handleError } from "../../utils/generatedApi";
import useOtherSuppliersModalSubmit from "./useOtherSuppliersModalSubmit";
import useToggleSupplier, { MAX_OTHER_SUPPLIERS } from "./useToggleSupplier";

export default function OtherSuppliersFromSolicitationModal({
  hideModal,
  supplierId,
  solicitationId,
  contractId,
  readOnlySupplierHandles,
  initialCurrentSuppliers,
  initialTotalSuppliersCount,
  initialPage,
  initialSuppliersToContact,
  setSuppliersToContact,
}: OtherSuppliersFromSolicitationModalProps) {
  const [currentSuppliersToContact, setCurrentSuppliersToContact] = useState<
    MessageSupplierData[]
  >(initialSuppliersToContact);
  const submit = useOtherSuppliersModalSubmit({
    supplierId,
    suppliersToContact: currentSuppliersToContact,
    setSuppliersToContact,
    hideModal,
  });

  const fetchOtherSuppliers = async (
    page: number
  ): Promise<OtherContractsResponse> => {
    try {
      const response = await ApiService.apiV1SolicitationsOtherRetrieve(
        contractId as string,
        solicitationId || "",
        "",
        page,
        "",
        10,
        true
      );
      return response;
    } catch (err) {
      handleError(err);
    }
    return { contracts: [], count: 0 };
  };

  const {
    list: currentSuppliers,
    fetchMore,
    isLoading: isLoadingContracts,
    count: totalSuppliersCount,
  } = usePaginatedQuery({
    initialList: initialCurrentSuppliers,
    initialPage,
    total: initialTotalSuppliersCount,
    fetchList: ({ page }) => fetchOtherSuppliers(page),
    onResponse: ({ contracts, count }) => {
      return { list: contracts, count };
    },
  });

  const toggle = useToggleSupplier({
    suppliersVisibleCount: currentSuppliers.length,
    currentSuppliersToContact,
    setCurrentSuppliersToContact,
    isRecommendationPage: false,
  });

  function handleViewMore() {
    const promotedSuppliers = currentSuppliers.filter(({ supplierAgreement }) =>
      supplierHasFeature(
        supplierAgreement.activeAgreements,
        "analyticsTrackIsPro"
      )
    );
    trackMessageSupplierModalViewMore({
      promotedSuppliersCount: promotedSuppliers.length,
      suppliersVisibleCount: totalSuppliersCount || 0,
    });
    fetchMore();
  }

  useEffect(() => {
    if (!contractId || !solicitationId) {
      return;
    }
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromBaseContract(currentSuppliers);

    if (promotedSupplierCount > 0) {
      trackMessageSupplierProSuppliersShown({
        supplierId,
        contractId,
        solicitationId,
        promotedSupplierCount,
        promotedSupplierIds,
        promotedSupplierHandles,
      });
    }
  }, [currentSuppliers, supplierId, contractId, solicitationId]);

  const limitReached = currentSuppliersToContact.length >= MAX_OTHER_SUPPLIERS;

  return (
    <Modal
      hideModal={hideModal}
      title="Message more awarded suppliers from this solicitation"
      subtitle={`${totalSuppliersCount} other awarded ${pluralize(
        "supplier",
        totalSuppliersCount
      )} on this solicitation could also meet your needs. Contacting multiple suppliers increases your chances of getting a fast response and gives you more options to choose from.`}
      ctaClass="analytics-message-other-suppliers-modal-submit"
      ctaText={`Message ${currentSuppliersToContact.length} ${pluralize(
        "supplier",
        currentSuppliersToContact.length
      )}`}
      onCtaClick={submit}
      error={
        limitReached
          ? `You can message up to ${MAX_OTHER_SUPPLIERS} suppliers at a time.`
          : ""
      }
    >
      <div className="flex flex-col gap-4 h-96 px-1">
        {currentSuppliers.map((solicitation) => {
          const messageSupplierData =
            getMessageSupplierDataFromBaseContract(solicitation);
          const toggled = !!currentSuppliersToContact.find(
            ({ supplier: currentSupplier }) =>
              currentSupplier.id === solicitation.supplier.id
          );
          const disabled =
            (limitReached && !toggled) ||
            readOnlySupplierHandles.includes(solicitation.supplier.handle);
          return (
            <SupplierCard
              className={clsx(
                "analytics-message-supplier-other-suppliers-modal-card",
                { "!cursor-default hover:!shadow-none grayscale": disabled }
              )}
              key={solicitation.supplier.id}
              supplier={{
                supplier: solicitation.supplier,
                serviceAreaData: { state: null, confidence: null },
                compliance: solicitation.supplierCompliance,
                rank: -1,
                supplierDisplay: solicitation.supplierDisplay,
                similarOfferings: [],
                supplierAgreement: solicitation.supplierAgreement,
              }}
              selected={toggled}
              onClick={() => {
                if (disabled) return;
                toggle(messageSupplierData);
              }}
              cta={
                <Checkbox
                  disabled={disabled}
                  label=""
                  name="check"
                  checked={toggled}
                  onChange={() => {
                    toggle(messageSupplierData);
                  }}
                />
              }
            />
          );
        })}
        {totalSuppliersCount &&
          currentSuppliers.length < totalSuppliersCount && (
            <div className="flex justify-around py-2">
              <ViewMoreButton
                className="analytics-message-supplier-modal-view-more"
                onClick={handleViewMore}
                isLoading={isLoadingContracts}
              />
            </div>
          )}
      </div>
    </Modal>
  );
}

// Note: prop values are not typesafe because of how useShowModal works
interface OtherSuppliersFromSolicitationModalProps {
  hideModal: () => void;
  initialCurrentSuppliers: BaseContract[];
  fetchMore: () => void;
  isLoadingContracts: boolean;
  initialTotalSuppliersCount: number;
  initialPage: number;
  supplierId: number;
  solicitationId: string;
  contractId: string;
  readOnlySupplierHandles: string[];
  initialSuppliersToContact: MessageSupplierData[];
  setSuppliersToContact: Dispatch<MessageSupplierData[]>;
}
