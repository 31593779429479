import { useEffect } from "react";

import { getParams, setParamNoHistory } from "../utils";

/**
 * 2 way data-bind page window <=> react state, allowing for persistent shareable URLs
 * Handles page params & state vars all in one dict
 * eg
 *  params ?query=stuff&zip=63111&page=2
 *  maps to
 *  paramsValue {"query": "stuff", "zip": "63111", "page": "2"}
 *
 * @param {object} paramsValue - getter from useState, a Dict<str:str>
 * @param {setter fn} setParamsValue - setter from useState
 */
export function useBindPageParamDict(paramsValue, setParamsValue) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: On page load, will invoke the setter setValue
  useEffect(() => {
    const params = getParams();
    setParamsValue(params);
  }, []);

  // Within the page, when `paramsValue` is updated, also update page params
  // (state -> page binding)
  useEffect(() => {
    Object.keys(paramsValue).forEach((key) => {
      setParamNoHistory(key, paramsValue[key]);
    });
    // Note: there's no cleanup function here, since matching the behavior above would
    // cause every URL param to come unset, which makes the URL bar flicker unnecessarily.
  }, [paramsValue]);
}
