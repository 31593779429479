import { useState } from "react";
import { useCreateSupplierContractFragment } from "../../hooks/useCreateSupplierContractFragment";
import type { SubmitFn } from "../../library/form/types";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import SupplierEditFormWrapper from "../supplier/SupplierEditForm/SupplierEditFormWrapper";
import { SUPPLIER_CONTRACT_BRANDS_FIELDS } from "../supplier/SupplierEditForm/constants";
import {
  StatusType,
  type SupplierContractBrandValues,
} from "../supplier/SupplierEditForm/types";

function splitAndCleanBrands(brands: Maybe<string>): string[] {
  let separator = ",";
  if (!brands?.includes(",")) {
    separator = "\n";
  }
  const cleanedList = brands
    ?.split(separator)
    .map((brand) => brand.trim())
    .filter((brand) => !!brand);
  if (cleanedList?.length) return cleanedList;
  return [];
}

export default function EditBrands({
  contractDetails,
  complete,
  setBrandsTaskCompletion,
}: {
  contractDetails: ContractSolicitationDetails;
  complete: boolean;
  setBrandsTaskCompletion: (hasBrands: boolean) => void;
}) {
  const {
    brands: initialBrands,
    contract_id: contractId,
    supplier,
  } = contractDetails;
  const [savedFields, setSavedFields] = useState<string[]>([]);
  const createSupplierContractFragment = useCreateSupplierContractFragment();

  const onSubmit: SubmitFn<SupplierContractBrandValues> = async (values, _) => {
    const brands = splitAndCleanBrands(values.brands);
    const success = await createSupplierContractFragment({
      supplierId: supplier.id,
      contractId,
      brands: brands,
    });
    if (!success) return false;

    setBrandsTaskCompletion(!!brands.length);
    setSavedFields(Object.keys(values));
    return true;
  };

  return (
    <SupplierEditFormWrapper
      fields={SUPPLIER_CONTRACT_BRANDS_FIELDS}
      onSubmit={onSubmit}
      initialValues={{ brands: initialBrands.join(", ") }}
      callouts={[
        {
          title: "Why add brands?",
          description:
            "This information helps match buyer searches for brand names to your profile and contracts.",
        },
      ]}
      savedFields={savedFields}
      status={complete ? StatusType.COMPLETE : StatusType.INCOMPLETE}
    />
  );
}
