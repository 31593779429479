import { useSetAtom } from "jotai";
import {
  initializeUserCallback,
  initializeUserZipCallback,
  userInitializedState,
} from "../jotai/user";

export default function useInitializeUser() {
  const initializeUser = initializeUserCallback();
  const initializeUserZip = initializeUserZipCallback();
  const setUserInitializedState = useSetAtom(userInitializedState);

  return async () => {
    // After the user is initialized, we can initialize the zip code.
    await initializeUser();
    await initializeUserZip();
    setUserInitializedState(true);
  };
}
