import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { useState } from "react";

import { contractSearchAnalyticsParamsState } from "../jotai/search";
import { userZipState } from "../jotai/user";
import type { CTA } from "../shared/ContractBase/types";
import { getParam } from "../utils";
import { handleError, postBenchmarkResult } from "../utils/api";
import { BenchmarkingScore } from "../utils/enums";

import { useAtomValue } from "jotai";
import useIsDebug from "./useIsDebug";

export function useBenchmarkingCtas({
  solicitationId,
  rank,
}: {
  solicitationId: string;
  rank: number;
}): CTA[] {
  const debug = useIsDebug();
  const { requestID } = useAtomValue(contractSearchAnalyticsParamsState);
  const userZip = useAtomValue(userZipState);
  const sessionId = getParam("benchmarkingSessionId");
  const [benchmarked, setBenchmarked] = useState(false);

  // Don't show benchmarking ctas if there is no session to avoid accidentally benchmarking
  // without one. Additionally, drop ctas if this result has already been clicked.
  if (!debug || !sessionId || benchmarked) return [];
  const onClick = async (relevance: BenchmarkingScore): Promise<void> => {
    const res = await postBenchmarkResult({
      sessionId,
      requestId: requestID || "",
      rank,
      zipCode: userZip,
      solicitationId,
      relevance,
    });
    handleError(res);
    setBenchmarked(true);
  };

  return [
    {
      styling: "success",
      Icon: ThumbUpOutlinedIcon,
      onClick: () => onClick(BenchmarkingScore.GOOD),
    },
    {
      styling: "warning",
      Icon: SentimentNeutralOutlinedIcon,
      onClick: () => onClick(BenchmarkingScore.NEUTRAL),
    },
    {
      styling: "destructive",
      Icon: ThumbDownOutlinedIcon,
      onClick: () => onClick(BenchmarkingScore.BAD),
    },
  ];
}
