import { createRoot } from "react-dom/client";

import LoginPage from "../../components/Account/Login";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import Page from "../Page/Page";

export default function Login({
  social_login_source,
}: {
  social_login_source?: string;
}) {
  return (
    <Page
      pageId=""
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      profileType={null}
      activeAgreements={null}
    >
      <LoginPage defaultSocialLoginSource={social_login_source || ""} />
    </Page>
  );
}

const container = getDOMAnchorById("login-container");
if (container) {
  const root = createRoot(container);
  root.render(<Login />);
}
