import clsx from "clsx";
import { Fragment, type ReactElement } from "react";
import type { FormFieldProps } from "./form/types";

const FORM_STYLES = "flex flex-col w-full text-left gap-5";
const FORM_SECTION_STYLES = "flex flex-col gap-x-4 gap-y-5 w-full";
const FORM_DIVIDER_STYLES = "my-3 last:hidden";

interface SectionedFieldsProps {
  sections: (FormFieldProps[][] | FormFieldProps[])[];
  fieldMapFn: (field: FormFieldProps, index: number) => ReactElement;
  className?: string;
}

export default function SectionedFields({
  sections,
  fieldMapFn,
  className,
}: SectionedFieldsProps) {
  function renderSection(section: FormFieldProps[]) {
    return (
      <>
        <div className={FORM_SECTION_STYLES}>{section.map(fieldMapFn)}</div>
        <hr className={FORM_DIVIDER_STYLES} />
      </>
    );
  }

  function render2DSection(section: FormFieldProps[][]) {
    return (
      <>
        {section.map((row, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: The index is ok here since form configs are static.
          <div key={index} className={clsx(FORM_SECTION_STYLES, "sm:flex-row")}>
            {row.map(fieldMapFn)}
          </div>
        ))}
        <hr className={FORM_DIVIDER_STYLES} />
      </>
    );
  }

  if (!sections[0].length) return null;

  return (
    <div className={clsx(FORM_STYLES, className)}>
      {sections.map((section, index) => {
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: The index is ok here since form configs are static.
          <Fragment key={index}>
            {Array.isArray(section[0])
              ? render2DSection(section as FormFieldProps[][])
              : renderSection(section as FormFieldProps[])}
          </Fragment>
        );
      })}
    </div>
  );
}
