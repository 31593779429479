import clsx from "clsx";
import { type MouseEvent, useState } from "react";

import { Button } from "../../library";

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success: boolean;
  try {
    success = document.execCommand("copy");
  } catch {
    success = false;
  }

  document.body.removeChild(textArea);
  return success;
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    return Promise.resolve(fallbackCopyTextToClipboard(text));
  }
  return navigator.clipboard.writeText(text).then(
    () => true,
    () => false
  );
}

export function copyDivToClipboard(
  elementId: string,
  selectorBackgroundToClear: string
) {
  const element = document.getElementById(elementId);
  if (!element) {
    return Promise.resolve(false);
  }

  // remove background color of a parent element to prevent
  // that color from copying to clipboard
  let parentWithBackground: HTMLElement | null = null;
  let oldBackground: string | null = null;
  if (selectorBackgroundToClear) {
    parentWithBackground = document.querySelector(
      selectorBackgroundToClear
    ) as HTMLElement;
    oldBackground = parentWithBackground.style.background;
    parentWithBackground.style.background = "transparent";
  }

  // copy div contents
  const range = document.createRange();
  range.selectNode(element);
  window.getSelection()?.removeAllRanges(); // clear current selection
  window.getSelection()?.addRange(range); // to select text
  document.execCommand("copy");
  window.getSelection()?.removeAllRanges(); // to deselect

  // add back a parent background color to restore element
  if (selectorBackgroundToClear && parentWithBackground) {
    (parentWithBackground as HTMLElement).style.background =
      oldBackground || "";
  }
  return Promise.resolve(true);
}

interface CopyButtonProps {
  divIdToCopy: string;
  selectorBackgroundToClear: string;
  textToCopy: string;
  btnText: string;
  className: string;
}

export default function CopyButton({
  divIdToCopy,
  selectorBackgroundToClear,
  textToCopy,
  btnText,
  className,
}: CopyButtonProps) {
  const [successCopy, setSuccessCopy] = useState<boolean>(false);

  function copyText(e?: MouseEvent) {
    e?.stopPropagation();

    let promise: Promise<boolean> | undefined;
    if (textToCopy) {
      promise = copyTextToClipboard(textToCopy);
    } else if (divIdToCopy) {
      promise = copyDivToClipboard(divIdToCopy, selectorBackgroundToClear);
    }
    if (!promise) {
      return;
    }

    promise.then((success) => {
      setSuccessCopy(success);
      setTimeout(() => {
        setSuccessCopy(false);
      }, 2000);
    });
  }

  return (
    <Button className={clsx({ [className]: className })} onClick={copyText}>
      {successCopy ? "Copied!" : btnText}
    </Button>
  );
}
