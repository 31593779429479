import clsx from "clsx";
import type { ReactNode } from "react";

import { Checkbox } from "../../library";

interface PreferenceCheckboxProps {
  name: string;
  analyticsClassName?: string;
  label: string;
  helperText?: ReactNode;
  onChange: (name: string, checked: boolean) => void;
  checked: boolean;
  size?: "none" | "sm" | "lg";
}

export default function PreferenceCheckbox({
  name,
  analyticsClassName,
  label,
  helperText,
  onChange,
  checked,
  size = "sm",
}: PreferenceCheckboxProps) {
  return (
    <div
      className={clsx({
        "py-3": size === "sm",
        "py-6": size === "lg",
      })}
    >
      <Checkbox
        align="top"
        name={name}
        checkboxClassName={analyticsClassName}
        label={label}
        onChange={(e) => onChange(name, e.target.checked)}
        checked={checked}
      />
      {helperText}
    </div>
  );
}
