/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ALL_NATIONAL_COOP` - All National Coop
 * * `ALL_STATE_SOURCE` - All State Source
 * * `ALL_ENTITY_TYPE_SOURCE` - All Entity Type Source
 */
export enum SourceAgencyGroupEnum {
    ALL_NATIONAL_COOP = 'ALL_NATIONAL_COOP',
    ALL_STATE_SOURCE = 'ALL_STATE_SOURCE',
    ALL_ENTITY_TYPE_SOURCE = 'ALL_ENTITY_TYPE_SOURCE',
}
