import { captureMessage } from "@sentry/browser";
import { useAtomValue } from "jotai";
import { ApiService } from "../generated";

import {
  governmentAffiliationDisplayNameLocalState,
  userDetailsState,
} from "../jotai/user";
import { FormWrapper } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { handleError } from "../utils/generatedApi";

import { MODAL_SOURCE } from "../utils/enums";
import { trackSearchFeedbackSubmit } from "../utils/tracking";
import { baseContactFields, useSubmitAndSave } from "./constants";

const FIELDS = baseContactFields("Provide more details about your search...");

interface FeedbackValues {
  email: string;
  publicAgency: string;
  message: string;
}

interface FeedbackModalProps {
  hideModal: () => void;
  query?: string;
  requestID?: string;
  source?: MODAL_SOURCE;
}

export default function FeedbackModal({
  hideModal,
  query,
  requestID,
  source,
}: FeedbackModalProps) {
  const { email } = useAtomValue(userDetailsState);
  const publicAgency = useAtomValue(governmentAffiliationDisplayNameLocalState);
  // Check for query, requestID, and requestHelpSource manually
  // since ModalConductor can't handle required props specific to a modal
  if (!query || !requestID || !source) {
    captureMessage("Missing required props for FeedbackModal", {
      extra: { query, requestID, source },
    });
  }
  const [handleSubmit, loading] = useSubmitAndSave(
    hideModal,
    async (values: FeedbackValues) => {
      try {
        await ApiService.apiV1ContactSearchFeedbackCreate({
          email: values.email,
          agency: values.publicAgency,
          message: values.message,
          query: query || "",
        });
        trackSearchFeedbackSubmit({
          requestID: requestID || "",
          source: source || MODAL_SOURCE.SEARCH_TIPS_SIDE_BAR,
        });
      } catch (e) {
        handleError(e);
      }
    }
  );

  return (
    <Modal
      className="feedback-modal-wrapper width-large"
      title="Get help finding a contract"
      hideModal={hideModal}
      subtitle="Tell us more about your need—it helps to be as specific as possible.
      Our team will do additional research and get back to you."
    >
      <FormWrapper
        fields={FIELDS}
        initialValues={{
          email: email || "",
          publicAgency,
          message: "",
        }}
        onSubmit={handleSubmit}
        disabled={loading}
        submitCta="Submit"
        submitClassName="analytics-submit-feedback"
      />
    </Modal>
  );
}
