import { useEffect } from "react";

import {
  initializeRecentlyViewedContractsCallback,
  piggybackedContractsState,
} from "../../jotai/entityContracts";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../library";
import type { GovernmentAgency } from "../../shared/types";

import { useAtomValue } from "jotai";
import ContractTabs from "./ContractTabs";
import TopCountsSection from "./TopCountsSection";

interface EntityContractsPageProps {
  governmentAgency: GovernmentAgency;
  total: number;
  coopSolicitationsCount: number;
  totalSolicitationCount: number;
  totalContractsCount: number;
  coopContractsCount: number;
  expiringSoonCount: number;
  piggybacksCount: number;
  piggybacksExpiringSoonCount: number;
}

export default function EntityContractsPage({
  total,
  governmentAgency,
  coopSolicitationsCount,
  totalSolicitationCount,
  totalContractsCount,
  coopContractsCount,
  expiringSoonCount,
  piggybacksCount,
  piggybacksExpiringSoonCount,
}: EntityContractsPageProps) {
  const piggybackedContracts = useAtomValue(piggybackedContractsState);
  const initializeRecentlyViewedContracts =
    initializeRecentlyViewedContractsCallback();
  useEffect(() => {
    initializeRecentlyViewedContracts();
  }, [initializeRecentlyViewedContracts]);

  return (
    <>
      <Button
        as={Link}
        linkProps={{
          href: "/entity-contracts/update",
          underline: false,
        }}
        theme={ButtonThemes.PRIMARY_DARK}
        size={ButtonSizes.SMALL}
        className="float-right analytics-entity-contracts-update-link-button"
      >
        Update contracts
      </Button>
      <Typography
        variant="headline"
        size="lg"
        color="brand.boldest.enabled"
        className="mt-12 mb-8"
      >
        {entityDisplayName(governmentAgency)}
      </Typography>
      <TopCountsSection
        total={total}
        coopSolicitationsCount={coopSolicitationsCount}
        totalSolicitationCount={totalSolicitationCount}
        totalContractsCount={totalContractsCount}
        coopContractsCount={coopContractsCount}
        expiringSoonCount={expiringSoonCount}
        piggybacksCount={piggybackedContracts.length || piggybacksCount}
        piggybacksExpiringSoonCount={piggybacksExpiringSoonCount}
      />
      <ContractTabs />
    </>
  );
}

function entityDisplayName(governmentAgency: GovernmentAgency) {
  return [
    governmentAgency.name,
    governmentAgency.appendStateForDisplay ? governmentAgency.stateCode : null,
  ]
    .filter(Boolean)
    .join(", ");
}
