import { useAtomValue, useSetAtom } from "jotai";
import { type Ref, forwardRef, useState } from "react";

import { Link, Typography } from "../../../library";
import SimpleSearchBar from "../../../shared/SearchBar/SimpleSearchBar";

import type { ContractHit } from "../../../generated";
import useIsDebug from "../../../hooks/useIsDebug";
import useRequestID from "../../../hooks/useRequestID";
import {
  supplierContractLastSearchQueryState,
  supplierContractSearchState,
  useSearchSupplierContractsCallback,
} from "../../../jotai/supplierContracts";
import { userZipState } from "../../../jotai/user";
import PaginatedListButtons from "../../../library/PaginatedListButtons";
import { getContractUrl, getTags } from "../../../shared/ContractBase";
import { LoadingSlimCards } from "../../../shared/Loading/LoadingCards";
import SearchFilterHeader, {
  SearchFilter,
} from "../../../shared/SearchPage/SearchResults/SearchFilterHeader";
import { getParam, goToURL } from "../../../utils";
import { parseDate } from "../../../utils/date";
import {
  LoginWallTriggers,
  SearchSource,
  pageNavigationSourceTypes,
  searchFilters,
} from "../../../utils/enums";
import {
  trackSupplierProfileContractSearch,
  trackViewContractFromSupplierProfile,
} from "../../../utils/tracking";
import { SlimContractCard } from "../../ContractSearch/SlimContractCard";
import type { SearchOptions } from "../../ContractSearch/types";

export const DEFAULT_FILTERS = [
  searchFilters.INCLUDE_NON_COOP,
  searchFilters.INCLUDE_EXPIRED,
];

interface PaginatedContractListProps {
  contracts: ContractHit[];
  page: number;
  pageSize: number;
}

function PaginatedContractList({
  contracts,
  page,
  pageSize,
}: PaginatedContractListProps) {
  const isDebug = useIsDebug();
  const requestID = useRequestID();
  const query = getParam("query");
  const userZip = useAtomValue(userZipState);

  function onContractClick(
    {
      docid,
      supplierId,
      supplierHandle,
      solicitationId,
      numSuppliers,
      offerings,
    }: ContractHit,
    index: number,
    query: Maybe<string>
  ) {
    const rank = page * pageSize + index + 1;

    trackViewContractFromSupplierProfile({
      rank,
      contractId: docid,
      supplierId,
      supplierHandle,
      numDocs: numSuppliers || 0,
      requestID,
    });

    goToURL(
      getContractUrl(
        solicitationId,
        docid,
        query,
        userZip,
        false,
        !!offerings.length,
        "",
        [],
        pageNavigationSourceTypes.SUPPLIER_PAGE,
        requestID
      )
    );
  }

  return (
    <div className="flex flex-col w-full gap-4">
      {contracts.map(
        (
          {
            docid,
            title,
            buyerLeadAgency,
            expirationTimestamp,
            contractNumber,
            cooperativeLanguage,
            cooperativeAffiliation,
            contractTags: contractTagData,
          },
          ix
        ) => {
          const expirationDate = parseDate(expirationTimestamp);
          const { contractTagElements: contractTags } = getTags({
            isCooperative: cooperativeLanguage,
            expiration_ts: expirationTimestamp,
            expiration_date: expirationDate,
            isDebug,
            contractTagData,
            size: "md",
            transparent: true,
          });

          return (
            <SlimContractCard
              key={docid}
              onClick={() => onContractClick(contracts[ix], ix, query)}
              title={title}
              contractTagElements={contractTags}
              contractNumber={contractNumber}
              ctaStyling="secondary"
              docId={docid}
              source={buyerLeadAgency}
              cooperative={cooperativeAffiliation ?? ""}
              loginWallTrigger={LoginWallTriggers.SUPPLIER_PAGE_CONTRACT_CLICK}
              ctaAnalyticsClass="analytics-supplier-profile-view-contract"
              className="!p-4"
              inlineTags
              expirationTimestamp={expirationTimestamp}
              hit={contracts[ix]}
            />
          );
        }
      )}
    </div>
  );
}

interface ScannableSupplierContractListProps {
  supplierName: string;
  supplierId: number;
  supplierHandle: string;
}

const ScannableSupplierContractList = forwardRef(
  (
    {
      supplierName,
      supplierId,
      supplierHandle,
    }: ScannableSupplierContractListProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { isLoading, isInitialized, supplierContracts, lastSearchQuery } =
      useAtomValue(supplierContractSearchState);
    const setLastSearchQuery = useSetAtom(supplierContractLastSearchQueryState);
    const [page, setPage] = useState(0);
    const supplierContractSearch = useSearchSupplierContractsCallback();
    const [filters, setFilters] = useState<string[]>(DEFAULT_FILTERS);
    const requestID = useRequestID();

    function reset() {
      setLastSearchQuery("");
      search("", 0, DEFAULT_FILTERS);
    }

    async function search(
      query: string,
      searchPage: number,
      filters: string[]
    ) {
      setPage(searchPage);
      trackSupplierProfileContractSearch({
        supplierId,
        supplierHandle,
        page: searchPage,
        filters,
        query,
        requestID,
      });
      await supplierContractSearch(supplierId, searchPage, filters, query);
    }

    const numPages = Math.ceil(
      supplierContracts.contractsTotalCount / supplierContracts.pageSize
    );
    const hasPages = !isInitialized || numPages > 0;

    const supplierPageFilters = [
      SearchFilter.SOURCES,
      SearchFilter.EXPIRATION,
      SearchFilter.CONTRACT_DOCUMENTS,
      SearchFilter.OTHER,
    ];

    const onSearch = (options: SearchOptions) =>
      supplierContractSearch(
        supplierId,
        0,
        options.newParams.filters ?? DEFAULT_FILTERS,
        lastSearchQuery
      );

    return (
      <div
        ref={ref}
        className="flex flex-col gap-4 relative scroll-m-[10rem] z-0"
      >
        <Typography
          variant="headline"
          color="brand.boldest.enabled"
          size="sm"
          emphasis
        >
          {`${supplierName} contracts (${supplierContracts.contractsTotalCount})`}
        </Typography>
        <SimpleSearchBar
          search={(query) => search(query, 0, filters)}
          ariaLabel="Search contracts"
          placeholder="Search contracts"
          query={lastSearchQuery}
        />
        <SearchFilterHeader
          filters={filters}
          setFilters={setFilters}
          onFilterChange={() => {}}
          searchSource={SearchSource.SUPPLIER_PAGE}
          onSearch={onSearch}
          filtersToShow={supplierPageFilters}
          hideSearchResultTypeToggle
        />
        {isLoading && <LoadingSlimCards />}
        {!hasPages && !isLoading && isInitialized && (
          <Typography>
            No contract results for "{lastSearchQuery}." Try a different term or{" "}
            <Link emphasis={false} onClick={reset}>
              browse all {supplierName} contracts
            </Link>
            .
          </Typography>
        )}
        {hasPages && !isLoading && (
          <>
            <PaginatedContractList
              contracts={supplierContracts.results}
              page={page}
              pageSize={supplierContracts.pageSize}
            />
            <PaginatedListButtons
              onClick={(next) => search(lastSearchQuery, next, filters)}
              index={page}
              count={numPages}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    );
  }
);

export default ScannableSupplierContractList;
