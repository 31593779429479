import { useAtomValue } from "jotai";
import BLAAutocompleteInput from "../../components/BLAAutocompleteInput";
import { blaFieldSignupState } from "../../jotai/signup";
import type { InputSizes } from "../Input/LabeledInput";
import Typography from "../Typography";
import type { CustomFormProps, FieldLabelProps } from "./types";

export interface BuyerLeadAgencyFieldValue {
  governmentAffiliationDisplayName: string;
  governmentAgencyId?: string;
}
interface BuyerLeadAgencyFieldProps extends FieldLabelProps {
  field: {
    name: string;
    value: BuyerLeadAgencyFieldValue;
  };
  editable: boolean;
  placeholder?: string;
  form: CustomFormProps;
  dataTestId?: string;
  sublabel?: string;
  size?: InputSizes;
  validationIcons?: boolean;
}

export default function BuyerLeadAgencyField({
  field,
  editable,
  placeholder = "ex. City of Mesa, AZ",
  form: { setFieldValue, errors, touched },
  ...rest
}: BuyerLeadAgencyFieldProps) {
  const buyerProfile = field.value;
  const buyerProfileFromSignup = useAtomValue(blaFieldSignupState);

  if (!editable) {
    const text = !buyerProfile
      ? "(none)"
      : buyerProfile.governmentAffiliationDisplayName;
    return <Typography>{text}</Typography>;
  }

  return (
    <div className="grid grid-flow-col gap-4">
      <div className="relative" data-testid="bla-input-container">
        <BLAAutocompleteInput
          analyticsClass="analytics-profile-page-govAffiliation-input"
          placeholder={placeholder}
          initialValue={buyerProfile.governmentAffiliationDisplayName}
          initialId={buyerProfile.governmentAgencyId}
          errorMessage={
            touched[field.name] && errors[field.name]
              ? "Public entity is required"
              : ""
          }
          onChange={(name, agency) => {
            if (
              agency?.id === buyerProfile.governmentAgencyId &&
              name === buyerProfile.governmentAffiliationDisplayName
            ) {
              return;
            }

            const governmentAgencyId =
              buyerProfileFromSignup?.governmentAffiliationDisplayName === name
                ? buyerProfileFromSignup.governmentAgencyId
                : agency?.id;

            setFieldValue(
              field.name,
              {
                ...buyerProfile,
                governmentAffiliationDisplayName: name,
                governmentAgencyId,
              },
              false
            );
          }}
          {...rest}
        />
      </div>
    </div>
  );
}
