import _omitBy from "lodash/omitBy";
import { ApiService, type QuoteRequestRequest } from "../generated";

import { FormWrapper } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { handleError } from "../utils/generatedApi";

import { useAtomValue } from "jotai";
import { ProjectCreationSource } from "../components/Project/types";
import { MODAL_FORM } from "../components/QuoteRequest/constants";
import { pageTypeState } from "../jotai/page";
import { requestIDState } from "../jotai/search";
import { Button, ButtonThemes } from "../library";
import { getParams, goToURL } from "../utils";
import { trackNewProject, trackQuoteRequestSubmit } from "../utils/tracking";
import { useSubmitAndSave } from "./constants";

interface QuoteRequestModalProps {
  hideModal: () => void;
  description: string;
}

export default function QuoteRequestModal({
  hideModal,
  description,
}: QuoteRequestModalProps) {
  const params = getParams();
  const requestID = useAtomValue(requestIDState);
  const pageType = useAtomValue(pageTypeState);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: Omit<QuoteRequestRequest, "description">) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const [project, quoteRequest] = await Promise.all([
          ApiService.apiV1ProjectsCreate({
            name: projectName,
          }),
          ApiService.apiV1QuoteRequestsCreate({
            description,
            searchLogId: requestID,
            ..._omitBy(values, (v) => !v),
          }),
        ]);

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });
        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
          pageType,
          quoteRequestId: quoteRequest.id,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }
  );

  return (
    <Modal
      title="We're happy to help! We need a few details..."
      hideModal={hideModal}
    >
      <FormWrapper
        className="gap-4"
        fields={MODAL_FORM}
        initialValues={{
          requestedCc: [],
          additionalRequirementsDetails: "",
          requestedPhoneNumber: "",
          purchaseTimeframe: "",
        }}
        onSubmit={handleSubmit}
        disabled={loading}
      >
        <div className="flex flex-row gap-2 justify-center">
          <Button
            className="analytics-submit-quote-request"
            type="submit"
            theme={ButtonThemes.PRIMARY_DARK}
            disabled={loading}
          >
            Submit request
          </Button>
          <Button theme={ButtonThemes.TERTIARY_DESTRUCTIVE} onClick={hideModal}>
            Cancel request
          </Button>
        </div>
      </FormWrapper>
    </Modal>
  );
}
