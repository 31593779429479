import pluralize from "pluralize";
import { formatDate, parseDate } from "../../../utils/date";
import type { ViewAnalytics } from "../types";

export function getCallouts({
  viewAnalytics,
  supplierName,
}: { viewAnalytics: ViewAnalytics; supplierName?: Maybe<string> }) {
  return [
    {
      title: "What is a qualified entity view?",
      description:
        "An entity view consists of multiple views from a single entity in a given month. An entity view is qualified if the entity is looking to buy what you sell in an area that you service.",
    },
    {
      title: `How many of ${
        supplierName || "your company"
      }’s views are qualified?`,
      description: getQEVText({ viewAnalytics, supplierName: supplierName }),
    },
  ];
}

function getQEVText({
  viewAnalytics,
  supplierName,
}: { viewAnalytics: ViewAnalytics; supplierName: Maybe<string> }) {
  supplierName = supplierName ?? "your company";
  const date = formatDate(
    parseDate(viewAnalytics.dateViewsStart),
    "MM/dd/yyyy"
  );

  const firstSentence = `As of your Pro plan start date (${date}), ${supplierName} has received a total of ${
    viewAnalytics.totalViews
  } entity ${pluralize("view", viewAnalytics.totalViews)}.`;

  const secondSentence =
    viewAnalytics.qualifiedEntityViews === 0
      ? "None of these views were qualified."
      : `${viewAnalytics.qualifiedEntityViews} of these ${pluralize(
          "was",
          viewAnalytics.qualifiedEntityViews
        )} qualified.`;

  return `${firstSentence} ${secondSentence}`;
}
