import { useState } from "react";
import { SuccessPopup } from "../../popups/AnimatedPopup";
import { getParam, setParamNoHistory } from "../../utils";

export default function VerificationSuccessPopup() {
  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(
    getParam("emailConfirmed") === "true"
  );
  return (
    <SuccessPopup
      show={hasConfirmedEmail}
      setShow={(show) => {
        setHasConfirmedEmail(show);
        setParamNoHistory("emailConfirmed", null);
      }}
      onClose={() => setHasConfirmedEmail(false)}
    >
      Success! Your email is now verified.
    </SuccessPopup>
  );
}
