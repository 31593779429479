import { captureException, withScope } from "@sentry/browser";
import { zip } from "fflate";
import { saveAs } from "file-saver";
import pLimit from "p-limit";
import type { RefObject } from "react";

import { downloadRemoteFileContents } from "../../utils/api";

// Download files with limited concurrency to improve download all speed.
const limit = pLimit(10);

const FILE_EXTENSION_REGEX = /\.([\w]{3,4})$/i;

function getFileExtension(fileName: string): string {
  const match = fileName.match(FILE_EXTENSION_REGEX);
  return match ? match[1].toLowerCase() : "";
}

// Taken from https://codesandbox.io/s/6pn8y
export async function downloadAllFiles(
  files: { url: string; name: string }[],
  zipFileName: string
) {
  // zip takes in toZip, which is of type AsyncZippable and needs to resemble the directory structure
  // since we are just providing the contracts with no folders, this is flat and will just be all the files
  const toZip: Record<string, Uint8Array> = {};
  const successfulDownloads: string[] = [];
  const failedDownloads: string[] = [];
  let latestException: Error | null = null;

  await Promise.all(
    files.map((file) => {
      return limit(async () => {
        const file_name = getFileExtension(file.name)
          ? file.name
          : `${file.name}.${getFileExtension(file.url)}`;
        try {
          const buffer = new Uint8Array(
            await downloadRemoteFileContents(file.url)
          );
          toZip[file_name] = buffer;
          successfulDownloads.push(file_name);
        } catch (exp) {
          latestException = exp as Error;
          failedDownloads.push(file_name);
        }
      });
    })
  );

  const data = await new Promise<Uint8Array>((res, rej) => {
    zip(toZip, { consume: true }, (err, data) => {
      if (err) return rej(err);
      res(data);
    });
  });

  if (successfulDownloads.length > 0) {
    const blob = new Blob([data], { type: "application/zip" });
    saveAs(blob, `${zipFileName}.zip`);
  } else if (files.length > 0) {
    // This indicates we've totally failed to download anything. This is an interesting case, so emit to Sentry.
    withScope((scope) => {
      scope.setExtra(
        "ONCALL_CONTEXT",
        "This triggers _only_ if download all failed to download anything. We emit only the last exception."
      );
      captureException(latestException);
    });
    throw "No files successfully downloaded.";
  }

  if (failedDownloads.length > 0) {
    throw `Failed to download these files: ${failedDownloads.join(", ")}`;
  }
}

export function scrollToSection({
  sectionRef,
  options,
}: {
  sectionRef: RefObject<HTMLDivElement>;
  options?: ScrollIntoViewOptions;
}) {
  setTimeout(() => {
    if (!sectionRef.current) return;
    const defaultOptions: ScrollIntoViewOptions = {
      behavior: "smooth",
    };

    sectionRef.current.scrollIntoView(options || defaultOptions);
  }, 0);
}
