import clsx from "clsx";
import type { MouseEvent, ReactNode } from "react";

import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Typography,
} from "../../../../../library";
import { borderColorClass } from "../../../../../utils/colors";
import StatusChip from "../../StatusChip";
import type { StatusType } from "../../types";

interface DialogCTA {
  text: string;
  onClick: (e?: MouseEvent) => void;
}

interface ContractDialogProps {
  status?: StatusType;
  primaryCta?: DialogCTA;
  secondaryCta?: DialogCTA;
  text?: string;
  children?: ReactNode;
}

export default function ContractDialog({
  status,
  primaryCta,
  secondaryCta,
  text,
  children,
}: ContractDialogProps) {
  return (
    <div
      className={clsx(
        "flex flex-col gap-3 border-l-3 border-solid px-3 h-fit w-[24rem]",
        borderColorClass.brand.bold.enabled
      )}
    >
      {status && <StatusChip status={status} />}
      {text && (
        <Typography size="sm" color="neutral.bolder.enabled">
          {text}
        </Typography>
      )}
      {children}
      <div className="flex gap-4">
        {primaryCta && (
          <Button
            theme={ButtonThemes.PRIMARY_LIGHT}
            onClick={primaryCta.onClick}
            size={ButtonSizes.SMALL}
            className="max-content"
          >
            <Typography
              color="brand.boldest.enabled"
              variant="cta"
              size="sm"
              emphasis
            >
              {primaryCta.text}
            </Typography>
          </Button>
        )}
        {secondaryCta && (
          <Button
            theme={ButtonThemes.SECONDARY_LIGHT}
            onClick={secondaryCta.onClick}
            size={ButtonSizes.SMALL}
            className="max-content"
          >
            <Typography
              color="brand.boldest.enabled"
              variant="cta"
              size="sm"
              emphasis
            >
              {secondaryCta.text}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
}
