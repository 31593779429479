import { Typography } from "../../../library";

import PavilionFavicon from "../../../../img/icons/pavilion-favicon.svg";

export default function SupplierContactGuarantee() {
  return (
    <div className="flex gap-2">
      <img src={PavilionFavicon} className="w-6 h-6 flex-none rounded-full" />
      <div className="flex flex-col gap-1">
        <Typography color="neutral.bolder.enabled" emphasis>
          Backed by Pavilion
        </Typography>
        <Typography variant="meta" size="sm" color="neutral.bolder.enabled">
          If this supplier is slow to respond, we’ll follow up for you and help
          find great alternatives.
        </Typography>
      </div>
    </div>
  );
}
