import type { FormikHelpers, FormikValues } from "formik";
import { useAtom, useAtomValue } from "jotai";
import Cookies from "js-cookie";
import { useState } from "react";

import useCookie from "../../hooks/useCookie";
import { userBonfireDetailsState, userStateState } from "../../jotai/user";
import { getPasswordErrorMessage } from "../../library/PasswordInput";
import { handleError, patchUserState, postSignup } from "../../utils/api";
import { SIGNUP_ORIGIN_COOKIE_KEY } from "../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../utils/tracking";
import { appendInitialCompleteAccountValues } from "./helpers";
import type { WindowType } from "./types";

export default function useSubmitBuyerInfo({
  email,
  onComplete,
  prefilledBLA,
  prefilledBLAId,
  parentWindow,
}: {
  email?: string;
  onComplete: (redirectUrl: string) => void;
  prefilledBLA: string;
  prefilledBLAId: string | null;
  parentWindow: WindowType;
}): [
  boolean,
  (values: FormikValues, helpers: FormikHelpers<FormikValues>) => void,
] {
  const [loading, setLoading] = useState(false);
  const bonfireDetails = useAtomValue(userBonfireDetailsState);
  const [userState, setUserState] = useAtom(userStateState);
  const [signupOrigin] = useCookie<SignupOrigin>(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );

  async function submitBuyerInfo(
    values: FormikValues,
    { setFieldError }: FormikHelpers<FormikValues>
  ) {
    setLoading(true);

    let form = new FormData();
    form = appendInitialCompleteAccountValues(form, values, email as string);
    form.append("bonfire_id", bonfireDetails?.userId);
    form.append(
      "governmentAffiliation",
      values.buyerProfile.governmentAffiliationDisplayName
    );
    if (values.buyerProfile.governmentAgencyId) {
      form.append("blaID", values.buyerProfile.governmentAgencyId);
    }
    form.append("role", values.buyerRole);
    const response = await postSignup(form);
    setLoading(false);

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        // When signing up, we use a form that specifically validates password1
        // https://django-allauth.readthedocs.io/en/latest/forms.html
        if (error?.password1) {
          const passwordErrorMessage = getPasswordErrorMessage(error.password1);
          setFieldError("password", passwordErrorMessage);
          trackSignupFlowFailure({
            emailEntered: email,
            loginType: LoginType.PAVILION,
            signupStep: loginSignupSteps.SIGNUP,
            error: passwordErrorMessage,
            accountType: loginSignupAccountTypes.BUYER,
            loginExperience: parentWindow,
          });
        }
      }
      return;
    }

    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      prefilledBLA,
      prefilledBLAId,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      emailEntered: email,
      entitySelected: values.buyerProfile.governmentAffiliationDisplayName,
      entityAutofilled: !!prefilledBLA,
      signupOrigin,
    });
    Cookies.remove(SIGNUP_ORIGIN_COOKIE_KEY);
    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    onComplete(response.url);
  }

  return [loading, submitBuyerInfo];
}
