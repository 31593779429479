import { createRoot } from "react-dom/client";

import { getDOMAnchorById } from "../utils";

import Page from "./Page/Page";

const container = getDOMAnchorById("react-header");
if (container) {
  const root = createRoot(container);
  root.render(<Page profileType={null} activeAgreements={null} />);
}
