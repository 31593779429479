import { ChipInput, LabeledInputVariants } from "../../library";
import { ChipInputType } from "../Input/ChipInput";

import type { CustomFormProps, FieldLabelProps } from "./types";

interface ChipInputFieldProps extends FieldLabelProps {
  field: {
    name: string;
    value: string[];
  };
  form: CustomFormProps;
  onChange?: (value?: string[]) => void;
  message?: string;
  leadingLabel?: string;
  trailingLabel?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  readOnly?: boolean;
  sublabel?: string;
}

export default function ChipInputField({
  field,
  form: { touched, errors, setFieldValue },
  message: messageProp,
  ...props
}: ChipInputFieldProps) {
  let initialVariant = LabeledInputVariants.DEFAULT;
  // In error state, show Formik error instead of field message
  let message = messageProp;
  if (touched[field.name] && errors[field.name]) {
    initialVariant = LabeledInputVariants.ERROR;
    message = errors[field.name]?.toString();
  }
  return (
    <ChipInput
      initialVariant={initialVariant}
      values={field.value}
      type={ChipInputType.Email}
      onChange={(value?: string[]) => {
        setFieldValue(field.name, value);
      }}
      message={message}
      {...props}
    />
  );
}
