import { useAtom, useAtomValue } from "jotai";
import { useMemo, useState } from "react";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Field, Form, Formik } from "formik";
import {
  AccountsService,
  type CheckUserRequest,
  type ExistingUser,
} from "../../../generated";
import { userDetailsState } from "../../../jotai/user";
import { Button, Link, Typography } from "../../../library";
import type { SubmitFn, Validate } from "../../../library/form/types";
import { getCSRFToken } from "../../../utils";
import { handleError, postLogin, postSendMagicLink } from "../../../utils/api";
import { LoginType, loginSignupSteps } from "../../../utils/enums";
import { handleError as handleGeneratedApiError } from "../../../utils/generatedApi";
import {
  LoginOrigin,
  trackHeapEvent,
  trackLoginFailure,
  trackLoginSuccess,
  trackSignupFlowFailure,
} from "../../../utils/tracking";
import yup from "../../../utils/yupPhone";
import type { WindowType } from "../../SignupSteps/types";
import SocialLoginSection from "./SocialLoginSection";
import {
  REDESIGNED_LOGIN_EMAIL_FIELD,
  REDESIGNED_LOGIN_PASSWORD_FIELD,
} from "./constants";

function PasswordLoginForm({
  onSwitchToMagicLink,
  isSubmitting,
}: {
  onSwitchToMagicLink: () => void;
  isSubmitting: boolean;
}) {
  return (
    <>
      <div className="grid gap-3">
        <Field
          {...REDESIGNED_LOGIN_EMAIL_FIELD}
          className="analytics-email-login-modal-email-input"
          editable
        />
        <Field
          {...REDESIGNED_LOGIN_PASSWORD_FIELD}
          showForgotPassword
          editable
        />
      </div>
      <div className="grid gap-2">
        <Button
          type="submit"
          disabled={isSubmitting}
          className="w-full analytics-login-modal-cta"
          dataTestId="email-login-button"
        >
          Log in
        </Button>
        <Button
          type="button"
          className="analytics-switch-to-magic-link-login"
          dataTestId="select-email-login"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={onSwitchToMagicLink}
          badgeProps={{
            Icon: AutoAwesomeOutlinedIcon,
            reverse: true,
            label: "Log in with a magic link",
          }}
        />
      </div>
    </>
  );
}

function MagicLinkForm({
  onSwitchToPassword,
  isSubmitting,
}: {
  onSwitchToPassword: () => void;
  isSubmitting: boolean;
}) {
  return (
    <>
      <Field
        {...REDESIGNED_LOGIN_EMAIL_FIELD}
        className="analytics-email-login-modal-email-input"
        editable
      />
      <div className="grid gap-2">
        <Button
          type="submit"
          disabled={isSubmitting}
          className="w-full analytics-login-modal-cta"
          dataTestId="magic-link-button"
        >
          Send link to log in
        </Button>
        <Button
          type="button"
          className="analytics-switch-to-password-login"
          dataTestId="select-email-login"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={onSwitchToPassword}
        >
          Log in with password
        </Button>
      </div>
    </>
  );
}

function MagicLinkSuccessMessage({
  onSwitchToPassword,
}: {
  onSwitchToPassword: () => void;
}) {
  const { email } = useAtomValue(userDetailsState);

  if (!email) {
    return null;
  }

  return (
    <div
      className="flex flex-col gap-4"
      data-testid="magic-link-success-message"
    >
      <div className="grid gap-2">
        <Typography color="neutral.boldest.enabled">
          We've emailed a link to your email:{" "}
          <Typography size="sm" component="span" color="neutral.bold.enabled">
            {email}
          </Typography>
        </Typography>
        <Typography color="neutral.boldest.enabled">
          Click the link to log in. The link expires automatically after 15
          minutes.
        </Typography>
      </div>
      <div className="grid gap-1">
        <Typography color="neutral.boldest.enabled" className="text-center">
          Not seeing the email?
        </Typography>
        <Button
          type="button"
          className="w-full analytics-switch-to-password-login"
          dataTestId="select-email-login"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={onSwitchToPassword}
        >
          Log in with password
        </Button>
      </div>
    </div>
  );
}

interface RedesignedLoginFormProps {
  initialEmail?: string;
  redirectSocialUser: (data: ExistingUser) => boolean;
  goToSignupPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
}
export default function RedesignedLoginForm({
  initialEmail,
  redirectSocialUser,
  goToSignupPageOrModal,
  onComplete,
  parentWindow,
}: RedesignedLoginFormProps) {
  const [userDetails, setUserDetails] = useAtom(userDetailsState);
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const [showMagicLinkSuccessMessage, setShowMagicLinkSuccessMessage] =
    useState(false);

  const fields = isPasswordLogin
    ? [REDESIGNED_LOGIN_EMAIL_FIELD, REDESIGNED_LOGIN_PASSWORD_FIELD]
    : [REDESIGNED_LOGIN_EMAIL_FIELD];

  const validationSchema = useMemo(
    () =>
      fields.reduce((schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      }, {}),
    [fields]
  );

  const handleLogin: SubmitFn<{ email: string; password: string }> = async (
    values,
    helpers
  ) => {
    const { email, password } = values;

    const emailData: CheckUserRequest = { email };
    try {
      const response =
        await AccountsService.accountsCheckExistingUserCreate(emailData);
      if (redirectSocialUser(response)) return true;
    } catch (err) {
      handleGeneratedApiError(err);
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: "Check existing user error",
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Check existing user error",
        loginExperience: parentWindow,
      });
      return false;
    }

    const form = new FormData();
    setUserDetails({ ...userDetails, email });
    form.append("login", email);
    form.append("password", password);
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    const response = await postLogin(form);
    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Incorrect password",
        loginExperience: parentWindow,
      });
      helpers?.setFieldError(
        "password",
        "Sorry, that password does not match this account."
      );
      return false;
    }
    trackLoginSuccess({
      emailEntered: email,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
    });
    onComplete(response.url);
    return true;
  };

  const handleSendMagicLink: SubmitFn<{
    email: string;
    password: string;
  }> = async (values, helpers) => {
    const { email } = values;
    setUserDetails({ ...userDetails, email });
    const response = await postSendMagicLink(email);
    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.MAGIC_LINK,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: errorMessage,
        loginExperience: parentWindow,
      });
      helpers?.setFieldError("email", "Authentication failed.");
      return false;
    }
    setShowMagicLinkSuccessMessage(true);
    trackHeapEvent("magic-login-link-sent");
    return true;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-end justify-between">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          Log in
        </Typography>
        {!showMagicLinkSuccessMessage && (
          <Typography size="sm" color="neutral.boldest.enabled">
            New to Pavilion?{" "}
            <Link
              size="sm"
              variant="body"
              onClick={goToSignupPageOrModal}
              newWindow={false}
              underline={false}
              dataTestId="select-signup"
            >
              Sign up
            </Link>
          </Typography>
        )}
      </div>
      {showMagicLinkSuccessMessage ? (
        <MagicLinkSuccessMessage
          onSwitchToPassword={() => {
            setIsPasswordLogin(true);
            setShowMagicLinkSuccessMessage(false);
          }}
        />
      ) : (
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={{
            email: initialEmail || "",
            password: "",
          }}
          onSubmit={async (values, helpers) => {
            if (isPasswordLogin) {
              await handleLogin(values, helpers);
            } else {
              await handleSendMagicLink(values, helpers);
            }
          }}
          validationSchema={yup.object(validationSchema)}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-4">
              {isPasswordLogin ? (
                <PasswordLoginForm
                  onSwitchToMagicLink={() => setIsPasswordLogin(false)}
                  isSubmitting={isSubmitting}
                />
              ) : (
                <MagicLinkForm
                  onSwitchToPassword={() => setIsPasswordLogin(true)}
                  isSubmitting={isSubmitting}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
      <SocialLoginSection
        heading="or log in with:"
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    </div>
  );
}
