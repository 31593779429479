import clsx from "clsx";
import SilhouetteWithLock from "../../../../img/icons/silhouette-with-lock.svg";
import { Button, ButtonSizes, Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { elevationClass } from "../../../utils/designTokens";

export default function RequestRoleManagement({
  ctaDisabled,
  onClick,
}: { ctaDisabled: boolean; onClick: () => void }) {
  return (
    <div
      className={clsx(
        bgColorClass.brand.subtlest.enabled,
        elevationClass["elevation-1"],
        "flex flex-col gap-6 p-6 rounded-2xl items-center"
      )}
    >
      <div className="flex flex-col gap-3 items-center">
        <img
          className="w-24"
          src={SilhouetteWithLock}
          alt="Silhouette with lock"
        />
        <Typography
          className="text-center"
          size="lg"
          color="brand.boldest.enabled"
        >
          Manage team roles in your entity
        </Typography>
        <Typography color="neutral.boldest.enabled">
          Empower your team with granular control. Set up admin roles for your
          team members to specify who can edit your entity’s pre-approved
          sources.
        </Typography>
      </div>

      <Button
        size={ButtonSizes.SMALL}
        className="w-full"
        onClick={onClick}
        disabled={ctaDisabled}
        dataTestId="request-admin"
      >
        {ctaDisabled ? "Request to add roles sent" : "Add roles"}
      </Button>
    </div>
  );
}
