import {
  denise,
  gene,
  greg,
  jennifer,
  paul,
} from "../../../../img/home/testimonials";
import { Button, ButtonThemes, Link, Typography } from "../../../library";
import { COMPANY_NAME } from "../../../utils/constants";

const testimonials = [
  {
    name: "Paul Brennan",
    position: "Procurement Manager",
    affiliation: "Rockland County, NY",
    blurb: (
      <span>
        &quot;As public procurement professionals, we know how valuable it is
        not to reinvent the wheel. Pavilion makes it so much easier to benefit
        from and share our work and experience across the profession.&quot;
      </span>
    ),
    image: paul,
  },
  {
    name: "Denise Finn",
    position: "Procurement Manager",
    affiliation: "City of Fort Myers, FL",
    blurb: (
      <span>
        &quot;As a manager, I&apos;m always on the lookout for tools that help
        my team save time and do their best work. We use pavilion weekly - it
        saves us so much time!&quot;
      </span>
    ),
    image: denise,
  },
  {
    name: "Jennifer Frates",
    position: "Chief Procurement Officer",
    affiliation: "Barnstable County, MA",
    blurb: (
      <span>
        &quot;As a buyer who also uses Bonfire, I was thrilled to see the
        Pavilion search bar directly embedded in their tool. A simple addition,
        but it makes my workflow much more streamlined.&quot;
      </span>
    ),
    image: jennifer,
  },
  {
    name: "Greg Long",
    position: "Director",
    affiliation: "Purchasing Seminole County Public Schools",
    blurb: (
      <span>
        &quot;You know the allegory about how teaching a man to fish is better
        than giving him food? Well, you know what&apos;s even better? Giving
        that man a guide to show him all the best fishing spots — and
        that&apos;s what Pavilion does for public purchasing!&quot;
      </span>
    ),
    image: greg,
  },
  {
    name: "Gene Duenas",
    position: "Procurement Manager",
    affiliation: "Orange County Public Works, CA",
    blurb: (
      <span>
        &quot;This tool is a must-have for any procurement professional looking
        to be efficient and effective.&quot;
      </span>
    ),
    image: gene,
  },
];

export default function TestimonialSection() {
  return (
    <div>
      <div className="bg-cp-midnight-300">
        <div className="flex flex-col py-12 md:py-16 cp-page-container xl:px-0 m-auto">
          <div className="flex flex-col lg:grid lg:grid-cols-3 gap-6 justify-items-center">
            <div className="flex flex-col gap-4">
              <Typography
                variant="headline"
                size="lg"
                color="brand.subtle.enabled"
                className="font-homeHeader md:text-cp-display-md flex"
              >
                Public servants love {COMPANY_NAME}
              </Typography>
              <Button
                as={Link}
                className="w-fit analytics-homepage-readTestimonials"
                linkProps={{
                  href: "/about/case-studies",
                  underline: false,
                  color: "brand.inverse.primary.enabled",
                }}
                theme={ButtonThemes.SECONDARY_LIGHT}
              >
                View all case studies
              </Button>
            </div>
            {testimonials.map((testimonial) => (
              <Testimonial key={testimonial.name} testimonial={testimonial} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
interface TestimonialProps {
  testimonial: {
    name: string;
    position: string;
    affiliation: string;
    blurb: JSX.Element;
    image: string;
  };
}
function Testimonial({ testimonial }: TestimonialProps) {
  return (
    <div
      key={testimonial.name}
      className="bg-white flex flex-col gap-4 md:gap-6 md:min-h-[350px] p-4 md:p-6 rounded-4xl"
    >
      <div className="flex gap-2 md:flex-col md:gap-4 md:items-center md:text-center">
        <img
          className="rounded-xl object-cover w-16 md:w-24 md:h-24"
          src={testimonial.image}
          alt={testimonial.name}
        />
        <div>
          <Typography
            variant="headline"
            size="xs"
            className="md:text-cp-headline-md"
            emphasis
            color="brand.boldest.enabled"
          >
            {testimonial.name}
          </Typography>
          <Typography
            size="sm"
            className="md:text-cp-body-md"
            color="neutral.bolder.enabled"
          >
            {testimonial.position && (
              <span className="block">{testimonial.position}</span>
            )}
            <span>{testimonial.affiliation}</span>
          </Typography>
        </div>
      </div>
      <Typography
        variant="body"
        color="brand.bold"
        className="md:cp-headline-sm"
      >
        {testimonial.blurb}
      </Typography>
    </div>
  );
}
