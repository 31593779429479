import { useAtomValue } from "jotai";

import useRequestMissingDocuments from "../../../hooks/useRequestMissingDocuments";
import { profileTypeState } from "../../../jotai/user";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { ProfileType } from "../../../utils/enums";

export default function NoDocumentsCTA({
  solicitationId,
  contractId,
}: {
  solicitationId: string;
  contractId: string;
}) {
  const [onRequestDocuments, loading] = useRequestMissingDocuments(
    contractId,
    solicitationId
  );

  const profileType = useAtomValue(profileTypeState);

  // Do not show this CTA for suppliers.
  if (profileType === ProfileType.SUPPLIER) return null;

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="gap-5 p-6 w-full md:max-w-108 rounded-6 h-fit"
    >
      <div className="grid gap-2">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.default.secondary.enabled"
        >
          Looking for contract documents?
        </Typography>
        <Typography size="sm" color="neutral.default.secondary.enabled">
          Submit a document request to our team and we will try to obtain
          documents for this contract.
        </Typography>
      </div>
      <Button
        disabled={loading}
        size={Button.sizes.SMALL}
        theme={Button.themes.SECONDARY_DARK}
        onClick={onRequestDocuments}
        className="analytics-supplier-cta-no-documents-request"
      >
        Submit document request
      </Button>
    </CardContainer>
  );
}
