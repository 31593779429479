import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { browserLocalStorage } from "../utils";

export default function useStateWithLocalStorage<T>(
  localStorageKey: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(
    browserLocalStorage.get(localStorageKey) !== null
      ? browserLocalStorage.get(localStorageKey)
      : defaultValue
  );

  useEffect(() => {
    browserLocalStorage.set(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue];
}
