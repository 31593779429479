import CheckRounded from "@mui/icons-material/CheckRounded";
import { useSetAtom } from "jotai";
import type { ProjectCreationSource } from "../components/Project/types";
import { ApiService, type ItemTypeEnum } from "../generated";
import { popupState } from "../jotai/page";
import { savedProjectState } from "../jotai/projects";
import { Badge, Link, Typography } from "../library";
import { BUTTON_PROPS } from "../shared/saveToProjectUtils";
import { PopupType } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import { trackSaveToProject } from "../utils/tracking";

export default function useSaveProjectItem(source?: ProjectCreationSource) {
  const setPopupState = useSetAtom(popupState);
  const setSavedProject = useSetAtom(savedProjectState);

  async function saveProjectItem({
    projectId,
    itemId,
    itemType,
  }: { projectId: string; itemId: string; itemType: ItemTypeEnum }) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId,
        itemType,
      });
      setSavedProject({ id: projectId, type: itemType });
      setPopupState({
        analyticsClassName: source
          ? `analytics-new-project-success-${source}`
          : "analytics-create-projects-success",
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Badge Icon={CheckRounded} iconClass="!mr-4">
            <Typography color="inverse">
              {BUTTON_PROPS[itemType].viewSavedCtaCopy}{" "}
              <Link
                color="inverse"
                emphasis={false}
                href={`/projects/${projectId}`}
              >
                here
              </Link>
              .
            </Typography>
          </Badge>
        ),
        show: true,
      });
      trackSaveToProject({ itemId, itemType });
    } catch (e) {
      handleError(e);
    }
  }
  return saveProjectItem;
}
