import { useAtomValue, useSetAtom } from "jotai";
import listify from "listify";
import { useMemo } from "react";
import { matchesSupplier } from "../../hooks/search/utils";
import { contractScopeState } from "../../jotai/contractScope";
import {
  currentFileIndexState,
  selectedBookmarkIdState,
} from "../../jotai/files";
import { productState } from "../../jotai/products";
import { searchQueryState } from "../../jotai/search";
import { BookmarkLink, Typography } from "../../library";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import SummarySection from "../../shared/SummarySection";
import type { Bookmark, ContractFile } from "../../shared/types";
import {
  getSentenceCase,
  sortFilesByType,
  sortStringsWithQuery,
} from "../../utils";
import { trackScopeSummaryBookmarkClick } from "../../utils/tracking";
import ProductSection from "../ContractSearch/ProductSection";
import { getFileIndex } from "./ContractFiles/utils";

function OfferingsSummary({
  offerings,
  summary,
  query,
}: { offerings: string[]; summary: Maybe<string>; query?: string }) {
  const renderText = () => {
    if (summary) return summary;
    if (!offerings.length) return null;

    let offeringsText = listify(
      offerings.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: offerings.length <= 3 ? "and" : "",
      }
    );

    if (offerings.length > 3) offeringsText += ", and more";

    return `This contract offers ${offeringsText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      items={offerings}
      linkClassName="analytics-scope-summary-offerings-toggle"
      dataTestId="contract-offerings-list"
      expandText="contract offerings"
    />
  );
}

function SupplierOfferingsSummary({
  offerings,
  supplierName,
  query,
}: { offerings: string[]; supplierName: string; query?: string }) {
  const renderText = () => {
    if (!offerings.length) return null;

    let offeringsText = listify(
      offerings.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: offerings.length <= 3 ? "and" : "",
      }
    );

    if (offerings.length > 3) offeringsText += ", and more";

    return `${supplierName} offers ${offeringsText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      items={offerings}
      linkClassName="analytics-scope-summary-keywords-toggle"
      expandText="supplier offerings"
    />
  );
}

function BrandsSummary({
  brands,
  query,
}: { brands: string[]; query?: string }) {
  const renderText = () => {
    if (!brands.length) return null;

    let listText = listify(
      brands.slice(0, 3).map((v) => v),
      {
        finalWord: brands.length <= 3 ? "and" : "",
      }
    );

    if (brands.length > 3) listText += ", and more";

    return `Brands on contract: ${listText}.`;
  };

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      items={brands}
      linkClassName="analytics-scope-summary-brands-toggle"
      expandText="brands"
    />
  );
}

interface FileBookmark {
  fileWithBookmark: ContractFile;
  bookmark: Bookmark;
}

export default function ScopeSummary({
  contractDetails,
  scrollToViewer,
  handleProductListClick,
  handleDocumentsClick,
  showProductList,
}: {
  contractDetails: ContractSolicitationDetails;
  scrollToViewer: () => void;
  handleProductListClick: () => void;
  handleDocumentsClick: () => void;
  showProductList?: boolean;
}) {
  const sortedFiles = useMemo(
    () => sortFilesByType(contractDetails.file_information),
    [contractDetails.file_information]
  );
  const setCurrentFileIndex = useSetAtom(currentFileIndexState);
  const setSelectedBookmarkId = useSetAtom(selectedBookmarkIdState);
  const query = useAtomValue(searchQueryState);
  const { initialProducts } = useAtomValue(productState);
  const { scope } = useAtomValue(contractScopeState);

  const supplierOfferings = useMemo(() => {
    const offerings = contractDetails.supplier_offerings;
    if (!offerings?.length) return [];

    if (scope.supplierOfferings?.length) {
      return scope.supplierOfferings
        .filter(({ offering }) => offerings.find((o) => o === offering))
        .map(({ offering }) => getSentenceCase(offering));
    }

    return sortStringsWithQuery(offerings.map(getSentenceCase), query);
  }, [contractDetails, query, scope.supplierOfferings]);

  const contractOfferings = useMemo(() => {
    const offerings = contractDetails.contract_offerings;
    if (!offerings?.length) return [];

    if (scope.offerings.length) {
      return scope.offerings
        .filter(({ offering }) => offerings.find((o) => o === offering))
        .map(({ offering }) => getSentenceCase(offering));
    }

    return sortStringsWithQuery(offerings.map(getSentenceCase), query);
  }, [contractDetails, query, scope.offerings]);

  const fileBookmark = useMemo(() => {
    if (!sortedFiles.length) return null;
    const file = sortedFiles.find((f) => f.bookmarks.length);
    if (!file) return null;
    return {
      fileWithBookmark: file,
      bookmark: file?.bookmarks[0],
    } as FileBookmark;
  }, [sortedFiles]);

  const handleBookmarkClick = ({
    fileWithBookmark,
    bookmark,
  }: FileBookmark) => {
    const fileIndex = getFileIndex(sortedFiles, fileWithBookmark.id);
    if (fileIndex === -1) return;
    setCurrentFileIndex(fileIndex);
    // Use the first bookmark available
    setSelectedBookmarkId(bookmark.id);
    scrollToViewer();
  };

  const bookmarkType =
    fileBookmark?.bookmark.bookmark_type || "contract documents";
  const queryMatchesSupplier = matchesSupplier(
    query || "",
    contractDetails.supplier.handle,
    contractDetails.supplier.displayName
  );
  const showProductSummary = showProductList && query && !queryMatchesSupplier;

  return (
    <div className="grid gap-4 h-fit">
      <div className="flex flex-row justify-between">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          Scope summary
        </Typography>
        <div className="flex flex-row gap-4">
          {showProductList && (
            <BookmarkLink
              onClick={() => {
                trackScopeSummaryBookmarkClick({
                  bookmarkType: "supplier products",
                });
                handleProductListClick();
              }}
              label="Jump to products"
              background
            />
          )}
          <BookmarkLink
            onClick={() => {
              trackScopeSummaryBookmarkClick({
                bookmarkType,
              });
              if (fileBookmark) handleBookmarkClick(fileBookmark);
              else handleDocumentsClick();
            }}
            label={`Jump to ${fileBookmark ? bookmarkType.toLowerCase() : "docs"}`}
            background
          />
        </div>
      </div>
      <OfferingsSummary
        offerings={contractOfferings}
        summary={contractDetails.summary}
        query={queryMatchesSupplier ? "" : query}
      />
      <BrandsSummary
        brands={contractDetails.brands}
        query={queryMatchesSupplier ? "" : query}
      />
      <SupplierOfferingsSummary
        offerings={supplierOfferings}
        supplierName={contractDetails.supplier.displayName}
        query={queryMatchesSupplier ? "" : query}
      />
      {showProductSummary && (
        <ProductSection
          products={initialProducts.hits}
          onClick={handleProductListClick}
        />
      )}
    </div>
  );
}
