import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";

function getAnalyticsClass(postfix: string, isMobile?: boolean) {
  return `analytics-${isMobile ? "mobile-" : ""}${postfix}`;
}

function defaultLinks(isMobile?: boolean): MenuItemProps[] {
  return [
    {
      label: "Public entities",
      href: "/about/public-entities",
      analyticsClassName: getAnalyticsClass(
        "homepage-forPublicEntities",
        isMobile
      ),
      color: "default",
    },
    {
      label: "Suppliers",
      href: "/about/suppliers",
      analyticsClassName: getAnalyticsClass("homepage-forSuppliers", isMobile),
      color: "default",
    },
    {
      label: "Resources",
      href: "/about/resources",
      analyticsClassName: getAnalyticsClass("homepage-resources", isMobile),
      color: "default",
    },
    {
      label: "About Pavilion",
      href: "/about/pavilion",
      analyticsClassName: getAnalyticsClass("homepage-team", isMobile),
      color: "default",
    },
  ];
}

interface HeaderLinkType {
  authenticated: () => MenuItemProps[];
  authenticatedWithBLA: () => MenuItemProps[];
  supplier: (supplierEditUrl: string, isApproved: boolean) => MenuItemProps[];
  default: {
    mobile: () => MenuItemProps[];
    full: () => MenuItemProps[];
  };
}

export const PROJECTS_TOOLTIP =
  "Your saved contracts have moved into a projects folder. Use projects to organize contracts and suppliers and to collaborate with colleagues.";

export const HEADER_LINKS: HeaderLinkType = {
  authenticated: () => [
    {
      label: "Get Quotes",
      href: "/quote-request",
      analyticsClassName: getAnalyticsClass("header-quote-request"),
    },
    {
      label: "Projects",
      href: "/projects",
      analyticsClassName: getAnalyticsClass("header-projects"),
    },
  ],
  authenticatedWithBLA: () => [
    {
      label: "Get Quotes",
      href: "/quote-request",
      analyticsClassName: getAnalyticsClass("header-quote-request"),
    },
    {
      label: "Projects",
      href: "/projects",
      analyticsClassName: getAnalyticsClass("header-projects"),
    },
    {
      label: "Entity Contracts",
      href: "/entity-contracts",
      analyticsClassName: getAnalyticsClass("header-entity-contracts"),
    },
  ],
  supplier: (supplierEditUrl) => {
    return [
      {
        label: "Business profile",
        href: supplierEditUrl,
        analyticsClassName: getAnalyticsClass("header-edit-supplier"),
      },
      {
        label: "Contracts",
        href: supplierEditUrl.replace("edit", "edit#contracts"),
        analyticsClassName: getAnalyticsClass("header-manage-contracts"),
      },
    ];
  },
  default: {
    mobile: () => defaultLinks(true),
    full: () => defaultLinks(),
  },
};
