import clsx from "clsx";

import type { AvatarSizes } from "../../library";

import { ICON_SIZES } from "./SingleDocumentIcon";

interface MultipleDocumentsIconProps {
  className?: string;
  size: AvatarSizes;
}

export function MultipleDocumentsIcon({
  className,
  size,
}: MultipleDocumentsIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(ICON_SIZES[size].sizeClass, className)}
      viewBox={ICON_SIZES[size].viewBox}
      fill="none"
    >
      <g filter="url(#a)">
        <path
          fill="#FFF8F4"
          d="M30.66 8.947a4.894 4.894 0 00-1.655-2.882 4.842 4.842 0 00-3.106-1.146H13.501a4.834 4.834 0 00-3.415 1.423 4.892 4.892 0 00-1.425 3.43v15.284a4.892 4.892 0 001.42 3.434 4.834 4.834 0 003.413 1.429h.67v-.182"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M30.66 8.947a4.894 4.894 0 00-1.655-2.882 4.842 4.842 0 00-3.106-1.146H13.501a4.834 4.834 0 00-3.415 1.423 4.892 4.892 0 00-1.425 3.43v15.284a4.892 4.892 0 001.42 3.434 4.834 4.834 0 003.413 1.429h.67v-.182"
        />
        <path
          fill="#FFF8F4"
          d="M28.199 6.861H18.69l-7.685 7.712v12.332a4.858 4.858 0 001.415 3.415 4.826 4.826 0 003.403 1.421h12.363a4.825 4.825 0 003.403-1.42 4.858 4.858 0 001.415-3.416V11.698a4.858 4.858 0 00-1.415-3.416 4.825 4.825 0 00-3.403-1.42h.012z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M33.005 26.905V11.698a4.858 4.858 0 00-1.415-3.416 4.825 4.825 0 00-3.403-1.42h.012-9.508l-7.685 7.711v12.332a4.858 4.858 0 001.415 3.415 4.826 4.826 0 003.403 1.421h2.182"
        />
        <path
          fill="#FFCBA6"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.03 14.548h3.185a4.603 4.603 0 003.246-1.356 4.634 4.634 0 001.35-3.258V6.861h-.12l-7.662 7.687z"
        />
        <path
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.757 17.161h12.51m-12.51 4.391H28.27m-12.513 4.793h4.513"
        />
        <path
          fill="#939495"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M30.84 26.581a2.5 2.5 0 01-2.5 2.5s.833-1.119.833-2.5c0-1.38-.834-2.5-.834-2.5a2.5 2.5 0 012.5 2.5zm-.39 3.75a2.888 2.888 0 012.89 2.889v.111a.75.75 0 01-.75.75h-1.14s.388-1.324-.055-2.222c-.334-.898-.944-1.528-.944-1.528z"
        />
        <circle
          cx="25.006"
          cy="26.581"
          r="2.5"
          fill="#FFE9D8"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="#FFE9D8"
          stroke="#939495"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.006 33.331a3 3 0 013-3h4a3 3 0 013 3 .75.75 0 01-.75.75h-8.5a.75.75 0 01-.75-.75z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="41.678"
          height="46.163"
          x="0.161"
          y="0.419"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.552941 0 0 0 0 0.211765 0 0 0 0 0.0941176 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_276_1091"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.552941 0 0 0 0 0.211765 0 0 0 0 0.0941176 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_276_1091"
            result="effect2_dropShadow_276_1091"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_276_1091"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
