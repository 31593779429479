import type { SupplierAlias as SupplierAliasType } from "../../../generated";
import type { TypographyColor } from "../../../library/Typography/types";
import { AvatarCard } from "../AvatarCard";

export interface SupplierAliasProps {
  alias: SupplierAliasType;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

export default function SupplierAlias({
  alias,
  onClickEdit,
  onClickDelete,
}: SupplierAliasProps) {
  const menuItems = [
    {
      onClick: onClickEdit,
      text: "Edit name",
    },
    {
      onClick: onClickDelete,
      color: "destructive.default.primary.enabled" as TypographyColor,
      text: "Delete name",
    },
  ];

  return (
    <AvatarCard
      name={alias.displayAlias}
      monogram={alias.displayAlias.charAt(0)}
      avatarBgColor="palettes.limeade.10"
      avatarTextColor="accent.default.tertiary.enabled"
      menuItems={menuItems}
    />
  );
}
