import { CalendlyButton, Typography } from "../../../library";

export default function ScheduleDemoSection() {
  return (
    <div className="bg-cp-lapis-500">
      <div className="flex flex-col items-center text-center py-12 md:py-16 cp-page-container xl:px-0 m-auto">
        <Typography
          variant="headline"
          size="lg"
          className="font-homeHeader md:text-cp-display-lg"
          color="neutral.subtlest.enabled"
          component="h2"
        >
          Not sure where to start?
        </Typography>
        <Typography
          color="neutral.subtlest.enabled"
          className="mt-4 md:text-cp-body-lg"
        >
          Talk with us about how your agency could use Pavilion.
        </Typography>
        <CalendlyButton
          rootContainer="home-container"
          className="analytics-homepage-scheduleDemo mt-10 bg-cp-lapis-100 text-cp-midnight-300 hover:bg-cp-lapis-100/80"
        />
      </div>
    </div>
  );
}
