import cloudFiles from "../../../img/icons/contracts-cloud.svg";
import { Banner, Typography } from "../../library";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { CONTRACT_EMAIL_ADDRESS } from "../../utils/constants";

export default function NoContractsBanner() {
  return (
    <Banner className="bg-gradient-to-br from-cp-cyan-100 to-cp-violet-300 p-6">
      <img
        src={cloudFiles}
        className="mx-auto float-right w-[212px] hidden md:block"
        alt="Contract documents"
      />
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="neutral.boldest.enabled"
      >
        Manage your entity contracts on Pavilion
      </Typography>
      <Typography color="neutral.boldest.enabled" className="mt-2">
        It looks like your entity doesn't have any contracts on Pavilion. Please
        email{" "}
        <SupportEmailLink
          underline
          className="analytics-entity-contracts-support"
          email={CONTRACT_EMAIL_ADDRESS}
        />{" "}
        to add contracts. Stay tuned for easier ways to upload and manage your
        contracts.
      </Typography>
    </Banner>
  );
}
