import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import quoteTeam from "../../../img/team-icons/quote-team.png";
import { Badge } from "../../library";

export default function QuoteRequestRepsOnline() {
  return (
    <div className="flex flex-none flex-col items-center">
      <img className="h-16 object-scale-down" src={quoteTeam} />
      <Badge
        Icon={FiberManualRecordIcon}
        size="sm-md"
        className="gap-1"
        iconClass="text-cp-limeade-700"
      >
        Pavilion reps online
      </Badge>
    </div>
  );
}
