import {
  AccountsService,
  type InferredEntityFromEmail,
} from "../../../generated";
import { EMAIL_REGEX } from "../../../utils/constants";
import { handleError } from "../../../utils/generatedApi";

export default function useInferExistingEntityFromEmail() {
  return async (email: string): Promise<InferredEntityFromEmail | null> => {
    const isValidEmail = EMAIL_REGEX.test(email);
    if (!isValidEmail) return null;
    try {
      const response = await AccountsService.accountsInferEntityFromEmailCreate(
        {
          email,
        }
      );
      return response;
    } catch (err) {
      handleError(err);
      return null;
    }
  };
}
