import { useState } from "react";

import { SUPPLIER_ALIAS_FIELDS } from "../../../components/supplier/SupplierEditForm/constants";
import type { SupplierAlias } from "../../../generated";
import Typography from "../../../library/Typography";
import { FormWrapper } from "../../../library/form";
import Modal from "../../../shared/Modal/Modal";
import { getUser, trackSupplierEditFormStatus } from "../../../utils/tracking";

interface EditAliasModalProps {
  alias?: SupplierAlias;
  title?: string;
  onSubmit: (displayAlias: string) => Promise<Maybe<string>>;
  hideModal: () => void;
}

export default function EditAliasModal({
  onSubmit,
  hideModal,
  alias,
  title = "Edit name",
}: EditAliasModalProps) {
  const [invalidRequest, setInvalidRequest] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const saveAlias = async ({
    displayAlias,
  }: Pick<SupplierAlias, "displayAlias">) => {
    setSubmitting(true);
    const trimmedDisplayAlias = displayAlias.trim();
    if (!trimmedDisplayAlias) {
      setInvalidRequest("Please enter a name.");
      setSubmitting(false);
      return;
    }

    trackSupplierEditFormStatus(getUser() || "", displayAlias);
    const error = await onSubmit(displayAlias);
    setSubmitting(false);
    if (error) {
      setInvalidRequest(error);
      return;
    }
    hideModal();
  };

  return (
    <Modal
      title={title}
      hideModal={hideModal}
      ctaClass="analytics-add-supplier-alias"
    >
      <div className="mb-5">
        <FormWrapper
          fields={SUPPLIER_ALIAS_FIELDS}
          initialValues={{
            displayAlias: alias?.displayAlias || "",
          }}
          onSubmit={saveAlias}
          submitClassName="analytics-supplier-alias-edit-cta"
          submitCta="Save name"
          disabled={submitting}
        />
        {invalidRequest && (
          <Typography
            className="mt-8"
            color="destructive.default.primary.enabled"
          >
            {invalidRequest}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
