import clsx from "clsx";

import { AvatarSizes } from "../../library";

const ICON_SIZES: Record<AvatarSizes, { sizeClass: string; viewBox: string }> =
  {
    [AvatarSizes.SMALL]: {
      sizeClass: "w-4 h-4",
      viewBox: "6 2 32 32",
    },
    [AvatarSizes.MEDIUM]: {
      sizeClass: "w-6 h-6",
      viewBox: "6 2 32 32",
    },
    [AvatarSizes.MED_LG]: {
      sizeClass: "TBD",
      viewBox: "TBD",
    },
    [AvatarSizes.LARGE]: {
      sizeClass: "w-12 h-12",
      viewBox: "-4 -6 48 48",
    },
    [AvatarSizes.XL]: {
      sizeClass: "w-16 h-16",
      viewBox: "4 0 36 36",
    },
  };

interface ContractSourcesIconProps {
  className?: string;
  size: AvatarSizes;
}

export function ContractSourcesIcon({
  className,
  size,
}: ContractSourcesIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(ICON_SIZES[size].sizeClass, className)}
      viewBox={ICON_SIZES[size].viewBox}
      fill="none"
    >
      <g filter="url(#filter0_d_793_13957)">
        <path
          d="M9.10742 7.20654C9.10742 6.10198 10.0029 5.20654 11.1074 5.20654H22.752C23.2966 5.20654 23.8176 5.42862 24.1948 5.82148L28.1638 9.95588C28.5214 10.3283 28.721 10.8246 28.721 11.3409V28.7895C28.721 29.8941 27.8256 30.7895 26.721 30.7895H11.1074C10.0029 30.7895 9.10742 29.8941 9.10742 28.7895V7.20654Z"
          fill="#FAF8FF"
        />
        <path
          d="M28.5072 10.1096L23.3906 5.41943V9.04369L24.2434 10.3228L28.5072 10.1096Z"
          fill="#C2A7F7"
        />
        <path
          d="M28.7282 26.0437C28.5065 26.0437 28.3274 26.2228 28.3274 26.4445V28.0648C28.3274 29.3993 27.2444 30.4824 25.9098 30.4824H11.9031C10.5686 30.4824 9.48555 29.3993 9.48555 28.0648V7.93097C9.48555 6.5964 10.5686 5.51338 11.9031 5.51338H22.9848V8.83917C22.9848 9.94776 23.8888 10.8517 24.9974 10.8517H28.3231V13.5038C28.3231 13.7255 28.5022 13.9046 28.7239 13.9046C28.9457 13.9046 29.1247 13.7255 29.1247 13.5038V10.4509C29.1247 10.3443 29.0821 10.242 29.0054 10.1652L23.6713 4.82691C23.5946 4.75016 23.4922 4.70752 23.3856 4.70752H11.9031C10.1251 4.70752 8.67969 6.15296 8.67969 7.93097V28.069C8.67969 29.847 10.1251 31.2925 11.9031 31.2925H25.9098C27.6878 31.2925 29.1333 29.847 29.1333 28.069V26.4488C29.129 26.2228 28.9499 26.0437 28.7282 26.0437ZM23.7907 8.83917V6.08474L27.7561 10.0501H24.9974C24.3322 10.0501 23.7907 9.50432 23.7907 8.83917Z"
          fill="#939495"
        />
        <path
          d="M21.1091 9.50908V7.71401C21.1091 7.04886 20.5676 6.50735 19.9024 6.50735H12.9567C12.2915 6.50309 11.75 7.04459 11.75 7.71401V9.50908C11.75 10.1742 12.2915 10.7157 12.9567 10.7157H19.8982C20.5676 10.7157 21.1091 10.1742 21.1091 9.50908ZM12.5559 9.50908V7.71401C12.5559 7.49229 12.7349 7.31321 12.9567 7.31321H19.8982C20.1199 7.31321 20.299 7.49229 20.299 7.71401V9.50908C20.299 9.7308 20.1199 9.90988 19.8982 9.90988H12.9567C12.7349 9.90988 12.5559 9.7308 12.5559 9.50908Z"
          fill="#939495"
        />
        <path
          d="M15.4041 24.6284H12.9567C12.2915 24.6284 11.75 25.1699 11.75 25.8351V28.2825C11.75 28.9477 12.2915 29.4892 12.9567 29.4892H15.4041C16.0693 29.4892 16.6108 28.9477 16.6108 28.2825V25.8351C16.615 25.1699 16.0735 24.6284 15.4041 24.6284ZM15.8092 28.2825C15.8092 28.5042 15.6301 28.6833 15.4084 28.6833H12.9609C12.7392 28.6833 12.5601 28.5042 12.5601 28.2825V25.8351C12.5601 25.6134 12.7392 25.4343 12.9609 25.4343H15.4084C15.6301 25.4343 15.8092 25.6134 15.8092 25.8351V28.2825Z"
          fill="#939495"
        />
        <path
          d="M16.615 19.5753C16.615 18.9102 16.0735 18.3687 15.4084 18.3687H12.9609C12.2915 18.3687 11.75 18.9102 11.75 19.5753V22.0228C11.75 22.6879 12.2915 23.2294 12.9567 23.2294H15.4041C16.0693 23.2294 16.6108 22.6879 16.6108 22.0228V19.5753H16.615ZM15.8092 22.027C15.8092 22.2487 15.6301 22.4278 15.4084 22.4278H12.9609C12.7392 22.4278 12.5601 22.2487 12.5601 22.027V19.5753C12.5601 19.3536 12.7392 19.1745 12.9609 19.1745H15.4084C15.6301 19.1745 15.8092 19.3536 15.8092 19.5753V22.027Z"
          fill="#939495"
        />
        <path
          d="M16.6147 13.3165C16.6147 12.6514 16.0732 12.1099 15.408 12.1099H12.9606C12.2954 12.1099 11.7539 12.6514 11.7539 13.3165V15.764C11.7539 16.4291 12.2954 16.9706 12.9606 16.9706H15.408C16.0732 16.9706 16.6147 16.4291 16.6147 15.764V13.3165ZM15.8088 15.7682C15.8088 15.9899 15.6297 16.169 15.408 16.169H12.9606C12.7389 16.169 12.5598 15.9899 12.5598 15.7682V13.3208C12.5598 13.0991 12.7389 12.92 12.9606 12.92H15.408C15.6297 12.92 15.8088 13.0991 15.8088 13.3208V15.7682Z"
          fill="#939495"
        />
        <path
          d="M28.7282 26.0437C28.5065 26.0437 28.3274 26.2228 28.3274 26.4445V28.0648C28.3274 29.3993 27.2444 30.4824 25.9098 30.4824H11.9031C10.5686 30.4824 9.48555 29.3993 9.48555 28.0648V7.93097C9.48555 6.5964 10.5686 5.51338 11.9031 5.51338H22.9848V8.83917C22.9848 9.94776 23.8888 10.8517 24.9974 10.8517H28.3231V13.5038C28.3231 13.7255 28.5022 13.9046 28.7239 13.9046C28.9457 13.9046 29.1247 13.7255 29.1247 13.5038V10.4509C29.1247 10.3443 29.0821 10.242 29.0054 10.1652L23.6713 4.82691C23.5946 4.75016 23.4922 4.70752 23.3856 4.70752H11.9031C10.1251 4.70752 8.67969 6.15296 8.67969 7.93097V28.069C8.67969 29.847 10.1251 31.2925 11.9031 31.2925H25.9098C27.6878 31.2925 29.1333 29.847 29.1333 28.069V26.4488C29.129 26.2228 28.9499 26.0437 28.7282 26.0437ZM23.7907 8.83917V6.08474L27.7561 10.0501H24.9974C24.3322 10.0501 23.7907 9.50432 23.7907 8.83917Z"
          fill="#939495"
        />
        <path
          d="M12.5559 9.50908V7.71401C12.5559 7.49229 12.7349 7.31321 12.9567 7.31321H19.8982C20.1199 7.31321 20.299 7.49229 20.299 7.71401V9.50908C20.299 9.7308 20.1199 9.90988 19.8982 9.90988H12.9567C12.7349 9.90988 12.5559 9.7308 12.5559 9.50908Z"
          fill="#E3D9FF"
        />
        <path
          d="M15.8092 28.2825C15.8092 28.5042 15.6301 28.6833 15.4084 28.6833H12.9609C12.7392 28.6833 12.5601 28.5042 12.5601 28.2825V25.8351C12.5601 25.6134 12.7392 25.4343 12.9609 25.4343H15.4084C15.6301 25.4343 15.8092 25.6134 15.8092 25.8351V28.2825Z"
          fill="#F0EBFF"
        />
        <path
          d="M15.8092 22.027C15.8092 22.2487 15.6301 22.4278 15.4084 22.4278H12.9609C12.7392 22.4278 12.5601 22.2487 12.5601 22.027V19.5753C12.5601 19.3536 12.7392 19.1745 12.9609 19.1745H15.4084C15.6301 19.1745 15.8092 19.3536 15.8092 19.5753V22.027Z"
          fill="#F0EBFF"
        />
        <path
          d="M15.8088 15.7682C15.8088 15.9899 15.6297 16.169 15.408 16.169H12.9606C12.7389 16.169 12.5598 15.9899 12.5598 15.7682V13.3208C12.5598 13.0991 12.7389 12.92 12.9606 12.92H15.408C15.6297 12.92 15.8088 13.0991 15.8088 13.3208V15.7682Z"
          fill="#F0EBFF"
        />
      </g>
      <circle
        cx="26.9824"
        cy="17.5005"
        r="2.5"
        fill="#F0EBFF"
        stroke="#939495"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9824 24.2505C21.9824 22.5936 23.3256 21.2505 24.9824 21.2505H28.9824C30.6393 21.2505 31.9824 22.5936 31.9824 24.2505C31.9824 24.6647 31.6466 25.0005 31.2324 25.0005H22.7324C22.3182 25.0005 21.9824 24.6647 21.9824 24.2505Z"
        fill="#F0EBFF"
        stroke="#939495"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8203 17.0825C32.8203 18.4632 31.701 19.5825 30.3203 19.5825C30.3203 19.5825 31.1536 18.4632 31.1536 17.0825C31.1536 15.7018 30.3203 14.5825 30.3203 14.5825C31.701 14.5825 32.8203 15.7018 32.8203 17.0825Z"
        fill="#939495"
        stroke="#939495"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.4316 20.8325C34.0269 20.8325 35.3201 22.1257 35.3201 23.721V23.8325C35.3201 24.2467 34.9843 24.5825 34.5701 24.5825H33.4312C33.4312 24.5825 33.819 23.2585 33.3757 22.3603C33.0423 21.4621 32.4316 20.8325 32.4316 20.8325Z"
        fill="#939495"
        stroke="#939495"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2407 13.3371C17.366 13.2159 17.4297 13.0603 17.4297 12.8872C17.4297 12.714 17.366 12.5585 17.2407 12.4372C17.1162 12.3166 16.96 12.2575 16.7886 12.2575C16.6172 12.2575 16.461 12.3166 16.3365 12.4372L16.5057 12.612L16.3365 12.4372L14.4812 14.2335L14.0263 13.7916L14.026 13.7913C13.9012 13.6704 13.7445 13.6116 13.5729 13.6116C13.4013 13.6116 13.2446 13.6704 13.1197 13.7913C12.9949 13.9122 12.9308 14.067 12.9297 14.2396L12.9297 14.2397C12.9286 14.4134 12.992 14.5696 13.1176 14.6912L14.0267 15.5714C14.1512 15.692 14.3064 15.7575 14.4809 15.7575C14.6555 15.7575 14.8106 15.692 14.9351 15.5714L14.9352 15.5713L17.2407 13.3371ZM17.2407 13.3371L17.0668 13.1575M17.2407 13.3371L17.2408 13.3371L17.0668 13.1575M17.0668 13.1575L14.7612 15.3918L14.4809 14.5817L16.5104 12.6168C16.5857 12.544 16.6784 12.5075 16.7886 12.5075C16.8988 12.5075 16.9916 12.544 17.0668 12.6168C17.1421 12.6897 17.1797 12.7798 17.1797 12.8872C17.1797 12.9945 17.1421 13.0847 17.0668 13.1575Z"
        fill="#C2A7F7"
        stroke="#F0EBFF"
        strokeWidth="0.5"
      />
      <path
        d="M17.2407 19.7371C17.366 19.6159 17.4297 19.4603 17.4297 19.2872C17.4297 19.114 17.366 18.9585 17.2407 18.8372C17.1162 18.7167 16.96 18.6575 16.7886 18.6575C16.6172 18.6575 16.461 18.7167 16.3365 18.8372L16.5057 19.012L16.3365 18.8372L14.4812 20.6335L14.0263 20.1916L14.026 20.1913C13.9012 20.0705 13.7445 20.0116 13.5729 20.0116C13.4013 20.0116 13.2446 20.0704 13.1197 20.1913C12.9949 20.3122 12.9308 20.467 12.9297 20.6396L12.9297 20.6397C12.9286 20.8134 12.992 20.9696 13.1176 21.0913L14.0267 21.9714C14.1512 22.092 14.3064 22.1575 14.4809 22.1575C14.6555 22.1575 14.8106 22.092 14.9351 21.9714L14.9352 21.9713L17.2407 19.7371ZM17.2407 19.7371L17.0668 19.5575M17.2407 19.7371L17.2408 19.7371L17.0668 19.5575M17.0668 19.5575L14.7612 21.7918L14.4809 20.9817L16.5104 19.0168C16.5857 18.944 16.6784 18.9075 16.7886 18.9075C16.8988 18.9075 16.9916 18.944 17.0668 19.0168C17.1421 19.0897 17.1797 19.1798 17.1797 19.2872C17.1797 19.3946 17.1421 19.4847 17.0668 19.5575Z"
        fill="#C2A7F7"
        stroke="#F0EBFF"
        strokeWidth="0.5"
      />
      <path
        d="M17.2407 25.9371C17.366 25.8159 17.4297 25.6603 17.4297 25.4872C17.4297 25.314 17.366 25.1585 17.2407 25.0372C17.1162 24.9166 16.96 24.8575 16.7886 24.8575C16.6172 24.8575 16.461 24.9166 16.3365 25.0372L16.5057 25.2119L16.3365 25.0372L14.4812 26.8335L14.0263 26.3916L14.026 26.3913C13.9012 26.2704 13.7445 26.2116 13.5729 26.2116C13.4013 26.2116 13.2446 26.2704 13.1197 26.3913C12.9949 26.5122 12.9308 26.667 12.9297 26.8396L12.9297 26.8396C12.9286 27.0133 12.992 27.1696 13.1176 27.2912L14.0267 28.1714C14.1512 28.2919 14.3064 28.3575 14.4809 28.3575C14.6555 28.3575 14.8106 28.2919 14.9351 28.1714L14.9352 28.1713L17.2407 25.9371ZM17.2407 25.9371L17.0668 25.7575M17.2407 25.9371L17.2408 25.937L17.0668 25.7575M17.0668 25.7575L14.7612 27.9918L14.4809 27.1817L16.5104 25.2168C16.5857 25.1439 16.6784 25.1075 16.7886 25.1075C16.8988 25.1075 16.9916 25.1439 17.0668 25.2168C17.1421 25.2897 17.1797 25.3798 17.1797 25.4872C17.1797 25.5945 17.1421 25.6847 17.0668 25.7575Z"
        fill="#C2A7F7"
        stroke="#F0EBFF"
        strokeWidth="0.5"
      />
      <defs>
        <filter
          id="filter0_d_793_13957"
          x="0.679688"
          y="0.70752"
          width="36.4531"
          height="42.585"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.760784 0 0 0 0 0.654902 0 0 0 0 0.968627 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_793_13957"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_793_13957"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
