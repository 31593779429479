import type { ReactNode } from "react";

import { Button } from "../../../library";
import Typography from "../../../library/Typography";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";

interface ContractUploadModalCtaProps {
  title: string;
  onClick: () => void;
  className?: string;
}

interface ContractUploadMessageModalProps {
  hideModal: () => void;
  title: string;
  message: string | ReactNode;
  primaryCta: ContractUploadModalCtaProps;
  secondaryCta?: ContractUploadModalCtaProps;
  image?: string;
  isBlocking?: boolean;
}

export default function ContractUploadMessageModal({
  hideModal,
  title,
  message,
  image,
  primaryCta,
  secondaryCta,
  isBlocking = false,
}: ContractUploadMessageModalProps) {
  return (
    <Modal
      modalSize={modalSizes.SMALL}
      title={title}
      hideModal={hideModal}
      isBlocking={isBlocking}
    >
      <div className="flex flex-col gap-8 items-center">
        {image && <img src={image} alt="" className="w-28 mx-auto" />}
        <Typography variant="body" color="neutral.bold.enabled">
          {message}
        </Typography>
        <div className="flex flex-row gap-2">
          <Button
            size={Button.sizes.LARGE}
            className={primaryCta.className}
            onClick={primaryCta.onClick}
          >
            {primaryCta.title}
          </Button>
          {secondaryCta && (
            <Button
              size={Button.sizes.LARGE}
              className={secondaryCta.className}
              theme={Button.themes.SECONDARY_DARK}
              onClick={secondaryCta.onClick}
            >
              {secondaryCta.title}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
