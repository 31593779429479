import clsx from "clsx";

import { captureMessage } from "@sentry/browser";
import { useState } from "react";
import businessDealHandshake from "../../../../img/icons/business-deal-handshake.svg";
import graphFirstPlace from "../../../../img/icons/graph-first-place.svg";
import pieLine from "../../../../img/icons/pie-line-graph-desktop.svg";
import sparkles from "../../../../img/icons/sparkles.svg";
import targetCenter from "../../../../img/icons/target-center.svg";
import { ApiService } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { Button, ButtonThemes, Typography } from "../../../library";
import { SupplierUpgradeInterestType } from "../../../modals/welcome/ConfirmSupplierUpgradeInterest";
import { ErrorPopup } from "../../../popups/AnimatedPopup";
import { bgColorClass, textColorClass } from "../../../utils/colors";
import { modals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import { trackSupplierDemoClick } from "../../../utils/tracking";
import type { SupplierWelcomePageData } from "../types";

const CONTAINER_STYLES =
  "flex flex-col p-6 gap-6 rounded-4 shadow-1 bg-gradient-to-r from-cp-limeade-250 to-cp-cyan-200";
const CARD_STYLES =
  "flex flex-col gap-2 p-4 rounded-4 bg-[rgba(255,255,255,0.5)]";

interface ScheduleSupplierDemoProps {
  supplierData: SupplierWelcomePageData;
}

export default function ScheduleSupplierDemo({
  supplierData,
}: ScheduleSupplierDemoProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const showModal = useShowModal(
    modals.CONFIRM_SUPPLIER_UPGRADE_INTEREST_SUCCESS
  );

  async function handleClick() {
    if (!supplierData.supplierId) {
      captureMessage("Supplier ID is missing in function");
      setError(true);
      return;
    }
    try {
      setLoading(true);
      await ApiService.apiV1ContactSupplierUpgradeInterestCreate({
        supplierId: supplierData.supplierId,
        interestType: SupplierUpgradeInterestType.SCHEDULE_DEMO,
      });
      showModal({
        interestType: SupplierUpgradeInterestType.SCHEDULE_DEMO,
      });
      trackSupplierDemoClick({ ...supplierData, source: "Supplier upsell" });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleError(e);
    }
  }

  // TODO: Use Tag component after fixing the issue with the component
  const upgrade_to_pro_tag = (
    <Typography
      component="div"
      variant="meta"
      size="sm"
      emphasis
      className={clsx(
        "rounded-3 flex gap-1 py-1 px-2 w-fit",
        textColorClass.neutral.subtlest.enabled,
        bgColorClass.brand.boldest.enabled
      )}
    >
      <img alt="sparkles" src={sparkles} className="w-4 h-4" />
      Upgrade to Pro
    </Typography>
  );

  return (
    <>
      <ErrorPopup show={error} onClose={() => setError(false)}>
        Unfortunately, we were unable to process your request. Please try again
        later.
      </ErrorPopup>
      <div className={CONTAINER_STYLES}>
        {upgrade_to_pro_tag}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 rounded-4">
          <div className={CARD_STYLES}>
            <img
              alt="arrow hitting target"
              src={targetCenter}
              className="w-12 h-12"
            />
            <Typography size="sm" color="neutral.boldest.enabled">
              Get 2-3x more views with more ways to highlight your products &
              services
            </Typography>
          </div>
          <div className={CARD_STYLES}>
            <img
              alt="pie chart line graph on desktop screen"
              src={pieLine}
              className="w-12 h-12"
            />
            <Typography size="sm" color="neutral.boldest.enabled">
              Receive more detailed buyer and entity insights
            </Typography>
          </div>
          <div className={CARD_STYLES}>
            <img
              alt="bar graph with first place icon"
              src={graphFirstPlace}
              className="w-12 h-12"
            />
            <Typography size="sm" color="neutral.boldest.enabled">
              Show up consistently as a top result in relevant searches
            </Typography>
          </div>
          <div className={CARD_STYLES}>
            <img
              alt="business deal handshake"
              src={businessDealHandshake}
              className="w-12 h-12"
            />
            <Typography size="sm" color="neutral.boldest.enabled">
              Get white-glove follow-up with potential leads
            </Typography>
          </div>
        </div>
        <Button
          theme={ButtonThemes.SECONDARY_DARK}
          className="w-fit self-center"
          disabled={loading}
          onClick={handleClick}
        >
          Schedule demo
        </Button>
      </div>
    </>
  );
}
