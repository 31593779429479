import SupplierAliasCard from "../../../../components/supplier/SupplierAlias";
import { ApiService, type SupplierAlias } from "../../../../generated";
import useShowModal from "../../../../hooks/useShowModal";
import { Button, Typography } from "../../../../library";
import { modals } from "../../../../utils/enums";
import { handleError } from "../../../../utils/generatedApi";
import SupplierLockedField from "../SupplierLockedField";
import type { Supplier } from "../types";

interface SupplierAliasSectionProps {
  currentSupplier: Supplier;
  setCurrentSupplier: (supplier: Supplier) => void;
  setUpdateDate: (value: Date | undefined) => void;
  disabled?: boolean;
}

export default function SupplierAliasSection({
  currentSupplier,
  setCurrentSupplier,
  setUpdateDate,
  disabled = false,
}: SupplierAliasSectionProps) {
  const showEditModal = useShowModal(modals.EDIT_ALIAS);
  async function handleAliasChange(
    displayAlias: string | null,
    clearedAlias: string | null
  ) {
    if (
      displayAlias &&
      currentSupplier.aliases?.some((a) => a.displayAlias === displayAlias)
    ) {
      return "Name already exists.";
    }

    try {
      const { aliases } = await ApiService.apiV1SupplierEditAliasesCreate(
        currentSupplier.handle,
        {
          displayAlias,
          clearedAlias,
        }
      );
      setCurrentSupplier({ ...currentSupplier, aliases });

      setUpdateDate(new Date());
    } catch (err) {
      handleError(err);
    }
  }

  const onClickEdit = (alias: SupplierAlias) => {
    showEditModal({
      alias,
      title: "Edit business name",
      onSubmit: (displayAlias: string) =>
        handleAliasChange(displayAlias, alias.displayAlias),
    });
  };

  const onClickAdd = () => {
    showEditModal({
      title: "Add an additional business name",
      onSubmit: (displayAlias: string) => handleAliasChange(displayAlias, null),
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="flex flex-col flex-1 gap-1">
          <Typography
            emphasis
            size="xs"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Additional names
          </Typography>
          <Typography color="neutral.default.primary.enabled" className="mt-1">
            Add additional names or DBAs associated with your business.
          </Typography>
        </div>
        {!disabled && (
          <Button
            size={Button.sizes.SMALL}
            theme={Button.themes.SECONDARY_DARK}
            className="analytics-add-business-contact h-fit w-fit"
            onClick={onClickAdd}
          >
            Add name +
          </Button>
        )}
      </div>
      <SupplierLockedField disabled={disabled}>
        {currentSupplier.aliases?.length ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            {currentSupplier?.aliases?.map((alias) => (
              <SupplierAliasCard
                key={alias.id}
                alias={alias}
                onClickEdit={() => onClickEdit(alias)}
                onClickDelete={() =>
                  handleAliasChange(null, alias.displayAlias)
                }
              />
            ))}
          </div>
        ) : (
          <div className="p-8 bg-cp-white-200 w-full rounded-lg text-center">
            <Typography>
              To add additional names or DBAs, click on the “Add name” button
              and enter the required information.
            </Typography>
          </div>
        )}
      </SupplierLockedField>
    </div>
  );
}
