import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import { MegaCategoryPage } from "../components/Category/MegaCategory/MegaCategory";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

interface MegaCategoryPageProps extends AuthPageProps {
  id: string;
  title: string;
  categories: { title: string; id: string }[];
}

export default function MegaCategory({
  id,
  title,
  categories,
  is_authenticated,
  profile_type,
  active_agreements,
}: MegaCategoryPageProps) {
  return (
    <Page
      searchSource="mega-category-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <MegaCategoryPage {...{ id, title, categories }} />
    </Page>
  );
}

const container = getDOMAnchorById("mega-category-detail-page");
const data = _get(container, "dataset.megaCategory");
if (data && container) {
  const root = createRoot(container);
  root.render(<MegaCategory {...JSON.parse(data)} />);
}
