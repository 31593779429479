import clsx from "clsx";
import type { ReactNode } from "react";

import { Link, Typography } from "../../library";

type CtaProps =
  | {
      title: string;
      analyticsClassName: string;
      onClick: () => void;
    }
  | {
      element: ReactNode;
      analyticsClassName: string;
    };

interface SectionHeaderProps {
  title: string;
  ctas?: CtaProps[];
  className?: string;
  dataTestId?: string;
}

export default function SectionHeader({
  title,
  ctas,
  className,
  dataTestId,
}: SectionHeaderProps) {
  const ctaElements = ctas?.map((cta, i) => {
    if ("title" in cta) {
      return (
        <Link
          // biome-ignore lint/suspicious/noArrayIndexKey: CTAs are static so an index is ok
          key={i}
          className={cta.analyticsClassName}
          onClick={cta.onClick}
          underline={false}
        >
          {title}
        </Link>
      );
    }
    if ("element" in cta) {
      return (
        // biome-ignore lint/suspicious/noArrayIndexKey: CTAs are static so an index is ok
        <div key={i} className={cta.analyticsClassName}>
          {cta.element}
        </div>
      );
    }
  });
  return (
    <div
      className={clsx(
        "w-full flex flex-col gap-2 sm:flex-row sm:items-center justify-between",
        className
      )}
      data-testid={dataTestId}
    >
      <Typography
        emphasis
        size="sm"
        variant="headline"
        color="brand.default.secondary.enabled"
      >
        {title}
      </Typography>
      {ctaElements && <div className="flex gap-4">{ctaElements}</div>}
    </div>
  );
}
