import clsx from "clsx";
import type { ReactNode } from "react";

import { Typography } from "../../library";

function BorderedCardList({
  children,
}: {
  children: ReactNode | ReactNode[];
}): JSX.Element {
  /* A small helper to contain some children rendering logic for BorderedCardSection.
   *
   * Specifically, we need to apply padding to appropriately space the child elements between the divisor.
   * Extant tailwind classes don't quite handle this the way we want, and first/last can't be applied with this approach.
   */
  if (Array.isArray(children)) {
    const numChildren = children?.length;
    return (
      <>
        {children.map((child, ix) => (
          <div
            className={clsx({
              "pb-2": numChildren > 1 && ix === 0,
              "py-2": ix !== 0 && ix !== numChildren - 1,
              "pt-2": numChildren > 1 && ix === numChildren - 1,
            })}
            // biome-ignore lint/suspicious/noArrayIndexKey: We expect a static array of children so index is ok.
            key={ix}
          >
            {child}
          </div>
        ))}
      </>
    );
  }

  return <>{children}</>;
}

interface BorderedCardSectionProps {
  children: ReactNode | ReactNode[];
  title?: string;
  label?: string;
  dividers?: boolean;
  sectionClass?: string;
}

export default function BorderedCardSection({
  children,
  title,
  label,
  dividers = true,
  sectionClass = "p-3 sm:p-4",
}: BorderedCardSectionProps) {
  const childrenArray = Array.isArray(children) ? children : [children];
  const nonNullChildren = childrenArray.filter((child: ReactNode) => child);

  if (nonNullChildren.length === 0) {
    return null;
  }

  return (
    <div className="relative mt-2">
      <div className="block absolute -top-2 ml-3 truncate px-1 bg-white w-auto max-w-5/6">
        {title && (
          <Typography variant="meta" size="sm" color="subtler">
            {title}
          </Typography>
        )}
      </div>
      <div
        className={clsx(
          "flex flex-col border border-neutral-220 rounded-2xl border-solid",
          dividers && "divide-y divide-neutral-220 divide-solid",
          sectionClass
        )}
      >
        <BorderedCardList>{nonNullChildren}</BorderedCardList>
      </div>
      {label && (
        <div className="w-full text-right mt-1">
          <Typography variant="meta" size="sm" color="subtler">
            {label}
          </Typography>
        </div>
      )}
    </div>
  );
}
