import { useAtomValue } from "jotai";
import { supplierSearchResponseDataState } from "../jotai/search";

export default function useExactSupplierMatchAlias({
  supplierId,
  supplierName,
}: {
  supplierId: Maybe<number>;
  supplierName: string;
}): string {
  const supplierSearchResponseState = useAtomValue(
    supplierSearchResponseDataState
  );
  const alias =
    supplierSearchResponseState?.supplierMatchingAliases && supplierId
      ? supplierSearchResponseState.supplierMatchingAliases[supplierId]
      : null;
  if (alias && alias !== supplierName) {
    return alias;
  }
  return "";
}
