import { useMemo } from "react";

import { Typography } from "../../library";
import { truncateAroundTargetWord } from "../../utils/format";

import { type ContractHit, MatchLevelEnum } from "../../generated";
import { matchesForContractHit, styleSearchResponseText } from "./utils";

const maxWords = 8;
const maxCharacters = 200;

export function FlexibleCardMatch({
  hit,
  showingProducts,
}: {
  hit: ContractHit;
  showingProducts?: boolean;
}) {
  const allMatches = useMemo(() => {
    let matches = matchesForContractHit(hit);

    if (showingProducts) {
      matches = matches.filter(
        (match) =>
          match.matchKind !== "contractOfferings" &&
          match.matchKind !== "autoExtractedOfferingsList" &&
          match.matchKind !== "semanticOffering"
      );
    }

    if (matches.length > 1)
      return matches.filter(
        ({ matchLevel }) => matchLevel !== MatchLevelEnum.SEMANTIC
      );
    return matches;
  }, [hit, showingProducts]);

  // Early break for when we have no match information.
  if (!allMatches.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-1 max-w-xl">
      {allMatches.slice(0, 2).map((match, ix) => {
        let styledValueText = <span key={match.value}>{match.value}</span>;
        if (match.matchLevel !== "semantic") {
          const value = truncateAroundTargetWord(
            match.value,
            "<em>",
            maxWords,
            maxCharacters
          );
          styledValueText = styleSearchResponseText(value, {
            highlightClassName: "font-semibold",
          });
        }

        return (
          <Typography
            size="sm"
            color="subtler"
            // biome-ignore lint/suspicious/noArrayIndexKey: These are static, so an index is ok.
            key={ix}
            className="sm:truncate"
          >
            {match.copyText} {styledValueText}
          </Typography>
        );
      })}
    </div>
  );
}
