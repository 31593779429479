import LogoUpload from "../components/LogoUpload";
import Modal from "../shared/Modal/Modal";

export default function LogoUploadModal({
  hideModal,
  onLogoChange,
  handle,
  logoUrl,
}: LogoUploadModalProps) {
  const onSubmitLogo = (imageUrl: string) => {
    if (onLogoChange) {
      onLogoChange(imageUrl);
    }
    hideModal();
  };
  return (
    <Modal hideModal={hideModal} title="Upload a new logo">
      <LogoUpload
        initialLogoUrl={logoUrl || ""}
        onUploadLogo={onSubmitLogo}
        handle={handle}
      />
    </Modal>
  );
}

interface LogoUploadModalProps {
  hideModal: () => void;
  handle: string;
  onLogoChange?: (logo: string) => void;
  logoUrl?: string;
}
