import type { ReactNode } from "react";

import { useAtomValue } from "jotai";
import type { ContractSearchParams } from "../../../components/ContractSearch/types";
import {
  allSearchesLoadedState,
  hasMatchedSearchResultsState,
  initialSearchesAreLoadingState,
  requestIDState,
  searchResultTypeState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../jotai/search";
import NoExactMatches from "../NoExactMatches";
import SupplierMatchSection from "../SupplierMatchSection";
import ContractResultsHeaderText, {
  SupplierCategoriesHeaderText,
  SupplierResultsHeaderText,
} from "./ResultsHeaderText";
import ShareSearchButton from "./ShareSearchButton";

interface SearchResultsHeaderProps {
  params: ContractSearchParams;
  children?: ReactNode;
}
export default function SearchResultsHeader({
  params,
  children,
}: SearchResultsHeaderProps) {
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);

  const requestID = useAtomValue(requestIDState);

  const suppliers = supplierResponseData?.supplierData?.suppliers || [];

  // Loading / result state variables
  const initialSearchesAreLoading = useAtomValue(
    initialSearchesAreLoadingState
  );
  const anyIsLoading = !useAtomValue(allSearchesLoadedState);
  const hasMatchedSearchResults = useAtomValue(hasMatchedSearchResultsState);
  const supplierMatchExists = suppliers.length > 0;

  // Wait for supplier and contract search to finish loading before rendering
  if (
    anyIsLoading &&
    searchResultType === "supplier" &&
    !showSupplierRedirect
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      {!initialSearchesAreLoading && supplierMatchExists && (
        <>
          <div className="flex justify-between items-center">
            <SupplierResultsHeaderText suppliers={suppliers} />
            <ShareSearchButton />
          </div>
          <SupplierMatchSection
            suppliers={suppliers}
            params={params}
            requestID={requestID}
            supplierCardDataTestId="supplier-card"
          />
        </>
      )}
      {!initialSearchesAreLoading && showSupplierRedirect && (
        <SupplierCategoriesHeaderText />
      )}
      {!anyIsLoading && hasMatchedSearchResults && !showSupplierRedirect && (
        <div className="flex justify-between items-center">
          <ContractResultsHeaderText />
          {!supplierMatchExists && <ShareSearchButton />}
        </div>
      )}
      {!anyIsLoading && !hasMatchedSearchResults && <NoExactMatches />}
      {children}
    </div>
  );
}
