export interface ProjectProps {
  id: string;
  name: string;
  startedAt: string;
  numContracts: number;
  numSuppliers: number;
  collaborators: Collaborator[];
}

export type ProjectUserRole = "Owner" | "Collaborator";

export interface ProjectDetailData {
  projectId: string;
  initialProjectName: string;
  initialProjectDescription: string;
  startedAt: string;
  projectUserRole: Maybe<ProjectUserRole>;
  hasVisitedProject: boolean;
  isArchived: boolean;
}

export enum ProjectCreationSource {
  SUPPLIER_PAGE = "SUPPLIER_PAGE",
  BUYER_PROFILE_PAGE = "BUYER_PROFILE_PAGE",
  CONTRACT_PAGE = "CONTRACT_PAGE",
  PROJECT_PAGE = "PROJECT_PAGE",
  DOWNLOAD_DOCS = "DOWNLOAD_DOCS",
  MESSAGE_SUPPLIER = "MESSAGE_SUPPLIER",
  SUGGESTED_SEARCHES_PROJECT_PAGE = "SUGGESTED_SEARCHES_PROJECT_PAGE",
  QUOTE_REQUEST = "QUOTE_REQUEST",
}

export interface Collaborator {
  userEmail: string;
  firstName: string;
  lastName: string;
}

export type SearchResult = {
  docid: string;
};

export type Search = {
  query: string;
  results: SearchResult[];
};
