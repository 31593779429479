import { Field, Label, Radio, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import type { ReactNode } from "react";

import Typography from "../Typography";
import type { TypographyColor } from "../Typography/types";
import type { FieldLabelProps } from "../form/types";

export type RadioButtonGroupOption = {
  key: string;
  label: ReactNode;
  sublabel?: string;
  children?: (props: { checked: boolean; disabled?: boolean }) => ReactNode;
  className?: string;
  disabled?: boolean;
};

interface RadioButtonGroupProps extends FieldLabelProps {
  value: string;
  options: RadioButtonGroupOption[];
  onChange: (value: string) => void;
  className?: string;
  itemClassName?: string;
  labelClassName?: string;
  labelColor?: TypographyColor;
}

const inputClass = (disabled?: boolean) =>
  clsx(
    `appearance-none w-4 h-4 border checked:border-5 rounded-full
      hover:border-cp-neutral-palette-950 disabled:border-cp-neutral-palette-400
      focus-visible:ring-cp-neutral-palette-700 checked:focus-visible:ring-cp-lapis-500 ring-offset-2
      focus-visible:ring-1 cursor-pointer disabled:cursor-default shrink-0`,
    {
      "border-cp-neutral-palette-700 checked:border-cp-lapis-500 checked:hover:border-cp-midnight-100":
        !disabled,
    }
  );

export default function RadioButtonGroup({
  value,
  options,
  onChange,
  className: groupClassName,
  itemClassName,
  labelClassName,
  labelColor = "neutral.boldest.enabled",
  labelEmphasis = false,
  labelSize = "md",
  labelTextVariant,
}: RadioButtonGroupProps) {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={clsx(
        "flex flex-col",
        { "gap-4": labelSize !== "sm", "gap-2": labelSize === "sm" },
        groupClassName
      )}
    >
      {options.map(
        ({ key, label, sublabel, children, disabled, className }) => (
          <Field
            key={key}
            className={clsx(
              {
                "cursor-pointer": !disabled,
                "pointer-events-none": disabled,
              },
              itemClassName,
              className
            )}
            onClick={() => !disabled && onChange(key)}
          >
            <Radio value={key} disabled={disabled}>
              {({ checked }) => (
                <>
                  <div className="flex flex-row items-center">
                    <input
                      type="radio"
                      className={inputClass(disabled)}
                      checked={checked}
                      disabled={disabled}
                      readOnly
                    />
                    <Label
                      as={Typography}
                      component="label"
                      color={disabled ? "disabled" : undefined}
                      className={clsx(
                        "ml-3 inline",
                        {
                          "cursor-pointer": !disabled,
                        },
                        labelClassName
                      )}
                    >
                      <div className="flex flex-col gap-2">
                        <Typography
                          component="span"
                          color={labelColor}
                          size={labelSize}
                          emphasis={labelEmphasis}
                          variant={labelTextVariant}
                        >
                          {label}
                        </Typography>
                        {sublabel && (
                          <Typography
                            component="span"
                            variant="meta"
                            size="md"
                            color={
                              disabled ? "disabled" : "neutral.bold.enabled"
                            }
                          >
                            {sublabel}
                          </Typography>
                        )}
                      </div>
                    </Label>
                  </div>

                  {children?.({ checked })}
                </>
              )}
            </Radio>
          </Field>
        )
      )}
    </RadioGroup>
  );
}
