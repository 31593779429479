import type { ReactNode } from "react";
import { Card, Link, Tooltip, Typography } from "../../library";
import type { TypographyColor } from "../../library/Typography/types";

const CONTAINER_STYLES = "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4";

interface TopCountsSectionProps {
  total: number;
  coopSolicitationsCount: number;
  totalSolicitationCount: number;
  totalContractsCount: number;
  coopContractsCount: number;
  expiringSoonCount: number;
  piggybacksCount: number;
  piggybacksExpiringSoonCount: number;
}

function ContractCountCard({
  title,
  count,
  countColor = "brand.boldest.enabled",
  children,
}: {
  title: ReactNode;
  count: number;
  countColor?: TypographyColor;
  children?: ReactNode;
}) {
  return (
    <Card className="!rounded-8 p-6 border-1" showShadow={false}>
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        className="h-12"
      >
        {title}
      </Typography>
      <Typography
        variant="display"
        size="lg"
        color={countColor}
        className="text-center"
      >
        {count}
      </Typography>
      {children}
    </Card>
  );
}

export default function TopCountsSection({
  total,
  coopSolicitationsCount,
  totalSolicitationCount,
  totalContractsCount,
  coopContractsCount,
  expiringSoonCount,
  piggybacksCount,
  piggybacksExpiringSoonCount,
}: TopCountsSectionProps) {
  return (
    <div className={CONTAINER_STYLES}>
      <ContractCountCard
        title="Total entity contracts"
        count={totalContractsCount}
      >
        {coopSolicitationsCount !== total && totalContractsCount > 0 && (
          <div className="bg-cp-cyan-200 p-2 rounded-3 mt-4">
            <Typography
              variant="body"
              size="sm"
              color="neutral.boldest.enabled"
            >
              <Typography component="span" emphasis>
                {coopContractsCount}{" "}
              </Typography>
              out of
              <Typography component="span" emphasis>
                {" "}
                {totalContractsCount}{" "}
              </Typography>
              contracts contain cooperative language. Learn how to add shareable
              language to contracts{" "}
              <Link
                size="sm"
                emphasis
                href="/about/guides/why-and-how-to-add-shareable-language-to-your-contracts"
              >
                here
              </Link>
              .
            </Typography>
          </div>
        )}
      </ContractCountCard>
      <ContractCountCard
        title={
          <>
            Total{" "}
            <Tooltip
              className="w-80"
              info="We try to group individual contracts by the solicitation used to create them.
                    Our methodology may not match actual solicitations."
            >
              solicitations
            </Tooltip>
          </>
        }
        count={totalSolicitationCount}
      />
      <ContractCountCard
        title="Piggybacked contracts"
        count={piggybacksCount}
      />
      <ContractCountCard
        title="Contracts expiring within 6 months"
        count={expiringSoonCount + piggybacksExpiringSoonCount}
        countColor="feedback.bold.error"
      >
        {expiringSoonCount > 0 && (
          <div className="bg-cp-persimmon-80 p-2 rounded-3 mt-4">
            <Typography
              variant="body"
              size="sm"
              color="accent.persimmon.enabled"
            >
              Upload new contract documents or search Pavilion to find
              cooperative contracts you can use instead.
            </Typography>
          </div>
        )}
      </ContractCountCard>
    </div>
  );
}
