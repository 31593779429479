import type { FieldValidator, FormikProps } from "formik";
import { type ChangeEvent, useEffect, useState } from "react";

import type { SupplierAddressValues } from "../../components/supplier/SupplierEditForm/types";
import type { GoogleAutoCompleteAddress } from "../../library/form/types";
import { formatAddressString } from "../../utils/format";

import GoogleAutocompleteSearch from "./GoogleAutocompleteSearch";

export interface GoogleAutocompleteFieldProps {
  field: {
    name: string;
    value: SupplierAddressValues["address"];
    onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
    validate: FieldValidator;
  };
  form: FormikProps<{ [x: string]: SupplierAddressValues["address"] }>;
  validationMessage?: string;
  onChange?: () => void;
  placeholder?: string;
}

export default function GoogleAutocompleteSearchField({
  field,
  form: { setFieldValue, setFieldTouched, errors, touched, submitForm },
  validationMessage,
  onChange,
  placeholder,
}: GoogleAutocompleteFieldProps) {
  const [supplierAddressDisplay, setSupplierAddressDisplay] = useState(
    formatAddressString(field.value)
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only submit when the value changes.
  useEffect(() => {
    if (!touched[field.name]) return;
    (async () => {
      try {
        await submitForm();
        setFieldTouched(field.name, false);
      } catch {
        // The field is invalid.
      }
    })();
  }, [field.value]);

  let errorMessage = "";
  if (touched[field.name] && errors[field.name]) {
    errorMessage = "Please select a valid address from the dropdown";
  }
  return (
    <div>
      <GoogleAutocompleteSearch
        placeholder={placeholder}
        setAddressDict={(supplierAddressDict: GoogleAutoCompleteAddress) => {
          setFieldValue(field.name, supplierAddressDict || {}, true);
        }}
        onChange={(value: string) => {
          setSupplierAddressDisplay(value);
          setFieldValue(field.name, {}, false);
          onChange?.();
        }}
        value={supplierAddressDisplay}
        errorMessage={errorMessage}
        validationMessage={validationMessage}
      />
    </div>
  );
}
