import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { popupState, sessionPopupState } from "../../jotai/page";
import { AnnouncementPopup, SuccessPopup } from "../../popups/AnimatedPopup";
import { PopupType } from "../../utils/enums";

export default function PopupConductor() {
  const currentPagePopupState = useAtomValue(popupState);
  const currentSessionPopupState = useAtomValue(sessionPopupState);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // If there's a session-level popup, prefer it over the page-level popup
  // (which does not persist after refresh).
  const currentPopupState = currentSessionPopupState || currentPagePopupState;

  useEffect(() => {
    if (currentPopupState?.name === PopupType.SUCCESS) {
      setShowSuccessPopup(true);
    } else {
      setShowSuccessPopup(false);
    }
  }, [currentPopupState]);

  const currentPopupName = currentPopupState?.name;
  if (currentPopupName === PopupType.CONTRACT_EDIT_MODE) {
    return (
      <AnnouncementPopup
        name="contract-edit-mode-popup"
        className={[
          currentPopupState?.analyticsClassName,
          currentPopupState?.className,
        ].join(" ")}
        onClose={currentPopupState?.onClose}
        allowDismiss={false}
      >
        {currentPopupState?.children}
      </AnnouncementPopup>
    );
  }

  return (
    <SuccessPopup
      className={[
        currentPopupState?.analyticsClassName,
        currentPopupState?.className,
      ].join(" ")}
      type={currentPopupState?.type}
      show={showSuccessPopup}
      setShow={setShowSuccessPopup}
      durationSeconds={currentPopupState?.durationSeconds}
      onClose={currentPopupState?.onClose}
    >
      {currentPopupState?.children}
    </SuccessPopup>
  );
}
