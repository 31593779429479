import clsx from "clsx";

import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { type SocialProvider, SocialProviders } from "../../utils/social";

import PushToLoginForm from "./PushToLoginForm";
import { WindowType } from "./types";

interface PushToLoginModalProp {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  socialLoginSource: SocialProvider | string;
}

export default function PushToLoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  goBack,
  socialLoginSource,
}: PushToLoginModalProp) {
  const isSocialLogin = SocialProviders.includes(
    socialLoginSource as SocialProvider
  );
  const analyticsClassName = clsx("account", {
    "analytics-push-to-sociallogin-modal": isSocialLogin,
    "analytics-push-to-login-modal": !isSocialLogin,
  });
  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className={analyticsClassName}
      formID="analytics-push-to-login-modal-submit"
      modalSize={modalSizes.SMALL}
      showLogoHeader={false}
      title="Welcome back!"
      goBack={goBack}
    >
      <PushToLoginForm
        onComplete={onComplete}
        socialLoginSource={socialLoginSource}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
