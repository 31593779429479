import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import useShowModal from "../../hooks/useShowModal";
import { userState } from "../../jotai/user";
import {
  ActionMenu,
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  Table,
  Typography,
} from "../../library";
import { LogoSizes } from "../../library/Logo";
import {
  createSupplierExclusion,
  deleteSupplierExclusion,
  getSupplierExclusions,
  handleError,
} from "../../utils/api";
import { borderColorClass } from "../../utils/colors";
import { stateOptions } from "../../utils/constants";
import { formatDate } from "../../utils/date";
import { PartnershipType, modals } from "../../utils/enums";
import {
  trackAddSupplierExclusion,
  trackRemoveSupplierExclusion,
} from "../../utils/tracking";
import BLAAndStateAutocompleteInput, {
  type BLAAutocompleteInputOnChange,
} from "../BLAAndStateAutocompleteInput";

interface SupplierExclusion {
  id: number;
  activeAt: string;
  buyerLeadAgency?: { displayName: string };
  key?: string;
}

export default function BillingPreferences() {
  const [exclusions, setExclusions] = useState<
    { id: number; entity: Maybe<string>; activeAt: Date }[]
  >([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState("");
  const { supplier } = useAtomValue(userState);
  const isViewsLimited = supplier?.activeAgreements.find(
    (v) => v === PartnershipType.VIEWS_LIMITED
  );
  const show = useShowModal(modals.EXCLUSION_REMOVAL_MODAL);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Rerun when the loading state or supplier has changed.
  useEffect(() => {
    if (hasLoaded) return;
    fetchExclusions();
  }, [hasLoaded, supplier]);

  async function fetchExclusions() {
    if (!supplier?.handle) return;
    const response = await getSupplierExclusions(supplier.handle);
    setHasLoaded(true);
    if (handleError(response)) return;
    const data = (await response.json()) as SupplierExclusion[];
    setExclusions(
      data.map(({ id, activeAt, buyerLeadAgency, key }) => {
        let entity = buyerLeadAgency?.displayName;
        if (!entity) {
          const state = stateOptions.find(({ value }) => value === key);
          entity = state ? `All entities in ${state.label}` : key;
        }
        return {
          id,
          activeAt: new Date(`${activeAt} 00:00:00.000`),
          entity,
        };
      })
    );
  }

  const handleSelectAgency: BLAAutocompleteInputOnChange = async (
    name,
    agency,
    { clear, selected }
  ) => {
    // Reset the error when the user types.
    setError("");
    // Only create an exclusion when an entity is selected from the dropdown.
    if (!supplier?.handle || !selected) return;

    const key = agency?.id ? null : agency?.stateCode || name;
    const buyerLeadAgencyId = agency?.id || null;
    const response = await createSupplierExclusion(supplier.handle, {
      buyerLeadAgencyId,
      key,
    });
    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      const { message } = await response.json();
      setError(message || "Failed to add exclusion. Please try again.");
      return;
    }
    clear();
    trackAddSupplierExclusion({
      handle: supplier.handle,
      buyerLeadAgencyName: agency?.name || name,
      buyerLeadAgencyId,
    });
    fetchExclusions();
  };
  async function handleDelete(id: number, activeAt: Date, entity: string) {
    show({
      entity,
      activeAt,
      onConfirm: async () => {
        if (!supplier?.handle) return;
        const response = await deleteSupplierExclusion(supplier.handle, id);
        if (handleError(response)) return;
        trackRemoveSupplierExclusion({
          handle: supplier.handle,
          buyerLeadAgencyName: entity,
        });
        fetchExclusions();
      },
    });
  }

  return (
    <div className="grid gap-12">
      <div className="grid gap-4">
        <Typography color="neutral.default.secondary.enabled">
          If you have any questions about billing or your contract with
          Pavilion, please contact your Billing Manager.
        </Typography>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flew-row gap-3">
            <Avatar
              size={AvatarSizes.LARGE}
              variant={AvatarVariant.CIRCLE}
              logoImageSrc="/static/img/team-icons/lillian.png"
              logoSize={LogoSizes.SMALL}
            />
            <div>
              <Typography
                variant="headline"
                size="sm"
                emphasis
                color="brand.boldest.enabled"
              >
                Lillian Zhao
              </Typography>
              <Typography variant="meta" color="brand.boldest.enabled">
                Billing Manager
              </Typography>
            </div>
          </div>
          <Button
            as="a"
            className="h-fit"
            size={Button.sizes.SMALL}
            linkProps={{ href: "mailto:lillian@withpavilion.com" }}
          >
            Contact Lillian
          </Button>
        </div>
      </div>
      <hr className={borderColorClass.neutral.default} />
      <div className="grid gap-10">
        <div className="grid gap-4">
          <Typography
            variant="headline"
            color="neutral.default.secondary.enabled"
          >
            Exclusion list
          </Typography>
          <Typography color="neutral.default.secondary.enabled">
            {isViewsLimited
              ? "Pavilion views after an entity is added to the Exclusion List will not be counted towards your Unique Entity Views.  But you also won’t get increased exposure when buyers from these entities search. Only add entities that you’re sure you’re already top of mind for."
              : "Sales generated through a Pavilion view that occurs after an entity is added to the Exclusion List will not be subject to our referral fee. But you also won’t get increased exposure when buyers from these entities search. Only add entities that you’re sure you’re already top of mind for."}
          </Typography>
        </div>
        <div className="relative">
          <BLAAndStateAutocompleteInput
            label="Add public entity or state"
            onChange={handleSelectAgency}
            errorMessage={error}
          />
        </div>
        {!!exclusions.length && (
          <Table
            columns={[
              {
                key: "entity",
                label: "Entity",
                render: (v) => v as string,
              },
              {
                key: "activeAt",
                label: "Date added",
                render: (v, { id, entity }) => {
                  return (
                    <div className="flex justify-between">
                      {formatDate(v, "MM/dd/yyyy")}{" "}
                      <ActionMenu
                        align="right"
                        items={[
                          {
                            text: "Remove entity",
                            color: "destructive.default.primary.enabled",
                            onClick: () =>
                              handleDelete(id, v as Date, entity as string),
                          },
                        ]}
                      />
                    </div>
                  );
                },
              },
            ]}
            data={exclusions}
            defaultSort={{ key: "activeAt", descending: true }}
          />
        )}
      </div>
    </div>
  );
}
