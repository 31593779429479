import { useAtomValue } from "jotai";
import { type FormEvent, useEffect, useState } from "react";

import {
  userEmailState,
  userSocialAccountProviderState,
  userTypeSignupState,
} from "../../jotai/user";
import { Button, Link, PasswordInput, Typography } from "../../library";
import { getCSRFToken } from "../../utils";
import { LoginType, loginSignupSteps } from "../../utils/enums";
import {
  trackForgotPasswordSuccess,
  trackSignupFlowFailure,
} from "../../utils/tracking";

import SocialAccountError from "./SocialAccountError";

interface PasswordChangeFormProps {
  newPasswordError: string;
  oldPasswordError?: string;
  invalidLink: boolean;
  showOldPassword?: boolean;
}
export default function PasswordChangeForm({
  newPasswordError,
  oldPasswordError,
  invalidLink,
  showOldPassword = false,
}: PasswordChangeFormProps) {
  const socialAccountProvider = useAtomValue(userSocialAccountProviderState);
  const userType = useAtomValue(userTypeSignupState);
  const email = useAtomValue(userEmailState);

  // Validate new passwords on the front-end as well to avoid unnecessary call to backend
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1ValidationError, setPassword1ValidationError] = useState(
    newPasswordError ? newPasswordError.replace(",", "") : ""
  );
  const [password2ValidationError, setPassword2ValidationError] = useState("");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on first render.
  useEffect(() => {
    if (!oldPasswordError) {
      return;
    }
    trackSignupFlowFailure({
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.FORGOT_PASSWORD,
      error: "Old password was incorrect",
    });
  }, []);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    if (password1 !== password2) {
      event.preventDefault();
      setPassword2ValidationError("Passwords must match");
      trackSignupFlowFailure({
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.FORGOT_PASSWORD,
        error: "Passwords do not match",
      });
    } else {
      setPassword2ValidationError("");
    }
    if (password1.length < 8) {
      event.preventDefault();
      setPassword1ValidationError("Passwords must be at least 8 characters");
      trackSignupFlowFailure({
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.FORGOT_PASSWORD,
        error: "Passwords must be at least 8 characters",
      });
    } else {
      setPassword1ValidationError("");
    }
    trackForgotPasswordSuccess({
      emailEntered: email,
      accountType: userType,
      loginType: socialAccountProvider as LoginType,
    });
  }

  if (socialAccountProvider) {
    trackSignupFlowFailure({
      loginType: socialAccountProvider,
      signupStep: loginSignupSteps.FORGOT_PASSWORD,
      error: "Cannot change password on social account user",
    });
    return <SocialAccountError />;
  }

  if (invalidLink) {
    return (
      <div className="cp-page-container mt-20">
        <Typography className="mb-10" variant="body" size="md">
          The password reset link was invalid, possibly because it has already
          been used. Please request a new password reset{" "}
          <Link
            href="/accounts/password/reset"
            underline={false}
            newWindow={false}
            emphasis={false}
          >
            here
          </Link>
          .
        </Typography>
      </div>
    );
  }

  // Note: to use django's pre-built forms, we need to use inputs with these names: old_password and new_password1 and new_password2
  return (
    <div className="cp-page-container pt-20 pb-20 flex flex-col md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
      <form
        id="passwordChangeForm"
        method="post"
        action="#"
        onSubmit={handleSubmit}
        className="md:col-span-4"
      >
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={getCSRFToken()}
        />
        <Typography
          variant="headline"
          color="brand.boldest.enabled"
          size="sm"
          className="mb-2"
          emphasis
        >
          Change password
        </Typography>
        <Typography color="neutral.bolder.enabled">
          Passwords must be at least 8 characters
        </Typography>
        {showOldPassword && (
          <div className="mt-10">
            <PasswordInput
              label="Old password"
              name="oldpassword"
              showForgotPassword
              errorMessage={oldPasswordError}
              className="grid gap-2"
            />
          </div>
        )}
        <div className="mt-10">
          <PasswordInput
            label="New password"
            name="password1"
            password={password1}
            setPassword={setPassword1}
            errorMessage={password1ValidationError}
            className="grid gap-2"
          />
        </div>
        <div className="mt-10">
          <PasswordInput
            label="New password confirmation"
            name="password2"
            password={password2}
            setPassword={setPassword2}
            errorMessage={password2ValidationError}
            className="grid gap-2"
          />
        </div>
        <Button className="analytics-password-reset-cta mt-10" type="submit">
          Save password
        </Button>
      </form>
    </div>
  );
}
