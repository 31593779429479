import useStateWithLocalStorage from "./useStateWithLocalStorage";

const MAX_WALKTHROUGH_SEARCHES = 3;

/**
 * Track the number of searches up to the max to show different help experiences
 * to users with more searches.
 */
export default function useHasMaxWalkthroughSearches(): [boolean, () => void] {
  const [searches, setSearches] = useStateWithLocalStorage(
    "searchesForWalkthrough",
    0
  );

  const increment = () =>
    searches < MAX_WALKTHROUGH_SEARCHES && setSearches(searches + 1);

  return [searches >= MAX_WALKTHROUGH_SEARCHES, increment];
}
