import clsx from "clsx";
import _get from "lodash/get";
import type { ReactNode } from "react";

import { type BgColor, bgColorClass } from "../../utils/colors";

export default function Banner({
  children,
  color = "neutral.default.primary.enabled",
  className,
}: {
  children: ReactNode;
  color?: BgColor;
  className?: string;
}) {
  const colorClass = _get(bgColorClass, color);
  return (
    <div className={clsx("rounded-3 p-6", colorClass, className)}>
      {children}
    </div>
  );
}
