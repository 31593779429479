import {
  type Placement,
  autoUpdate,
  flip,
  offset,
  useFloating,
} from "@floating-ui/react";
import clsx from "clsx";
import {
  type CSSProperties,
  type ComponentType,
  type FC,
  type ReactNode,
  forwardRef,
} from "react";

import Typography from "../Typography";

export interface TooltipProps {
  children?: ReactNode;
  info: ReactNode;
  className?: string;
  placement?: Placement;
  fitContent?: boolean;
}

export const TooltipContent = forwardRef(
  (
    {
      className,
      children,
      style,
    }: { className?: string; children: ReactNode; style: CSSProperties },
    ref
  ) => {
    return (
      <Typography
        size="sm"
        color="inverse"
        className={clsx(
          "hidden group-hover:block bg-cp-midnight-300 p-3 rounded-3 z-4 max-w-200 whitespace-pre-wrap",
          className
        )}
        component="div"
        ref={ref}
        style={style}
      >
        {children}
      </Typography>
    );
  }
);

function Tooltip({
  children,
  info,
  className,
  placement = "left",
  fitContent = true,
}: TooltipProps) {
  const { refs, floatingStyles } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(4), flip()],
  });
  return (
    <div
      ref={refs.setReference}
      className={clsx("group relative", {
        "max-w-fit": fitContent,
      })}
    >
      {children}
      <TooltipContent
        className={className}
        ref={refs.setFloating}
        style={floatingStyles}
      >
        {info}
      </TooltipContent>
    </div>
  );
}

interface WithTooltipProps {
  tooltip?: string;
}

/**
 * A Higher Order component to conditionally render a tooltip if one is provided,
 * otherwise the wrapped component is rendered directly.
 */
export function withTooltip<T extends object>(
  WrappedComponent: ComponentType<T>,
  extra?: Omit<TooltipProps, "info">
): FC<T & WithTooltipProps> {
  return function Component({ tooltip, ...props }: T & WithTooltipProps) {
    if (!tooltip) return <WrappedComponent {...(props as T)} />;
    return (
      <Tooltip info={tooltip} {...extra}>
        <WrappedComponent {...(props as T)} />
      </Tooltip>
    );
  };
}

export default Tooltip;
