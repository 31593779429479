import { Typography } from "../../library";
import SupportEmailLink from "../../shared/SupportEmailLink";

export default function SocialAccountError() {
  return (
    <div className="cp-page-container mt-20">
      <Typography className="mb-10" variant="body" size="md">
        Our records indicate that you have logged in with a third party provider
        so you are unable to change your password. <br />
        <br /> Please contact <SupportEmailLink /> if you need further
        assistance.
      </Typography>
    </div>
  );
}
