import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Badge, Button, ButtonThemes, Typography } from "../../library";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { CONTRACT_EMAIL_ADDRESS } from "../../utils/constants";

import UploadContractsForm from "./UploadContractsForm";
import { CONTRACT_METADATA_FIELDS, FILE_UPLOADS_FIELDS } from "./constants";

interface UploadContractsPageProps {
  supplierHandle: string;
}

export default function UploadContractsPage({
  supplierHandle,
}: UploadContractsPageProps) {
  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 md:grid-cols-8 justify-between gap-x-6 gap-y-8">
        <div className="w-full flex flex-col gap-4 col-span-1 md:col-span-5 pr-10">
          <Typography variant="headline" color="brand.boldest.enabled">
            Upload contract
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Upload all documents associated with a single contract, including:
            signed contract document (required), RFP, pricing documents and
            amendments.
          </Typography>
          <Typography color="neutral.boldest.enabled">
            <Typography
              color="neutral.boldest.enabled"
              emphasis
              component="span"
            >
              Not sure if a contract is cooperative/piggybackable?
            </Typography>{" "}
            We manually review all contract uploads and will inform you if any
            of them aren’t cooperative.
          </Typography>

          <Button
            as={Badge}
            theme={ButtonThemes.TERTIARY_DARK}
            onClick={() => {
              window.open("/document-examples", "_parent");
            }}
            badgeProps={{
              iconClass: "text-cp-lapis-500",
              Icon: InfoOutlinedIcon,
            }}
            className="px-[0px] py-[0px] w-fit"
          >
            View document examples
          </Button>
        </div>
        <div className="w-full flex flex-col gap-1 p-6 bg-cp-violet-100 rounded-4 h-fit col-span-3">
          <Typography color="brand.boldest.enabled" emphasis>
            Have more than 10 contracts to upload?
          </Typography>
          <Typography component="span">
            Email them to{" "}
            <SupportEmailLink
              className="analytics-click-pro-supplier-bulk-contract-email"
              underline
              email={CONTRACT_EMAIL_ADDRESS}
            />{" "}
            and we will upload them for you!
          </Typography>
        </div>
      </div>
      <UploadContractsForm
        fileUploadFields={FILE_UPLOADS_FIELDS}
        metadataFields={CONTRACT_METADATA_FIELDS}
        supplierHandle={supplierHandle}
      />
    </div>
  );
}
