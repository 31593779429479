// Import and init Sentry as early as possible to catch and report all subsequent JS errors
import { captureConsoleIntegration, init } from "@sentry/browser";

const env = process.env.ENVIRONMENT || "development";
const enableDebug = env === "development";

// release is specified by SentryWebpackPlugin
init({
  dsn: process.env.SENTRY_DSN_BROWSER,
  environment: env,
  debug: enableDebug,
  // Ignore intermittent network errors in sentry. There's nothing we can do
  // to stop these. Note that the only time these errors _are_ valid is with
  // CORS issues. however, we don't make any cross-origin requests and
  // shouldn't have to worry about them given our django configuration.
  // If you're here, modifying our CORS rules, you may want to remove this
  // exclusion while rolling out changes.
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Unhandled Promise Rejection: Failed to fetch",
  ],
  integrations: [
    captureConsoleIntegration({
      levels: ["error"],
    }),
  ],
});

import "./css/tailwind.css";

/* eslint-disable import/no-unresolved */
import "./js/pages/Account/BuyerSignupWelcome";
import "./js/pages/Account/CompleteBuyerSignup";
import "./js/pages/Account/CompleteSupplierSignup";
import "./js/pages/Account/InactiveAccount";
import "./js/pages/Account/InitialSignup";
import "./js/pages/Account/Login";
import "./js/pages/Account/PostSocialAuth";
import "./js/pages/BugReport";
import "./js/pages/Categories";
import "./js/pages/Category";
import "./js/pages/ConfirmSupplierContact";
import "./js/pages/Contract";
import "./js/pages/ContractSearch";
import "./js/pages/DocumentExamples";
import "./js/pages/EntityContracts";
import "./js/pages/EntityContractsUpdate";
import "./js/pages/EntityList";
import "./js/pages/Header";
import "./js/pages/Home";
import "./js/pages/LandingPage";
import "./js/pages/MegaCategory";
import "./js/pages/MessageSupplier";
import "./js/pages/MessageSupplierSuccess";
import "./js/pages/OurProduct";
import "./js/pages/Page404";
import "./js/pages/PasswordReset/PasswordChange";
import "./js/pages/PasswordReset/PasswordChangeDone";
import "./js/pages/PasswordReset/PasswordResetChange";
import "./js/pages/PasswordReset/PasswordResetChangeDone";
import "./js/pages/PasswordReset/PasswordResetEmail";
import "./js/pages/PasswordReset/PasswordResetEmailSent";
import "./js/pages/Profile";
import "./js/pages/ProjectDetail";
import "./js/pages/Projects";
import "./js/pages/QuoteRequest.tsx";
import "./js/pages/QuoteRequestSuccess.tsx";
import "./js/pages/Solicitation";
import "./js/pages/Supplier";
import "./js/pages/SupplierClaimForm";
import "./js/pages/UploadContracts";
import "./js/pages/Welcome";

import "./js/iframes/Bonfire/serp";
import "./js/iframes/CityOfLACA";
import "./js/iframes/ESC18";
import "./js/iframes/Embed404";

import "./js/iframes/Generic";
import "./js/iframes/IonWave/serp";
import "./js/iframes/KCRPC";
import "./js/iframes/NIGP";
import "./js/iframes/NPI";
import "./js/iframes/OpenGov";
import "./js/iframes/OrangeCountyCA";
import "./js/iframes/SAVE";
import "./js/iframes/USF";
