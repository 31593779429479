import type { ContractFile } from "../../../shared/types";

export function getFileIndex(files: ContractFile[], fileID: string) {
  if (!files || !fileID) {
    return -1;
  }
  return files.findIndex((file) => file.id.toString() === fileID.toString());
}

export function getFileLink(file: ContractFile, bookmarkID: string) {
  const url = new URL(window.location.toString());
  url.searchParams.set("fileID", file.id.toString());
  if (bookmarkID) {
    url.searchParams.set("bookmarkID", bookmarkID);
  }
  return url;
}

export function getBookmarkIndexFromFile(
  file: ContractFile,
  bookmarkID: string
) {
  if (!file || !bookmarkID) {
    return -1;
  }
  return file.bookmarks.findIndex(
    (bookmark) => bookmark.id.toString() === bookmarkID.toString()
  );
}

export function getPageFromBookmark({
  selectedFile,
  selectedBookmarkId,
}: {
  selectedFile?: ContractFile;
  selectedBookmarkId: Maybe<string>;
}) {
  if (!selectedFile || !selectedBookmarkId) {
    return -1;
  }
  const bookmarkIndex = getBookmarkIndexFromFile(
    selectedFile,
    selectedBookmarkId
  );
  if (bookmarkIndex !== -1) {
    return selectedFile.bookmarks[bookmarkIndex].page_number;
  }
}

// Make file name more human-readable
export function getCleanedFileName(
  file: ContractFile,
  contractNumber?: string
) {
  // Remove underscores and trim
  let cleanedFileName = file.name.replace(/_/g, " ").trim();
  // Remove file extension
  cleanedFileName = cleanedFileName.replace(/\.[^/.]+$/, "");
  // Remove leading contract number
  if (contractNumber) {
    cleanedFileName = removeRedundantPrefix(cleanedFileName, contractNumber);
  }
  // Remove leading file type
  cleanedFileName = removeRedundantPrefix(cleanedFileName, file.type);

  return cleanedFileName;
}

function removeRedundantPrefix(fileName: string, prefix: string) {
  const firstWord = fileName.split(" ")[0];
  // if the filename is one word, return it
  if (firstWord === fileName) {
    return fileName;
  }
  if (firstWord.toLowerCase() === prefix.toLowerCase()) {
    return fileName.split(" ").slice(1).join(" ");
  }
  return fileName;
}

export function isMsOfficeFile(file?: ContractFile) {
  return (
    /^.*\.(doc|docx|xls|xlsx|ppt|pptx)$/i.test(file?.url || "") ||
    /^.*\.(doc|docx|xls|xlsx|ppt|pptx)$/i.test(file?.name || "")
  );
}
