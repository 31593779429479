import { type ChangeEvent, useRef, useState } from "react";

import { ApiService } from "../../generated";
import { Button, Typography } from "../../library";
import Logo from "../../library/Logo";
import { FIVE_MB_IN_BYTES } from "../../utils/constants";
import { handleError as handleGeneratedApiError } from "../../utils/generatedApi";

interface LogoUploadProps {
  initialLogoUrl: string;
  onUploadLogo: (imageUrl: string) => void;
  handle: string;
}

export default function LogoUpload({
  initialLogoUrl,
  onUploadLogo,
  handle,
}: LogoUploadProps) {
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState(initialLogoUrl);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const newImage = !!image;
  const existingImageHasNotChanged = !newImage && initialLogoUrl;

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.files?.[0].size &&
      e.target.files?.[0].size <= FIVE_MB_IN_BYTES
    ) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setErrorMessage("");
      return;
    }
    setImage(undefined);
    setImageUrl("");
    setErrorMessage("File is too large, please pick a file smaller than 5MB");
  };

  const handleSubmit = async () => {
    if (!image) {
      setErrorMessage("Please enter an image");
      return;
    }
    setIsLoading(true);
    const logoForm = new FormData();
    logoForm.append("image", image, image.name);
    try {
      const response = await ApiService.apiV1SupplierEditLogoLogoUploadCreate(
        handle,
        { image: image }
      );
      onUploadLogo(response.s3Url);
    } catch (err) {
      handleGeneratedApiError(err);
      setErrorMessage(
        "We could not process this image, please try a different file."
      );
    }
    setIsLoading(false);
  };

  const handleChooseImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteLogo = async () => {
    setIsLoading(true);
    try {
      await ApiService.apiV1SupplierEditLogoLogoDeleteDestroy(handle);
      onUploadLogo("");
    } catch (err) {
      handleGeneratedApiError(err);
      setErrorMessage(
        "We could not process this image, please try a different file."
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-y-10 items-center">
      <input
        type="file"
        name="logo"
        accept="image/jpeg,image/png,image/gif"
        onChange={(e) => {
          handleImageChange(e);
        }}
        ref={fileInputRef}
        hidden
      />
      <div className="flex flex-col">
        <Button
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={handleChooseImageClick}
        >
          Choose new logo
        </Button>
        <Typography variant="meta" size="sm" color="neutral.bold.enabled">
          PNG, JPG or GIF (max 5MB)
        </Typography>
      </div>
      {imageUrl && <Logo imageSrc={imageUrl} />}
      {errorMessage && (
        <Typography
          variant="meta"
          color="destructive.default.primary.enabled"
          className="text-left"
        >
          {errorMessage}
        </Typography>
      )}

      {existingImageHasNotChanged && (
        <Button
          theme={Button.themes.PRIMARY_DESTRUCTIVE}
          size={Button.sizes.SMALL}
          onClick={handleDeleteLogo}
          className="w-full"
          disabled={isLoading}
        >
          Delete logo
        </Button>
      )}
      {newImage && (
        <div className="grid grid-cols-2 gap-5">
          <Button
            theme={Button.themes.SECONDARY_DESTRUCTIVE}
            size={Button.sizes.SMALL}
            onClick={() => onUploadLogo(initialLogoUrl)}
            disabled={isLoading}
          >
            Cancel upload
          </Button>
          <Button
            theme={Button.themes.PRIMARY_DARK}
            size={Button.sizes.SMALL}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Save logo
          </Button>
        </div>
      )}
    </div>
  );
}
