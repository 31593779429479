import { useAtomValue } from "jotai";

import useLoginWall from "../../../hooks/useLoginWall";
import { isAuthenticatedState } from "../../../jotai/user";
import { Link } from "../../../library";
import { LoginWallTriggers } from "../../../utils/enums";

export default function ManagePreferencesLink() {
  const requireLogin = useLoginWall();
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  if (isAuthenticated) {
    return (
      <Link
        underline={false}
        newWindow={false}
        className="flex"
        href="/profile#preferences"
      >
        Manage my account preferences
      </Link>
    );
  }
  return (
    <Link
      underline={false}
      className="flex"
      onClick={() =>
        requireLogin({
          subtitle: "You must log in or sign up to manage search preferences",
          triggerType: LoginWallTriggers.SEARCH_PREFERENCES,
          onComplete: () => {
            window.open("/profile#preferences", "_blank");
            window.location.reload();
          },
        })
      }
    >
      Sign up to manage preferences
    </Link>
  );
}
