import clsx from "clsx";

import { AvatarSizes } from "../../library";

const ICON_SIZES: Record<AvatarSizes, { sizeClass: string; viewBox: string }> =
  {
    [AvatarSizes.SMALL]: {
      sizeClass: "w-4 h-4",
      viewBox: "8 8 32 32",
    },
    [AvatarSizes.MEDIUM]: {
      sizeClass: "w-6 h-6",
      viewBox: "8 8 32 32",
    },
    [AvatarSizes.MED_LG]: {
      sizeClass: "TBD",
      viewBox: "TBD",
    },
    [AvatarSizes.LARGE]: {
      sizeClass: "w-12 h-12",
      viewBox: "0 0 48 48",
    },
    [AvatarSizes.XL]: {
      sizeClass: "w-16 h-16",
      viewBox: "6 6 36 36",
    },
  };

interface VerifyEmailIconProps {
  className?: string;
  size: AvatarSizes;
}

export function VerifyEmailIcon({ className, size }: VerifyEmailIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(ICON_SIZES[size].sizeClass, className)}
      fill="none"
      viewBox={ICON_SIZES[size].viewBox}
    >
      <g filter="url(#verifyEmailIconFilter)">
        <rect
          x="10.821"
          y="15.296"
          width="25.125"
          height="15.595"
          rx="2"
          fill="#F8FFF0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m37.62 23.079-1.519-.532v-6.02c0-.48-.178-.93-.501-1.274 0 0 0-.004-.005-.004 0 0-.004 0-.004-.004l-.013-.013c-.353-.371-.85-.58-1.357-.58H12.268a1.88 1.88 0 0 0-1.357.58l-.013.013-.004.004s0 .004-.005.009a1.85 1.85 0 0 0-.501 1.273v12.894c0 .559.248 1.086.676 1.444.004.004.013.009.022.013.004.005.008.005.008.009.332.266.746.419 1.174.419h11.454a.32.32 0 0 0 0-.642H12.268c-.131 0-.262-.021-.384-.065l9.67-6.177.083.07a2.527 2.527 0 0 0 1.605.576c.572 0 1.139-.192 1.601-.576l.083-.07 2.007 1.283v2.203c0 .964.2 1.897.558 2.756l.305.642a7.184 7.184 0 0 0 3.363 3.145l1.069.48a.735.735 0 0 0 .602 0l1.064-.48a7.184 7.184 0 0 0 4.218-6.535v-4.148a.731.731 0 0 0-.493-.693Zm-5.335-1.697a.723.723 0 0 1 .488 0l2.696.938v-5.797c0-.227-.061-.437-.17-.624l-9.85 8.1 1.488.95v-1.177c0-.31.196-.589.493-.693l4.855-1.697Zm2.556-5.928a1.24 1.24 0 0 0-.62-.175H12.263a1.24 1.24 0 0 0-.62.175l10.395 8.554c.698.576 1.71.576 2.404 0l10.399-8.554ZM11.02 29.421c0 .297.108.571.291.794l9.723-6.212-9.845-8.1a1.24 1.24 0 0 0-.17.624v12.894Zm22.611 4.445a6.546 6.546 0 0 0 3.848-5.954v-4.14a.104.104 0 0 0-.07-.096l-4.85-1.697c-.014-.004-.022-.004-.035-.004-.01 0-.022 0-.031.004l-4.855 1.697a.098.098 0 0 0-.07.096v4.14c0 2.56 1.51 4.898 3.848 5.954l1.068.48a.092.092 0 0 0 .083 0l1.065-.48Z"
          fill="#939495"
        />
        <path
          d="M37.48 27.912c0 2.56-1.51 4.898-3.847 5.954l-1.065.48a.092.092 0 0 1-.083 0l-1.068-.48a6.546 6.546 0 0 1-3.848-5.954v-4.14c0-.043.027-.083.07-.096l4.855-1.697c.009-.004.022-.004.03-.004.014 0 .022 0 .035.004l4.85 1.697c.04.013.07.053.07.096v4.14Z"
          fill="#D7FFA6"
        />
        <path
          d="M35.591 15.245c0 .004.004.004.004.004a1.85 1.85 0 0 1 .506 1.278v6.02l1.518.532a.731.731 0 0 1 .493.693v4.148a7.184 7.184 0 0 1-4.218 6.535l-1.064.48a.72.72 0 0 1-.602 0l-1.069-.48a7.184 7.184 0 0 1-3.363-3.145l-.305-.642a7.17 7.17 0 0 1-.558-2.756v-2.203l-2.007-1.282-.083.07a2.498 2.498 0 0 1-1.6.575 2.527 2.527 0 0 1-1.606-.576l-.083-.07-9.67 6.177c.122.044.253.065.384.065h11.454a.32.32 0 0 1 0 .641H12.268c-.428 0-.842-.152-1.174-.418 0-.004-.004-.004-.008-.009-.01-.004-.018-.009-.022-.013a1.883 1.883 0 0 1-.676-1.444V16.531c0-.48.178-.929.501-1.273.005-.005.005-.009.005-.009l.004-.004m24.693 0-.013-.013m.013.013-.013-.013m0 0c-.353-.371-.85-.58-1.357-.58H12.268a1.88 1.88 0 0 0-1.357.58m0 0-.013.013m.013-.013-.013.013m21.875 6.137a.723.723 0 0 0-.488 0l-4.855 1.697a.737.737 0 0 0-.493.693v1.178l-1.488-.951 9.85-8.1c.109.187.17.397.17.624v5.797l-2.696-.938Zm1.448-6.103c.218 0 .432.066.62.175l-10.4 8.554a1.888 1.888 0 0 1-2.403 0l-10.394-8.554a1.24 1.24 0 0 1 .62-.175H34.22ZM11.312 30.215a1.245 1.245 0 0 1-.292-.794V16.527c0-.223.061-.436.17-.624l9.845 8.1-9.723 6.212Zm26.168-2.303c0 2.56-1.51 4.898-3.847 5.954l-1.065.48a.092.092 0 0 1-.083 0l-1.068-.48a6.546 6.546 0 0 1-3.848-5.954v-4.14c0-.043.027-.083.07-.096l4.855-1.697c.009-.004.022-.004.03-.004.014 0 .022 0 .035.004l4.85 1.697c.04.013.07.053.07.096v4.14Z"
          stroke="#939495"
          strokeWidth=".1"
        />
        <path
          d="m36.446 23.942-3.678-1.287a.71.71 0 0 0-.484 0l-3.677 1.287a.739.739 0 0 0-.493.693v3.285a5.958 5.958 0 0 0 3.498 5.417l.616.275a.718.718 0 0 0 .602 0l.61-.275a5.958 5.958 0 0 0 3.498-5.417v-3.285a.737.737 0 0 0-.492-.693Zm-.144 3.978a5.327 5.327 0 0 1-3.123 4.841l-.611.275a.093.093 0 0 1-.083 0l-.615-.275a5.32 5.32 0 0 1-3.123-4.837V24.64c0-.044.026-.079.065-.096l3.677-1.287a.085.085 0 0 1 .066 0l3.677 1.287a.1.1 0 0 1 .065.096v3.28h.005Z"
          fill="#939495"
        />
        <path
          d="M36.302 27.92a5.327 5.327 0 0 1-3.123 4.841l-.611.275a.093.093 0 0 1-.083 0l-.615-.275a5.32 5.32 0 0 1-3.123-4.837V24.64c0-.044.026-.079.065-.096l3.677-1.287a.085.085 0 0 1 .066 0l3.677 1.287a.1.1 0 0 1 .065.096v3.28h.005Z"
          fill="#7DDA0B"
        />
        <path
          d="m36.446 23.942-3.678-1.287a.71.71 0 0 0-.484 0l-3.677 1.287a.739.739 0 0 0-.493.693v3.285a5.958 5.958 0 0 0 3.498 5.417l.616.275a.718.718 0 0 0 .602 0l.61-.275a5.958 5.958 0 0 0 3.498-5.417v-3.285a.737.737 0 0 0-.492-.693Zm-.144 3.978a5.327 5.327 0 0 1-3.123 4.841l-.611.275a.093.093 0 0 1-.083 0l-.615-.275a5.32 5.32 0 0 1-3.123-4.837V24.64c0-.044.026-.079.065-.096l3.677-1.287a.085.085 0 0 1 .066 0l3.677 1.287a.1.1 0 0 1 .065.096v3.28h.005Z"
          stroke="#939495"
          strokeWidth=".1"
        />
        <path
          d="M36.302 27.92a5.327 5.327 0 0 1-3.123 4.841l-.611.275a.093.093 0 0 1-.083 0l-.615-.275a5.32 5.32 0 0 1-3.123-4.837V24.64c0-.044.026-.079.065-.096l3.677-1.287a.085.085 0 0 1 .066 0l3.677 1.287a.1.1 0 0 1 .065.096v3.28h.005Z"
          stroke="#939495"
          strokeWidth=".1"
        />
        <path
          d="m34.688 26.817-2.674 2.674a.33.33 0 0 1-.45 0l-1.169-1.17a.31.31 0 0 1-.091-.222.31.31 0 0 1 .314-.314.32.32 0 0 1 .222.092l.72.72a.321.321 0 0 0 .45 0l2.224-2.225a.321.321 0 0 1 .45 0 .31.31 0 0 1 .09.222.269.269 0 0 1-.086.223Z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="verifyEmailIconFilter"
          x="2"
          y="10.602"
          width="44.162"
          height="36.448"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.215686 0 0 0 0 0.384314 0 0 0 0 0.0156863 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6592_10910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_6592_10910"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
