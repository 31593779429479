import { Field, Form, Formik, type FormikHelpers } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { Button, Typography } from "../../library";
import type { FormFieldProps, Validate } from "../../library/form/types";

interface ProfileEditFormProps<T extends object> {
  title: string;
  subtitle?: string;
  fields: FormFieldProps[];
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  name: string;
  expandOnOpen?: boolean;
}

export default function ProfileEditForm<T extends object>({
  title,
  subtitle,
  name,
  fields,
  initialValues,
  onSubmit,
  expandOnOpen: autoFocusOnOpen = false,
}: ProfileEditFormProps<T>) {
  const [editable, setEditable] = useState(autoFocusOnOpen);
  const validationSchema: Record<string, Validate> = {} as Record<
    keyof T,
    Validate
  >;
  fields.forEach(({ name, validate }) => {
    if (!validate) return;
    validationSchema[name] = validate;
  });

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSubmit(values, formikHelpers);
          setEditable(false);
        }}
        validationSchema={yup.object(validationSchema)}
      >
        {({ handleReset }) => (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center gap-2 h-[36px]">
                <Typography
                  variant="headline"
                  size="sm"
                  color="brand.default.secondary.enabled"
                  emphasis
                >
                  {title}
                </Typography>
                {!editable && (
                  <Button
                    size={Button.sizes.SMALL}
                    theme={Button.themes.TERTIARY_DARK}
                    className={`analytics-profile-edit-${name} cursor-pointer`}
                    onClick={() => {
                      setEditable(true);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </div>
              {subtitle && (
                <Typography color="neutral.bolder.enabled">
                  {subtitle}
                </Typography>
              )}
            </div>
            <Form>
              <div className="flex w-full gap-4">
                {fields.map(
                  ({ name, placeholder, component, validate, ...rest }) => (
                    <Field
                      key={name}
                      name={name}
                      component={component}
                      placeholder={placeholder}
                      editable={editable}
                      validate={validate}
                      {...rest}
                    />
                  )
                )}
              </div>
              {editable && (
                <div className="flex items-center gap-2">
                  <Button
                    type="submit"
                    name={name}
                    size={Button.sizes.SMALL}
                    dataTestId={`profile-edit-${name}-btn`}
                    className="mt-4"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      handleReset();
                      setEditable(false);
                    }}
                    size={Button.sizes.SMALL}
                    theme={Button.themes.TERTIARY_DARK}
                    dataTestId={`profile-edit-${name}-cancel-btn`}
                    className="mt-4"
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
