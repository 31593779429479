import type { ReactNode } from "react";

import Google from "../../img/icons/Google.svg";
import Microsoft from "../../img/icons/Microsoft.svg";

import { getGoogleLoginLink, getMicrosoftLoginLink } from ".";

// https://django-allauth.readthedocs.io/en/latest/providers.html
export enum SocialProvider {
  microsoft = "microsoft",
  google = "google",
}

export const SocialProviders: SocialProvider[] = Object.values(SocialProvider);

interface SocialProvidersDetails {
  loginLink: Maybe<string>;
  displayName: string;
  icon: ReactNode;
}

// https://django-allauth.readthedocs.io/en/latest/providers.html
export const socialProvidersDetails: Record<
  SocialProvider,
  SocialProvidersDetails
> = {
  microsoft: {
    displayName: "Microsoft",
    icon: <img className="w-4 mr-3" src={Microsoft} alt="Microsoft logo" />,
    loginLink: getMicrosoftLoginLink(),
  },
  google: {
    displayName: "Google",
    icon: <img className="w-5 mr-3" src={Google} alt="Google logo" />,
    loginLink: getGoogleLoginLink(),
  },
};
