import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import _get from "lodash/get";
import type { MouseEventHandler, ReactNode } from "react";

import { Badge, Typography } from "../../library";
import { bgColorClass, iconColorClass } from "../../utils/colors";

export const pillClassName = (className: Maybe<string>) =>
  clsx(
    "whitespace-nowrap flex flex-row items-center py-1 px-2 rounded-3 w-fit inline-flex",
    bgColorClass.brand.subtlest.hovered,
    className
  );

export interface PillProps {
  children: ReactNode;
  className?: string;
  iconClassName?: string;
  onClickIcon?: MouseEventHandler;
}

export default function Pill({
  children,
  className,
  iconClassName,
  onClickIcon,
}: PillProps) {
  return (
    <Badge
      as={Typography}
      Icon={CloseRoundedIcon}
      iconClass={clsx(_get(iconColorClass, "subtle") as string, iconClassName)}
      reverse
      onClickIcon={onClickIcon}
      linkProps={{ variant: "meta", color: "subtle", emphasis: false }}
      size="sm"
      className={pillClassName(className)}
    >
      {children}
    </Badge>
  );
}
