import { useAtomValue } from "jotai";
import { useRef } from "react";
import type { SearchOptions } from "../components/ContractSearch/types";
import { userZipState } from "../jotai/user";
import { getParams } from "../utils";
import { SearchSource, conversionLabels } from "../utils/enums";
import { getSearchBasePath, getWindowLocationHref } from "../utils/format";
import {
  type TrackAutocompleteOptions,
  trackGTMEvent,
  trackUserSelectedFromAutocomplete,
} from "../utils/tracking";
import type { OnCompleteOptions as OnCompleteDisambiguationOptions } from "./useSearchDisambiguationSurvey";
import type { OnCompleteOptions } from "./useSearchIntentSurvey";

export type SearchContractsParams = {
  query: string;
  isLocationRelevant?: boolean;
  clickedQuerySuggestionTrackingValue?: TrackAutocompleteOptions;
  originalAmbiguousQuery?: string;
};

export default function useSearchContracts({
  searchContractWithParams,
  cbOnSearchRedirect,
  searchInNewTab,
  searchUrl,
  buyerLeadAgencyId,
  searchSource,
  autocompleteTrackingInfo,
  setShowTypeahead,
  searchIntentSurvey,
  searchDisambiguationSurvey,
}: {
  searchContractWithParams: (args: SearchOptions) => void;
  cbOnSearchRedirect?: (query: string) => void;
  searchInNewTab: boolean;
  searchUrl?: string;
  buyerLeadAgencyId?: string;
  searchSource: SearchSource;
  autocompleteTrackingInfo?: TrackAutocompleteOptions;
  setShowTypeahead: (show: boolean) => void;
  searchIntentSurvey: () => Promise<OnCompleteOptions>;
  searchDisambiguationSurvey: Maybe<
    () => Promise<OnCompleteDisambiguationOptions>
  >;
}) {
  const conversionTracked = useRef(false);
  const searchEndpoint = searchUrl || getSearchBasePath();
  const url = new URL(searchEndpoint, getWindowLocationHref());
  const params = getParams();
  const isSERP = searchSource === SearchSource.CONTRACTS_SERP_SEARCH;
  const isLandingPage = [
    SearchSource.LANDING_PAGE_SEARCH,
    SearchSource.LANDING_PAGE_ENTITY_SEARCH,
  ].includes(searchSource);
  const isEntityContractsSearch =
    searchSource === SearchSource.ENTITY_CONTRACTS_PAGE;
  const userZip = useAtomValue(userZipState);

  // Record Google Ads (Search on Landing Page) conversions
  function trackSearchOnLandingPageConversion() {
    if (conversionTracked.current) return;

    // only track searches in the landing page searchbar
    if (!isLandingPage) return;

    trackGTMEvent(conversionLabels.searchOnLandingPage);

    conversionTracked.current = true;
  }

  return async ({
    query,
    isLocationRelevant,
    clickedQuerySuggestionTrackingValue,
  }: SearchContractsParams) => {
    cbOnSearchRedirect?.(query);
    let originalAmbiguousQuery = null;
    let selectedDisambiguationOption = null;

    if (searchEndpoint === window.location.pathname) {
      if (params.filters) {
        url.searchParams.set("filters", params.filters.toString());
      }
      if (params["widget-search-source"]) {
        url.searchParams.set(
          "widget-search-source",
          params["widget-search-source"]?.toString() || ""
        );
      }
      if (params.embedSourceEntityId && !buyerLeadAgencyId) {
        url.searchParams.set(
          "embedSourceEntityId",
          params.embedSourceEntityId.toString()
        );
      }
      if (params.feature) {
        url.searchParams.set("feature", params.feature.toString());
      }
      if (params.debug) {
        url.searchParams.set("debug", params.debug.toString());
      }
      if (params.benchmarkingSessionId) {
        url.searchParams.set(
          "benchmarkingSessionId",
          params.benchmarkingSessionId.toString()
        );
      }
      if (isLandingPage && params.landingPageSlug) {
        url.searchParams.set(
          "landingPageSlug",
          params.landingPageSlug.toString()
        );
      }
    }

    if (buyerLeadAgencyId) {
      url.searchParams.set("embedSourceEntityId", buyerLeadAgencyId);
    }

    // Make Heap tracking call for typeahead data
    if (autocompleteTrackingInfo || clickedQuerySuggestionTrackingValue) {
      // If selected from default, change search source
      trackUserSelectedFromAutocomplete(
        autocompleteTrackingInfo ||
          (clickedQuerySuggestionTrackingValue as TrackAutocompleteOptions)
      );
    }

    if (searchDisambiguationSurvey) {
      const { newQuery, selectedOption } = await searchDisambiguationSurvey();
      if (newQuery) {
        originalAmbiguousQuery = query;
        // Only pass a selected disambiguation option to the search endpoint if the query has changed
        if (selectedOption !== query && selectedOption !== "Other")
          selectedDisambiguationOption = selectedOption;
        query = newQuery;
      }
    }
    const { filters } =
      isLandingPage || isEntityContractsSearch
        ? { filters: [] }
        : await searchIntentSurvey();
    if (filters?.length) url.searchParams.set("filters", filters.join(";"));

    if (!query && !isEntityContractsSearch) return;

    url.searchParams.set("query", query);
    if (isLocationRelevant) {
      url.searchParams.set("zip", userZip);
    } else {
      url.searchParams.append("filters", "IS_NOT_LOCATION_RELEVANT");
    }

    // Add param to track searches from default list
    const userSelectedFromDefault =
      autocompleteTrackingInfo?.selectedDefaultQuery ||
      clickedQuerySuggestionTrackingValue?.selectedDefaultQuery;

    if (userSelectedFromDefault) {
      url.searchParams.set(
        "user-selected-from-default",
        userSelectedFromDefault.toString()
      );
    }

    url.searchParams.set("analytics-search-source", `${searchSource}`);

    // Record Google Ads (Search on Landing Page) conversions
    trackSearchOnLandingPageConversion();

    if (isSERP || isLandingPage) {
      setShowTypeahead(false);
      searchContractWithParams({
        newParams: {
          query,
          zip: userZip,
          embedSourceEntityId: params.embedSourceEntityId?.toString(),
          searchSource,
          filters: params.filters?.toString().split(";") || [],
          userSelectedFromDefault,
          landingPageSlug: params.landingPageSlug?.toString(),
          ...(originalAmbiguousQuery && { originalAmbiguousQuery }),
          ...(selectedDisambiguationOption && { selectedDisambiguationOption }),
        },
      });
      window.scrollTo(0, 0);
      return;
    }

    if (searchInNewTab) {
      window.open(url.href, "_blank");
    } else {
      window.location.href = url.href;
    }
  };
}
