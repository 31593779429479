import { Typography } from "../../../../../library";
import type { ContractOptimizationStatus } from "../../../../../shared/ContractBase/types";
import { StatusType } from "../../types";

import ContractDialog from "./ContractDialog";

interface ContractOptimizationTasksProps {
  optimizationStatus: Maybe<ContractOptimizationStatus>;
}

export default function ContractOptimizationTasks({
  optimizationStatus,
}: ContractOptimizationTasksProps) {
  if (!optimizationStatus) return;

  const isOptimized = Object.values(optimizationStatus).every(Boolean);

  return (
    <ContractDialog
      text={
        isOptimized
          ? "No remaining tasks"
          : "Remaining tasks for this contract:"
      }
      status={isOptimized ? StatusType.COMPLETE : StatusType.INCOMPLETE}
    >
      {!isOptimized && (
        <ul className="list-disc ml-5">
          {!optimizationStatus.hasContractScope && (
            <li>
              <Typography size="sm" color="neutral.bolder.enabled">
                Confirm what can be sold under this contract
              </Typography>
            </li>
          )}
          {!optimizationStatus.hasSummary && (
            <li>
              <Typography size="sm" color="neutral.bolder.enabled">
                Add contract summary
              </Typography>
            </li>
          )}
          {!optimizationStatus.hasBrands && (
            <li>
              <Typography size="sm" color="neutral.bolder.enabled">
                Add brands you sell
              </Typography>
            </li>
          )}
          {!optimizationStatus.hasContractDoc && (
            <li>
              <Typography size="sm" color="neutral.bolder.enabled">
                Upload award documents
              </Typography>
            </li>
          )}
        </ul>
      )}
    </ContractDialog>
  );
}
