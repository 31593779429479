import clsx from "clsx";
import _clamp from "lodash/clamp";
import _get from "lodash/get";
import { type BgColor, bgColorClass, textColorClass } from "../../utils/colors";

export enum ProgressVariant {
  ENTITY_VIEWS = "ENTITY_VIEWS",
  DEFAULT = "DEFAULT",
}

const BASE_STYLE = "w-full leading-4 transition-all duration-300";
const SIZES: Record<
  "sm" | "md" | "lg",
  {
    textClass: string;
    circleClass: string;
    barClass: string;
    leftOffset: string;
  }
> = {
  sm: {
    textClass: "text-[0.5rem]",
    circleClass: "top-[-7px] h-6 w-6",
    barClass: "h-[10px] rounded-2",
    leftOffset: "12px",
  },
  md: {
    textClass: "text-[11px]",
    circleClass: "top-[-8px] h-9 w-9",
    barClass: "h-4 rounded-2",
    leftOffset: "18px",
  },
  lg: {
    textClass: "text-[16px]",
    circleClass: "top-[-10px] h-12 w-12",
    barClass: "h-6 rounded-3",
    leftOffset: "24px",
  },
};

const VARIANT_CONFIG: Record<
  ProgressVariant,
  {
    progressColor: BgColor;
    progressBarColor: BgColor;
  }
> = {
  [ProgressVariant.ENTITY_VIEWS]: {
    progressColor: "feedback.bold.success",
    progressBarColor: "brand.subtler.enabled",
  },
  [ProgressVariant.DEFAULT]: {
    progressColor: "brand.bold.enabled",
    progressBarColor: "disabled",
  },
};

type Size = "sm" | "md" | "lg";

export interface ProgressBarWithValueProps {
  value: number;
  max?: number;
  progressBarColor?: BgColor;
  progressColor?: BgColor;
  progressBarClass?: string;
  progressClass?: string;
  valueContainerClass?: string;
  variant?: ProgressVariant;
  className?: string;
  showValue?: boolean;
  size?: Size;
}

export default function ProgressBarWithValue({
  value,
  max = 100,
  variant = ProgressVariant.DEFAULT,
  progressColor,
  progressBarColor,
  valueContainerClass,
  progressClass,
  progressBarClass,
  showValue = false,
  size = "md",
  className,
}: ProgressBarWithValueProps) {
  const { textClass, leftOffset } = SIZES[size];
  if (!progressColor) progressColor = VARIANT_CONFIG[variant].progressColor;
  if (!progressBarColor)
    progressBarColor = VARIANT_CONFIG[variant].progressBarColor;

  const percentage = _clamp(max ? value / max : 0, 0, 1);
  const displayPercentage = percentage.toLocaleString(undefined, {
    style: "percent",
  });
  const valueContainer = (
    <div
      className={clsx(
        getValueContainerStyles({
          bgColor: percentage ? progressColor : progressBarColor,
          size,
        }),
        valueContainerClass
      )}
      style={{
        left: `calc(${displayPercentage} - ${leftOffset})`,
        transition: "all 300ms linear",
      }}
    >
      <p
        className={clsx(
          textClass,
          percentage
            ? textColorClass.neutral.subtlest.enabled
            : textColorClass.brand.boldest.enabled
        )}
      >
        {displayPercentage}
      </p>
    </div>
  );

  return (
    <div className={className}>
      <div
        className={clsx(
          getProgressBarStyles({ progressBarColor, size }),
          progressBarClass
        )}
      >
        <div
          style={{ width: displayPercentage }}
          className={clsx(
            getProgressStyles({ progressColor, size }),
            progressClass
          )}
        />
        {showValue && valueContainer}
      </div>
    </div>
  );
}

function getProgressBarStyles({
  progressBarColor,
  size,
}: { progressBarColor?: BgColor; size: Size }) {
  const barColor = _get(bgColorClass, progressBarColor || "");
  const barClass = SIZES[size].barClass;
  return `relative w-full ${BASE_STYLE} ${barColor} ${barClass}`;
}

function getProgressStyles({
  progressColor,
  size,
}: { progressColor?: BgColor; size: Size }) {
  const color = _get(bgColorClass, progressColor || "");
  const barClass = SIZES[size].barClass;
  return `${BASE_STYLE} ${color} ${barClass}`;
}

function getValueContainerStyles({
  bgColor,
  size,
}: { size: Size; bgColor?: BgColor }) {
  const baseClass = "absolute flex items-center justify-center rounded-full";
  const bgClass = _get(bgColorClass, bgColor || "");
  return `${baseClass} ${bgClass} ${SIZES[size].circleClass}`;
}
