import { CardContainer, Typography } from "../../library";
import SupplierContactGuarantee from "../supplier/SupplierCTAContainer/SupplierContactGuarantee";

const howItWorksBullets = [
  "Share some details about your need.",
  "We'll research options and connect you with qualified suppliers in 48 hours.",
  "Respond directly to suppliers in your inbox.",
];

export function HowItWorksCard() {
  return (
    <CardContainer className="p-6 flex flex-col gap-4">
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader text-center mb-4"
      >
        How it works
      </Typography>
      {howItWorksBullets.map((bullet, index) => (
        <HowItWorksBullet
          key={`how-it-works-${
            // biome-ignore lint/suspicious/noArrayIndexKey: This is a static array
            index
          }`}
          index={index + 1}
          text={bullet}
        />
      ))}
      <hr className="my-3" />
      <SupplierContactGuarantee />
    </CardContainer>
  );
}

function HowItWorksBullet({ index, text }: { index: number; text: string }) {
  return (
    <div className="flex gap-2">
      <div className="flex flex-none items-center justify-center rounded-full w-6 h-6 bg-cp-violet-300">
        <Typography
          variant="display"
          size="xs"
          component="span"
          color="brand.boldest.enabled"
          emphasis
        >
          {index}
        </Typography>
      </div>
      <Typography color="brand.boldest.enabled">{text}</Typography>
    </div>
  );
}
