import { createRoot } from "react-dom/client";

import BugReportForm from "../components/BugReport/BugReportForm";
import { Typography } from "../library";
import { getDOMAnchorById } from "../utils";
import { PageType, type ProfileTypes } from "../utils/enums";

import Page from "./Page/Page";

interface BugReportProps {
  is_authenticated?: boolean;
  profile_type?: ProfileTypes;
}

export default function BugReport({
  is_authenticated,
  profile_type,
}: BugReportProps) {
  return (
    <Page
      pageType={PageType.BUG_REPORT}
      showHeader
      searchSource="report-bug-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={null}
    >
      <div className="cp-page-container my-20">
        <Typography className="mb-6" variant="headline" size="lg">
          Report a bug
        </Typography>
        <BugReportForm />
      </div>
    </Page>
  );
}

const container = getDOMAnchorById("bug-report-container");
if (container) {
  const root = createRoot(container);
  root.render(<BugReport />);
}
