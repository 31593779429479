/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `AL` - Alabama
 * * `AK` - Alaska
 * * `AS` - American Samoa
 * * `AZ` - Arizona
 * * `AR` - Arkansas
 * * `AA` - Armed Forces Americas
 * * `AE` - Armed Forces Europe
 * * `AP` - Armed Forces Pacific
 * * `CA` - California
 * * `CO` - Colorado
 * * `CT` - Connecticut
 * * `DE` - Delaware
 * * `DC` - District of Columbia
 * * `FL` - Florida
 * * `GA` - Georgia
 * * `GU` - Guam
 * * `HI` - Hawaii
 * * `ID` - Idaho
 * * `IL` - Illinois
 * * `IN` - Indiana
 * * `IA` - Iowa
 * * `KS` - Kansas
 * * `KY` - Kentucky
 * * `LA` - Louisiana
 * * `ME` - Maine
 * * `MD` - Maryland
 * * `MA` - Massachusetts
 * * `MI` - Michigan
 * * `MN` - Minnesota
 * * `MS` - Mississippi
 * * `MO` - Missouri
 * * `MT` - Montana
 * * `NE` - Nebraska
 * * `NV` - Nevada
 * * `NH` - New Hampshire
 * * `NJ` - New Jersey
 * * `NM` - New Mexico
 * * `NY` - New York
 * * `NC` - North Carolina
 * * `ND` - North Dakota
 * * `MP` - Northern Mariana Islands
 * * `OH` - Ohio
 * * `OK` - Oklahoma
 * * `OR` - Oregon
 * * `PA` - Pennsylvania
 * * `PR` - Puerto Rico
 * * `RI` - Rhode Island
 * * `SC` - South Carolina
 * * `SD` - South Dakota
 * * `TN` - Tennessee
 * * `TX` - Texas
 * * `UT` - Utah
 * * `VT` - Vermont
 * * `VI` - Virgin Islands
 * * `VA` - Virginia
 * * `WA` - Washington
 * * `WV` - West Virginia
 * * `WI` - Wisconsin
 * * `WY` - Wyoming
 */
export enum StateCodeEnum {
    AL = 'AL',
    AK = 'AK',
    AS = 'AS',
    AZ = 'AZ',
    AR = 'AR',
    AA = 'AA',
    AE = 'AE',
    AP = 'AP',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    GU = 'GU',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    MP = 'MP',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    PR = 'PR',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VI = 'VI',
    VA = 'VA',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY',
}
