import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

function NPI() {
  const { zip, entity } = getParams();
  function getCustomizedUrl(url) {
    if (entity) {
      url.searchParams.set("governmentAffiliation", entity);
    }
    if (zip) {
      url.searchParams.set("zip", zip);
    }
    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Search for Shareable Contracts"
        subtitle="Empower your team. Search tens of thousands of competitively solicited shareable contracts available to use now."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          getCustomizedUrl={getCustomizedUrl}
          searchSource={agencySearchSource[agencies.NPI]}
          queryPlaceholder="What are you looking for? Equipment, services, vehicles..."
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-npi");
if (container) {
  const root = createRoot(container);
  root.render(<NPI />);
}
