/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ADMIN` - Admin
 * * `PURCHASING_MEMBER` - Purchasing team member
 * * `DEPARTMENT_USER` - Department user
 */
export enum PermissionRoleEnum {
    ADMIN = 'ADMIN',
    PURCHASING_MEMBER = 'PURCHASING_MEMBER',
    DEPARTMENT_USER = 'DEPARTMENT_USER',
}
