import { Button, ButtonThemes, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";

export default function ConfirmChangeAdminModal({
  hideModal,
  onComplete,
}: ConfirmChangeAdminModalProps) {
  const ctas = (
    <div className="flex gap-4 justify-center">
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        onClick={hideModal}
        dataTestId="cancel-cta"
      >
        Cancel
      </Button>
      <Button
        theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
        onClick={onComplete}
        dataTestId="remove-as-admin"
      >
        Remove me as an admin
      </Button>
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Are you sure you want to remove yourself as an admin at your entity?"
      className="analytics-confirm-change-admin-modal w-full"
      modalSize={modalSizes.SMALL}
      ctaSection={ctas}
    >
      <Typography>
        You will no longer be able to set team member roles or set approved
        sources for the entity.
      </Typography>
    </Modal>
  );
}

interface ConfirmChangeAdminModalProps {
  hideModal: () => void;
  onComplete?: () => void;
}
