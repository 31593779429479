import {
  disallowedSupplierSearchQueryState,
  selectedSupplierSearchSuggestionState,
  supplierSearchResponseDataState,
} from "../jotai/search";

import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import type { SupplierSearchResponse } from "../generated/models/SupplierSearchResponse";

export function useHideSupplierSearch() {
  const hide = useCallback(async (_get: Getter, set: Setter, query: string) => {
    set(supplierSearchResponseDataState, {} as SupplierSearchResponse);
    set(disallowedSupplierSearchQueryState, query);
    set(selectedSupplierSearchSuggestionState, null);
  }, []);
  return useAtomCallback(hide);
}
