import { type Getter, type Setter, atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService, type SemanticKeywordAndOffering } from "../generated";
import { handleError } from "../utils/generatedApi";

const contractScopeListState = atom<SemanticKeywordAndOffering>({
  supplierOfferings: [],
  offerings: [],
});

const contractScopeInitializedState = atom(false);

export const contractScopeState = atom(
  (get) => {
    return {
      isInitialized: get(contractScopeInitializedState),
      scope: get(contractScopeListState),
    };
  },
  (
    _get,
    set,
    value: { scope: SemanticKeywordAndOffering; isInitialized: boolean }
  ) => {
    set(contractScopeListState, value.scope);
    set(contractScopeInitializedState, value.isInitialized);
  }
);

export function initializeContractScopeCallback() {
  const initialize = useCallback(
    async (get: Getter, set: Setter, contractId: string, query: string) => {
      const contractScope = get(contractScopeState);
      if (contractScope.isInitialized) return contractScope;
      try {
        const response = await ApiService.apiV1ContractsSemanticScopeRetrieve(
          contractId,
          query
        );
        set(contractScopeState, {
          scope: response,
          isInitialized: true,
        });
      } catch (error) {
        handleError(error);
        set(contractScopeState, {
          scope: { supplierOfferings: [], offerings: [] },
          isInitialized: true,
        });
      }
      return contractScope;
    },
    []
  );
  return useAtomCallback(initialize);
}
