import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { useAtom, useSetAtom } from "jotai";
import { ApiService, type Piggyback } from "../../../generated";
import {
  addPiggybackSuccessState,
  piggybackErrorState,
  piggybackedContractsLoadingState,
  piggybackedContractsState,
  removePiggybackSuccessState,
} from "../../../jotai/entityContracts";
import { profileTypeState } from "../../../jotai/user";
import { Link, Typography } from "../../../library";
import {
  AnimatedPopupTypes,
  ErrorPopup,
  SuccessPopup,
} from "../../../popups/AnimatedPopup";
import { ProfileType } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import {
  type PiggybackedContractParams,
  trackAddPiggybackedContract,
  trackRemovePiggybackedContract,
} from "../../../utils/tracking";
import { PIGGYBACK_CONTRACTS_URL } from "../constants";
import type { PiggyBackedContract } from "../types";

export default function usePiggybackedContracts() {
  const setContracts = useSetAtom(piggybackedContractsState);
  const setIsLoading = useSetAtom(piggybackedContractsLoadingState);
  const [showAddPiggybackSuccessPopup, setShowAddPiggybackSuccessPopup] =
    useAtom(addPiggybackSuccessState);
  const [showRemovePiggybackSuccessPopup, setShowRemovePiggybackSuccessPopup] =
    useAtom(removePiggybackSuccessState);
  const [errorMessage, setErrorMessage] = useAtom(piggybackErrorState);
  const profileType = useAtomValue(profileTypeState);

  useEffect(() => {
    fetchContracts();
  }, []);

  async function fetchContracts() {
    if (profileType !== ProfileType.BUYER) return;
    try {
      setIsLoading(true);
      const response = await ApiService.apiV1PiggybackList();
      updateContractRows(response);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function piggybackOnContract({
    contractId,
    solicitationId,
    supplierId,
    supplierHandle,
  }: PiggybackedContractParams) {
    try {
      setIsLoading(true);
      const response = await ApiService.apiV1PiggybackCreate({
        contractIds: [contractId],
      });
      setShowAddPiggybackSuccessPopup(true);
      updateContractRows(response);
      trackAddPiggybackedContract({
        contractId,
        solicitationId,
        supplierId,
        supplierHandle,
      });
    } catch (error) {
      handleError(error);
      setErrorMessage("Failed to piggyback on contract");
    } finally {
      setIsLoading(false);
    }
  }

  async function removePiggybackedContract({
    id,
    contract: { contractId, solicitationId, supplierId, supplierHandle },
  }: {
    id: number;
    contract: PiggybackedContractParams;
  }) {
    try {
      setIsLoading(true);
      const response = await ApiService.apiV1PiggybackDestroy(id);
      setShowRemovePiggybackSuccessPopup(true);
      trackRemovePiggybackedContract({
        contractId,
        solicitationId,
        supplierId,
        supplierHandle,
      });
      updateContractRows(response as unknown as Piggyback[]);
    } catch (error) {
      handleError(error);
      setErrorMessage("Failed to remove contract");
    } finally {
      setIsLoading(false);
    }
  }

  function updateContractRows(response: Piggyback[]) {
    const contracts = flattenPiggybackedContracts(response);
    setContracts(contracts);
  }

  function handleViewContracts() {
    window.open(PIGGYBACK_CONTRACTS_URL, "_parent");
    setShowAddPiggybackSuccessPopup(false);
  }

  const addPiggybackSuccessPopup = (
    <SuccessPopup
      type={AnimatedPopupTypes.CLICK}
      show={showAddPiggybackSuccessPopup}
      setShow={setShowAddPiggybackSuccessPopup}
      onClose={() => setShowAddPiggybackSuccessPopup(false)}
      className="analytics-add-piggyback-success-popup w-[410px]"
    >
      <Typography color="neutral.subtlest.enabled" size="sm">
        Expiration notifications enabled!{" "}
        <Link
          color="neutral.subtlest.enabled"
          emphasis={false}
          size="sm"
          component="span"
          onClick={handleViewContracts}
          dataTestId="piggybacked-contracts-link"
        >
          View contracts
        </Link>
      </Typography>
    </SuccessPopup>
  );

  const addPiggybackSuccessPopupEntityPage = (
    <SuccessPopup
      type={AnimatedPopupTypes.CLICK}
      show={showAddPiggybackSuccessPopup}
      setShow={setShowAddPiggybackSuccessPopup}
      onClose={() => setShowAddPiggybackSuccessPopup(false)}
      className="analytics-add-piggyback-success-popup-entity-page"
    >
      <Typography color="neutral.subtlest.enabled" size="sm">
        Expiration notifications enabled!
      </Typography>
    </SuccessPopup>
  );

  const removePiggybackSuccessPopup = (
    <SuccessPopup
      type={AnimatedPopupTypes.TIMED}
      show={showRemovePiggybackSuccessPopup}
      setShow={setShowRemovePiggybackSuccessPopup}
      className="analytics-remove-piggyback-success-popup"
    >
      <Typography color="neutral.subtlest.enabled" size="sm">
        Expiration notifications disabled!
      </Typography>
    </SuccessPopup>
  );

  const piggybackErrorPopup = (
    <ErrorPopup
      type={AnimatedPopupTypes.TIMED}
      show={!!errorMessage}
      setShow={() => setErrorMessage("")}
      onClose={() => setErrorMessage("")}
      durationSeconds={5}
      className="analytics-piggyback-error-popup"
    >
      {errorMessage}
    </ErrorPopup>
  );

  return {
    piggybackOnContract,
    removePiggybackedContract,
    addPiggybackSuccessPopup,
    addPiggybackSuccessPopupEntityPage,
    removePiggybackSuccessPopup,
    piggybackErrorPopup,
  };
}

export function flattenPiggybackedContracts(
  contracts: Piggyback[]
): PiggyBackedContract[] {
  return contracts.map(({ contract, id, isDeletable }) => {
    return {
      id,
      isDeletable,
      ...contract,
    };
  });
}
