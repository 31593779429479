import { Suspense, lazy } from "react";

import { useAtomValue } from "jotai";
import { contractSearchResponseDataState } from "../../jotai/search";
import { profileTypeState } from "../../jotai/user";
import { Typography } from "../../library";
import { ProfileType } from "../../utils/enums";
import SuggestedSearches from "../SuggestedSearches";
import { getCompetitorKeywords } from "./utils";

const QuotingServiceSidebar = lazy(() => import("./QuotingServiceSidebar"));

export default function Sidebar() {
  const profileType = useAtomValue(profileTypeState);

  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const searchResults = contractResponseData?.contractData?.results || [];
  const competitorKeywords = getCompetitorKeywords(searchResults);

  return (
    <div className="sticky top-24 flex flex-col gap-6">
      {!!competitorKeywords.length && (
        <SuggestedSearches
          searchSource="suggested-supplier-keywords"
          searchTerms={competitorKeywords}
          header={
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.bolder.enabled"
            >
              Other people searched for
            </Typography>
          }
        />
      )}
      {profileType !== ProfileType.SUPPLIER && (
        <div className="grid gap-4 min-w-[21rem]">
          <Suspense fallback={null}>
            <QuotingServiceSidebar />
          </Suspense>
        </div>
      )}
    </div>
  );
}
