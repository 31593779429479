import clsx from "clsx";
import type { ContractWithBuyerLeadAgency } from "../../generated";
import { Checkbox, Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";

export default function ContractCheckbox({
  docid,
  title,
  suppliers,
  buyerLeadAgency,
  cooperativeAffiliation,
  checked,
  handleCheckboxChange,
}: Partial<ContractWithBuyerLeadAgency> & {
  checked: boolean;
  handleCheckboxChange: (docid: string) => void;
}) {
  if (!docid || !title) return null;
  // show cooperative affiliation only if it's different from the buyer lead agency
  const showCooperativeAffiliation =
    cooperativeAffiliation && cooperativeAffiliation !== buyerLeadAgency;
  return (
    <div
      className={clsx(
        "flex gap-2 justify-start border border-solid rounded-3 p-3 items-start",
        borderColorClass.neutral.subtle.enabled
      )}
      data-testid={`contract-card-${docid}`}
    >
      <Checkbox
        checked={checked}
        onChange={() => handleCheckboxChange(docid)}
        name={docid}
        align="top"
        label={
          <div className="flex flex-col gap-1 w-[500px]">
            <Typography
              variant="meta"
              size="sm"
              emphasis
              className="line-clamp-2"
            >
              {title}
            </Typography>
            <Typography variant="meta" size="sm">
              Supplier: {suppliers}
            </Typography>
            <Typography variant="meta" size="sm">
              Lead agency: {buyerLeadAgency}
            </Typography>
            {showCooperativeAffiliation && (
              <Typography variant="meta" size="sm">
                Cooperative: {cooperativeAffiliation}
              </Typography>
            )}
          </div>
        }
      />
    </div>
  );
}
