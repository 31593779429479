import type { FormikValues } from "formik";
import { useState } from "react";

import { SourceEnum, type UserSupplierContact } from "../../generated";
import { Typography } from "../../library";
import { FormWrapper } from "../../library/form";
import { ErrorPopup } from "../../popups/AnimatedPopup";
import { handleError, patchSupplierContact } from "../../utils/api";
import { SupplierContactSourceType } from "../../utils/enums";
import { SUPPLIER_PERSON_CONTACT_FIELDS } from "../supplier/SupplierEditForm/constants";

interface ConfirmSupplierContactFormProps {
  contactId: number;
  supplierHandle: string;
  initialContact: UserSupplierContact;
  setContact: (c: UserSupplierContact) => void;
  sharedLinkId: string;
  onComplete: () => void;
}
export default function ConfirmSupplierContactForm({
  contactId,
  supplierHandle,
  initialContact,
  setContact,
  sharedLinkId,
  onComplete,
}: ConfirmSupplierContactFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    email: initialEmail,
    firstName: initialFirstName,
    lastName: initialLastName,
    title: initialTitle,
    phoneNumber: initialPhone,
    isPrimaryRecipient: initialIsPrimaryRecipient,
  } = initialContact;

  async function handleSubmit({
    firstName,
    lastName,
    title,
    phone: phoneNumber,
    email,
    isPrimaryRecipient,
  }: FormikValues) {
    setLoading(true);
    const response = await patchSupplierContact(
      {
        firstName,
        lastName,
        phoneNumber,
        email,
        sharedLinkId,
        source: SourceEnum.MANUAL_ENTRY,
        isPrimaryRecipient: isPrimaryRecipient,
        ...(title && { title }), // optionally include title field
      },
      contactId,
      supplierHandle
    );
    setLoading(false);
    if (handleError(response)) {
      setError(true);
      return;
    }
    setContact({
      email,
      firstName,
      lastName,
      title,
      phoneNumber,
      id: contactId,
      isPrimaryRecipient,
      source: SupplierContactSourceType.MANUAL_ENTRY,
    });
    onComplete();
  }

  return (
    <>
      <Typography
        variant="headline"
        size="sm"
        color="brand.default.secondary.enabled"
        emphasis
        className="w-full border-solid border-white bg-white text-center"
      >
        Your contact for government sales
      </Typography>
      <FormWrapper
        fields={SUPPLIER_PERSON_CONTACT_FIELDS}
        initialValues={{
          firstName: initialFirstName || "",
          lastName: initialLastName || "",
          title: initialTitle || "",
          phone: initialPhone?.phoneNumber,
          extension: initialPhone?.extension,
          email: initialEmail,
          isPrimaryRecipient: initialIsPrimaryRecipient,
        }}
        submitCta="Save contact information"
        onSubmit={handleSubmit}
        submitClassName="analytics-submit-confirm-supplier-contact"
        disabled={loading}
      />
      <ErrorPopup show={error} onClose={() => setError(false)}>
        Unable to save contact information. Please try again.
      </ErrorPopup>
    </>
  );
}
