import * as yup from "yup";

import LabeledInputField from "../../library/form/LabeledInputField";
import type { FormFieldProps } from "../../library/form/types";

import BuyerLeadAgencyField from "./BuyerLeadAgencyField";

export const EDIT_BLA_FIELDS: FormFieldProps[] = [
  {
    name: "entity",
    component: BuyerLeadAgencyField,
    validate: yup.object().shape({
      buyerProfile: yup.object().shape(
        {
          governmentAffiliationDisplayName: yup
            .string()
            .when("governmentAgency", {
              is: (val: { id: string }) => !val || !val.id,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
          governmentAgency: yup
            .object({ id: yup.string().nullable() })
            .when("governmentAffiliationDisplayName", {
              is: (val: string) => !val,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
        },
        [["governmentAgency", "governmentAffiliationDisplayName"]]
      ),
      searchZip: yup
        .string()
        .matches(/^[A-Z\d\s]{0,7}$/, {
          message: "Zip code must be valid.",
        })
        .nullable(),
    }),
  },
];

export const EDIT_TITLE_FIELDS: FormFieldProps[] = [
  {
    name: "role",
    placeholder: "e.g. Procurement Specialist",
    size: "md",
    component: LabeledInputField,
    validate: yup.string().required("Title is required."),
  },
];

export const GROUP_DISPLAY_NAME: Record<
  string,
  { title: string; subtitle?: string }
> = {
  COBID: {
    title: "Oregon State Programs",
    subtitle:
      "Issued by the Certification Office for Business Inclusion and Diversity (COBID)",
  },
  "California OSDS": {
    title: "California State Programs",
    subtitle:
      "Issued by the Office of Small Business and Disabled Veteran Business Enterprise Services (OSDS)",
  },
  "TX HUB": {
    title: "Texas State Programs",
    subtitle: "Issued by the Historically Underutilized Business Program (HUB)",
  },
  "Virginia SWaM": {
    title: "Virginia State Programs",
    subtitle:
      "Issued by the Small, Women-owned, and Minority-owned Business Program (SWaM)",
  },
  "Other Federal": { title: "Federal programs" },
  SBA: { title: "Federal programs (Small Business Administration, SBA)" },
  GSA: { title: "Federal programs (General Services Administration, GSA)" },
};

export const EMAIL_VALIDATION = yup
  .string()
  .email("Valid email is required.")
  .required("Valid email is required.");
