import type { ReactNode } from "react";

interface EmptyInfoContainerProps {
  children: ReactNode;
}

export default function EmptyInfoContainer({
  children,
}: EmptyInfoContainerProps) {
  return (
    <div className="my-6 bg-cp-white-200 p-10 text-center rounded-lg">
      {children}
    </div>
  );
}
