import clsx from "clsx";

import { Typography } from "../../../../library";
import Tabs, { type Tab } from "../../../../shared/Tabs";
import { borderColorClass } from "../../../../utils/colors";
import { trackBuyerInsightsTabClick } from "../../../../utils/tracking";
import type { SupplierWelcomePageData, ViewAnalytics } from "../../types";
import BuyerActivity from "./BuyerActivity";
import EntityActivity from "./EntityActivity";
import Leads from "./Leads";

export interface BuyerInsightsProps {
  viewAnalytics: ViewAnalytics;
  supplierData: SupplierWelcomePageData;
  isLoading: boolean;
  className?: string;
}

export default function BuyerInsights({
  viewAnalytics,
  supplierData,
  isLoading,
  className,
}: BuyerInsightsProps) {
  const numLeads = viewAnalytics.leads.length;
  const numBuyerSessions = viewAnalytics.sessions?.length || 0;
  const numEntitySessions = viewAnalytics.entityActivity?.length || 0;
  if (!numBuyerSessions && !numLeads && !numEntitySessions) {
    return null;
  }

  function handleTabClick(tab: Tab) {
    trackBuyerInsightsTabClick({
      ...supplierData,
      tabName: tab.name,
    });
  }

  const countIcon = (
    <div className="w-4 h-4 rounded-full bg-cp-limeade-250">
      <Typography
        color="accent.limeade.enabled"
        variant="meta"
        emphasis
        size="sm"
        className="px-1"
      >
        {numLeads}
      </Typography>
    </div>
  );

  const buyerInsightTabs: Tab[] = [
    {
      name: "Leads",
      Component: () => (
        <Leads viewAnalytics={viewAnalytics} isLoading={isLoading} />
      ),
      hidden: numLeads === 0,
      Icon: countIcon,
    },
    {
      name: "Buyer Activity",
      Component: () => (
        <BuyerActivity
          viewAnalytics={viewAnalytics}
          isLoading={isLoading}
          supplierData={supplierData}
        />
      ),
      hidden: numBuyerSessions === 0 || supplierData.isProSupplier,
    },
    {
      name: "Entity Activity",
      Component: () => (
        <EntityActivity viewAnalytics={viewAnalytics} isLoading={isLoading} />
      ),
      hidden: numEntitySessions === 0 || !supplierData.isProSupplier,
    },
  ];

  return (
    <div
      className={clsx(
        "flex flex-col gap-6 p-6 w-full rounded-8 border border-solid overflow-auto analytics-buyer-insights",
        borderColorClass.neutral.subtle.enabled,
        className
      )}
    >
      <Tabs tabs={buyerInsightTabs} onTabClick={handleTabClick} />
    </div>
  );
}
