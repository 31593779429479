import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { Badge, Typography } from "..";
import type { BasicContractFile } from "../../shared/types";

// Using functionality from this stack overflow
//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

function formatSizeString(bytes: number) {
  let sizeString = "";
  if (bytes >= 1073741824) {
    sizeString = `${(bytes / 1073741824).toFixed(2)} GB`;
  } else if (bytes >= 1048576) {
    sizeString = `${(bytes / 1048576).toFixed(2)} MB`;
  } else if (bytes >= 1024) {
    sizeString = `${(bytes / 1024).toFixed(2)} KB`;
  } else if (bytes > 1) {
    sizeString = `${bytes} bytes`;
  } else if (bytes === 1) {
    sizeString = `${bytes} byte`;
  } else {
    sizeString = "0 bytes";
  }
  return sizeString;
}

export default function UploadedFileCard({
  file,
  onDelete,
}: {
  file: File | BasicContractFile;
  onDelete: (fileName: string) => void;
}) {
  return (
    <div className="flex flex-row justify-between w-full items-center p-2 rounded-xl bg-cp-neutral-30">
      <div className="flex flex-row gap-2">
        <Typography variant="meta" size="sm" color="neutral.bolder.enabled">
          {file.name}
        </Typography>
        {file instanceof File && !!file.size && (
          <Typography variant="meta" size="sm" color="neutral.bold.enabled">
            {formatSizeString(file.size)}
          </Typography>
        )}
      </div>
      <div className="cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full">
        <Badge
          Icon={CloseRoundedIcon}
          className="analytics-upload-contract-cancel"
          iconClass="text-cp-neutral-palette-700 cursor-pointer"
          size="md"
          onClick={() => onDelete(file.name)}
        />
      </div>
    </div>
  );
}
