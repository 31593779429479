/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `COMPETITORS_UPDATE` - Competitors Update
 * * `PRODUCT_LIST_UPLOAD` - Product List Upload
 * * `PRODUCT_LIST_DELETE` - Product List Delete
 * * `UNVERIFIED_AGENCY_ADD` - Unverified Agency Add
 * * `UNVERIFIED_AGENCY_REMOVE` - Unverified Agency Remove
 * * `CONTRACT_FILE_UPLOAD` - Contract File Upload
 * * `CONTRACT_FILE_DELETE` - Contract File Delete
 */
export enum SupplierEditRequestTypeEnum {
    COMPETITORS_UPDATE = 'COMPETITORS_UPDATE',
    PRODUCT_LIST_UPLOAD = 'PRODUCT_LIST_UPLOAD',
    PRODUCT_LIST_DELETE = 'PRODUCT_LIST_DELETE',
    UNVERIFIED_AGENCY_ADD = 'UNVERIFIED_AGENCY_ADD',
    UNVERIFIED_AGENCY_REMOVE = 'UNVERIFIED_AGENCY_REMOVE',
    CONTRACT_FILE_UPLOAD = 'CONTRACT_FILE_UPLOAD',
    CONTRACT_FILE_DELETE = 'CONTRACT_FILE_DELETE',
}
