/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `BUYER` - Buyer
 * * `END_USER` - End User
 * * `LEADERSHIP` - Leadership
 * * `OTHER` - Other
 */
export enum BuyerRoleEnum {
    BUYER = 'BUYER',
    END_USER = 'END_USER',
    LEADERSHIP = 'LEADERSHIP',
    OTHER = 'OTHER',
}
