import clsx from "clsx";
import type { ReactNode } from "react";

import { Typography } from "../../library";
import { CARD_WIDTH_STYLE } from "../../library/GenericCard";
import { LoadingCards } from "../../shared/Loading/LoadingCards";

const CONTAINER_STYLE = "flex flex-col gap-6";

export default function ProjectDetailCardSection({
  title,
  children,
  isLoading,
}: {
  title: string;
  children: ReactNode;
  isLoading: boolean;
}) {
  return (
    <div className={CONTAINER_STYLE}>
      <Typography
        color="neutral.boldest.enabled"
        emphasis
        size="sm"
        variant="headline"
      >
        {title}
      </Typography>
      {isLoading ? (
        <LoadingCards className={clsx(CONTAINER_STYLE, CARD_WIDTH_STYLE)} />
      ) : (
        children
      )}
    </div>
  );
}
