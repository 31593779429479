import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { captureMessage } from "@sentry/browser";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { useSetAtom } from "jotai";
import type { UserSupplierContact } from "../../../generated";
import useLoginWall from "../../../hooks/useLoginWall";
import { popupState } from "../../../jotai/page";
import { isAuthenticatedState } from "../../../jotai/user";
import { Button, DropdownPicker, Typography } from "../../../library";
import type { DedupedSupplierContact } from "../../../shared/types";
import { getSupplierContact } from "../../../utils/api";
import {
  LoginWallTriggers,
  SupplierContactSourceType,
} from "../../../utils/enums";
import { formatPhoneNumber } from "../../../utils/format";
import { handleError } from "../../../utils/generatedApi";
import {
  getContactDisplayName,
  groupContactsByTitle,
} from "../SupplierEditForm/SupplierEditSection/helpers";
import { ContactNavigationButtons } from "./SupplierContactNavigationButtons";

function PhoneNumber({
  contactPhones,
  phoneIndex,
  contact,
}: {
  contact: DedupedSupplierContact;
  contactPhones: UserSupplierContact[];
  phoneIndex: number;
}) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const requireLogin = useLoginWall();

  if (!isAuthenticated) {
    return (
      <Button
        size={Button.sizes.SMALL}
        theme={Button.themes.TERTIARY_DARK}
        onClick={() =>
          requireLogin({
            triggerId: contact.id.toString(),
            triggerType:
              LoginWallTriggers.SOLICITATION_PAGE_VIEW_PHONE_NUMBER_CLICK,
            onComplete: () => {},
          })
        }
        className="whitespace-nowrap w-fit"
        badgeProps={{
          Icon: LocalPhoneRoundedIcon,
          className: "gap-1",
        }}
      >
        View phone number
      </Button>
    );
  }

  const number =
    contactPhones.length > 0 && contactPhones[phoneIndex]?.phoneNumber;
  if (!number) return null;

  return (
    <Typography size="sm" color="neutral.bolder.enabled">
      {formatPhoneNumber(number)}
    </Typography>
  );
}

export default function SupplierContactInfo({
  supplierId,
  supplierName,
  dedupedContacts,
}: {
  supplierId: number;
  supplierName: string;
  dedupedContacts: DedupedSupplierContact[];
}) {
  const requireLogin = useLoginWall();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setPopupState = useSetAtom(popupState);
  // The currently selected title. When null, we show the supplier's first contact.
  // When a title is selected, we show all the contacts with this title.
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);

  // Index for the current deduped contact, which may have multiple phone numbers.
  // We keep track of this so we can call `getSupplierContact` for one contact
  // at a time, rather than all of them at once.
  const [contactIndex, setContactIndex] = useState(0);
  // Array of phone numbers for the current contact.
  const [contactPhones, setContactPhones] = useState<UserSupplierContact[]>([]);
  // Index for the current phone number (technically also a `DedupedSupplierContact`)
  // for dedupedContacts[contactIndex]
  const [phoneIndex, setPhoneIndex] = useState(0);

  const contactsByTitle = useMemo(
    () => groupContactsByTitle(dedupedContacts),
    [dedupedContacts]
  );

  useEffect(() => {
    if (isAuthenticated) {
      getContactsWithPhoneNumbers({ index: 0 }).then((contacts) =>
        setContactPhones(contacts)
      );
    }
  }, [isAuthenticated]);

  async function getContactsWithPhoneNumbers({
    index,
  }: {
    index: number;
  }): Promise<UserSupplierContact[]> {
    const contact = dedupedContacts[index];
    const responses = await Promise.all(
      contact.duplicatePhoneIds.map(async (contactId) => {
        const response = await getSupplierContact(supplierId, contactId);
        if (response.status === 200) return response.json();
        // This case should not happen, though this tracks if it does.
        if (response.status === 403)
          captureMessage("Invalid user requesting contact information.");
        else if (response.status === 404) {
          captureMessage(
            `No contact with id ${contactId} found for supplier ${supplierId}`
          );
        } else {
          handleError(response);
        }
        return null;
      })
    );
    return responses.filter((v) => !!v?.phoneNumber);
  }

  function onClickBack() {
    setPopupState(null);
    if (phoneIndex > 0) {
      setPhoneIndex((prev) => prev - 1);
    } else if (contactIndex > 0) {
      getContactsWithPhoneNumbers({ index: contactIndex - 1 }).then(
        (response) => {
          setContactIndex(contactIndex - 1);
          setContactPhones(response);
          setPhoneIndex(0);
        }
      );
    }
  }

  function onClickNext() {
    requireLogin({
      triggerId: contactState.id.toString(),
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_NEXT_CONTACT_CLICK,
      onComplete: () => {
        setPopupState(null);
        if (phoneIndex + 1 < contactPhones.length) {
          setPhoneIndex((prev) => prev + 1);
        } else if (contactIndex + 1 < dedupedContacts.length) {
          getContactsWithPhoneNumbers({ index: contactIndex + 1 }).then(
            (response) => {
              setContactIndex(contactIndex + 1);
              setContactPhones(response);
              setPhoneIndex(0);
            }
          );
        }
      },
    });
  }

  const selectedContactSet = selectedTitle
    ? contactsByTitle[selectedTitle]
    : dedupedContacts;
  const contactState = selectedContactSet[contactIndex];

  const displayName = getContactDisplayName(contactState, supplierName);
  const isVerified =
    contactState.source === SupplierContactSourceType.MANUAL_ENTRY;
  const verifiedText = isVerified ? " (verified)" : null;

  const titles = Object.keys(contactsByTitle);
  const titleOptions = titles.map((title) => ({
    key: title,
    value: title,
    label: <Typography>{title}</Typography>,
  }));

  const dropdownOptions = [
    {
      key: "all-contacts",
      value: "",
      label: "All contacts",
    },
    ...titleOptions,
  ];

  return (
    <div className="flex flex-col gap-4 h-fit">
      {titleOptions.length > 1 && (
        <DropdownPicker
          initialValue={""}
          options={dropdownOptions}
          onChange={(value) => setSelectedTitle(value)}
          className="w-full"
          buttonClassName="!px-3"
        />
      )}
      <div className="flex flex-col gap-1">
        <Typography
          emphasis
          color="neutral.boldest.enabled"
          className="capitalize"
        >
          {displayName}
          <Typography
            component="span"
            emphasis={false}
            color="neutral.bolder.enabled"
          >
            {verifiedText}
          </Typography>
        </Typography>
        {(contactState.firstName || contactState.lastName) &&
          contactState.title && (
            <Typography color="neutral.bolder.enabled" size="sm">
              {contactState.title}
            </Typography>
          )}
        <PhoneNumber
          contactPhones={contactPhones}
          phoneIndex={phoneIndex}
          contact={contactState}
        />
      </div>
      {selectedContactSet.length > 1 && (
        <ContactNavigationButtons
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          isBackDisabled={phoneIndex === 0 && contactIndex === 0}
          isNextDisabled={
            !contactPhones[phoneIndex + 1] &&
            !selectedContactSet[contactIndex + 1]
          }
          contactIndex={contactIndex}
          contactsCount={selectedContactSet.length}
        />
      )}
    </div>
  );
}
