import clsx from "clsx";
import type { ContractHit, ContractResult } from "../../generated";

import LoadingHit from "../../shared/SearchPage/SearchHit/LoadingHit";

import { isContractHit } from "../../shared/SearchPage/utils";
import { pageNavigationSourceTypes } from "../../utils/enums";
import SerpCardContainer from "../ContractSearch/SerpCardContainer";

interface ResultsSectionProps {
  hits?: ContractResult[];
  sourceValue?: string;
  isLoading: boolean;
  query: string;
  className?: string;
  trackContractClick: (hit: ContractHit) => void;
  requestID?: string;
  diversityPreferences: (string | null)[];
}

const CONTAINER_STYLE = "grid grid-cols-4 md:grid-cols-12 gap-6 pb-10";
const CARD_STYLE =
  "col-start-1 col-span-full md:col-end-10 xl:col-end-9 flex flex-col gap-6";

export default function ResultsSection({
  hits,
  className,
  isLoading,
  query,
  trackContractClick,
  requestID,
  diversityPreferences,
}: ResultsSectionProps) {
  if (isLoading) {
    return (
      <div className={CONTAINER_STYLE}>
        <div className={CARD_STYLE}>
          <LoadingHit />
          <LoadingHit />
        </div>
      </div>
    );
  }

  if (!hits || !hits.length) {
    return null;
  }

  return (
    <div className={clsx(CONTAINER_STYLE, className)}>
      <div className={CARD_STYLE}>
        {hits.map((hit) =>
          isContractHit(hit) ? (
            <SerpCardContainer
              key={hit.docid}
              hit={hit}
              query={query}
              cardAnalyticsClass="analytics-landing-page-contract-card"
              trackContractClick={() => {
                trackContractClick(hit);
              }}
              pageNavigationSource={pageNavigationSourceTypes.LANDING_PAGE}
              requestID={requestID}
              diversityPreferences={diversityPreferences}
            />
          ) : null
        )}
      </div>
    </div>
  );
}
