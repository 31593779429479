import clsx from "clsx";

const inputClass = (disabled?: boolean) =>
  clsx(
    `appearance-none w-4 h-4 translate-y-0.5 border checked:border-5 rounded-full
      hover:border-cp-neutral-palette-950 disabled:border-cp-neutral-palette-400
      focus:ring-cp-neutral-palette-700 checked:focus:ring-cp-lapis-500 ring-offset-2
      focus:ring-1`,
    {
      "border-cp-neutral-palette-700 checked:border-cp-lapis-500 checked:hover:border-cp-midnight-100":
        !disabled,
    },
    "cursor-pointer disabled:cursor-default"
  );

interface RadioButtonProps {
  disabled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
}

function RadioButton({ disabled, checked, readOnly }: RadioButtonProps) {
  return (
    <input
      type="radio"
      disabled={disabled}
      className={inputClass(disabled)}
      checked={checked}
      readOnly={readOnly}
    />
  );
}

export default RadioButton;
