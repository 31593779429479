import * as yup from "yup";

/* The _correct_ way to validate phone numbers would be to use something like
 * libphonenumber to ensure the number has accurate area codes and international
 * codes. However, we don't _require_ that level of validation at the moment and
 * just want to check if this looks like a phone number. More robust validation
 * should happen on the backend if we'd like. */
const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

function isValidPhoneNumber(phone: Maybe<string>) {
  return PHONE_REGEX.test(phone || "");
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  "phone",
  function (required = true) {
    return this.test("phone", "Invalid phone number", (value) => {
      if (!required && !value) return true;
      return isValidPhoneNumber(value);
    });
  }
);

export default yup;
