import type { FormikProps, FormikValues } from "formik";
import _isEmpty from "lodash/isEmpty";
import {
  type ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import * as yup from "yup";

import useShowModal from "../../hooks/useShowModal";
import { Button } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  ChipInputField,
  InlineFormWrapper,
  TextareaField,
} from "../../library/form";
import { goToURL } from "../../utils";
import { modals } from "../../utils/enums";

import { DETAIL_PLACEHOLDER } from "./helpers";
import { MessageSupplierSource } from "./types";

const FIELDS = [
  [
    {
      name: "ccEmails",
      leadingLabel: "Cc",
      placeholder: "Type email addresses and press enter",
      dataTestId: "message-supplier-cc-emails",
      component: ChipInputField,
      message:
        "You can add other members of your team to this thread. No need to input your own email address.",
    },
  ],
  [
    {
      name: "message",
      component: TextareaField,
      size: "lg" as InputSizes,
      className: "analytics-message-supplier-email-text",
      dataTestId: "message-supplier-email-text",
      validate: yup
        .string()
        .required("Message must not be empty")
        .test({
          name: "hasPrefillPlaceholder",
          exclusive: true,
          message: `Describe the product or service you need in the message by changing the "${DETAIL_PLACEHOLDER}" placeholder.`,
          test: (value) => !value?.includes(DETAIL_PLACEHOLDER),
        }),
    },
  ],
];

const CTA_PROPS: {
  [key in MessageSupplierSource]: {
    ctaCopy: string;
    analytics: string;
  };
} = {
  [MessageSupplierSource.CONTRACT]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [MessageSupplierSource.RECOMMENDATION]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [MessageSupplierSource.SUPPLIER]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [MessageSupplierSource.PROJECT]: {
    ctaCopy: "project",
    analytics: "analytics-back-to-project",
  },
  [MessageSupplierSource.SUPPLIER_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [MessageSupplierSource.CONTRACT_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  // We never show the message form for this source,
  // but it's here for type completion.
  [MessageSupplierSource.BUYER_OPT_IN]: {
    ctaCopy: "",
    analytics: "",
  },
};

export interface MessageSupplierFormProps {
  supplierDisplayName: string;
  initialMessage?: string;
  prefillCcEmails?: string[];
  goBackUrl: URL;
  messageSupplierSource: MessageSupplierSource;
  isLoading: boolean;
  trackLeaveMessagePage: (touchedForm: boolean) => void;
  handleSubmit: (values: FormikValues) => void;
}

export const MessageSupplierForm = forwardRef(
  (
    {
      supplierDisplayName,
      messageSupplierSource,
      goBackUrl,
      isLoading,
      initialMessage,
      prefillCcEmails = [],
      trackLeaveMessagePage,
      handleSubmit,
    }: MessageSupplierFormProps,
    ref: ForwardedRef<FormikProps<FormikValues> | null>
  ) => {
    const innerRef = useRef<FormikProps<FormikValues> | null>(null);
    // biome-ignore lint/style/noNonNullAssertion: We know that this ref must be mounted.
    useImperativeHandle(ref, () => innerRef.current!, []);
    const showConfirmationModal = useShowModal(modals.CONFIRM_DELETE_MESSAGE);

    const navigateBack = () => {
      goToURL(goBackUrl, {}, false);
      trackLeaveMessagePage(!_isEmpty(innerRef.current?.touched));
    };

    function secondaryCtaOnClick() {
      if (innerRef.current?.touched.message) {
        showConfirmationModal({
          subtitle: `If you leave this page, you’ll lose your draft message to ${supplierDisplayName}.`,
          secondaryCtaProps: {
            className: "analytics-message-supplier-back-to-contract",
            ctaText: `Delete message and go back to ${CTA_PROPS[messageSupplierSource].ctaCopy}`,
            onClick: navigateBack,
            buttonVariant: Button.themes.SECONDARY_DESTRUCTIVE,
            align: "right",
          },
        });
        return;
      }
      navigateBack();
    }

    return (
      <InlineFormWrapper
        ref={innerRef}
        fields={FIELDS}
        initialValues={{
          ccEmails: prefillCcEmails,
          message: initialMessage,
        }}
        submitCta="Send"
        disabled={isLoading}
        submitClassName="analytics-submit-message-supplier"
        secondaryCtaProps={{
          className: CTA_PROPS[messageSupplierSource].analytics,
          cta: `Back to ${CTA_PROPS[messageSupplierSource].ctaCopy}`,
          onClick: secondaryCtaOnClick,
          align: "right",
        }}
        onSubmit={handleSubmit}
      />
    );
  }
);
