import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import saveAs from "file-saver";
import listify from "listify";
import _uniqBy from "lodash/uniqBy";
import pluralize from "pluralize";
import productList from "../../../../../img/supplier-profile/product-list.png";
import useShowModal from "../../../../hooks/useShowModal";
import { Badge, Button, Link, Typography } from "../../../../library";
import UploadedFileCard from "../../../../library/FileDropzone/UploadedFileCard";
import type { SubmitFn } from "../../../../library/form/types";
import ClarityCallout from "../../../../shared/ClarityCallout";
import {
  handleError,
  postSupplierProductListUpload,
} from "../../../../utils/api";
import { iconColorClass } from "../../../../utils/colors";
import { type SupplierBusinessTypes, modals } from "../../../../utils/enums";
import { trackUploadProductListSubmit } from "../../../../utils/tracking";
import StatusChip from "../StatusChip";
import SupplierLockedField from "../SupplierLockedField";
import { TEMPLATES_BY_BUSINESS_TYPE, TEMPLATE_MAP } from "../constants";
import { StatusType, type SupplierProductListValues } from "../types";

export default function SupplierProductsSection({
  supplierId,
  businessTypes,
  disabled = false,
  completed,
  uploadedProductLists,
  onProductListsUpdated,
}: {
  supplierId: number;
  businessTypes?: SupplierBusinessTypes[];
  disabled: boolean;
  completed: boolean;
  uploadedProductLists: string[];
  onProductListsUpdated: SubmitFn<SupplierProductListValues>;
}) {
  const showModal = useShowModal(modals.UPLOAD_PRODUCT_LIST);
  const status = disabled
    ? StatusType.LOCKED
    : completed
      ? StatusType.COMPLETE
      : StatusType.INCOMPLETE;
  async function onSubmit(productLists: File[]) {
    const uploadFormData = new FormData();
    productLists.forEach((file, index) => {
      uploadFormData.append(`file${index}`, file);
    });
    const response = await postSupplierProductListUpload(
      supplierId,
      uploadFormData
    );

    const trackData = {
      supplierId,
      fileNames: productLists.map((file: File) => file.name),
      fileSizes: productLists.map((file: File) => file.size),
    };

    if (
      handleError(response, {
        log400ErrorsToSentry: true,
        logToSentry: true,
        onLogToSentry: (error: string) => {
          trackUploadProductListSubmit({ ...trackData, error });
        },
      })
    ) {
      return false;
    }

    trackUploadProductListSubmit(trackData);
    return onProductListsUpdated({
      productListNames: productLists.map((file) => file.name),
      isUpload: true,
    });
  }

  // When the supplier has only one business type,
  // provide only that corresponding template
  const templatesShown = businessTypes?.length
    ? _uniqBy(
        businessTypes.map((type) => TEMPLATES_BY_BUSINESS_TYPE[type]),
        "name"
      )
    : Object.values(TEMPLATE_MAP);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <Typography
            variant="headline"
            size="sm"
            color="brand.default.secondary.enabled"
            emphasis
          >
            Product and service list
          </Typography>
          <StatusChip status={status} />
        </div>
        <Typography color="neutral.boldest.enabled">
          Upload your product and/or service list to make it easier for buyers
          to confirm that you sell what they need.
        </Typography>
        <Typography color="neutral.boldest.enabled">
          Tips: The most effective way to help Pavilion is to provide an Excel
          or CSV file with product line-items, followed by any catalog-level
          data that you have. We've provided templates below to reference for
          both products and services you may offer.
        </Typography>
        <Typography color="neutral.boldest.enabled">
          Please note: We cannot accept PDF formats.
        </Typography>
      </div>
      {!disabled && uploadedProductLists.length > 0 && (
        <div className="flex flex-col gap-2">
          {uploadedProductLists.map((fileName) => (
            <UploadedFileCard
              key={fileName}
              // Create a file with no data, indicating that its size will not be displayed.
              file={new File([], fileName)}
              onDelete={(fileName: string) => {
                const values: SupplierProductListValues = {
                  productListNames: [fileName],
                  isUpload: false,
                };
                onProductListsUpdated(values);
              }}
            />
          ))}
        </div>
      )}
      <SupplierLockedField disabled={disabled}>
        <div className="flex flex-col gap-4">
          <Button
            size={Button.sizes.SMALL}
            theme={Button.themes.SECONDARY_DARK}
            className="w-fit"
            onClick={() => showModal({ supplierId, onSubmit })}
          >
            Upload {listify(templatesShown.map(({ type }) => type))}{" "}
            {pluralize("list", templatesShown.length)}
          </Button>
          <div className="flex flex-col gap-2">
            {templatesShown.map(({ name, source }) => (
              <div key={name} className="flex gap-2 items-center">
                <Link emphasis={false} onClick={() => saveAs(source)}>
                  {name}
                </Link>
                <Badge
                  onClick={() => saveAs(source)}
                  as={Link}
                  label="Download"
                  Icon={FileDownloadOutlinedIcon}
                  linkProps={{
                    size: "sm",
                    color: "neutral.bold.enabled",
                    emphasis: false,
                    underline: false,
                  }}
                  iconClass={iconColorClass.neutral.bold.enabled}
                />
              </div>
            ))}
          </div>
        </div>
        <ClarityCallout
          callouts={[
            {
              title: "What value does a product list add?",
              description:
                "This data is core to Pavilion’s ability to match buyer searches to your profile and contracts.",
              children: (
                <div className="flex flex-col items-start gap-2">
                  <img
                    className="rounded-6 border object-cover h-[200px]"
                    src={productList}
                  />
                  <Typography
                    variant="meta"
                    size="sm"
                    color="neutral.bolder.enabled"
                  >
                    Product list on Pavilion.
                  </Typography>
                </div>
              ),
            },
          ]}
        />
      </SupplierLockedField>
    </div>
  );
}
