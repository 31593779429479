import { Suspense, lazy } from "react";

import { Typography } from "../../../library";

import type { MegaCategories } from "../../../generated";

const CategoryCardList = lazy(() => import("./CategoryCardList"));

interface CategorySectionProps {
  categories: MegaCategories[];
}

export default function CategorySection({ categories }: CategorySectionProps) {
  return (
    <div className="hidden md:block bg-cp-violet-100">
      <div className="py-16 cp-page-container xl:px-0 m-auto">
        <Typography variant="display" size="lg" color="brand.boldest.enabled">
          Explore the marketplace
        </Typography>
        <Typography color="neutral.bolder.enabled" className="my-4">
          Pavilion helps you quickly find, use, and share contracts from public
          entities across hundreds of categories.
        </Typography>
        <Suspense fallback={null}>
          <CategoryCardList categories={categories} className="mt-10" />
        </Suspense>
      </div>
    </div>
  );
}
