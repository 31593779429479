import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import {
  AgencyTypeEnum,
  type ApprovedSource,
  ApprovedSourceStatusEnum,
  StateCodeEnum,
} from "../../../generated";
import { ActionMenu, Badge, Link, Table, Typography } from "../../../library";
import { iconColorClass } from "../../../utils/colors";
import {
  type TrackApprovedSourceParams,
  type TrackSortTableParams,
  trackSortApprovedSourcesTable,
} from "../../../utils/tracking";

export default function ApprovedSourcesTable({
  sources,
  onRemoveSource,
  updateApprovedSourceStatus,
  allowEdits,
}: {
  sources: ApprovedSource[];
  onRemoveSource?: (id: string, params: TrackApprovedSourceParams) => void;
  updateApprovedSourceStatus?: (
    id: string,
    status: ApprovedSourceStatusEnum
  ) => void;
  allowEdits?: boolean;
}) {
  return (
    <Table
      columns={[
        {
          key: "sourceName",
          label: "Contract source",
          isSortable: true,
          render: (
            v,
            { sourceCity, sourceState, sourceLandingPageUrl, status }
          ) => (
            <>
              {status === ApprovedSourceStatusEnum.PENDING ? (
                <Typography
                  className="line-clamp-2 opacity-50"
                  color="neutral.boldest.enabled"
                >
                  {v} (pending approval)
                </Typography>
              ) : sourceLandingPageUrl ? (
                <Link
                  href={sourceLandingPageUrl}
                  underline={false}
                  className="line-clamp-2"
                >
                  {v}
                </Link>
              ) : (
                <Typography
                  color="neutral.boldest.enabled"
                  className="line-clamp-2"
                >
                  {v}
                </Typography>
              )}
              {!!sourceCity && !!sourceState ? (
                <Typography variant="meta" color="neutral.bold.enabled">
                  {sourceCity}, {sourceState}
                </Typography>
              ) : null}
            </>
          ),
        },
        {
          key: "numContracts",
          label: "# Contracts",
          isSortable: true,
          render: (v, { status }) => (
            <Typography
              className={clsx({
                "opacity-50": status === ApprovedSourceStatusEnum.PENDING,
              })}
              color="neutral.boldest.enabled"
            >
              {v}
            </Typography>
          ),
        },
        {
          key: "sourceTypeLabel",
          label: "Type",
          isSortable: true,
          render: (
            v,
            { id, sourceName, sourceTypeLabel, sourceId, status }
          ) => {
            return (
              <div className="flex justify-between">
                <Typography
                  className={clsx({
                    "opacity-50": status === ApprovedSourceStatusEnum.PENDING,
                  })}
                  color="neutral.boldest.enabled"
                >
                  {v}
                </Typography>
                {allowEdits && (
                  <div className="inline-flex">
                    {status === ApprovedSourceStatusEnum.PENDING &&
                      allowEdits && (
                        <div className="flex px-3 gap-6">
                          <Badge
                            Icon={CheckIcon}
                            onClickIcon={() => {
                              updateApprovedSourceStatus?.(
                                id,
                                ApprovedSourceStatusEnum.APPROVED
                              );
                            }}
                            iconClass={iconColorClass.feedback.bold.success}
                          />
                          <Badge
                            Icon={CloseIcon}
                            onClickIcon={() => {
                              updateApprovedSourceStatus?.(
                                id,
                                ApprovedSourceStatusEnum.REJECTED
                              );
                            }}
                            iconClass={iconColorClass.feedback.bold.error}
                          />
                        </div>
                      )}
                    {status !== ApprovedSourceStatusEnum.PENDING && (
                      <ActionMenu
                        align="right"
                        buttonClassName="analytics-approved-sources-item-menu"
                        ariaLabel={`Menu for ${sourceName}`}
                        items={[
                          {
                            analyticsClassName:
                              "analytics-approved-sources-item-delete",
                            text: "Remove approved source",
                            color: "destructive.default.primary.enabled",
                            onClick: () =>
                              onRemoveSource?.(id, {
                                sourceName,
                                sourceTypeLabel,
                                sourceId,
                              }),
                          },
                        ]}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          },
        },
      ]}
      pinned={[
        {
          key: "status",
          values: [ApprovedSourceStatusEnum.PENDING],
        },
        {
          key: "sourceAgencyGroupType",
          values: Object.values(AgencyTypeEnum),
        },
        {
          key: "sourceAgencyGroupState",
          values: Object.values(StateCodeEnum),
        },
      ]}
      defaultSort={{ key: "sourceName", descending: true }}
      data={sources}
      trackSortTable={(params: TrackSortTableParams) => {
        trackSortApprovedSourcesTable(params);
      }}
    />
  );
}
