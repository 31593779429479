import clsx from "clsx";
import { add, set } from "date-fns";
import hourglass from "../../../../../img/icons/hourglass.svg";
import { Typography } from "../../../../library";
import { bgColorClass } from "../../../../utils/colors";
import { formatDate } from "../../../../utils/date";
import EntityActivityTable from "./EntityActivityTable";
import type { BuyerInsightsProps } from "./index";

export default function EntityActivity({
  viewAnalytics,
  isLoading,
}: Omit<BuyerInsightsProps, "className" | "supplierData">) {
  const now = new Date();
  const thisMonth = formatDate(now, "LLLL");
  const firstOfNextMonth = formatDate(
    add(set(now, { date: 1 }), { months: 1 }),
    "MMMM d, yyyy"
  );

  return (
    <div className="grid gap-6">
      <Typography size="sm" color="neutral.bolder.enabled">
        Buyers who have recently viewed your contracts on Pavilion.
      </Typography>
      <div
        className={clsx(
          "flex items-center gap-3 px-4 py-1 rounded-3",
          bgColorClass.accent.persimmon.subtle
        )}
      >
        <img src={hourglass} className="w-10 h-10" />
        <Typography size="sm" color="neutral.bolder.enabled">
          Detailed entity activity data for for {thisMonth} will be shown on{" "}
          {firstOfNextMonth}.
        </Typography>
      </div>
      <EntityActivityTable
        viewAnalytics={viewAnalytics}
        isLoading={isLoading}
      />
    </div>
  );
}
