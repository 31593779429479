/**
 * Small file to keep interlinked component types in sync.
 */
import type { ElementType } from "react";

import type { RENEWAL_INFO_RESPONSE } from "../../utils/constants";

import type { FrontendTag } from "../../generated";
import type { TagVariants } from "../Tag";
import type { StylingKeys } from "./ContractCTASection";

// New Types
export enum CTAType {
  SAVE = "save",
  CHECKBOX = "checkbox",
  NONE = "none",
}
export interface CTA {
  type?: CTAType;
  name?: string;
  styling: StylingKeys;
  text?: string;
  Icon?: ElementType;
  classNames?: string;
  ctaAnalyticsClass?: string;
  onClick: (e?: unknown) => void;
  trackSerpClick?: () => void;
  selected?: boolean;
}

export interface Solicitation {
  id: string;
  title: string;
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
}

export interface ContractDocumentData {
  hasContractDoc: boolean;
  hasPricingDoc: boolean;
  hasRfpBidDoc: boolean;
}

export interface ContractEditRequestData {
  hasPendingRenewalDoc: boolean;
  renewalInfoConfirmation: keyof typeof RENEWAL_INFO_RESPONSE;
  numPendingDocs: number;
  needsExpirationReview: boolean;
  needsDocReview: boolean;
}

export interface Contract {
  title: string;
  docid: string;
  cooperativeAffiliation: string;
  cooperativeLanguage: boolean;
  buyerLeadAgency: string;
  url: string;
  expirationDate: string;
  expirationTimestamp?: number;
  isAutorenewing?: boolean;
  suppliers: string[];
  numDocs?: number;
  contractNumber?: string;
  contractDocumentData?: ContractDocumentData;
  contractEditRequestData?: ContractEditRequestData;
}

export interface ContractWithTags {
  contract: Contract;
  supplierTags: FrontendTag[];
  contractTags: FrontendTag[];
  optimizationStatus?: ContractOptimizationStatus;
}
export interface TagType {
  text: string;
  variant: TagVariants;
  tooltip?: string;
}

export interface ContractOptimizationStatus {
  hasSummary: boolean;
  hasContractScope: boolean;
  hasBrands: boolean;
  hasContractDoc: boolean;
}
