import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  PoweredByPavilion,
  WidgetSearchBarWrapper,
  WidgetThemes,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

function OpenGov() {
  // all param fields are optional
  const { zip, entity } = getParams();

  function getCustomizedUrl(url: URL) {
    if (zip) {
      url.searchParams.set("zip", zip as string);
    }
    if (entity) {
      url.searchParams.set("governmentAffiliation", entity as string);
    }
    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Find and use shareable contracts"
        subtitle="Search contracts from public entities near you and trusted national sources, all in one place for free."
        theme={WidgetThemes.OPENGOV}
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          searchSource={agencySearchSource[agencies.OPENGOV]}
          queryPlaceholder="Search products, services, brands, and suppliers."
          getCustomizedUrl={getCustomizedUrl}
          theme={WidgetThemes.OPENGOV}
        />
        <PoweredByPavilion />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-opengov");
if (container) {
  const root = createRoot(container);
  root.render(<OpenGov />);
}
