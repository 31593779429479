import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { userTypeSignupState } from "../jotai/user";
import { accountModals, modals } from "../utils/enums";
import { isFeatureEnabled } from "../utils/split";
import useShowModal from "./useShowModal";

export default function useShowSignupSurvey() {
  const userType = useAtomValue(userTypeSignupState);
  const showSurveyModal = useShowModal(
    userType === "buyer"
      ? accountModals.SIGNUP_STEPS_SURVEY
      : modals.SUPPLIER_SURVEY
  );

  const show = useCallback(
    ({ onComplete }: { onComplete?: () => void }) => {
      if (isFeatureEnabled("signupSurvey")) {
        showSurveyModal({
          onComplete,
        });
      } else {
        onComplete?.();
      }
    },
    [showSurveyModal]
  );

  return show;
}
