import useUserStateCode from "../../../hooks/useUserStateCode";
import { Button, ButtonSizes, Typography } from "../../../library";
import Modal from "../../Modal/Modal";

import ContractDocumentsFilter from "./ContractDocumentsFilter";
import ExpirationFilter from "./ExpirationFilter";
import { OtherFilters } from "./OtherFilters";
import SourcesFilter from "./SourcesFilter";
import SupplierLocationFilter from "./SupplierLocationFilter";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";

function MobileFiltersModalSectionHeader({ text }: { text: string }) {
  return (
    <Typography variant="headline" size="sm" emphasis={true} className="mb-2">
      {text}
    </Typography>
  );
}

interface MobileFiltersModalProps {
  onChange?: OnFilterChangeFn;
  filters?: string[];
  onToggleFilter?: OnToggleFilterFn;
  hideModal: () => void;
  isLandingPage: boolean;
}

export function MobileFiltersModal({
  filters = [],
  onToggleFilter = () => {},
  onChange = () => {},
  hideModal,
  isLandingPage,
}: MobileFiltersModalProps) {
  const userStateCode = useUserStateCode();
  const resetFilters = useResetFiltersAndSearch();
  return (
    <Modal
      hideModal={hideModal}
      title="Filters"
      className="justify-center rounded-none"
      titleSize="md"
    >
      <div className="grid gap-6">
        <div className="grid gap-2">
          <MobileFiltersModalSectionHeader text="Contract source" />
          <SourcesFilter onChange={onChange} />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Supplier location" />
          <SupplierLocationFilter
            stateCode={userStateCode}
            filters={filters}
            onChange={onChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Expiration date" />
          <ExpirationFilter
            isLandingPage={isLandingPage}
            filters={filters}
            onChange={onChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Contract documents" />
          <ContractDocumentsFilter
            filters={filters}
            onChange={onChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Additional preferences" />
          <OtherFilters
            filters={filters}
            onChange={onChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="flex flex-row gap-3 mt-4">
          <Button onClick={hideModal} size={ButtonSizes.SMALL}>
            Show results
          </Button>
          <Typography
            component="button"
            emphasis
            color="brand.default.primary.enabled"
            className="cursor-pointer items-center"
            onClick={resetFilters}
          >
            Reset Filters
          </Typography>
        </div>
      </div>
    </Modal>
  );
}
