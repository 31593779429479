import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRounded from "@mui/icons-material/ArrowForwardRounded";
import { Button, Typography } from "../../../library";

export function ContactNavigationButtons({
  onClickBack,
  onClickNext,
  isBackDisabled,
  isNextDisabled,
  contactIndex,
  contactsCount,
}: {
  onClickBack: () => void;
  onClickNext: () => void;
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  contactIndex: number;
  contactsCount: number;
}) {
  return (
    <div className="flex grow justify-between items-center">
      <Button
        badgeProps={{ Icon: ArrowBackRounded, includeMargin: true }}
        size={Button.sizes.XS}
        theme={Button.themes.TERTIARY_DARK}
        onClick={onClickBack}
        disabled={isBackDisabled}
      >
        Previous
      </Button>
      <Typography size="sm" color="neutral.bolder.enabled">
        {contactIndex + 1} of {contactsCount}
      </Typography>
      <Button
        badgeProps={{
          Icon: ArrowForwardRounded,
          includeMargin: true,
          reverse: true,
        }}
        size={Button.sizes.XS}
        theme={Button.themes.TERTIARY_DARK}
        onClick={onClickNext}
        disabled={isNextDisabled}
      >
        Next
      </Button>
    </div>
  );
}
