import { useAtomValue } from "jotai";
import { ApiService, type SupplierContractFragmentRequest } from "../generated";
import { userDetailsState } from "../jotai/user";
import { handleError } from "../utils/generatedApi";

export const useCreateSupplierContractFragment = () => {
  const { email } = useAtomValue(userDetailsState);

  return async (request: SupplierContractFragmentRequest): Promise<boolean> => {
    if (!email || !request.contractId || !request.supplierId) return false;
    try {
      await ApiService.apiV1SuppliersContractsContractFragmentsCreate(
        request.contractId,
        request.supplierId,
        {
          ...request,
          userId: email,
        }
      );
    } catch (error) {
      handleError(error);
      return false;
    }
    return true;
  };
};
