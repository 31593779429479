import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import clsx from "clsx";
import { bgColorClass, textColorClass } from "../../../utils/colors";
import Typography from "../../Typography";

export default function ExpandIconTableCell({
  isExpanded,
  canExpand,
  handleExpand,
  className,
}: {
  isExpanded: boolean;
  canExpand: boolean;
  handleExpand: () => void;
  className?: string;
}) {
  return (
    <Typography
      className={className}
      component="td"
      color="neutral.bolder.enabled"
      variant="meta"
    >
      <KeyboardArrowRightIcon
        onClick={handleExpand}
        className={clsx(
          "h-6 p-1 rounded-full cursor-pointer transition-all duration-500 hover:bg-cp-violet-200",
          {
            [`${bgColorClass.brand.subtler.enabled} rotate-90`]:
              isExpanded && canExpand,
            [`${textColorClass.disabled} pointer-events-none`]: !canExpand,
          }
        )}
      />
    </Typography>
  );
}
