/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Contract` - Contract
 * * `Supplier` - Supplier
 * * `SearchLog` - Contract Search Result
 */
export enum SubjectTypeEnum {
    CONTRACT = 'Contract',
    SUPPLIER = 'Supplier',
    SEARCH_LOG = 'SearchLog',
}
