import { useEffect, useState } from "react";
import { ApiService, ContractFileTypeEnum } from "../../../generated";
import { Typography } from "../../../library";
import type { ContractSolicitationDetails } from "../../../pages/Contract";
import ClarityCallout from "../../../shared/ClarityCallout";
import type { BasicContractFile } from "../../../shared/types";
import StatusChip from "../../supplier/SupplierEditForm/StatusChip";
import { StatusType } from "../../supplier/SupplierEditForm/types";
import type { GroupedContractDocuments } from "../types";
import FilesUploadSection from "./FilesUploadSection";
import { getEditableContractFiles } from "./utils";

function groupFilesByType(
  files: BasicContractFile[]
): GroupedContractDocuments {
  const grouped: GroupedContractDocuments = {
    contractDocuments: [],
    bidSolicitationDocuments: [],
    bidTabulationDocuments: [],
    pricingDocuments: [],
    renewalDocuments: [],
    otherDocuments: [],
  };
  files.map((f) => {
    switch (f.type) {
      case ContractFileTypeEnum.CONTRACT:
        grouped.contractDocuments.push(f);
        break;
      case ContractFileTypeEnum.BID_SOLICITATION:
        grouped.bidSolicitationDocuments.push(f);
        break;
      case ContractFileTypeEnum.BID_TABULATION:
        grouped.bidTabulationDocuments.push(f);
        break;
      case ContractFileTypeEnum.PRICING:
        grouped.pricingDocuments.push(f);
        break;
      case ContractFileTypeEnum.RENEWAL:
        grouped.renewalDocuments.push(f);
        break;
      default:
        grouped.otherDocuments.push(f);
    }
  });
  return grouped;
}

export default function EditContractFiles({
  contractDetails,
  title,
  complete,
  setDocumentsTaskCompletion,
}: {
  contractDetails: ContractSolicitationDetails;
  title: string;
  complete: boolean;
  setDocumentsTaskCompletion(hasContractDoc: boolean): void;
}) {
  const [allFiles, setAllFiles] = useState<BasicContractFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    file_information,
    supplier,
    contract_id: docid,
    contract_number: contractNumber,
  } = contractDetails;

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const editRequests =
        await ApiService.apiV1SuppliersEditRequestSummaryRetrieve(supplier.id);
      const existingFiles: BasicContractFile[] = file_information.map(
        // Strip out unnecessary ContractFile fields
        ({ name, type }) => ({ name, type })
      );
      const newAllFiles = getEditableContractFiles(
        existingFiles,
        editRequests,
        docid
      );
      setAllFiles(newAllFiles);
      setDocumentsTaskCompletion(
        newAllFiles.some(({ type }) => type === ContractFileTypeEnum.CONTRACT)
      );
      setIsLoading(false);
    })();
  }, [supplier.id, file_information, setDocumentsTaskCompletion, docid]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <Typography
          emphasis
          size="sm"
          variant="headline"
          color="brand.default.secondary.enabled"
        >
          Documents
        </Typography>
        <StatusChip
          status={complete ? StatusType.COMPLETE : StatusType.INCOMPLETE}
        />
      </div>
      <ClarityCallout
        callouts={[
          {
            title: "Why add documents?",
            description: (
              <>
                Contract pages with critical documents are 2x more likely to
                generate leads. Critical documents typically include the award
                document, bid solicitation, and bid tabulation.
                <br />
                <br />
                Documents improve your ranking in search and enable procurement
                to quickly evaluate their ability to work with you legally.
              </>
            ),
          },
        ]}
        className="my-2"
      />
      {!isLoading && (
        <FilesUploadSection
          files={groupFilesByType(allFiles)}
          supplierId={supplier.id}
          uploadContractDetails={{
            docid,
            title,
            contractNumber,
          }}
          setCompletedStatus={setDocumentsTaskCompletion}
        />
      )}
    </div>
  );
}
