import type { InputSizes } from "../Input/LabeledInput";
import PasswordInput from "../PasswordInput";
import type { CustomFormProps, FieldLabelProps } from "./types";

interface PasswordInputFieldProps extends FieldLabelProps {
  field: {
    name: string;
    value: string;
  };
  form: CustomFormProps;
  sublabel?: string;
  placeholder: string;
  size: InputSizes;
  className?: string;
  validationMessage?: string;
}
export default function PasswordInputField({
  field,
  form: { setFieldTouched, setFieldValue, touched, errors },
  validationMessage,
  ...rest
}: PasswordInputFieldProps) {
  return (
    <PasswordInput
      name={field.name}
      setPassword={(value) => setFieldValue(field.name, value)}
      onBlur={() => setFieldTouched(field.name, true)}
      password={field.value}
      errorMessage={touched[field.name] ? (errors[field.name] as string) : ""}
      validationMessage={touched[field.name] ? validationMessage : ""}
      {...rest}
    />
  );
}
