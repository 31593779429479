import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { userEmailVerifiedState } from "../jotai/user";
import { getUserEmailVerified } from "../utils/api";
import { handleError } from "../utils/generatedApi";

export default function useUpdateEmailVerified() {
  return useAtomCallback(
    useCallback(async (_get, set) => {
      try {
        const verified = await getUserEmailVerified();
        set(userEmailVerifiedState, verified);
        return verified;
      } catch (err) {
        handleError(err);
      }
      return false;
    }, [])
  );
}
