import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Typography } from "../../library";
import { WidgetFooterWrapper } from "../../shared/SearchBar/WidgetSearchBar/shared";

SearchCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  query: PropTypes.string,
  setQuery: PropTypes.func,
};

function SearchCategories({ categories, query, setQuery }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (query) {
      setShow(false);
    }
  }, [query]);

  return (
    <div>
      {!show && (
        <WidgetFooterWrapper>
          <Typography
            size="sm"
            color="brand.default.primary.enabled"
            underline
            className="cursor-pointer"
            onClick={() => {
              setQuery("");
              setShow(true);
            }}
          >
            Find ESC contracts by category
          </Typography>
        </WidgetFooterWrapper>
      )}
      {show && (
        <div className="mx-4 max-w-fit w-full shadow rounded max-h-[160px] overflow-hidden">
          <div className="overflow-y-scroll max-h-[160px]">
            {categories.map((category) => (
              <Typography
                size="sm"
                className="border-t border-t-neutral-120 border-solid py-1.5 px-4 cursor-pointer hover:bg-neutral-120"
                key={category}
                onClick={() => setQuery(category)}
              >
                {category}
              </Typography>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchCategories;
