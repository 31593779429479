import { useAtomValue } from "jotai";
import useShowModal from "../../hooks/useShowModal";
import { requestIDState } from "../../jotai/search";
import { Card, Typography } from "../../library";
import SuggestedSearches from "../../shared/SuggestedSearches";
import { getParams } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { SearchSource, modals } from "../../utils/enums";
import { trackSearchRedirectCTAClick } from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarCtaTypes, SearchBarSizes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

interface SearchBarCardProps {
  suggestedSearchTerms: string[];
  searchSource?: SearchSource;
  suggestedSearchTitle: string;
}

export default function SearchBarCard({
  suggestedSearchTerms,
  suggestedSearchTitle,
  searchSource = SearchSource.BUYER_WELCOME_PAGE,
}: SearchBarCardProps) {
  const requestID = useAtomValue(requestIDState);

  const showSearchModal = useShowModal(modals.SEARCH);
  const urlParams = getParams();
  const query = (urlParams.query || "").toString();

  function handleEmptyQuery() {
    showSearchModal({
      source: SearchSource.BUYER_WELCOME_PAGE,
    });
  }

  function cbOnSearchRedirect(newSearchQuery: string) {
    trackSearchRedirectCTAClick({
      oldSearchQuery: query,
      newSearchQuery,
      requestID: requestID || "",
    });
  }

  return (
    <Card
      showShadow
      className="flex flex-col gap-6 p-6 analytics-search-bar-card"
      bgColor={bgColorClass.neutral.subtlest.enabled}
    >
      <div className="flex flex-col gap-2">
        <Typography
          variant="headline"
          size="xs"
          color="brand.boldest.enabled"
          emphasis
        >
          Find and use contracts
        </Typography>
        <Typography color="neutral.bolder.enabled" size="sm">
          Search contracts from public entities near you and trusted national
          sources, all in one place for free.
        </Typography>
        <div className="flex">
          <SearchBar
            className="hidden md:flex mt-4"
            cbOnEmptyQuery={handleEmptyQuery}
            searchSource={searchSource}
            size={SearchBarSizes.FULL}
            theme={SearchBarThemes.DARK}
            submitCta={SearchBarCtaTypes.TEXT}
            cbOnSearchRedirect={cbOnSearchRedirect}
            disambiguate
          />
          <MobileSearchButton
            searchSource={searchSource || SearchSource.DEFAULT}
          />
        </div>
        <SuggestedSearches
          title={suggestedSearchTitle}
          searchSource={searchSource || SearchSource.DEFAULT}
          searchTerms={suggestedSearchTerms}
        />
      </div>
    </Card>
  );
}
