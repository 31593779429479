import { InlineWidget } from "react-calendly";

import { Link, Typography } from "../../library";
import { getCalendlyLink } from "../../utils";
import { COMPANY_NAME } from "../../utils/constants";

export default function OurProductPage() {
  return (
    <div className="cp-page-container mx-auto">
      <div>
        <section id="webinar" className="max-w-[1140px] my-20 mx-auto pb-12">
          <Typography
            variant="headline"
            size="lg"
            color="brand.boldest.enabled"
            className="font-semibold"
          >
            Schedule a Walkthrough
          </Typography>
          <Typography
            size="sm"
            color="accent.neutral.enabled"
            className="font-semibold"
          >
            See how{" "}
            <Link href="/about/public-entities" newWindow={false}>
              {COMPANY_NAME}
            </Link>{" "}
            can help you save up to 20 hours each week.
          </Typography>
          <InlineWidget url={getCalendlyLink("opcta", "website")} />
        </section>
      </div>
    </div>
  );
}
