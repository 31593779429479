import clsx from "clsx";
import type { ReactNode } from "react";

import { titleCase } from "title-case";
import useIsDebug from "../../hooks/useIsDebug";
import { Typography } from "../../library";
import type { TypographySize } from "../../library/Typography/types";

export default function ContractTitleSubsection({
  title,
  subtitle,
  titleSize = "sm",
  subtitleSize = "md",
}: ContractTitleSubsectionProps) {
  const debug = useIsDebug();
  return (
    <div className="flex flex-col">
      <Typography
        component="div"
        variant="headline"
        size={titleSize || "sm"}
        color="brand.bold"
        emphasis
        className="mb-3 line-clamp-2"
      >
        {typeof title === "string" ? titleCase(title) : title}
      </Typography>
      {subtitle && (
        <Typography
          component="div"
          size={subtitleSize || "md"}
          color="subtler"
          className={clsx({ "line-clamp-2": !debug })}
        >
          {subtitle}
        </Typography>
      )}
    </div>
  );
}

interface ContractTitleSubsectionProps {
  title: ReactNode | string;
  subtitle?: ReactNode;
  titleSize?: TypographySize;
  subtitleSize?: TypographySize;
  className?: string;
}
