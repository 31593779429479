import type { Dispatch } from "react";

import { getPromotedSuppliersDataFromMessageSupplierData } from "../../components/MessageSupplierPage/helpers";
import type { MessageSupplierData } from "../../jotai/messageSupplier";
import { trackSuppliersModalSaveSelection } from "../../utils/tracking";

export default function useOtherSuppliersModalSubmit({
  supplierId,
  suppliersToContact,
  setSuppliersToContact,
  hideModal,
}: {
  supplierId: number;
  suppliersToContact: MessageSupplierData[];
  setSuppliersToContact: Dispatch<MessageSupplierData[]>;
  hideModal: () => void;
}) {
  const submit = () => {
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromMessageSupplierData(suppliersToContact);

    setSuppliersToContact(suppliersToContact);
    trackSuppliersModalSaveSelection({
      supplierId,
      savedSupplierIds: suppliersToContact.map(({ supplier }) => supplier.id),
      savedSupplierHandles: suppliersToContact.map(
        ({ supplier }) => supplier.handle
      ),
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
      isRecommendationPage: true,
    });
    hideModal();
  };
  return submit;
}
