import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import clsx from "clsx";
import { titleCase } from "title-case";

import { Badge, Link, Typography } from "../../../library";

interface CategoryHitProps {
  className?: string;
  id?: string;
  title: string;
  mega_category_id: string;
}
export function CategoryHit({
  className,
  id,
  title,
  mega_category_id,
}: CategoryHitProps) {
  return (
    <div className={clsx("category-hit", className)}>
      <Link
        href={`/categories/${mega_category_id}/${id}`}
        underline={false}
        emphasis={false}
        newWindow={false}
      >
        <div className="flex w-full p-6 rounded-3xl shadow-lg shadow-cp-white-300/50 hover:shadow-cp-white-300">
          <Typography
            component="span"
            variant="headline"
            size="sm"
            emphasis
            className="flex-1 text-cp-midnight-100 group-hover:text-cp-lapis-500"
          >
            {titleCase(title)}
          </Typography>
          <Badge Icon={ArrowForwardRoundedIcon} size="lg" />
        </div>
      </Link>
    </div>
  );
}

interface MegaCategoryPageProps {
  id: string;
  title: string;
  categories: { title: string; id: string }[];
}
export function MegaCategoryPage({
  id,
  title,
  categories,
}: MegaCategoryPageProps) {
  return (
    // check the min height
    <div className="flex flex-row cp-page-container m-auto py-6 min-h-[28rem]">
      <div className="flex flex-col w-full gap-6">
        <Typography
          variant="headline"
          emphasis
          color="brand.bold"
          component="h1"
        >
          Shareable, cooperative public entity contracts for {title}:
        </Typography>
        <div className="grid md:grid-cols-2 gap-8">
          {categories.map(({ title, id: category_id }) => (
            <CategoryHit
              key={category_id}
              id={category_id}
              className="analytics-mega-category-related-category"
              title={title}
              mega_category_id={id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
