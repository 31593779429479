import { useAtomValue } from "jotai";

import { profileTypeState, supplierAccountHandleState } from "../jotai/user";
import { ProfileType } from "../utils/enums";

export default function useSupplierEditUrl() {
  const profileType = useAtomValue(profileTypeState);
  const supplierAccountHandle = useAtomValue(supplierAccountHandleState);
  if (profileType !== ProfileType.SUPPLIER) return null;
  return `/suppliers/${supplierAccountHandle}/edit`;
}
