import type { FormikValues } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { ApiService } from "../../generated";
import { LabeledInputVariants } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  InlineFormWrapper,
  LabeledInputField,
  TextareaField,
} from "../../library/form";
import Modal from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";
import { useSubmitAndSave } from "../constants";

interface EditProjectModalProps {
  hideModal: () => void;
  projectId: string;
  projectName: string;
  projectDescription: string;
  setProjectName: (name: string) => void;
  setProjectDescription: (description: string) => void;
}

const FIELDS = [
  [
    {
      name: "projectName",
      component: LabeledInputField,
      label: "Project name",
      className: "analytics-project-name",
      dataTestId: "project-name",
      initialVariant: LabeledInputVariants.DEFAULT,
      validate: yup.string().required("Name is required."),
    },
  ],
  [
    {
      name: "projectDescription",
      component: TextareaField,
      size: "sm" as InputSizes,
      label: "Project description",
      placeholder:
        "Describe your need. You may include pricing, quantity, budget, and compliance considerations.",
      className: "analytics-project-description",
      dataTestId: "project-description",
      initialVariant: LabeledInputVariants.DEFAULT,
    },
  ],
];

export default function EditProjectModal({
  hideModal,
  projectId,
  projectName,
  setProjectName,
  setProjectDescription,
  projectDescription = "",
}: EditProjectModalProps) {
  const [error, setError] = useState("");

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      const updates = {
        name: values.projectName,
        description: values.projectDescription,
      };

      try {
        await ApiService.apiV1ProjectsPartialUpdate(projectId, updates);
        setProjectName(values.projectName);
        setProjectDescription(values.projectDescription);

        hideModal();
      } catch (error) {
        handleError(error);
        setError(
          "Your update could not be processed at the moment, please try again later."
        );
      }
    }
  );

  return (
    <Modal hideModal={hideModal} title="Edit project info" error={error}>
      <InlineFormWrapper
        fields={FIELDS}
        onSubmit={handleSubmit}
        initialValues={{ projectName, projectDescription }}
        disabled={isLoading}
        submitClassName="analytics-edit-project-info-cta"
        submitCta="Save"
      />
    </Modal>
  );
}
