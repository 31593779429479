import yup from "../../utils/yupPhone";

import LabeledInputField from "./LabeledInputField";
import type { FormFieldProps } from "./types";

export const PHONE_FIELDS: FormFieldProps[] = [
  {
    name: "phone",
    placeholder: "(000) 000-0000",
    label: "Phone number",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim("Your phone number cannot include leading and trailing spaces")
      .phone()
      .required("Phone number is required."),
    className: "analytics-phone-input",
    dataTestId: "phone-number-input",
    autoComplete: "tel",
  },
];

export const PHONE_FIELDS_WITH_EXTENSION: FormFieldProps[] = [
  PHONE_FIELDS[0],
  {
    name: "phoneExtension",
    placeholder: "1234",
    label: "Phone extension",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim("Your phone extension cannot include leading and trailing spaces")
      .matches(/^\d{1,10}$/, "Extension must be a number")
      .max(6, "You can enter up to 6 digits")
      .nullable(),
    className: "analytics-phone-extension-input",
    dataTestId: "phone-extension-input",
    autoComplete: "tel-extension",
  },
];

export const NAME_FIELDS: FormFieldProps[] = [
  {
    name: "firstName",
    label: "First name",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim("Your first name cannot include leading and trailing spaces")
      .min(1, "Your first name needs to be at least 1 character")
      .max(150, "Your first name cannot exceed 150 characters")
      .required("First name is required."),
    dataTestId: "first-name-input",
    className: "analytics-first-name-input",
    autoComplete: "given-name",
  },
  {
    name: "lastName",
    label: "Last name",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim("Your last name cannot include leading and trailing spaces")
      .min(1, "Your last name needs to be at least 1 character")
      .max(150, "Your last name cannot exceed 150 characters")
      .required("Last name is required."),
    dataTestId: "last-name-input",
    className: "analytics-last-name-input",
    autoComplete: "family-name",
  },
];
