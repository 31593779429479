import type {
  ContractHit,
  QueryLocation,
  SupplierCardType,
} from "../../generated";
import { dedupeSuppliers } from "../../shared/SupplierCard/utils";

import pluralize from "pluralize";
import { titleCase } from "title-case";

import searchNoResults from "../../../img/icons/search-no-results.svg";
import { Bullets, Card, Link, Typography } from "../../library";
import MobileSearchButton from "../../shared/MobileSearchButton";
import SupplierCarousel from "../../shared/RecommendationCarousel/SupplierCarousel";
import SearchBar, {
  SearchBarCtaTypes,
  SearchBarSizes,
} from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import SuggestedSearches from "../../shared/SuggestedSearches";
import {
  PageType,
  SearchSource,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { trackCategorySearchContractClick } from "../../utils/tracking";
import SerpCardContainer from "../ContractSearch/SerpCardContainer";

interface SolicitationGroupProps {
  categoryId: string;

  contracts: ContractHit[];
  query: string;
  queryLocation?: QueryLocation;
  queryZip?: string;
  title: string;
}
function SolicitationGroup({
  categoryId,
  contracts,
  query,
  title,
}: SolicitationGroupProps) {
  return (
    <div className="mt-8">
      <Typography variant="headline" size="md" emphasis className="mb-4">
        {titleCase(title)}
      </Typography>
      <div className="grid grid-cols-4 md:grid-cols-12 gap-6 pb-10">
        <div className="col-start-1 col-span-full md:col-end-10 xl:col-end-9 flex flex-col gap-6">
          {contracts.map((hit) => (
            <SerpCardContainer
              key={hit.docid}
              hit={hit}
              cardAnalyticsClass="analytics-category-contract"
              query={query}
              trackContractClick={() => {
                trackCategorySearchContractClick({
                  contractId: hit.docid,
                  solicitationId: hit.solicitationId,
                  categorySearchGroup: title,
                  categoryId,
                });
              }}
              pageNavigationSource={pageNavigationSourceTypes.CATEGORY_PAGE}
              requestID={null}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface SuppliersGroupProps {
  suppliers: SupplierCardType[];
  title: string;
  pageId: string;
}
function SuppliersGroup({ suppliers, title, pageId }: SuppliersGroupProps) {
  // Limit to showing max 8 suppliers
  const suppliersSlice = suppliers.slice(0, 8);
  return (
    <SupplierCarousel
      showCtaInHeader
      suppliers={suppliersSlice}
      title={`Awarded suppliers for ${title}`}
      sourceData={{
        pageType: PageType.CATEGORY_PAGE,
        pageId,
      }}
      onClick={() => null}
    />
  );
}

export interface CategoryContract {
  title: string;
  contracts: ContractHit[];
  query: string;
  queryLocation: QueryLocation;
  queryZip: string;
}
interface CategoryPageProps {
  id: string;
  title: string;
  searchSuggestions: string[];
  categoryContracts: CategoryContract[];
  coops: {
    name: string;
    url: string;
  }[];
}
export function CategoryPage({
  categoryContracts,
  coops,
  id,
  searchSuggestions,
  title,
}: CategoryPageProps) {
  // Include only the suggestive part of the title if it includes a semicolon.
  const [suggestive] = title.split(":");
  const coopItems = coops.map((coop) => {
    if (coop.url) {
      return (
        <Link href={coop.url} key={coop.name}>
          {coop.name}
        </Link>
      );
    }
    return coop.name;
  });

  //  Remove dupes. Sometimes searches result in contracts from the same supplier.
  const suppliers = dedupeSuppliers(
    categoryContracts.flatMap((categoryContract) =>
      categoryContract.contracts.map((contract) => {
        return {
          supplier: {
            id: contract.supplierId,
            displayName: contract.supplierDisplayName,
            handle: contract.supplierHandle,
            about: "",
            addressCity: contract.supplierAddressCity || "",
            addressStateCode: null,
            logoUrl: "",
            offeringsList: [],
            displayAddress: "",
          },
          supplierAgreement: { activeAgreements: [] },
          supplierDisplay: {
            numActiveContracts: 0,
            numExpiredContracts: 0,
          },
          compliance: {
            aliases: contract.supplierAliases || [],
            tags: contract.supplierTags,
            publicEntitiesServed: [],
            diversityCertifications:
              contract.supplierDiversityCertificationIds.map((id) => ({
                id: id,
                types: [],
                name: "",
                shortName: "",
                group: "",
                coverage: "",
                number: "",
                entityName: "",
              })),
          },
          serviceAreaData: {
            state: null,
            confidence: null,
          },
          rank: -1,
          similarOfferings: [],
          contact: { fullName: "", title: "", isPrimaryRecipient: false },
        };
      })
    )
  );

  return (
    <div className="flex flex-col gap-8 cp-page-container m-auto mb-6 pt-[46px] pb-6 min-h-[28rem]">
      <Typography
        variant="headline"
        size="md"
        color="brand.bold"
        emphasis
        component="h1"
      >
        {titleCase(title)} Procurement Made Easy
      </Typography>

      <Card
        className="bg-gradient-to-bl from-violet-200 via-white to-violet-200 grid grid-cols-4"
        responsive
      >
        <div className="col-span-3">
          <Typography variant="headline" color="subtle" className="mb-2">
            Find a cooperative contract
          </Typography>
          <Typography variant="body">
            Try other searches for contracts that cover {title.toLowerCase()}.
            Search the nation&apos;s largest network of over 100,000 cooperative
            and piggybackable contracts—for free
          </Typography>
          <div className="flex">
            <SearchBar
              className="mt-6 hidden md:flex"
              searchSource={SearchSource.CATEGORY_PAGE}
              size={SearchBarSizes.FULL}
              theme={SearchBarThemes.DARK}
              submitCta={SearchBarCtaTypes.TEXT}
              disambiguate
            />
            <MobileSearchButton
              searchSource={SearchSource.CATEGORY_PAGE}
              className="mt-6"
            />
          </div>
          {searchSuggestions.length > 0 && (
            <SuggestedSearches
              searchTerms={searchSuggestions}
              className="mt-6"
              searchSource={SearchSource.CATEGORY_PAGE}
            />
          )}
        </div>
        <div>
          <img
            className="w-full h-full mb-4"
            src={searchNoResults}
            alt="Search contracts"
          />
        </div>
      </Card>

      {!!coops.length && (
        <div className="cp-white-300 border border-solid border-cp-neutral-20 rounded-lg p-8">
          <Typography variant="headline" size="md" emphasis className="mb-4">
            Purchasing {pluralize("Cooperative", coops.length)} you can work
            with for {title.toLowerCase()}
          </Typography>
          <Bullets itemsList={coopItems} numItemsWhenMinimized={10} />
        </div>
      )}
      <div>
        {categoryContracts.map(
          ({ contracts, query, queryLocation, queryZip }) => (
            <SolicitationGroup
              key={title}
              title={`${title} Cooperative Contracts`}
              categoryId={id}
              contracts={contracts}
              query={query}
              queryLocation={queryLocation}
              queryZip={queryZip}
            />
          )
        )}
      </div>
      <SuppliersGroup suppliers={suppliers} title={suggestive} pageId={id} />
    </div>
  );
}
