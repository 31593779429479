import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { Suspense, lazy } from "react";
import { Button, Typography } from "../../../library";

const ShoppingCart = lazy(() => import("../../../shared/ShoppingCartImage"));

const CONTAINER_STYLES =
  "analytics-pavilion-at-a-glance flex p-6 rounded-8 gap-6 shadow-1 bg-gradient-to-r from-cp-persimmon-80 to-cp-violet-300 justify-between";
const CARD_STYLES =
  "flex flex-col gap-2 w-full p-4 rounded-4 bg-[rgba(255,255,255,0.5)]";

export default function PavilionAtAGlance() {
  return (
    <div className={CONTAINER_STYLES}>
      <div className="flex flex-col gap-5 basis-[57rem]">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="neutral.boldest.enabled"
          >
            Pavilion puts you in front of local agencies actively looking to buy
            through existing contracts.
          </Typography>
          <Button
            theme={Button.themes.TERTIARY_DARK}
            size={Button.sizes.SMALL}
            badgeProps={{ Icon: LaunchRoundedIcon, reverse: true }}
            className="w-fit ml-[-16px] analytics-supplier-welcome-page-stand-out-cta"
            onClick={() => {
              window.open(
                "https://www.withpavilion.com/about/guides/watch-stand-out-on-pavilion-as-a-pro-supplier"
              );
            }}
          >
            Learn how to stand out to buyers
          </Button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          <div className={CARD_STYLES}>
            <Typography
              variant="headline"
              size="lg"
              emphasis
              color="brand.bold.enabled"
            >
              2,500+
            </Typography>
            <Typography size="sm">active SLED entities</Typography>
          </div>
          <div className={CARD_STYLES}>
            <Typography
              variant="headline"
              size="lg"
              emphasis
              color="brand.bold.enabled"
            >
              3 of 4
            </Typography>
            <Typography size="sm">
              users use Pavilion to decide on a supplier
            </Typography>
          </div>
          <div className={CARD_STYLES}>
            <Typography
              variant="headline"
              size="lg"
              emphasis
              color="brand.bold.enabled"
            >
              $1.5b
            </Typography>
            <Typography size="sm">annual sales</Typography>
          </div>
          <div className={CARD_STYLES}>
            <Typography
              variant="headline"
              size="lg"
              emphasis
              color="brand.bold.enabled"
            >
              2x
            </Typography>
            <Typography size="sm">annual growth</Typography>
          </div>
        </div>
      </div>
      <Suspense fallback={<div className="w-30" />}>
        <ShoppingCart className="hidden md:flex w-30 basis-content" />
      </Suspense>
    </div>
  );
}
