import clsx from "clsx";
import _get from "lodash/get";
import type { ElementType, ReactNode } from "react";

import { ActionMenu, Badge, Typography } from "../../../library";
import Avatar, { AvatarSizes, AvatarVariant } from "../../../library/Avatar";
import type { BadgeProps } from "../../../library/Badge";
import type { ActionMenuItem } from "../../../library/Dropdown/ActionMenu";
import type {
  TypographyColor,
  TypographyVariant,
} from "../../../library/Typography/types";
import { type BgColor, bgColorClass } from "../../../utils/colors";

export interface AvatarMenuItem {
  onClick: (arg?: unknown) => void;
  text: string;
  color?: TypographyColor;
}

interface AvatarCardProps {
  name: string;
  className?: string;
  subsection?: ReactNode;
  badgeProps?: BadgeProps;
  monogram?: string;
  Icon?: ElementType;
  avatarBgColor: BgColor;
  avatarTextColor: TypographyColor;
  avatarTextVariant?: TypographyVariant;
  menuItems: ActionMenuItem[];
  children?: ReactNode;
}

export function AvatarCard({
  name,
  className,
  subsection,
  badgeProps,
  monogram,
  Icon,
  avatarBgColor,
  avatarTextColor,
  avatarTextVariant,
  menuItems,
  children,
}: AvatarCardProps) {
  let avatar: Maybe<ReactNode> = null;
  if (monogram) {
    avatar = (
      <Avatar
        monogram={monogram}
        variant={AvatarVariant.CIRCLE}
        typographyVariantProp={avatarTextVariant}
        size={AvatarSizes.MED_LG}
        bgColor={avatarBgColor}
        textColor={avatarTextColor}
      />
    );
  } else if (Icon) {
    avatar = (
      <Badge
        size="md"
        className={clsx(
          "w-10 h-10 rounded-full flex justify-center items-center",
          _get(bgColorClass, avatarBgColor)
        )}
        Icon={Icon}
        includeMargin={false}
      />
    );
  }
  return (
    <div
      className={clsx(
        "flex flex-col gap-2 rounded-3xl border border-solid relative h-min p-6",
        className
      )}
    >
      {badgeProps && (
        <Badge
          {...badgeProps}
          className={clsx(
            badgeProps.className,
            "absolute top-0 translate-y-[-12px] py-1 px-3 rounded-6"
          )}
        />
      )}
      <div className="flex gap-4 justify-between">
        {avatar}
        <div className="flex flex-col gap-1 flex-grow">
          <Typography
            variant="headline"
            size="sm"
            color="brand.default.secondary.enabled"
            emphasis
          >
            {name}
          </Typography>
          {subsection && (
            <Typography variant="meta" color="neutral.bold.enabled">
              {subsection}
            </Typography>
          )}
        </div>
        <ActionMenu
          ariaLabel={`Menu for ${name}`}
          items={menuItems}
          align="right"
        />
      </div>
      {children && <div className="flex flex-col gap-1">{children}</div>}
    </div>
  );
}
