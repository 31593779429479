/*
An extension of the basic Card to be used for contract cards.
 */

import clsx from "clsx";
import type { ReactNode } from "react";

import useLoginWall from "../../hooks/useLoginWall";
import { ClickableCard } from "../../library";
import type { LoginWallTriggers } from "../../utils/enums";

import ContractCTASection from "./ContractCTASection";
import ContractTitleSubsection from "./ContractTitleSubsection";
import type { CTA } from "./types";

interface BaseCardProps {
  className?: string;
  title: ReactNode | string;
  subtitle?: ReactNode;
  tags?: ReactNode[];
  children?: ReactNode;
  onClick?: (e: unknown) => void;
  trackSerpClick?: () => void;
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  ctas?: CTA[];
}

export default function BaseCard({
  className,
  title,
  subtitle,
  tags,
  children,
  onClick,
  trackSerpClick,
  loginWallTriggerId,
  loginWallTrigger,
  ctas,
}: BaseCardProps) {
  // NOTE: The child CTAs inherit the login wall for the card!
  const requireLogin = useLoginWall();

  // Wrap our onClick behavior in the login wall if we have the relevant props/state.
  const onCardClick = (e: unknown) => {
    trackSerpClick?.();
    if (!loginWallTrigger || !loginWallTriggerId) {
      if (onClick) {
        onClick(e);
        return;
      }
    }
    void requireLogin({
      triggerId: loginWallTriggerId,
      onComplete: onClick,
      triggerType: loginWallTrigger,
    });
  };

  return (
    <ClickableCard
      className={clsx(
        "flex flex-col border-cp-white-300 !rounded-2xl items-start p-6 min-w-[300px] sm:min-w-0 max-w-[49.5rem]",
        className
      )}
      onClick={onCardClick}
    >
      <>
        <div className="flex flex-row w-full mb-4">
          <div className="flex-col mr-6">
            <ContractTitleSubsection title={title} subtitle={subtitle} />
            {/* contract tags are rendered here */}
            {!!tags?.length && (
              <div className="mt-3 flex flex-row flex-wrap gap-y-2 gap-x-2">
                {tags}
              </div>
            )}
          </div>
          <div className="hidden sm:block ml-auto">
            {ctas && (
              <ContractCTASection
                loginWallTrigger={loginWallTrigger}
                loginWallTriggerId={loginWallTriggerId}
                ctas={ctas}
              />
            )}
          </div>
        </div>
        {children && (
          <div className="flex flex-col w-full gap-y-2">{children}</div>
        )}
        {ctas && (
          <div className="block sm:hidden w-full mt-8">
            <ContractCTASection
              loginWallTrigger={loginWallTrigger}
              loginWallTriggerId={loginWallTriggerId}
              ctas={ctas}
            />
          </div>
        )}
      </>
    </ClickableCard>
  );
}
