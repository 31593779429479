import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { DropdownMenu } from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import { ClickSource } from "../../utils/tracking";

interface HamburgerMenuProps {
  onClickSignUp: (source: ClickSource) => void;
  onClickLogin: (source: ClickSource) => void;
  items: MenuItemProps[];
}

export default function HamburgerMenu({
  onClickSignUp,
  onClickLogin,
  items,
}: HamburgerMenuProps) {
  const options = [
    {
      label: "Log in",
      onClick: () => onClickLogin(ClickSource.HAMBURGER_MENU),
      analyticsClassName: "analytics-mobile-homepage-signIn cursor-pointer",
      dataTestId: "mobile-login",
    },
    {
      label: "Sign up",
      onClick: () => onClickSignUp(ClickSource.HAMBURGER_MENU),
      analyticsClassName: "analytics-mobile-homepage-signUp cursor-pointer",
      dataTestId: "mobile-signup",
    },
    ...items,
  ];

  return (
    <div className="sm:relative xl:hidden mr-2 lg:mr-4">
      <DropdownMenu items={options} responsive>
        <MenuRoundedIcon className="analytics-mobile-homepage-hamburgerMenu text-cp-lapis-500 my-5" />
      </DropdownMenu>
    </div>
  );
}
