import { useState } from "react";

import { SUPPLIER_ADDITIONAL_LOCATIONS_FIELDS } from "../../../components/supplier/SupplierEditForm/constants";
import type {
  SupplierLocation,
  SupplierLocationValues,
} from "../../../components/supplier/SupplierEditForm/types";
import Typography from "../../../library/Typography";
import { FormWrapper } from "../../../library/form";
import Modal from "../../../shared/Modal/Modal";
import {
  addSupplierLocation,
  getErrorMessage,
  handleError,
  patchSupplierLocation,
} from "../../../utils/api";

interface AddAddressModalProps {
  initialLocation?: SupplierLocation;
  hideModal: () => void;
  id?: number;
  handle: string;
  onUpsert: (c: SupplierLocation) => void;
}

export default function AddAddressModal({
  initialLocation,
  hideModal,
  id,
  handle,
  onUpsert,
}: AddAddressModalProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const isNewLocation = !initialLocation?.id;
  const modalTitle = isNewLocation
    ? "Add an additional address"
    : "Edit Address";
  const ctaClass = isNewLocation
    ? "analytics-add-additional-location"
    : "analytics-edit-additional-location";
  const submitCta = isNewLocation ? "Save address" : "Edit address";

  const saveLocation = async (locationData: SupplierLocationValues) => {
    const data = {
      id: id || 0,
      ...locationData,
    };
    let response: Response;
    if (id) {
      response = await patchSupplierLocation(data, id, handle);
    } else {
      response = await addSupplierLocation(data, handle);
    }
    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const errorMessage = await getErrorMessage(response);
        setErrorMessage(errorMessage);
      }

      return;
    }
    // Response data has new contact id
    const { id: updatedId, locationName, ...address } = await response.json();
    onUpsert({ id: updatedId, locationName, address });
    hideModal();
  };

  return (
    <Modal
      title={modalTitle}
      hideModal={hideModal}
      ctaClass={ctaClass}
      data-testId="add-additional-locations-modal"
    >
      <div>
        <FormWrapper
          fields={SUPPLIER_ADDITIONAL_LOCATIONS_FIELDS}
          onSubmit={saveLocation}
          initialValues={
            initialLocation || {
              address: {
                addressCity: "",
                addressLine1: "",
                addressLine2: "",
                addressStateCode: "",
                addressZip: "",
              },
              locationName: "",
            }
          }
          disabled={false}
          submitClassName="analytics-additional-locations-cta"
          submitCta={submitCta}
        />
        {errorMessage && (
          <Typography
            color="destructive.default.primary.enabled"
            className="whitespace-pre-line"
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
