import { createRoot } from "react-dom/client";

import UploadContractsPage from "../components/UploadContractsPage";
import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface UploadContractsProps extends AuthPageProps {
  supplier_handle: string;
}

export default function UploadContracts({
  is_authenticated,
  profile_type,
  active_agreements,
  supplier_handle,
}: UploadContractsProps) {
  return (
    <Page
      pageId=""
      searchSource="upload-contracts-search-bar"
      pageType={PageType.UPLOAD_CONTRACTS}
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <PageSection className="pt-9 pb-22">
        <UploadContractsPage supplierHandle={supplier_handle} />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("upload-contracts");
if (container) {
  const props = JSON.parse(container.dataset.data || "");
  const root = createRoot(container);
  root.render(<UploadContracts {...props} />);
}
