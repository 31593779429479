import type { FormikProps, FormikValues } from "formik";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";

import usePaginatedQuery from "../../hooks/usePaginatedQuery";
import useShowModal from "../../hooks/useShowModal";
import {
  type MessageSupplierData,
  messageSupplierStoreAtom,
} from "../../jotai/messageSupplier";
import {
  governmentAffiliationDisplayNameLocalState,
  userDetailsState,
  userEmailVerifiedState,
} from "../../jotai/user";
import { Typography } from "../../library";
import { useSubmitAndSave } from "../../modals/constants";
import { postContactEmailSupplier } from "../../utils/api";
import { GOV_EMAIL_REGEX } from "../../utils/constants";
import {
  ContactSupplierHeapSource,
  MODAL_SOURCE,
  accountModals,
  modals,
} from "../../utils/enums";
import {
  trackContactSupplierErrorHeap,
  trackContactSupplierGTM,
  trackContactSupplierHeap,
  trackLeaveMessagePage,
  trackViewMessageSupplier,
} from "../../utils/tracking";

import {
  ApiError,
  ApiService,
  type BaseContract,
  InteractionTypeEnum,
  type OtherContractsResponse,
  type SupplierConnectionRequest,
} from "../../generated";
import { supplierHasFeature } from "../../utils/featureManagement";
import {
  getErrorMessage as getGeneratedErrorMessage,
  handleError as handleGeneratedError,
} from "../../utils/generatedApi";
import {
  MessageSupplierForm,
  type MessageSupplierFormProps,
} from "./MessageSupplierForm";
import {
  MessageSupplierHeader,
  type MessageSupplierHeaderProps,
} from "./MessageSupplierHeader";
import MessageSupplierInformation from "./MessageSupplierInformation";
import SupplierChipInput, {
  type SupplierChipInputProps,
} from "./SupplierChipInput";
import { MessageSupplierSource } from "./types";

import { isAfter, sub } from "date-fns";
import useSendEmailVerification from "../../hooks/useSendEmailVerification";
import useUpdateEmailVerified from "../../hooks/useUpdateEmailVerified";
import { getRequestID } from "../../utils";
import { getPromotedSuppliersDataFromMessageSupplierData } from "./helpers";

export interface SharedMessageSupplierPageProps {
  headerProps: MessageSupplierHeaderProps;
  messageSupplierSource: MessageSupplierSource;
  initialSuppliersToContact: MessageSupplierData[];
  supplierChipInputProps: Pick<
    SupplierChipInputProps,
    "numSuppliers" | "readOnlySupplierHandles"
  >;
  messageFormProps: Omit<
    MessageSupplierFormProps,
    "isLoading" | "trackLeaveMessagePage" | "handleSubmit"
  >;
  onSuccessUrl: URL;
  otherSupplierModalFromListProps?: {
    supplierList: MessageSupplierData[];
  };
  extraContractInfo?: Maybe<{
    contractId: string;
    solicitationId: string;
    initialSupplierForQuery?: BaseContract;
  }>;
  extraSupplierInfo?: Maybe<{
    id: number;
    handle: string;
    contact: { full_name?: string };
  }>;
}

export default function SharedMessageSupplierPage({
  headerProps,
  messageSupplierSource,
  initialSuppliersToContact,
  supplierChipInputProps,
  messageFormProps,
  onSuccessUrl,
  otherSupplierModalFromListProps,
  extraContractInfo,
  extraSupplierInfo,
}: SharedMessageSupplierPageProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const emailVerified = useAtomValue(userEmailVerifiedState);
  const [sentVerificationAt, setSentVerificationAt] = useState<Date | null>(
    null
  );
  const [{ firstName, lastName, email }, setUserDetailState] =
    useAtom(userDetailsState);
  const governmentAffiliationDisplayName = useAtomValue(
    governmentAffiliationDisplayNameLocalState
  );
  const showVerifyEmailModal = useShowModal(modals.PLEASE_VERIFY_EMAIL_MODAL);
  const sendVerificationEmail = useSendEmailVerification({
    source: MODAL_SOURCE.MESSAGE_SUPPLIER,
    onError: setErrorMessage,
  });
  const showChangeEmailModal = useShowModal(accountModals.CHANGE_EMAIL);
  const showOtherSuppliersFromSolicitationModal = useShowModal(
    modals.MESSAGE_OTHER_SUPPLIERS_FROM_SOLICITATION
  );
  const showOtherSuppliersFromListModal = useShowModal(
    modals.MESSAGE_OTHER_SUPPLIERS_FROM_LIST
  );
  const showExpansionModal = useShowModal(modals.MESSAGE_SUPPLIER_EXPANSION);

  const [suppliersToContact, setSuppliersToContact] = useState(
    initialSuppliersToContact
  );
  const [emailChangeSuccess, setEmailChangeSuccess] = useState("");
  const [messageSupplierStore, setMessageSupplierStore] = useAtom(
    messageSupplierStoreAtom
  );
  const updateEmailVerified = useUpdateEmailVerified();

  const ref = useRef<FormikProps<FormikValues> | null>(null);
  useEffect(() => {
    if (!sentVerificationAt || !emailVerified) return;

    const oneDayAgo = sub(new Date(), { days: 1 });
    // If verification was sent a day ago, don't autosend.
    if (isAfter(oneDayAgo, sentVerificationAt)) return;

    // If verification was sent within the last day, automatically submit the form
    // and continue.
    ref.current?.submitForm();
  }, [sentVerificationAt, emailVerified]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once on page load.
  useEffect(() => {
    const contractId = extraContractInfo?.contractId;
    const supplierId =
      extraSupplierInfo?.id || initialSuppliersToContact?.[0].supplier.id;
    const supplierHandle =
      extraSupplierInfo?.handle ||
      initialSuppliersToContact?.[0].supplier.handle;
    if (!supplierId && !contractId) return;

    trackViewMessageSupplier({
      contractId,
      supplierId,
      messageSupplierSource,
      supplierHandle,
    });
  }, []);

  const rootKey =
    messageSupplierSource === MessageSupplierSource.CONTRACT ||
    messageSupplierSource === MessageSupplierSource.RECOMMENDATION
      ? extraContractInfo?.contractId
      : extraSupplierInfo?.handle;
  const rootState = messageSupplierStore[rootKey || ""];

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      // We expect this field, but make this check to make typescript happy
      if (!email) return;
      if (!GOV_EMAIL_REGEX.test(email)) {
        showChangeEmailModal({
          title: "Please Update Your Email",
          subtitle: `It looks like you're sending this message
                  from a personal or non-government email address.
                  Please update your email to a government email address.`,
          onComplete: (newEmail: string) => {
            setEmailChangeSuccess(
              "Email information saved! You should be able to send your message now."
            );
            setUserDetailState((prev) => ({
              ...prev,
              email: newEmail,
              firstName,
              lastName,
            }));
          },
          requireGovEmail: true,
          canSkip: false,
        });
        return;
      }

      if (!emailVerified) {
        // We check email verified status again via the an API because a user could have
        // verified their email before a page reload, so then jotai state would not update.
        const verified = await updateEmailVerified();
        if (!verified) {
          sendVerificationEmail(() => showVerifyEmailModal({}));
          setSentVerificationAt(new Date());
          return;
        }
      }

      if (suppliersToContact.length === 0) {
        setErrorMessage("Please add one or more suppliers to contact");
        return;
      }

      let response: SupplierConnectionRequest;
      const suppliers = suppliersToContact.map(
        ({ supplier }) => supplier.handle
      );
      try {
        response = await postContactEmailSupplier({
          contractId: extraContractInfo?.contractId,
          suppliers,
          name: [firstName, lastName].join(" ").trim(),
          cc: values.ccEmails,
          email,
          publicAgency: governmentAffiliationDisplayName,
          description: values.message,
          interactionType: InteractionTypeEnum.MESSAGE_SUPPLIER,
        });
        _trackContactSupplier(values.ccEmails, { isSuccess: true });

        setMessageSupplierStore((prev) => ({
          ...prev,
          [rootKey || ""]: {
            ...prev[rootKey || ""],
            ccEmails: values.ccEmails,
            messagedSuppliers: suppliersToContact,
          },
        }));
        if (messageSupplierSource !== MessageSupplierSource.RECOMMENDATION) {
          showExpansionModal({
            connectionRequestId: response.id,
            contractId: extraContractInfo?.contractId,
            suppliers,
            onComplete: () => {
              // Don't open message success page in a new window
              window.open(onSuccessUrl, "_parent");
            },
          });
        } else {
          window.open(onSuccessUrl, "_parent");
        }
      } catch (err) {
        const isAPIError =
          handleGeneratedError(err, {
            logToSentry: true,
            log400ErrorsToSentry: false,
          }) &&
          err instanceof ApiError &&
          err.status === 400;
        if (!isAPIError) return;

        const errorMessage = getGeneratedErrorMessage(err);
        setErrorMessage(errorMessage);
        _trackContactSupplier(values.ccEmails, {
          isSuccess: false,
          errorMessage,
          errorType: err.status,
        });
      }
    }
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch the initial page only once.
  useEffect(() => {
    // We only fetch if it's from a contract page
    if (messageSupplierSource !== MessageSupplierSource.CONTRACT) return;
    fetchMore();
  }, []);

  const fetchOtherSuppliers = async (
    page: number
  ): Promise<OtherContractsResponse> => {
    try {
      const response = await ApiService.apiV1SolicitationsOtherRetrieve(
        extraContractInfo?.contractId || "",
        extraContractInfo?.solicitationId || "",
        "",
        page,
        "",
        10,
        true
      );
      return response;
    } catch (err) {
      handleGeneratedError(err);
    }
    return { contracts: [], count: 0 };
  };

  // Special fetches for message on solicitation pages
  const {
    list: currentSuppliers,
    fetchMore,
    page,
    count,
  } = usePaginatedQuery({
    initialList: [extraContractInfo?.initialSupplierForQuery],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }) => fetchOtherSuppliers(page),
    onResponse: ({ contracts, count }) => {
      return { list: contracts, count };
    },
  });

  function _trackContactSupplier(
    ccEmails: string[],
    {
      isSuccess,
      errorMessage,
      errorType,
    }: { isSuccess: boolean; errorMessage?: string; errorType?: number }
  ) {
    trackContactSupplierGTM();

    const contactedSupplierHandles = suppliersToContact.map(
      ({ supplier }) => supplier.handle
    );
    const contactedSupplierIds = suppliersToContact
      .filter(({ supplier }) => !!supplier.id)
      .map(({ supplier }) => supplier.id as number);

    const contactedPromotedSuppliers = suppliersToContact.filter((supplier) =>
      messageSupplierSource === MessageSupplierSource.CONTRACT
        ? supplier.contractIsPro
        : supplierHasFeature(
            supplier.supplierAgreement.activeAgreements,
            "analyticsTrackIsPro"
          )
    );
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromMessageSupplierData(
      contactedPromotedSuppliers
    );

    const properties = {
      contractId: extraContractInfo?.contractId,
      solicitationId: extraContractInfo?.solicitationId,
      supplierHandles: contactedSupplierHandles,
      supplierIds: contactedSupplierIds,
      email,
      buyerLoc: governmentAffiliationDisplayName,
      hasVerifiedContact: !!extraSupplierInfo?.contact?.full_name,
      supplierPOC: extraSupplierInfo?.contact?.full_name || "",
      promotedSupplierIds,
      promotedSupplierCount,
      promotedSupplierHandles,
      ccEmails,
      numCcEmails: ccEmails.length,
      source: ContactSupplierHeapSource.SHARED_MESSAGE_SUPPLIER_PAGE,
      interactionType: InteractionTypeEnum.MESSAGE_SUPPLIER,
      messageSupplierSource,
      requestID: getRequestID(),
    };

    if (isSuccess) {
      trackContactSupplierHeap(properties);
    } else {
      trackContactSupplierErrorHeap({ ...properties, errorMessage, errorType });
    }
  }

  function handleChipDelete(supplierHandle: string) {
    const newSupplierArr = suppliersToContact.filter(
      ({ supplier }) => supplierHandle !== supplier.handle
    );
    setSuppliersToContact(newSupplierArr);
  }

  function _trackLeaveMessagePage(touchedForm: boolean) {
    trackLeaveMessagePage({
      hasVerifiedEmail: emailVerified,
      touchedForm,
    });
  }

  return (
    <div className="flex flex-col md:flex-row bg-cp-white-100 pb-16">
      <Typography
        component="div"
        color="brand.bold"
        className="cp-page-container text-left p-10 mx-auto w-full"
      >
        <div className="flex flex-col gap-8 md:gap-10">
          <MessageSupplierHeader {...headerProps} />

          <div className="flex flex-col-reverse grid md:flex-row lg:grid-cols-4 gap-6">
            <MessageSupplierInformation
              isPageFromContract={
                messageSupplierSource === MessageSupplierSource.CONTRACT
              } // TODO: refactor all to use enum
            />
            <div className="flex flex-col gap-6 w-full lg:grid lg:col-span-3">
              <SupplierChipInput
                {...supplierChipInputProps}
                isPageFromContract={
                  messageSupplierSource === MessageSupplierSource.CONTRACT ||
                  messageSupplierSource === MessageSupplierSource.RECOMMENDATION
                }
                suppliersToContact={suppliersToContact}
                handleChipDelete={handleChipDelete}
                numSuppliers={
                  messageSupplierSource === MessageSupplierSource.CONTRACT
                    ? currentSuppliers.length
                    : supplierChipInputProps.numSuppliers
                }
                showOtherSuppliersModal={() => {
                  messageSupplierSource === MessageSupplierSource.CONTRACT
                    ? showOtherSuppliersFromSolicitationModal({
                        supplierId: extraSupplierInfo?.id,
                        solicitationId: extraContractInfo?.solicitationId,
                        contractId: extraContractInfo?.contractId,
                        readOnlySupplierHandles: [extraSupplierInfo?.handle],
                        initialTotalSuppliersCount: count,
                        initialCurrentSuppliers: currentSuppliers,
                        initialPage: page,
                        initialSuppliersToContact: suppliersToContact,
                        setSuppliersToContact,
                      })
                    : showOtherSuppliersFromListModal({
                        supplierId: extraSupplierInfo?.id,
                        readOnlySupplierHandles: [],
                        initialSuppliersToContact: suppliersToContact,
                        setSuppliersToContact,
                        supplierList:
                          otherSupplierModalFromListProps?.supplierList, // TODO: fix during a refactor!
                      });
                }}
              />
              <MessageSupplierForm
                ref={ref}
                {...messageFormProps}
                prefillCcEmails={rootState?.ccEmails}
                isLoading={isLoading}
                trackLeaveMessagePage={_trackLeaveMessagePage}
                handleSubmit={handleSubmit}
              />
              {(errorMessage || emailChangeSuccess) && (
                <Typography
                  color={
                    errorMessage
                      ? "feedback.bold.error"
                      : "feedback.bold.success"
                  }
                  variant="meta"
                  size="lg"
                  className="mt-2 text-right"
                >
                  {errorMessage || emailChangeSuccess}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </Typography>
    </div>
  );
}
