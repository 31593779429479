import clsx from "clsx";

import { Typography } from "../../../library";

interface WordCarouselProps {
  wordList: string[];
  textClass: string;
}

export default function WordCarousel({
  wordList,
  textClass,
}: WordCarouselProps) {
  const TIME_FOR_EACH_TERM = 4;

  return (
    <div className="h-9 xl:h-20 md:h-16 relative overflow-hidden">
      {wordList.map((word, index) => (
        <Typography
          key={word}
          className={clsx(textClass, "absolute top-full animate-slideIn")}
          style={{ animationDelay: `${index * TIME_FOR_EACH_TERM}s` }}
        >
          {word}
        </Typography>
      ))}
    </div>
  );
}
