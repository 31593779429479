/*
A small container for tags.
 */
import clsx from "clsx";

import type { TagType } from "../ContractBase/types";
import TransparentTag from "../TransparentTag";

interface SupplierTagSubsectionProps {
  className?: string;
  tags: TagType[];
}

export default function SupplierTagSubsection({
  className,
  tags,
}: SupplierTagSubsectionProps) {
  if (!tags.length) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        "analytics-supplier-tags-shown flex gap-x-2 flex-wrap gap-y-1"
      )}
    >
      {tags.slice(0, 3).map((tag, ix) => (
        <TransparentTag
          // biome-ignore lint/suspicious/noArrayIndexKey: Tags aren't re-sorted so using index is ok here.
          key={ix}
          variant={tag.variant}
          tooltip={tag.tooltip}
          size="md"
        >
          {tag.text}
        </TransparentTag>
      ))}
    </div>
  );
}
