/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `SEARCH` - Search
 * * `CONTRACT` - Contract
 * * `CONFIRM_SUPPLIER_CONTACT` - Confirm Supplier Contact
 */
export enum ShareTypeEnum {
    SEARCH = 'SEARCH',
    CONTRACT = 'CONTRACT',
    CONFIRM_SUPPLIER_CONTACT = 'CONFIRM_SUPPLIER_CONTACT',
}
