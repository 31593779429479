import { useAtomValue } from "jotai";

import { userEmailState } from "../jotai/user";
import { handleError, postConfirmEmail } from "../utils/api";
import type { MODAL_SOURCE } from "../utils/enums";
import { trackEmailVerification } from "../utils/tracking";

interface UseSendEmailVerificationProps {
  source: MODAL_SOURCE;
  onError?: (message: string) => void;
}

export default function useSendEmailVerification({
  source,
  onError,
}: UseSendEmailVerificationProps) {
  const userEmail = useAtomValue(userEmailState);

  return async function sendVerificationEmail(onComplete: () => void) {
    const response = await postConfirmEmail(userEmail);
    if (handleError(response)) {
      onError?.("Could not send verification email.");
      return;
    }
    trackEmailVerification({ source });
    onComplete();
  };
}
