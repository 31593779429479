import clsx from "clsx";
import pluralize from "pluralize";

import {
  Button,
  ButtonSizes,
  CardContainer,
  Link,
  Typography,
} from "../../library";

const NO_ITEMS_DESCRIPTION =
  "Save contracts and suppliers to your project, and we will connect you with  suppliers who can fulfill the contract.";
interface ProjectQuoteCTAProps {
  projectId: string;
  disabled: boolean;
  numItems: number;
  className?: string;
  isSidebar?: boolean;
}

export default function ProjectQuoteCTA({
  projectId,
  disabled,
  numItems,
  className,
  isSidebar,
}: ProjectQuoteCTAProps) {
  const titleAndCTACopy = `Request ${numItems > 1 ? "quotes" : "a quote"}`;

  return (
    <div className={className}>
      <CardContainer
        className={clsx("h-fit p-6 gap-6", {
          "w-80 sticky top-24": isSidebar,
        })}
      >
        <Typography
          variant="headline"
          color="brand.default.secondary.enabled"
          emphasis
        >
          {titleAndCTACopy}
        </Typography>
        <Typography size="sm" color="neutral.bolder.enabled">
          {numItems > 0
            ? `Want to check contract requirements or request quotes? Email all ${numItems} ${pluralize(
                "supplier",
                numItems
              )} in your project with one click. You'll get replies in your email inbox.`
            : NO_ITEMS_DESCRIPTION}
        </Typography>
        <Button
          as={Link}
          className="analytics-project-get-quotes"
          linkProps={{
            href: `/projects/${projectId}/message`,
            underline: false,
          }}
          size={ButtonSizes.SMALL}
          disabled={disabled}
          dataTestId="project-get-quotes"
        >
          {titleAndCTACopy}
        </Button>
        <Typography
          variant="meta"
          size="sm"
          color="neutral.default.secondary.enabled"
        >
          Suppliers typically respond in 1-3 days.
        </Typography>
      </CardContainer>
    </div>
  );
}
