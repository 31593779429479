import { createJSONStorage } from "jotai/utils";
import type { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
// TODO: For now these variables exists as separate atoms for backwards compatibility with pre-existing cookies.
import Cookies from "js-cookie";
import _isEmpty from "lodash/isEmpty";
import { COOKIE_NO_EXPIRE, hasWindow } from "../utils";

/**
 * `createSessionStorage` creates a typed `storage` object that directs
 * Jotai's `atomWithStorage` utility to persist data to sessionStorage.
 * `atomWithStorage` persists data in localStorage by default.
 *
 * @template T - The type of data to be stored in the session storage.
 * @returns `SyncStorage` or `AsyncStorage` storage object to be used with `atomWithStorage`.
 */
export function createSessionStorage<T>() {
  return createJSONStorage<T>(() =>
    // Check for "window" to handle SSR. If there's no window, pass a mock
    // storage to allow SSR initialization.
    hasWindow()
      ? window.sessionStorage
      : {
          getItem: (_key) => "",
          setItem: (_key, _newValue) => {},
          removeItem: (_key) => {},
        }
  );
}

export function isStorableValue<T>(value: T) {
  return !_isEmpty(value) || ["boolean", "number"].includes(typeof value);
}

type CookieStorageOptions<T> = {
  serialize: (value: T) => string;
  deserialize: (value: string) => T;
  defaultValue: string;
};

/**
 * Creates a cookie storage for storing and retrieving values.
 * `createCookieStorage` creates a typed `storage` object that directs
 * Jotai's `atomWithStorage` utility to persist data with `js-cookies`.
 * `atomWithStorage` persists data in localStorage by default.
 * @template T - The type of the value to be stored.
 * @param options - Optional. By default, handles strings. For JSON values, pass in `serialize`, `deserialize`, and `defaultValue` functions.
 * @returns `SyncStorage` or `AsyncStorage` storage object to be used with `atomWithStorage`.
 */
export function createCookieStorage<T>(
  options: CookieStorageOptions<T> = {
    serialize: (value) => `${value}`,
    deserialize: (value) => value as T,
    defaultValue: "",
  }
): SyncStorage<T> {
  const { serialize, deserialize, defaultValue } = options;
  return {
    getItem: (key: string) => {
      const storedValue = Cookies.get(key) || defaultValue;
      return deserialize(storedValue);
    },
    setItem: (key, newValue) => {
      // Don't serialize data if it was initialized using the atom's default value.
      if (isStorableValue(newValue)) {
        Cookies.set(key, serialize(newValue), {
          expires: COOKIE_NO_EXPIRE,
        });
      }
    },
    removeItem: (key) => Cookies.remove(key),
  };
}
