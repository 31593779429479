import { Field, useField } from "formik";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { inferredEntitySignupState } from "../../../jotai/signup";
import { LabeledInputVariants } from "../../../library";
import type { SupplierInputFieldValue } from "../../../library/form/SupplierInputField";
import type { FormFieldProps } from "../../../library/form/types";

/**
 * Wraps `SupplierInputField` while subscribing to `inferredEntitySignupState`
 * and Formik field value. Sets the field's validation state accordingly.
 */
export default function InferredSupplierField({
  editable,
  ...props
}: {
  editable: boolean;
} & FormFieldProps) {
  const [field, { value }, { setValue }] = useField<SupplierInputFieldValue>({
    name: props.name,
  });
  const [validationMessage, setValidationMessage] = useState("");
  const [variant, setVariant] = useState(LabeledInputVariants.DEFAULT);
  const [inferredEntity, setInferredEntity] = useAtom(
    inferredEntitySignupState
  );

  useEffect(() => {
    const inferredEntityDisplayName = inferredEntity?.supplierDisplayName;
    const isSupplierOnPavilion =
      inferredEntityDisplayName || !!value.supplier.handle;
    if (!isSupplierOnPavilion) {
      setValidationMessage("");
      setVariant(LabeledInputVariants.DEFAULT);
      return;
    }

    const valueIsInferredSupplier =
      value.supplier.displayName === inferredEntityDisplayName;
    const valueIsFromDropdown = value.supplier.handle;

    const shouldResetField =
      variant === LabeledInputVariants.SUCCESS &&
      !valueIsFromDropdown &&
      !valueIsInferredSupplier;

    // Reset inferredSupplier when the field value changes from user input
    if (shouldResetField) {
      setInferredEntity(null);
      setValidationMessage("");
      setVariant(LabeledInputVariants.DEFAULT);
      return;
    }

    // Only autofill the from inferredSupplier if the field is blank. We don't
    // want to overwrite the user's input if they've already typed something.
    if (value.supplier.displayName === "" && inferredEntityDisplayName) {
      setValue({
        supplier: { displayName: inferredEntityDisplayName },
      });
    }

    setValidationMessage("Your business is on Pavilion!");
    setVariant(LabeledInputVariants.SUCCESS);
  }, [setValue, setInferredEntity, inferredEntity, variant, value.supplier]);

  return (
    <Field
      field={field}
      validationMessage={validationMessage}
      initialVariant={variant}
      editable={editable}
      required
      {...props}
    />
  );
}
