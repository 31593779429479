import searchNoResults from "../../../img/icons/search-no-results.svg";
import { Card, Typography } from "../../library";
import { BLACard } from "../../shared/BLACard";
import MobileSearchButton from "../../shared/MobileSearchButton";
import SearchBar, {
  SearchBarCtaTypes,
  SearchBarSizes,
} from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import SuggestedSearches from "../../shared/SuggestedSearches";
import type { GovernmentAgency } from "../../shared/types";
import { SearchSource } from "../../utils/enums";

interface EntityListPageProps {
  entities: GovernmentAgency[];
  pageTitle: string;
  pageSubtitle: string;
}

export default function EntityListPage({
  entities,
  pageTitle,
  pageSubtitle,
}: EntityListPageProps) {
  return (
    <div className="pt-10 pb-36">
      <Typography variant="headline" size="lg" className="mb-6">
        {pageTitle}
      </Typography>
      <Typography size="md" color="subtlest">
        {pageSubtitle}
      </Typography>

      <Card
        className="bg-gradient-to-bl from-violet-200 via-white to-violet-200 grid grid-cols-4 mt-8"
        responsive
      >
        <div className="col-span-3">
          <Typography variant="headline" color="subtle" className="mb-2">
            Find a cooperative contract
          </Typography>
          <Typography variant="body">
            Try other searches for contracts that cover managed print services.
            Search the nation&apos;s largest network of over 100,000 cooperative
            and piggybackable contracts—for free
          </Typography>
          <div className="flex">
            <SearchBar
              className="mt-6 hidden md:flex"
              searchSource={SearchSource.AGENCIES_PAGE}
              size={SearchBarSizes.FULL}
              theme={SearchBarThemes.DARK}
              submitCta={SearchBarCtaTypes.TEXT}
              disambiguate
            />
            <MobileSearchButton
              searchSource={SearchSource.AGENCIES_PAGE}
              className="mt-6"
            />
          </div>
          <SuggestedSearches
            searchTerms={[
              "Playground equipment",
              "Safeware",
              "Panasonic AG-HVX205",
            ]}
            className="mt-6"
            searchSource={SearchSource.AGENCIES_PAGE}
          />
        </div>
        <div>
          <img
            className="w-full h-full mb-4"
            src={searchNoResults}
            alt="Search contracts"
          />
        </div>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-16">
        {entities.map((entity) => (
          <BLACard
            agency={entity}
            showType
            showButton={false}
            key={entity.name}
          />
        ))}
      </div>
    </div>
  );
}
