import { atom } from "jotai";
import type { InferredEntityFromEmail } from "../generated";
import type { BuyerLeadAgencyFieldValue } from "../library/form/BuyerLeadAgencyField";
import type { SupplierInputFieldValue } from "../library/form/SupplierInputField";

export const inferredEntitySignupState = atom<InferredEntityFromEmail | null>(
  null
);

export const blaFieldSignupState = atom<BuyerLeadAgencyFieldValue | null>(null);

export const supplierFieldSignupState = atom<SupplierInputFieldValue | null>(
  null
);
