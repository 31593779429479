import BuyerOptInModal from "./BuyerOptinModal";
import type { SharedBuyerOptInModalProps } from "./types";

export type ShowBuyerOptInBeforeDownloadModal = (
  args: Omit<SharedBuyerOptInModalProps, "hideModal">
) => void;

export default function BuyerOptInBeforeDownloadModal({
  ...props
}: SharedBuyerOptInModalProps) {
  return (
    <BuyerOptInModal
      {...props}
      title="Get pricing and contract details in your inbox"
      subtitle="We'll connect you with the right contacts to share pricing and answer any questions you have."
      ctaText="Download documents"
      analyticsClassName="analytics-supplier-outreach-opt-in-before-download"
    />
  );
}
