import { PLAN_SUPPLIER_FEATURE_MAP } from "./constants";

export function supplierHasFeature(
  activeAgreements: Maybe<string[]>,
  feature: string
): boolean {
  if (!activeAgreements) return false;
  const featureAgreements =
    PLAN_SUPPLIER_FEATURE_MAP[
      feature as keyof typeof PLAN_SUPPLIER_FEATURE_MAP
    ];
  return featureAgreements.some((agreement) =>
    activeAgreements.includes(agreement)
  );
}
