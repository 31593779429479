import pluralize from "pluralize";

import { Avatar, AvatarSizes, Typography } from "../../library";
import GenericCard from "../../library/GenericCard";
import { FileFolderIcon } from "../../shared/CustomIcons/FileFolderIcon";

import type { Project, ProjectCollaborator } from "../../generated";
import CollaboratorAvatars from "./CollaboratorAvatars";

function Collaborators({
  collaborators,
}: { collaborators: ProjectCollaborator[] }) {
  if (collaborators.length < 2) return null;
  return (
    <div className="mt-4">
      <Typography color="neutral.bold.enabled" size="sm" variant="meta">
        Collaborators
      </Typography>
      <CollaboratorAvatars className="mt-1" collaborators={collaborators} />
    </div>
  );
}

export default function ProjectCard({
  id,
  name,
  startedAt,
  numContracts,
  numSuppliers,
  collaborators,
}: Project) {
  let itemCopy = "";
  if (numContracts) {
    itemCopy = `${numContracts} ${pluralize("contract", numContracts)}`;
  } else if (numSuppliers) {
    itemCopy = `${numSuppliers} ${pluralize("supplier", numSuppliers)}`;
  }

  const avatar = (
    <Avatar
      size={AvatarSizes.LARGE}
      IconComponent={FileFolderIcon}
      bgColor="accent.leaf.enabled"
    />
  );

  return (
    <GenericCard
      className="!flex-row gap-4"
      href={`/projects/${id}`}
      responsive
    >
      <div className="w-12">{avatar}</div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between flex-wrap">
          {itemCopy && (
            <Typography color="neutral.bold.enabled" size="sm" variant="meta">
              {itemCopy}
            </Typography>
          )}
          <Typography color="neutral.bold.enabled" size="sm" variant="meta">
            {startedAt}
          </Typography>
        </div>
        <Typography
          color="brand.default.primary.enabled"
          emphasis
          size="sm"
          variant="headline"
        >
          {name}
        </Typography>
        <Collaborators collaborators={collaborators} />
      </div>
    </GenericCard>
  );
}
