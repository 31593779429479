import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import useShowModal from "../../hooks/useShowModal";
import { isAuthenticatedState } from "../../jotai/user";
import {
  ActionMenu,
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Typography,
} from "../../library";
import { CARD_WIDTH_STYLE } from "../../library/GenericCard";
import { modals } from "../../utils/enums";

import type { ProjectCollaborator } from "../../generated";
import CollaboratorAvatars from "./CollaboratorAvatars";
import ProjectDetailBanner, { BannerType } from "./ProjectDetailBanner";
import type { ProjectUserRole } from "./types";

interface ProjectDetailPageHeaderProps {
  projectId: string;
  projectName: string;
  projectUserRole: Maybe<ProjectUserRole>;
  hasEditAccess: boolean;
  hasVisitedProject: boolean;
  collaborators: ProjectCollaborator[];
  isArchived: boolean;
  onClickShowEditModal: () => void;
  onClickShowArchiveProjectModal: () => void;
}

export default function ProjectDetailPageHeader({
  projectId,
  projectName,
  projectUserRole,
  hasEditAccess,
  hasVisitedProject,
  collaborators,
  isArchived,
  onClickShowEditModal,
  onClickShowArchiveProjectModal,
}: ProjectDetailPageHeaderProps) {
  const showInviteModal = useShowModal(modals.PROJECT_INVITE_MODAL);
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  const isProjectOwner = projectUserRole === "Owner";
  const showBanner =
    !isAuthenticated ||
    !hasEditAccess ||
    (!hasVisitedProject && !isProjectOwner);

  return (
    <div className="flex flex-col w-full">
      <div className={clsx("flex flex-col gap-4", CARD_WIDTH_STYLE)}>
        <div className="flex flex-col gap-1">
          <Typography
            className="inline-flex"
            color="neutral.bold.enabled"
            variant="meta"
            size="sm"
            component="a"
            href="/projects"
          >
            <Badge Icon={ArrowBackIosNewRoundedIcon} size="sm" />
            Projects
          </Typography>
          <Typography
            variant="headline"
            emphasis
            color="brand.boldest.enabled"
            component="h1"
          >
            {projectName} {isArchived && "(Archived)"}
          </Typography>
        </div>
        {hasEditAccess && (
          <div className="flex items-center">
            <Button
              className="analytics-archive-project-cta whitespace-nowrap mr-4"
              onClick={onClickShowArchiveProjectModal}
              badgeProps={{
                Icon: CheckRoundedIcon,
              }}
              size={ButtonSizes.SMALL}
              theme={ButtonThemes.SECONDARY_DARK}
              type="button"
              disabled={isArchived}
              dataTestId="archive-project-cta"
            >
              Archive project
            </Button>
            <Button
              className="analytics-invite-members-project-cta whitespace-nowrap mr-4"
              onClick={() => {
                showInviteModal({ projectId });
              }}
              badgeProps={{
                Icon: GroupOutlinedIcon,
              }}
              size={ButtonSizes.SMALL}
              theme={ButtonThemes.SECONDARY_DARK}
              type="button"
              disabled={isArchived}
              dataTestId="invite-members-project-cta"
            >
              Add team member
            </Button>
            {collaborators.length > 1 && (
              <CollaboratorAvatars
                className="ml-2"
                collaborators={collaborators}
              />
            )}
            <ActionMenu
              align="left"
              buttonClassName="analytics-project-detail-dropdown-menu"
              items={[
                {
                  analyticsClassName: "analytics-project-detail-dropdown-edit",
                  text: "Edit project info",
                  color: "destructive.default.primary.enabled",
                  onClick: onClickShowEditModal,
                  disabled: isArchived,
                },
              ]}
            />
          </div>
        )}
      </div>
      {showBanner && (
        <ProjectDetailBanner
          type={
            !isAuthenticated
              ? BannerType.ANON
              : !hasEditAccess
                ? BannerType.NON_COLLAB
                : BannerType.FIRST_VISIT
          }
        />
      )}
    </div>
  );
}
