import clsx from "clsx";

interface SupplierResultLoadingHitProps {
  className?: string;
}
function SupplierResultLoadingHit({
  className,
}: SupplierResultLoadingHitProps) {
  return (
    <div className={clsx("flex flex-col gap-5 border-cp-white-300", className)}>
      <div className="grid gap-4">
        <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-12 w-1/2" />
        <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5" />
      </div>
      <div className="grid gap-3">
        <div className="flex flex-row gap-5 rounded-4 border border-solid border-cp-white-300 p-4 h-20">
          <div className="grid gap-1 h-full w-5/6">
            <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5 w-full" />
            <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5 w-1/2" />
          </div>
          <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5 w-1/6" />
        </div>
        <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5 w-1/3" />
      </div>
    </div>
  );
}

export default SupplierResultLoadingHit;
