import { useMemo } from "react";

import { getRequestID, hasWindow } from "../utils";

export default function useRequestID() {
  const isWindow = hasWindow();
  const requestID = useMemo(() => (isWindow ? getRequestID() : ""), [isWindow]);

  return requestID;
}
