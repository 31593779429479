import { createRoot } from "react-dom/client";

import DocumentExamplesPage from "../components/DocumentExamplesPage";
import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";

import Page from "./Page/Page";

export default function DocumentExamples() {
  return (
    <Page
      pageId=""
      showHeader={false}
      searchSource="document-examples-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <PageSection className="pt-9 pb-22">
        <DocumentExamplesPage />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("document-examples");
if (container) {
  const root = createRoot(container);
  root.render(<DocumentExamples />);
}
