import { captureException, withScope } from "@sentry/browser";

import { ApiError } from "../generated";

export function handleError(
  error: unknown,
  logParams: {
    logToSentry: boolean;
    log400ErrorsToSentry: boolean;
    onLogToSentry?: (error: string) => void;
  } = {
    logToSentry: true,
    log400ErrorsToSentry: true,
  }
) {
  if (
    error instanceof ApiError &&
    logParams.logToSentry &&
    (error.status !== 400 || logParams.log400ErrorsToSentry)
  ) {
    withScope((scope) => {
      scope.setFingerprint([error.url.split("?")[0], String(error.status)]);
      let errorString = error.status.toString();
      if (error.status !== 500) {
        // 500 errors give entire HTML pages as text,
        // so don't include that in the error message
        errorString += `: ${JSON.stringify(error.body)}`;
      }
      captureException(new Error(errorString), {
        extra: { error },
      });
    });
  }

  return true;
}

export function getErrorMessage(error: unknown) {
  if (!(error instanceof ApiError)) return "An error occurred.";
  let fullErrorValue = "";
  for (const [key, value] of Object.entries(error.body)) {
    const error = `There is a problem with ${key}: ${value}\n`;
    fullErrorValue += error;
  }
  return fullErrorValue;
}
