import { useAtom, useAtomValue } from "jotai";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import BLAAutocompleteInput from "../../components/BLAAutocompleteInput";
import type { BuyerProfileTypeEnum, GovernmentAgency } from "../../generated";
import useCookie from "../../hooks/useCookie";
import {
  buyerProfileState,
  userInitializedState,
  userStateState,
  userTypeSignupState,
} from "../../jotai/user";
import {
  DropdownPicker,
  DropdownPickerVariants,
} from "../../library/Dropdown/DropdownPicker";
import { ToggleTabs } from "../../library/ToggleTabs";
import { BUYER_ROLE_FIELDS } from "../../library/form";
import { handleError, patchUserState, postSocialSignup } from "../../utils/api";
import {
  SIGNUP_ORIGIN_COOKIE_KEY,
  SOCIAL_SIGNUP_TYPES,
} from "../../utils/constants";
import {
  type LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackHeapEvent,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../utils/tracking";

import clsx from "clsx";
import type { FormikHelpers, FormikValues } from "formik";
import { useSetAtom } from "jotai";
import type { BuyerLeadAgency } from "../../generated";
import SupplierSignupFormWrapper from "../../library/form/SupplierSignupFormWrapper";
import PrivacyPolicyNotice from "../../shared/PrivacyPolicyNotice";
import SubmitButton from "./SubmitButton";
import {
  POST_SOCIAL_AUTH_SUPPLIER_FIELDS,
  type UserTypeTab,
  userTypeTabData,
} from "./constants";
import { type InferredBLA, WindowType } from "./types";

interface PostSocialAuthFormProps {
  onComplete: (redirectUrl: string) => void;
  provider?: string;
  userEmail?: string;
  parentWindow: WindowType;
  inferredBLA?: InferredBLA;
}

const defaultError = { organization: "", role: "" };

export default function PostSocialAuthForm({
  onComplete,
  provider,
  userEmail,
  parentWindow,
  inferredBLA,
}: PostSocialAuthFormProps) {
  const [isCTALoading, setIsCTALoading] = useState(false);
  const userType = useAtomValue(userTypeSignupState);
  const setUserType = useSetAtom(userTypeSignupState);
  const [errorMessage, setErrorMessage] = useState(defaultError);
  const [buyerProfile, setBuyerProfile] = useAtom(buyerProfileState);
  const [userState, setUserState] = useAtom(userStateState);
  const [userTypeTab, setUserTypeTab] = useState<UserTypeTab>(
    userTypeTabData[userType]
  );
  const isSupplierSignup = userType === userTypeTabData.supplier.type;
  const buyerRoleField = BUYER_ROLE_FIELDS[0];

  const isInitialized = useAtomValue(userInitializedState);
  const [signupOrigin] = useCookie(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only set the buyer profile after the page is initialized.
  useEffect(() => {
    if (!isInitialized || !inferredBLA) return;
    setBuyerProfile({
      ...buyerProfile,
      governmentAffiliationDisplayName: inferredBLA.display_name,
      governmentAgency: {
        id: inferredBLA.id,
        name: inferredBLA.display_name,
      } as unknown as GovernmentAgency,
    });
  }, [isInitialized]);

  const onChangeBLA = (
    name: string | null,
    governmentAgency: BuyerLeadAgency | null
  ) => {
    setBuyerProfile({
      ...buyerProfile,
      governmentAffiliationDisplayName: name || "",
      governmentAgency: governmentAgency as unknown as GovernmentAgency,
    });
    setErrorMessage({ ...errorMessage, organization: "" });
  };

  const onChangeBuyerRole = (buyerProfileType?: BuyerProfileTypeEnum) => {
    setBuyerProfile({
      ...buyerProfile,
      buyerProfileType,
    });
    setErrorMessage({ ...errorMessage, role: "" });
  };

  const validateInput = useCallback(() => {
    const errorMessages: Record<string, string> = {};
    if (!buyerProfile.governmentAffiliationDisplayName) {
      errorMessages.organization = "Agency name is required";
    }

    if (!buyerProfile.buyerProfileType) {
      errorMessages.role = "This field is required.";
    }

    if (Object.keys(errorMessages).length > 0) {
      setErrorMessage({ ...defaultError, ...errorMessages });

      trackSignupFlowFailure({
        emailEntered: userEmail,
        accountType: isSupplierSignup
          ? loginSignupAccountTypes.SUPPLIER
          : loginSignupAccountTypes.BUYER,
        loginType: provider,
        signupStep: loginSignupSteps.SIGNUP,
        error: errorMessages.organization || errorMessages.role,
        loginExperience: parentWindow,
      });

      return false;
    }

    return true;
  }, [
    provider,
    parentWindow,
    userEmail,
    isSupplierSignup,
    buyerProfile.governmentAffiliationDisplayName,
    buyerProfile.buyerProfileType,
  ]);

  async function handleSignup(
    form: FormData,
    entitySelected: string,
    helpers?: FormikHelpers<FormikValues>
  ) {
    const response = await postSocialSignup(form);

    setIsCTALoading(false);
    if (
      handleError(response, { logToSentry: true, log400ErrorsToSentry: false })
    ) {
      trackHeapEvent("social-signup-failure");
      trackSignupFlowFailure({
        emailEntered: userEmail,
        loginType: provider,
        signupStep: loginSignupSteps.SIGNUP,
        error: "Social signup failed",
        loginExperience: parentWindow,
      });

      if (helpers && response.status === 400) {
        const error = await response.json();
        if (error?.phoneNumber) {
          helpers.setFieldError("phone", error.phoneNumber[0].message);
        }
      }
      return;
    }
    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      accountType: isSupplierSignup
        ? loginSignupAccountTypes.SUPPLIER
        : loginSignupAccountTypes.BUYER,
      loginType: provider as unknown as LoginType,
      emailEntered: userEmail,
      entitySelected,
      signupOrigin,
    });
    Cookies.remove(SIGNUP_ORIGIN_COOKIE_KEY);
    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    onComplete(response.url);
  }

  async function submitBuyerInfo() {
    const isValid = validateInput();
    if (!isValid) return false;

    setIsCTALoading(true);
    const form = new FormData();
    form.append(
      "governmentAffiliation",
      buyerProfile.governmentAffiliationDisplayName || ""
    );
    form.append("signUpType", SOCIAL_SIGNUP_TYPES.BUYER);
    form.append("buyerRole", buyerProfile.buyerProfileType || "");
    if (buyerProfile.governmentAgency?.id) {
      form.append("blaID", buyerProfile.governmentAgency.id);
    }
    await handleSignup(
      form,
      buyerProfile.governmentAffiliationDisplayName || ""
    );
  }

  async function submitSupplierInfo(
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) {
    setIsCTALoading(true);
    const form = new FormData();
    form.append("supplierHandle", values.supplier?.handle || "");
    form.append("supplierName", values.supplier?.displayName || "");
    form.append("signUpType", SOCIAL_SIGNUP_TYPES.SUPPLIER);
    form.append("supplierRole", values.supplierRole || "");
    if (values.phone) form.append("phoneNumber", values.phone);
    if (values.phoneExtension)
      form.append("phoneExtension", values.phoneExtension);
    await handleSignup(form, values.supplier?.displayName || "", helpers);
  }

  function trackInvalidForm(error: Record<string, string>) {
    trackSignupFlowFailure({
      emailEntered: userEmail,
      accountType: loginSignupAccountTypes.SUPPLIER,
      loginType: provider,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  function onSelectTab(index?: number) {
    if (index === userTypeTabData.supplier.index) {
      setUserTypeTab(userTypeTabData.supplier);
      setUserType(userTypeTabData.supplier.type);
    } else if (index === userTypeTabData.buyer.index) {
      setUserTypeTab(userTypeTabData.buyer);
      setUserType(userTypeTabData.buyer.type);
    }
  }

  const btnClassName = "analytics-post-sociallogin-modal-cta !w-full";
  return (
    <>
      <div className="flex flex-col gap-6 w-full">
        <ToggleTabs
          onSelectTab={onSelectTab}
          defaultTabIndex={userTypeTab.index}
        >
          <div label={userTypeTabData.buyer.label} />
          <div label={userTypeTabData.supplier.label} />
        </ToggleTabs>
        {isSupplierSignup ? (
          <>
            <SupplierSignupFormWrapper
              fields={POST_SOCIAL_AUTH_SUPPLIER_FIELDS}
              initialValues={{ phone: null, phoneExtension: null }}
              preValidatedFields={[]}
              lockedFields={[]}
              submitCta="Create your account"
              submitClassName={btnClassName}
              onSubmit={submitSupplierInfo}
              disabled={isCTALoading}
              trackInvalidForm={trackInvalidForm}
            />
          </>
        ) : (
          <div className="flex flex-col gap-6 relative">
            <div className="relative" data-testid="bla-input-container">
              <BLAAutocompleteInput
                onChange={onChangeBLA}
                initialId={inferredBLA?.id || undefined}
                initialValue={inferredBLA?.display_name || ""}
                analyticsClass="analytics-post-sociallogin-modal-govAffiliation-input text-left"
                errorMessage={errorMessage.organization}
                dataTestId="bla-input"
              />
            </div>
            <DropdownPicker
              className="flex-grow w-full"
              onChange={(e) => {
                onChangeBuyerRole(e as BuyerProfileTypeEnum);
              }}
              label={buyerRoleField.label}
              placeholder={buyerRoleField.placeholder}
              options={buyerRoleField.options}
              dataTestId={buyerRoleField.dataTestId}
              renderSelected={buyerRoleField.renderSelected}
              initialValue={buyerProfile.buyerProfileType as string}
              variant={
                errorMessage.role
                  ? DropdownPickerVariants.ERROR
                  : DropdownPickerVariants.DEFAULT
              }
              message={errorMessage.role}
            />
            <SubmitButton
              isCTALoading={isCTALoading}
              handleSubmit={submitBuyerInfo}
              ctaText="Create your account"
              btnClassName={btnClassName}
              dataTestId="submit-create-account"
            />
          </div>
        )}
      </div>
      <PrivacyPolicyNotice
        className={clsx({ "mt-10": parentWindow === WindowType.Modal })}
        size="sm"
      />
    </>
  );
}
