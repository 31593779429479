import { createRoot } from "react-dom/client";

import QuoteRequestPage from "../components/QuoteRequest/QuoteRequestPage";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface QuoteRequestProps extends AuthPageProps {
  isSSR?: boolean;
}
export default function QuoteRequest({
  is_authenticated,
  profile_type,
  active_agreements,
  isSSR = true,
}: QuoteRequestProps) {
  return (
    <Page
      searchSource="quote-request-header-search-bar"
      isAuthenticated={is_authenticated}
      pageType={PageType.QUOTE_REQUEST}
      profileType={profile_type}
      activeAgreements={active_agreements}
      isSSR={isSSR}
    >
      <QuoteRequestPage />
    </Page>
  );
}

const container = getDOMAnchorById("quote-request");
if (container) {
  const root = createRoot(container);
  root.render(<QuoteRequest isSSR={false} />);
}
