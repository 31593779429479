import { captureException } from "@sentry/browser";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import * as yup from "yup";

import {
  governmentAffiliationDisplayNameLocalState,
  isAuthenticatedState,
  userEmailState,
} from "../jotai/user";
import { CheckboxField, NAME_FIELDS, TextareaField } from "../library/form";
import LabeledInputField from "../library/form/LabeledInputField";
import { EMAIL_REGEX } from "../utils/constants";

export const defaultContactFields = (
  messagePlaceholder: string,
  supplierDisplayName: string
) => [
  ...NAME_FIELDS,
  ...baseContactFields(messagePlaceholder),
  {
    name: "ccSupplier",
    label: `Copy ${supplierDisplayName} on message`,
    component: CheckboxField,
    validate: yup.boolean(),
  },
];

export const baseContactFields = (messagePlaceholder: string) => [
  {
    name: "email",
    label: "Email",
    component: LabeledInputField,
    validate: yup
      .string()
      .matches(EMAIL_REGEX, "A valid email is required.")
      .required("Email is required."),
  },
  {
    name: "publicAgency",
    label: "Public Agency",
    component: LabeledInputField,
    validate: yup.string().required("Public Agency is required."),
  },
  {
    name: "message",
    placeholder: messagePlaceholder || "Your message...",
    component: TextareaField,
    validate: yup.string().required("Message is required."),
  },
];

export function useSubmitAndSave<T extends {}>(
  hideModal: () => void,
  fn: (values: T) => void | Promise<void>
): [(values: T) => void, boolean] {
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setEmail = useSetAtom(userEmailState);
  const setPublicAgency = useSetAtom(
    governmentAffiliationDisplayNameLocalState
  );

  async function handleSubmit(values: T) {
    setLoading(true);
    try {
      await fn(values);
      if (!isAuthenticated) {
        if ("email" in values) setEmail(values.email as string);
        if ("publicAgency" in values)
          setPublicAgency(values.publicAgency as string);
      }
    } catch (err) {
      captureException(err);
    }
    setLoading(false);
    hideModal();
  }

  return [handleSubmit, loading];
}
