import { Avatar, AvatarSizes, Typography } from "../../../library";
import Logo, { LogoSizes } from "../../../library/Logo";
import { formatDate, parseDate } from "../../../utils/date";

interface ProSupplierPageHeaderProps {
  dateViewsStart: string;
  supplierName: Maybe<string>;
  logoUrl?: string;
}
export default function ProSupplierPageHeader({
  supplierName,
  logoUrl,
  dateViewsStart,
}: ProSupplierPageHeaderProps) {
  return (
    <div className="col-span-1 flex flex-row gap-x-5 items-center">
      {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
      {!logoUrl && supplierName && (
        <Avatar
          className="min-w-[64px]"
          monogram={supplierName[0]}
          size={AvatarSizes.XL}
          bgColor="success.bold.primary.hovered"
        />
      )}
      <div className="py-2">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
          >
            Your qualified entity views
          </Typography>
          <Typography variant="meta" size="sm" color="neutral.bold.enabled">
            Views start on {formatDate(parseDate(dateViewsStart), "MM/dd/yyyy")}{" "}
            &middot; Updated live
          </Typography>
        </div>
      </div>
    </div>
  );
}
