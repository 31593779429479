import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import { Link, Typography } from "../../library";
import type { SearchSource } from "../../utils/enums";
import Modal from "../Modal/Modal";
import SearchBar, { SearchBarSizes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

const searches = [
  "Playground equipment",
  "Panasonic AG-HVX205",
  "Caterpillar",
  "IT Consulting",
  "Classroom desk",
];

interface SearchModalProps {
  hideModal: () => void;
  source?: SearchSource;
  isLocationRelevant?: boolean;
}

export default function SearchModal({
  hideModal,
  source,
  isLocationRelevant,
}: SearchModalProps) {
  const search = useSearchContractWithParams();

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-search-modal"
      title="Search on Pavilion"
      subtitle="Search contracts from public entities near you and trusted national sources, all in one place for free."
    >
      <SearchBar
        theme={SearchBarThemes.DARK}
        disableAutocomplete
        size={SearchBarSizes.RESPONSIVE}
        isLocationRelevant={isLocationRelevant}
        searchSource={source}
        onSearch={(args) => {
          search(args);
          hideModal();
        }}
        disambiguate
      />
      <div className="flex flex-col justify-center">
        <Typography
          component="span"
          color="subtle"
          className="text-center mt-8 mb-4"
        >
          Not sure yet? Try one of these searches:
        </Typography>
        <div className="max-w-md text-center mr-auto ml-auto">
          {searches.map((search) => (
            <Link
              underline={false}
              color="brand.bold"
              className={`analytics-${source}-search-steps-query-suggestion inline-block py-2 px-3 bg-cp-violet-300 rounded-xl m-1`}
              key={search}
              href={`/contract-search?query=${search}`}
            >
              {search}
            </Link>
          ))}
        </div>
      </div>
    </Modal>
  );
}
