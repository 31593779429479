import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import * as yup from "yup";

import HeartContract from "../../../img/icons/contracts-heart.svg";
import useShowModal from "../../hooks/useShowModal";
import { profileTypeState, userAdminState } from "../../jotai/user";
import { Typography } from "../../library";
import type {
  FormFieldProps,
  SubmitFn,
  Validate,
} from "../../library/form/types";
import { fieldMapFn } from "../../library/form/utils";
import SubmitButton from "../../modals/SignupSteps/SubmitButton";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { handleError, postSupplierContractUpload } from "../../utils/api";
import { CONTRACT_EMAIL_ADDRESS } from "../../utils/constants";
import { modals } from "../../utils/enums";
import { trackUploadContractSubmit } from "../../utils/tracking";
import { goToHomePage } from "../Account/helpers";

interface UploadContractsFormValues {
  contractFiles: File[];
  contractNumber: string;
  buyerLeadAgency: {
    governmentAffiliationDisplayName: string;
  };
  expirationDate: string;
  contractTitle: string;
  supplierHandle?: string;
}

interface UploadContractsFormProps {
  fileUploadFields: FormFieldProps[];
  metadataFields: FormFieldProps[];
  supplierHandle: string;
}
export default function UploadContractsForm({
  fileUploadFields,
  metadataFields,
  supplierHandle,
}: UploadContractsFormProps) {
  const showModal = useShowModal(modals.CONTRACT_UPLOAD_MESSAGE);
  const hideModal = useShowModal("");
  const profileType = useAtomValue(profileTypeState);
  const isAdmin = useAtomValue(userAdminState);

  const showConfirmationModal = () => {
    showModal({
      title: "Contract uploaded!",
      message:
        "Your account executive will manually review the documents before publishing them. You should see this contract live on Pavilion in 2-4 business days.",
      image: HeartContract,
      isBlocking: true,
      primaryCta: {
        title: "Upload another contract",
        onClick: () => window.location.reload(),
        className: "analytics-upload-another-contract",
      },
      secondaryCta: {
        title: "Close",
        onClick: () => goToHomePage(),
      },
    });
  };

  const showUploadErrorModal = () => {
    showModal({
      title: "Contract upload failed",
      message: (
        <>
          Please try again. If you continue to have trouble, please contact{" "}
          <SupportEmailLink
            underline
            className="analytics-pro-supplier-contact-support"
            email={CONTRACT_EMAIL_ADDRESS}
          />
        </>
      ),
      primaryCta: {
        title: "Close",
        onClick: () => hideModal({}),
      },
    });
  };

  const validationSchema = useMemo(() => {
    return [...fileUploadFields, ...metadataFields].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [fileUploadFields, metadataFields]);

  const initialValues: UploadContractsFormValues = {
    contractFiles: [],
    contractNumber: "",
    buyerLeadAgency: { governmentAffiliationDisplayName: "" },
    expirationDate: "",
    contractTitle: "",
  };

  const handleSubmit: SubmitFn<UploadContractsFormValues> = async (values) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedValues: Record<string, string | File> = {
      contractNumber: values.contractNumber,
      buyerLeadAgency: values.buyerLeadAgency.governmentAffiliationDisplayName,
      expiration: values.expirationDate,
      title: values.contractTitle,
    };
    values.contractFiles.forEach((file, index) => {
      Object.assign(parsedValues, { [`file${index}`]: file });
    });
    const uploadFormData = new FormData();
    for (const key in parsedValues) {
      uploadFormData.append(key, parsedValues[key]);
    }
    const response = await postSupplierContractUpload(
      uploadFormData,
      supplierHandle
    );

    const trackData = {
      supplierHandle,
      fileNames: values.contractFiles.map((file) => file.name),
      fileTypes: values.contractFiles.map((file) => file.type),
      contractNumber: values.contractNumber,
      uploader: isAdmin ? "admin" : profileType || "",
      leadAgency: values.buyerLeadAgency.governmentAffiliationDisplayName,
      expiration: values.expirationDate,
      title: values.contractTitle,
    };

    if (
      handleError(response, {
        log400ErrorsToSentry: true,
        logToSentry: true,
        onLogToSentry: (error: string) =>
          trackUploadContractSubmit({
            ...trackData,
            error,
          }),
      })
    ) {
      showUploadErrorModal();
      return false;
    }
    trackUploadContractSubmit(trackData);
    showConfirmationModal();
    return true;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit({ ...values, supplierHandle })}
      validationSchema={yup.object(validationSchema)}
    >
      {({ touched, isSubmitting, isValid }) => (
        <Form>
          <div className="flex flex-col w-full gap-6 mb-4">
            <div className="grid grid-cols-1 md:grid-cols-8 justify-between gap-x-6 gap-y-8">
              <div className="flex flex-col gap-4 w-full col-span-5">
                <div className="flex flex-col gap-6">
                  <Typography
                    variant="headline"
                    size="xs"
                    color="brand.boldest.enabled"
                    emphasis
                  >
                    Step 1: Add contract documents
                  </Typography>
                  <div className="flex flex-col gap-2">
                    {fileUploadFields.map(fieldMapFn)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-8 w-full col-span-3">
                <div className="flex flex-col gap-6">
                  <Typography
                    variant="headline"
                    size="xs"
                    color="brand.boldest.enabled"
                    emphasis
                  >
                    Step 2: Add contract metadata
                  </Typography>
                  <div className="flex flex-col gap-5 w-full">
                    {metadataFields.map(fieldMapFn)}
                  </div>
                </div>
                <div className="flex flex-col gap-3 items-start">
                  <Typography
                    variant="headline"
                    size="xs"
                    color="brand.boldest.enabled"
                    emphasis
                  >
                    Step 3: Upload contract
                  </Typography>
                  {(!isValid || !Object.keys(touched).length) && (
                    <Typography size="sm" color="neutral.bolder.enabled">
                      Add at least one document in order to upload a contract to
                      Pavilion.
                    </Typography>
                  )}
                  <SubmitButton
                    btnClassName="w-fit"
                    isCTALoading={isSubmitting}
                    ctaText="Upload contract"
                    disabled={!isValid || Object.keys(touched).length === 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
