import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import * as yup from "yup";

import useSendEmailVerification from "../../hooks/useSendEmailVerification";
import {
  userDetailsState,
  userEmailVerifiedState,
  userSocialAccountProviderState,
} from "../../jotai/user";
import { Button, Checkbox, Link, Typography } from "../../library";
import LabeledInputField from "../../library/form/LabeledInputField";
import { MODAL_SOURCE } from "../../utils/enums";
import {
  type SocialProvider,
  socialProvidersDetails,
} from "../../utils/social";

import { AccountsService } from "../../generated";
import { handleError } from "../../utils/generatedApi";
import { EMAIL_VALIDATION } from "./constants";

interface EmailSectionProps {
  setError: (error: string) => void;
  setSuccess: (success: string) => void;
}

export default function EmailSection({
  setError,
  setSuccess,
}: EmailSectionProps) {
  const [{ email, ...userDetails }, setUserDetails] = useAtom(userDetailsState);
  const emailVerified = useAtomValue(userEmailVerifiedState);
  const socialAccountProvider = useAtomValue(userSocialAccountProviderState);
  const [showPassword, setShowPassword] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidRequest, setInvalidRequest] = useState("");

  const validationSchema = { newEmail: EMAIL_VALIDATION };

  async function updateEmail(values: { newEmail: string; password: string }) {
    setIsLoading(true);
    if (email === values.newEmail) {
      setInvalidRequest("New email entered is the same as old email.");
      setIsLoading(false);
      return;
    }
    try {
      await AccountsService.accountsEmailChangeCreate(values);
      setUserDetails({ email: values.newEmail, ...userDetails });
      setSuccess(
        "Account information saved! Check your inbox to verify your email address."
      );
      setEditable(false);
      setIsLoading(false);
    } catch (err) {
      handleError(err);
      setInvalidRequest(
        "Invalid email or password. The new email address may also already exist."
      );
      setIsLoading(false);
    }
  }

  const sendEmail = useSendEmailVerification({
    source: MODAL_SOURCE.PROFILE_PAGE,
    onError: setError,
  });

  function getVerifyEmailLink() {
    return (
      !emailVerified &&
      !editable && (
        <Link
          className="analytics-verify-email-from-profile ml-1"
          onClick={() =>
            sendEmail(() => {
              window.location.href = "/accounts/email/verification-sent";
            })
          }
          underline={false}
          emphasis={false}
          newWindow={false}
        >
          (Verify Email)
        </Link>
      )
    );
  }

  // User with social account shouldn't be able to change their email, only show email and verify link
  if (socialAccountProvider) {
    return (
      <>
        <div className="inline-flex w-full mb-2 items-center">
          <Typography emphasis>Email</Typography>
        </div>
        <div className="inline-flex w-full mb-6 items-center">
          <Typography size="sm" color="subtler">
            Managed by{" "}
            {
              socialProvidersDetails[socialAccountProvider as SocialProvider]
                .displayName
            }
          </Typography>
        </div>
        {email}
        {getVerifyEmailLink()}
      </>
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{ newEmail: "", password: "" }}
        onSubmit={(values) => {
          updateEmail(values);
        }}
        validationSchema={yup.object(validationSchema)}
      >
        {({ handleReset }) => (
          <>
            <div className="flex justify-between h-[36px]">
              <Typography
                variant="headline"
                size="sm"
                color="brand.default.secondary.enabled"
                emphasis
              >
                Email
              </Typography>
              {!editable && (
                <Button
                  size={Button.sizes.SMALL}
                  theme={Button.themes.TERTIARY_DARK}
                  className="analytics-profile-edit-email cursor-pointer"
                  onClick={() => {
                    setEditable(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
            <Form>
              <div
                className={clsx({
                  "pointer-events-none opacity-50": isLoading,
                })}
              >
                <div className="flex flex-col mt-6 gap-2">
                  {editable ? (
                    <Field
                      name="newEmail"
                      label="New Email Address"
                      component={LabeledInputField}
                      validate={EMAIL_VALIDATION}
                      editable
                      size="md"
                      dataTestId="edit-email-field"
                    />
                  ) : (
                    <span>
                      {email}
                      {getVerifyEmailLink()}
                    </span>
                  )}
                </div>
                {editable && (
                  <div className="flex flex-col my-6 gap-2">
                    <Field
                      name="password"
                      component={LabeledInputField}
                      label="Confirm your Pavilion password"
                      type={showPassword ? "text" : "password"}
                      size="md"
                      dataTestId="edit-email-enter-password"
                      editable
                    />
                    <Checkbox
                      name="showPassword"
                      label={
                        <Typography
                          color="neutral.bolder.enabled"
                          variant="meta"
                        >
                          Show password
                        </Typography>
                      }
                      onChange={() => setShowPassword(!showPassword)}
                      checked={showPassword}
                    />
                  </div>
                )}
                {editable && (
                  <>
                    <Button
                      type="submit"
                      size={Button.sizes.SMALL}
                      dataTestId="profile-edit-email-btn"
                      className="analytics-change-email-submit mt-4"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        handleReset();
                        setInvalidRequest("");
                        setEditable(false);
                      }}
                      size={Button.sizes.SMALL}
                      theme={Button.themes.TERTIARY_DARK}
                      className="analytics-change-email-cancel mt-4"
                    >
                      Cancel
                    </Button>
                    {invalidRequest && (
                      <Typography
                        className="mt-2"
                        color="destructive.default.primary.enabled"
                        emphasis
                      >
                        Error changing email. {invalidRequest}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
