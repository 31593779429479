import clsx from "clsx";

import type {
  SupplierCompliance,
  SupplierServiceAreaData,
} from "../../../generated";
import { ItemTypeEnum } from "../../../generated";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import Avatar, { AvatarSizes } from "../../../library/Avatar";
import Logo, { LogoSizes } from "../../../library/Logo";
import SaveToProjectButton from "../../../shared/SaveToProjectButton";
import SupplierTagSubsection from "../../../shared/SupplierCard/SupplierTagSubsection";
import { getFormattedTagsForSupplier } from "../../../shared/SupplierCard/utils";
import { ProjectCreationSource } from "../../Project/types";

interface SupplierHeaderProps {
  title: string;
  supplierName?: string;
  supplierId: number;
  supplierActiveAgreements?: string[];
  supplierServiceArea: SupplierServiceAreaData;
  supplierCompliance?: SupplierCompliance;
  satUrl?: string;
  logoUrl?: string;
  className?: string;
  showSaveToProject?: boolean;
}

export default function SupplierHeader({
  title,
  supplierName,
  supplierId,
  supplierActiveAgreements,
  supplierCompliance,
  supplierServiceArea,
  className,
  satUrl,
  logoUrl,
  showSaveToProject = false,
}: SupplierHeaderProps) {
  return (
    <div
      className={clsx("flex col-start-1 col-end-10 justify-between", className)}
    >
      <div className="flex gap-x-5 items-center">
        {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
        {!logoUrl && supplierName && (
          <Avatar
            className="min-w-[64px]"
            monogram={supplierName[0]}
            size={AvatarSizes.XL}
            bgColor="success.bold.primary.hovered"
          />
        )}
        <div className="flex flex-col gap-y-2">
          <Typography variant="headline" size="lg" color="brand.bold">
            {title}
          </Typography>
          <SupplierTagSubsection
            tags={getFormattedTagsForSupplier({
              compliance: supplierCompliance,
              serviceArea: supplierServiceArea,
              activeAgreements: supplierActiveAgreements,
            })}
          />
        </div>
        {satUrl && (
          <Button
            as={Link}
            linkProps={{
              href: satUrl,
              underline: false,
            }}
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.SECONDARY_DARK}
          >
            SAT 🪑
          </Button>
        )}
      </div>
      {showSaveToProject && (
        <SaveToProjectButton
          itemId={supplierId.toString()}
          itemType={ItemTypeEnum.SUPPLIER}
          source={ProjectCreationSource.SUPPLIER_PAGE}
        />
      )}
    </div>
  );
}
