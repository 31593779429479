import clsx from "clsx";
import useShowModal from "../../../../hooks/useShowModal";
import { Button, Typography } from "../../../../library";
import Logo from "../../../../library/Logo";
import { modals } from "../../../../utils/enums";
import type { Supplier } from "../types";

interface SupplierLogoSectionProps {
  handle: string;
  currentSupplier: Supplier;
  setCurrentSupplier: (supplier: Supplier) => void;
}

export default function SupplierLogoSection({
  currentSupplier,
  setCurrentSupplier,
  handle,
}: SupplierLogoSectionProps) {
  const showModal = useShowModal(modals.LOGO_UPLOAD);

  const onLogoChange = (logoUrl: string) => {
    setCurrentSupplier({
      ...currentSupplier,
      logoUrl,
    });
  };

  return (
    <div>
      <Typography
        emphasis
        size="sm"
        variant="headline"
        color="brand.default.secondary.enabled"
        className="mb-5"
      >
        Logo Upload
      </Typography>
      <div
        className={clsx("flex flex-col gap-10", {
          "items-center": currentSupplier.logoUrl,
        })}
      >
        {currentSupplier.logoUrl && <Logo imageSrc={currentSupplier.logoUrl} />}
        <Button
          size={Button.sizes.SMALL}
          theme={Button.themes.SECONDARY_DARK}
          className="analytics-add-supplier-logo h-fit w-fit"
          onClick={() => {
            showModal({
              onLogoChange,
              handle,
              logoUrl: currentSupplier.logoUrl,
            });
          }}
        >
          {currentSupplier.logoUrl ? "Edit logo" : "Add logo +"}
        </Button>
      </div>
    </div>
  );
}
