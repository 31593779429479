import clsx from "clsx";
import { useAtomValue } from "jotai";
import { searchQueryState } from "../../../jotai/search";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { elevationClass } from "../../../utils/designTokens";
import { trackViewContractCatalog } from "../../../utils/tracking";

export default function ContractCatalogCTA({
  contractCatalogUrl,
  contractId,
  supplierId,
}: {
  contractCatalogUrl: string;
  contractId: string;
  supplierId: number;
}) {
  const query = useAtomValue(searchQueryState);
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-2 w-full md:max-w-108 rounded-6 h-fit",
        elevationClass["elevation-1"]
      )}
    >
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.default.secondary.enabled"
      >
        Browse catalog
      </Typography>
      <Typography size="sm" color="neutral.bolder.enabled">
        Browse this supplier's catalog to see prices, get instant quotes, and
        place orders online.
      </Typography>
      <Button
        as="a"
        theme={Button.themes.PRIMARY_DARK}
        className="mt-4"
        onClick={() => {
          trackViewContractCatalog({
            contractId,
            supplierId,
            query,
            destinationUrl: contractCatalogUrl,
          });
        }}
        linkProps={{
          href: contractCatalogUrl,
          target: "_blank",
          rel: "noreferrer",
        }}
      >
        View catalog with prices
      </Button>
    </CardContainer>
  );
}
