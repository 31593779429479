import { atomWithStorage } from "jotai/utils";

import type { BaseSupplier, SupplierAgreementData } from "../generated";
import { createSessionStorage } from "./util";

export interface MessageSupplierData {
  contact?: { fullName?: string; title?: Maybe<string> };
  supplier: BaseSupplier;
  supplierAgreement: SupplierAgreementData;
  contractId?: string;
  contractIsPro?: boolean;
  saved?: boolean;
}

interface SharedMessageSupplierProps {
  ccEmails: string[];
  /**
   * Suppliers that the user messaged most recently for this contract. Used for:
   * 1. success page copy (`we messaged ${supplier} and ${messagedSuppliers.length} others`
   * 2. pass to getSolicitationOthers
   */
  messagedSuppliers: MessageSupplierData[];

  /**
   * The suppliers/solicitations we showed on the message success page.
   * Populates the "add more suppliers" modal on the post-success message page.
   */
  recommendedSuppliers: MessageSupplierData[];
  /**
   * Selected from our recommendations on the message success page.
   * Populates the initial SupplierChipInput on the post-success message page.
   */
  recommendedSuppliersToMessage: MessageSupplierData[];
}

interface MessageSupplierFromSupplierProps extends SharedMessageSupplierProps {
  rootSupplierHandle: string;
}

interface MessageSupplierFromContractProps extends SharedMessageSupplierProps {
  rootDocid: string;
  /**
   * Used for initialList in getSolicitationOthers
   */
  selectedContractIds: string[];
}

// The key should be the root doc id or root supplier handle
type MessageSupplierStore = Record<
  string,
  MessageSupplierFromSupplierProps | MessageSupplierFromContractProps
>;

const messageSessionStorage = createSessionStorage<MessageSupplierStore>();
export const messageSupplierStoreAtom = atomWithStorage<MessageSupplierStore>(
  "messageSupplierStore",
  {},
  messageSessionStorage,
  { getOnInit: true }
);
