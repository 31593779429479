interface ContractLeadSubtitleProps {
  coop: Maybe<string>;
  bla?: string;
  isCooperative?: boolean;
}

export default function ContractLeadSubtitle({
  coop,
  bla,
  isCooperative,
}: ContractLeadSubtitleProps) {
  /**
   * Default: “[Coop] shareable contract led by [BLA]”
   * If cooperative, but no BLA: “Shareable contract from [coop or entity]”
   * If non-cooperative: “Contract from [coop or entity]”
   */
  if (!isCooperative) {
    return (
      <div className="line-clamp-2">
        Contract from <span className="font-semibold">{coop || bla}</span>
      </div>
    );
  }
  if (!bla || !coop) {
    return (
      <div className="line-clamp-2">
        Shareable contract from{" "}
        <span className="font-semibold">{coop || bla}</span>
      </div>
    );
  }
  return (
    <div className="line-clamp-2">
      <span className="font-semibold">{coop}</span> shareable contract led by{" "}
      <span className="font-semibold">{bla}</span>
    </div>
  );
}
